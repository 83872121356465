import React from "react";
import PropTypes from 'prop-types';

class ArticleTitle extends React.Component {
    render() {
        const {pageDef, level, ...props} = this.props;
        const {id, title} = pageDef;
        const Tag = `h${level}`;

        return (
            <Tag id={id} {...props}>{title}{this.props.children}</Tag>
        );
    }
}

ArticleTitle.propTypes = {
    pageDef: PropTypes.object,
    level: PropTypes.number
};

export default ArticleTitle;