import React from "react";
import Pie from "@/components/Charts/Pie";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {buildChartDataSet, createPieChartOptions} from "@/utilities/dashboard";

class MedicalDentalChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createPieChartOptions("Missions by Category")
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {bjmpTotal, externalTotal} = this.props;

        return bjmpTotal !== nextProps.bjmpTotal || externalTotal !== nextProps.externalTotal;
    }

    buildDataSet = () => {
        const {bjmpTotal, externalTotal} = this.props;

        return buildChartDataSet(["BJMP-Led", "External Partners"],
            [bjmpTotal, externalTotal],
            "Missions",
            ["#f59fad", "#f5df9b"],
            ["#fff", "#fff"],
            0.8
        );
    };

    render() {
        const dataSet = this.buildDataSet();
        return (
            <div className="j-dash-treatment-pie-chart">
                <Pie data={dataSet} options={this.chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        )
    }
}

export default MedicalDentalChart;