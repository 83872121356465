import React from "react";
import Article from "@/screens/Docs/Article";
import ArticleImage from "@/screens/Docs/Article/ArticleImage";
import UserTag from "@/screens/AccessManagement/Users/Tag";
import Tag from "@/screens/AccessManagement/Roles/Tag";
import acc01 from './images/acc-01.jpg';
import acc02 from './images/acc-02.jpg';
import acc03 from './images/acc-03.jpg';
import acc04 from './images/acc-04.jpg';
import acc05 from './images/acc-05.jpg';
import acc06 from './images/acc-06.jpg';
import acc07 from './images/acc-07.jpg';
import acc08 from './images/acc-08.jpg';
import acc09 from './images/acc-09.jpg';
import acc10 from './images/acc-10.jpg';
import {createPageDefinition} from "@/screens/Docs/utilities";
import ArticleTitle from "@/screens/Docs/Article/ArticleTitle";

const P_ACCESS = 'access-mgt';
const H_OVERVIEW = createPageDefinition(P_ACCESS, 'overview', 'Overview');
const H_OVERVIEW_TAGS = createPageDefinition(P_ACCESS, 'overview-tags', 'Common Access Management Tags');
const H_USERS = createPageDefinition(P_ACCESS, 'users', 'Users');
const H_USERS_ACTIONS = createPageDefinition(P_ACCESS, 'users-actions', 'User Actions');
const H_USERS_FIELDS = createPageDefinition(P_ACCESS, 'users-fields', 'User Fields');
const H_USERS_CREATE_WINDOW = createPageDefinition(P_ACCESS, 'users-create-confirmation', 'Create User Confirmation Window');
const H_USERS_RESET_PASS = createPageDefinition(P_ACCESS, 'users-reset-pass', 'Reset Password');
const H_USERS_ACT_STATUS = createPageDefinition(P_ACCESS, 'users-activate-deactivate', 'Activate/Deactivate an Account');
const H_ROLES = createPageDefinition(P_ACCESS, 'roles', 'Roles');
const H_ROLES_ACTIONS = createPageDefinition(P_ACCESS, 'roles-actions', 'Role Actions');
const H_ROLES_FIELDS = createPageDefinition(P_ACCESS, 'roles-fields', 'Role Fields');
const H_OFFICES = createPageDefinition(P_ACCESS, 'offices', 'Offices');
const H_OFFICES_ACTIONS = createPageDefinition(P_ACCESS, 'offices-actions', 'Office Actions');
const H_OFFICES_FIELDS = createPageDefinition(P_ACCESS, 'offices-fields', 'Office Fields');

const contents = [
    {
        ...H_OVERVIEW,
        contents: [
            H_OVERVIEW_TAGS
        ]
    },
    {
        ...H_USERS,
        contents: [
            H_USERS_ACTIONS,
            H_USERS_FIELDS,
            H_USERS_CREATE_WINDOW,
            H_USERS_RESET_PASS,
            H_USERS_ACT_STATUS
        ]
    },
    {
        ...H_ROLES,
        contents: [
            H_ROLES_ACTIONS,
            H_ROLES_FIELDS
        ]
    },
    {
        ...H_OFFICES,
        contents: [
            H_OFFICES_ACTIONS,
            H_OFFICES_FIELDS
        ]
    }
];

class AccessManagement extends React.Component {
    render() {
        return (
            <div className="row">
                <Article title="Access Management" id={P_ACCESS}
                         contentTitle="The access management page is where you can manage user accounts, roles, and offices."
                         contents={contents}>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_OVERVIEW}/>
                        <ArticleImage src={acc01} alt="Access Management Overview"/>
                        <ol>
                            <li>
                                <strong>Page Navigation</strong>
                                <p>Navigate through the different access management pages</p>
                            </li>
                            <li>
                                <strong>Access Management Page</strong>
                                <p>Displays the currently selected access management page</p>
                            </li>
                        </ol>
                        <ArticleTitle level={4} pageDef={H_OVERVIEW_TAGS}/>
                        <p>The access management tags indicate the state of an entry.</p>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Status</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><UserTag active={true}/></td>
                                <td>Indicates that an entry is currently active.</td>
                            </tr>
                            <tr>
                                <td><UserTag active={false}/></td>
                                <td>Indicates that an entry is currently inactive.</td>
                            </tr>
                            <tr>
                                <td><Tag type="system" label="System Preset"/></td>
                                <td>Denotes that an entry is a system default. System preset
                                    entries are not editable or deletable.</td>
                            </tr>
                            <tr>
                                <td><Tag type="in-use" label="In Use"/></td>
                                <td>Indicates that an entry is currently in use. Entries that are currently
                                    in use can be deleted but not deleted.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_USERS}/>
                        <p>You can manage user accounts in this page.</p>
                        <ArticleTitle level={4} pageDef={H_USERS_ACTIONS}/>
                        <ol>
                            <li>To create a new user, click the <code>Create New User</code> button.</li>
                            <li>To edit a user, click the <code>Edit</code> button.</li>
                        </ol>
                        <ArticleImage src={acc02} alt="User Actions"/>
                        <ArticleTitle level={4} pageDef={H_USERS_FIELDS}/>
                        <ArticleImage src={acc03} alt="User Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Username</strong></td>
                                <td>Must be a unique username.</td>
                            </tr>
                            <tr>
                                <td><strong>First Name</strong></td>
                                <td>User's first name</td>
                            </tr>
                            <tr>
                                <td><strong>Middle Name</strong></td>
                                <td>User's middle name. This field is optional.</td>
                            </tr>
                            <tr>
                                <td><strong>Last Name</strong></td>
                                <td>User's last name</td>
                            </tr>
                            <tr>
                                <td><strong>Office Type</strong></td>
                                <td>The office type for this user account. This field
                                    only appears on headquarters and regional administrator screens.</td>
                            </tr>
                            <tr>
                                <td><strong>Office</strong></td>
                                <td>Office for this user account. This field only appears
                                    on headquarters and regional administrator screens.</td>
                            </tr>
                            <tr>
                                <td><strong>Roles</strong></td>
                                <td>Select the roles to give this user account. Each role is
                                    assigned multiple permissions, which gives user abilities to use one or more
                                    system features.</td>
                            </tr>
                            <tr>
                                <td><strong>Effective Permissions</strong></td>
                                <td>Permissions that this user account will receive
                                    depending on the selected roles.</td>
                            </tr>
                            </tbody>
                        </table>
                        <ArticleTitle level={4} pageDef={H_USERS_CREATE_WINDOW}/>
                        <p>
                            This window appears upon successful creation of a new user. You can copy the user's
                            generated temporary password in this window.
                        </p>
                        <ArticleImage src={acc04} alt="Create User Confirmation Window"/>
                        <ArticleTitle level={4} pageDef={H_USERS_RESET_PASS}/>
                        <p>
                            To reset a user's password, click on the <code>Reset Password</code> button in the
                            edit user window. This will generate a temporary password.
                        </p>
                        <ArticleImage src={acc05} alt="Reset Password"/>
                        <ArticleTitle level={4} pageDef={H_USERS_ACT_STATUS}/>
                        <p>
                            To change an account's activation status, click on the <code>Activate Account</code> /{" "}
                            <code>Deactivate Account</code> button in the edit user window. When a user account is
                            deactivated, it is no longer possible to log in to the system using that account.
                        </p>
                        <ArticleImage src={acc06} alt="Activation Status"/>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_ROLES}>
                            {" "}<em>(Headquarters Administrator)</em>
                        </ArticleTitle>
                        <p>
                            You can manage roles in this page. This page is only accessible to headquarters
                            administrator users.
                        </p>
                        <ArticleTitle level={4} pageDef={H_ROLES_ACTIONS}/>
                        <ol>
                            <li>To create a new role, click the <code>Create New Role</code> button.</li>
                            <li>To edit a role, click the <code>Edit</code> button</li>
                            <li>To delete a role, click the <code>Delete</code> button.</li>
                        </ol>
                        <ArticleImage src={acc07} alt="Role Actions"/>
                        <ArticleTitle level={4} pageDef={H_ROLES_FIELDS}/>
                        <ArticleImage src={acc08} alt="Role Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Role Name</strong></td>
                                <td>Role name must be unique.</td>
                            </tr>
                            <tr>
                                <td><strong>Role Visibility</strong></td>
                                <td>Select which user account types can be given
                                    this role. Select <code>All</code> if it can be given to any user account type.</td>
                            </tr>
                            <tr>
                                <td><strong>Permissions</strong></td>
                                <td>Check the box next to the permissions that will be granted
                                    to a user if this role is assigned to that user.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_OFFICES}>
                            {" "}<em>(Headquarters Administrator)</em>
                        </ArticleTitle>
                        <p>
                            You can manage offices in this page. This page is only accessible to headquarters
                            administrator users.
                        </p>
                        <ArticleTitle level={4} pageDef={H_OFFICES_ACTIONS}/>
                        <ol>
                            <li>To create a new office, click the <code>Create New Office</code> button.</li>
                            <li>The <code>Office Type</code> dropdown filters the list of offices based on their office type.</li>
                            <li>
                                The <code>Region</code> dropdown filters the list of offices based on region.
                                This dropdown only appears when the <code>Office Type</code> selected is
                                "Jail Level".
                            </li>
                            <li>To edit an office, click the <code>Edit</code> button.</li>
                            <li>To delete an office, click the <code>Delete</code> button.</li>
                        </ol>
                        <ArticleImage src={acc09} alt="Office Actions"/>
                        <ArticleTitle level={4} pageDef={H_OFFICES_FIELDS}/>
                        <ArticleImage src={acc10} alt="Office Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Office</strong></td>
                                <td>Name of the office.</td>
                            </tr>
                            <tr>
                                <td><strong>Order Index</strong></td>
                                <td>
                                    Specifies the order in which the office should appear in consolidated
                                    XLSX reports and dropdown filters.
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Type</strong></td>
                                <td>You can specify whether the office is a jail level or a
                                    regional office here.</td>
                            </tr>
                            <tr>
                                <td><strong>Region</strong></td>
                                <td>This field only appears if the selected type is jail level.
                                    Choose the regional office where the jail is located.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                </Article>
            </div>
        );
    }
}

AccessManagement.contents = contents;
AccessManagement.pageId = P_ACCESS;
export default AccessManagement;