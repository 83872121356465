import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {Office} from "@/records/Office";
import {config} from "@/config";

const attributes = {
    year: PropTypes.number,
    month: PropTypes.number,
    indicators: PropTypes.object,
    entryMorbidities: PropTypes.object,
    dentalHealthIndicators: PropTypes.object,
    mentalHealthIndicators: PropTypes.object,
    medicalProcedures: PropTypes.object,
    detentionMorbidities: PropTypes.object,
    medications: PropTypes.object,
    causesOfDeath: PropTypes.object,
    deathRecords: PropTypes.array,
    finalized: PropTypes.bool
};

const toOneRelationships = {
    office: () => Office
};

const toManyRelationships = {};

const meta = {
    isComplete: PropTypes.bool
};

export class JailLevelReport extends JsonApiRecord(
    'jail-level-reports',
    `${config.apiBaseUrl}/jail-level-reports`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'JailLevelReport';
    }
}