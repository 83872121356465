import React from 'react';
import BaseIndicatorCategories from "@/screens/Configuration/IndicatorCategories";
import {MentalHealthIndicatorCategory} from "@/records/MentalHealthIndicatorCategory";

class MentalHealthIndicatorCategories extends React.Component {
    render() {
        return (
            <BaseIndicatorCategories
                resourceType={MentalHealthIndicatorCategory}
                hasParentSubcategoryNumbered={false}
                title="Mental Health Indicator Categories"/>
        );
    }
}

export default MentalHealthIndicatorCategories;