import React from 'react';
import PropTypes from 'prop-types';
import Form from '@vallarj/react-adminlte/Form';
import SelectInput from '@vallarj/react-adminlte/Form/SelectInput';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import NumericInput from "@/components/NumericInput";
import Checkbox from '@vallarj/react-adminlte/Form/CheckBox';
import BasicResourceModal from "@/components/BasicResourceModal";

class IndicatorSubcategoryModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indicatorSubcategories: [],
            item: {}
        };
    }

    registerOpenModal = openModal => {
        this.props.openModalRef((view, item) => {
            openModal(view, item);
            this.setState({item});
        });
    };

    handleItemChangeNotify = (field, value, item) => {
        if (field === 'orderIndex') {
            if (value === '') {
                value = null;
            } else {
                value = parseInt(value, 10);
            }
        }

        return item.update(field, value);
    };

    resolveIndicatorSubcategories = category => {
        if (!category) {
            return [];
        }

        const {indicatorSubcategories} = this.props;
        const {item} = this.state;

        const filtered = indicatorSubcategories.filter(i => i.category.id === category.id);
        return this.flattenSubcategories(filtered, item);
    };

    flattenSubcategories = (subcategories, skip) => {
        let flattened = [];
        subcategories.forEach(s => {
            if (s.id !== skip.id) {
                flattened = [...flattened, s, ...this.flattenSubcategories(s.children, skip)];
            }
        });
        return flattened;
    };

    getLabelByName = option => option.name || "<No Label>";
    getValueById = option => option.id;

    renderItemView = (view, item, errors, notifyItemChange) => {
        const {indicatorCategories, hasShowTotal, hasQuarterly} = this.props;
        const indicatorSubcategories = this.resolveIndicatorSubcategories(item.category);

        return (
            <Form onChange={notifyItemChange} errors={errors}>
                <SelectInput label="Indicator Category" name="category" value={item.category}
                             options={indicatorCategories} getOptionValue={this.getValueById}
                             getOptionLabel={this.getLabelByName} disabled={view === 'create'}
                             searchable/>
                {
                    (view === 'edit' || (view === 'create' && item.parent)) &&
                    <SelectInput label="Parent Subcategory" name="parent"
                                 value={item.parent && indicatorSubcategories.find(i => item.parent.id === i.id)}
                                 options={indicatorSubcategories} getOptionValue={this.getValueById}
                                 getOptionLabel={this.getLabelByName} disabled={view === 'create'}
                                 searchable clearable/>
                }
                <TextInput label="Name" name="name" value={item.name} maxLength={255}/>
                <NumericInput label="Order Index" name="orderIndex" value={item.orderIndex}/>
                <h3>Options</h3>
                {
                    view === 'edit' &&
                    <Checkbox label="Active" name="active" checked={item.active}/>
                }
                <Checkbox label="Show Header" name="showHeader" checked={item.showHeader}/>
                {
                    hasShowTotal &&
                    <Checkbox label="Show Indicator Group Total" name="showTotal" checked={item.showTotal}/>
                }
                {
                    hasQuarterly &&
                    <Checkbox label="Quarterly" name="quarterly" checked={item.quarterly}/>
                }
            </Form>
        );
    };

    renderConfirmDeleteView = item => {
        return (
            <div className="j-config-modal-confirm-delete">
                <p>Are you sure you want to delete this indicator subcategory?{" "}
                    <strong>This action cannot be undone.</strong>
                </p>
                <table>
                    <tbody>
                    <tr>
                        <td>Indicator Subcategory</td>
                        <td>{item.name}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        return (
            <BasicResourceModal openModalRef={this.registerOpenModal}
                                onCreate={this.props.onSuccess}
                                onEdit={this.props.onSuccess}
                                onDelete={this.props.onSuccess}
                                itemSingular="Indicator Subcategory"
                                renderConfirmDeleteView={this.renderConfirmDeleteView}
                                renderItemView={this.renderItemView}
                                onItemChangeNotify={this.handleItemChangeNotify}
                                />
        );
    }
}

IndicatorSubcategoryModal.propTypes = {
    openModalRef: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    hasShowTotal: PropTypes.bool.isRequired,
    hasQuarterly: PropTypes.bool.isRequired
};

export default IndicatorSubcategoryModal;