import React from 'react';
import BaseIndicators from "@/screens/Configuration/Indicators";
import {DentalHealthIndicatorCategory} from "@/records/DentalHealthIndicatorCategory";
import {DentalHealthIndicatorSubcategory} from "@/records/DentalHealthIndicatorSubcategory";
import {DentalHealthIndicator} from "@/records/DentalHealthIndicator";

class DentalHealthIndicators extends React.Component {
    render() {
        return (
            <BaseIndicators
                categoryResourceType={DentalHealthIndicatorCategory}
                subcategoryResourceType={DentalHealthIndicatorSubcategory}
                indicatorResourceType={DentalHealthIndicator}
                title="Dental Health Indicators"
                hasSpecial={false}
                hasDataSource={false}
            />
        );
    }
}

export default DentalHealthIndicators;