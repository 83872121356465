import {SET_CLIENT_INIT_TIME, SET_SYSTEM_TIME, SET_TIME_DIFF} from "@/actions/actionTypes";
import {getRequest} from "@/utilities/axios";
import {config} from "@/config";
import moment from "moment";
import store from "@/store";

export const setSystemTime = payload => ({
    type: SET_SYSTEM_TIME,
    payload
});

export const setClientInitTime = payload => ({
    type: SET_CLIENT_INIT_TIME,
    payload
});

export const setTimeDiff = payload => ({
    type: SET_TIME_DIFF,
    payload
});

export const fetchSystemTime = () => {
    return getRequest(`${config.apiBaseUrl}/`)
        .then(({data: document}) => {
            const systemTime = moment(document.meta["system_time"]);
            const initTime = moment();
            store.dispatch(setSystemTime(systemTime));
            store.dispatch(setClientInitTime(initTime));
            store.dispatch(setTimeDiff(systemTime.diff(initTime)));
        })
        .catch(err => {
            console.log(err);
            throw err;
        });
};

export function resolveCurrentSystemTime() {
    const {timeDiff} = store.getState().global;
    return moment().add(timeDiff, 'ms');
}