import React from 'react';
import PropTypes from 'prop-types';
import Form from "@vallarj/react-adminlte/Form";
import TextInput from "@vallarj/react-adminlte/Form/TextInput";
import Checkbox from "@vallarj/react-adminlte/Form/CheckBox";
import BasicResourceModal from "@/components/BasicResourceModal";

class OptionModal extends React.Component {
    handleItemChangeNotify = (field, value, item) => {
        return item.update(field, value);
    };

    renderOptionView = (view, item, errors, notifyItemChange) => {
        const {itemSingular} = this.props;

        return (
            <Form onChange={notifyItemChange} errors={errors}>
                <TextInput label={itemSingular} name="name" value={item.name} maxLength={255}/>
                {
                    view === 'edit' &&
                    <>
                        <h3>Options</h3>
                        <div>
                            <Checkbox label="Active" name="active" checked={item.active}/>
                        </div>
                    </>
                }
            </Form>
        );
    };

    renderConfirmDeleteView = item => {
        const {itemSingular} = this.props;

        return (
            <div className="j-config-modal-confirm-delete">
                <p>Are you sure you want to delete this {itemSingular.toLowerCase()}?{" "}
                    <strong>This action cannot be undone.</strong>
                </p>
                <table>
                    <tbody>
                    <tr>
                        <td>{itemSingular}</td>
                        <td>{item.name}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        return (
            <BasicResourceModal openModalRef={this.props.openModalRef}
                                onCreate={this.props.onCreate}
                                onEdit={this.props.onEdit}
                                onDelete={this.props.onDelete}
                                itemSingular={this.props.itemSingular}
                                renderConfirmDeleteView={this.renderConfirmDeleteView}
                                renderItemView={this.renderOptionView}
                                onItemChangeNotify={this.handleItemChangeNotify}/>
        );
    }
}

OptionModal.propTypes = {
    openModalRef: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    itemSingular: PropTypes.string.isRequired
};

export default OptionModal;