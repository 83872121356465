import React from "react";

class ArticleImage extends React.Component {
    render() {
        const {alt, ...props} = this.props;

        return (
            <div className="help-image-container">
                <img className="help-image"
                     alt={alt} {...props}/>
            </div>
        )
    }
}

export default ArticleImage;