import React from "react";
import InfoBox from "@/components/InfoBox";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_MANAGE_JHU = 'default_120';
const TAG_EMERGENCY_REF = 'default_124';
const TAG_OUTPATIENT = 'default_126';
const TAG_HOSPITALIZATION = 'default_125';
const TAG_SUCCESSFUL_REF = 'default_123';
const TAG_NOT_REFERRED = 'default_127';

class Interventions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            managedJhu: 0,
            pManagedJhu: 0,
            emergencyReferral: 0,
            pEmergencyReferral: 0,
            outpatient: 0,
            pOutpatient: 0,
            hospitalization: 0,
            pHospitalization: 0,
            total: 0,
            successfulRef: 0,
            pSuccessfulRef: 0,
            notReferred: 0,
            pNotReferred: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const managedJhu = getAggregateByTag(TAG_MANAGE_JHU).reportTotal;
        const emergencyReferral = getAggregateByTag(TAG_EMERGENCY_REF).reportTotal;
        const outpatient = getAggregateByTag(TAG_OUTPATIENT).reportTotal;
        const hospitalization = getAggregateByTag(TAG_HOSPITALIZATION).reportTotal;
        const total = managedJhu + emergencyReferral + outpatient + hospitalization;

        const successfulRef = getAggregateByTag(TAG_SUCCESSFUL_REF).reportTotal;
        const notReferred = getAggregateByTag(TAG_NOT_REFERRED).reportTotal;
        const totalReferrals = successfulRef + notReferred;

        this.setState({
            managedJhu,
            pManagedJhu: pctTotalOver(managedJhu, total),
            emergencyReferral,
            pEmergencyReferral: pctTotalOver(emergencyReferral, total),
            outpatient,
            pOutpatient: pctTotalOver(outpatient, total),
            hospitalization,
            pHospitalization: pctTotalOver(hospitalization, total),
            total,
            successfulRef,
            pSuccessfulRef: pctTotalOver(successfulRef, totalReferrals),
            notReferred,
            pNotReferred: pctTotalOver(notReferred, totalReferrals),
        });
    };

    render() {
        const {
            managedJhu, pManagedJhu, emergencyReferral, pEmergencyReferral, outpatient,
            pOutpatient, hospitalization, pHospitalization, total, successfulRef, pSuccessfulRef,
            notReferred, pNotReferred,
        } = this.state;

        return (
            <div className="j-dash-box-subgroup">
                <h3>Commonly Recommended Interventions</h3>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="j-dash-tabular-group">
                            <table>
                                <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Count</th>
                                    <th>%</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Managed by Jail Health Unit</td>
                                    <td>{managedJhu}</td>
                                    <td>{pManagedJhu}%</td>
                                </tr>
                                <tr>
                                    <td>Emergency Referral</td>
                                    <td>{emergencyReferral}</td>
                                    <td>{pEmergencyReferral}%</td>
                                </tr>
                                <tr>
                                    <td>Out Patient Referral</td>
                                    <td>{outpatient}</td>
                                    <td>{pOutpatient}%</td>
                                </tr>
                                <tr>
                                    <td>For Hospitalization</td>
                                    <td>{hospitalization}</td>
                                    <td>{pHospitalization}%</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{total}</td>
                                    <td>100.00%</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <InfoBox className="simple-info-box"
                                 title="Managed by Jail Health Unit" value={managedJhu}
                                 progressDescription={`${pManagedJhu}% interventions done within the jail`}
                                 progress={pManagedJhu} showProgress/>
                        <InfoBox className="simple-info-box"
                                 title="Successful Referrals" value={successfulRef}
                                 progressDescription={`${pSuccessfulRef}% requested referrals were referred`}
                                 progress={pSuccessfulRef} showProgress/>
                        <InfoBox className="simple-info-box"
                                 title="Not Referred" value={notReferred}
                                 progressDescription={`${pNotReferred}% were not referred`}
                                 progress={pNotReferred} showProgress/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(Interventions);