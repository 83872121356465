import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    officeType: PropTypes.string,
};

const toOneRelationships = {
    parent: () => Office
};

const toManyRelationships = {
    // Temporary performance fix, commented out to reduce hydration levels
    // children: () => Office
};

const meta = {
    inUse: PropTypes.bool
};

export class Office extends JsonApiRecord(
    'offices',
    `${config.apiBaseUrl}/offices`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'Office';
    }
}