import React from 'react';
import PropTypes from 'prop-types';
import Modal from "@vallarj/react-adminlte/Modal";
import FileInput from "@/components/FileInput";
import {createCancelToken} from "@/utilities/jsonapi";
import {Morbidity} from "@/records/Morbidity";
import {MedicalProcedure} from "@/records/MedicalProcedure";
import {Medication} from "@/records/Medication";
import {CauseOfDeath} from "@/records/CauseOfDeath";
import {
    baseProcessMortalityWorksheet,
    fetchOptions,
    validateWorkbook
} from "@/screens/JailLevelReports/JailLevelReportEntry/utilities";
import {noop} from "@/utilities/noop";
import {notifyError, notifySuccess} from "@/utilities/notifications";
import {SelectInput} from "@vallarj/react-adminlte/Form";
import {fetchIndicatorStructure, processWorksheet} from "@/utilities/indicator-report";
import {IndicatorCategory} from "@/records/IndicatorCategory";
import {IndicatorSubcategory} from "@/records/IndicatorSubcategory";
import {Indicator} from "@/records/Indicator";
import {DentalHealthIndicatorCategory} from "@/records/DentalHealthIndicatorCategory";
import {DentalHealthIndicatorSubcategory} from "@/records/DentalHealthIndicatorSubcategory";
import {DentalHealthIndicator} from "@/records/DentalHealthIndicator";
import {MentalHealthIndicatorCategory} from "@/records/MentalHealthIndicatorCategory";
import {MentalHealthIndicatorSubcategory} from "@/records/MentalHealthIndicatorSubcategory";
import {MentalHealthIndicator} from "@/records/MentalHealthIndicator";

class ImportAllModal extends React.Component {
    constructor(props) {
        super(props);
        props.registerOpenModal(this.handleOpenModal);

        this.state = {
            show: false,
            isProcessing: false,
            optionsMap: null,
            file: null,
            errors: {},
            view: 'import',
            missing: {},
            partialSelected: null,
            partialOptions: [],
        };

        this.partialOptionsMap = {
            indicators: "Indicator Reporting Tool (J001)",
            entryMorbidities: "Morbidities Detected Upon Entry (J002)",
            dentalHealthIndicators: "Dental Health (J003)",
            mentalHealthIndicators: "Mental Health (J004)",
            medicalProcedures: "Medical Procedures Requested (J005)",
            detentionMorbidities: "Morbidities During Detention (J006)",
            medications: "Medications (J007)",
            causesOfDeath: "Causes of Death (J008)",
            deathRecords: "Mortality Registry (J009)"
        };

        this.fetchCancelToken = createCancelToken();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    handleOpenModal = () => {
        this.setState({show: true});
    };

    handleEnter = () => {
        const {optionsMap} = this.state;
        if (!optionsMap) {
            this.fetchDependencies();
        }
    }

    fetchDependencies = () => {
        const optionTypes = [
            Morbidity,
            MedicalProcedure,
            Medication,
            CauseOfDeath
        ];

        this.setState({isProcessing: true});
        Promise.all([
            fetchIndicatorStructure(
                IndicatorCategory,
                IndicatorSubcategory,
                Indicator,
                true,
                this.fetchCancelToken,
                this.props.month
            ),
            fetchIndicatorStructure(
                DentalHealthIndicatorCategory,
                DentalHealthIndicatorSubcategory,
                DentalHealthIndicator,
                false,
                this.fetchCancelToken,
                this.props.month
            ),
            fetchIndicatorStructure(
                MentalHealthIndicatorCategory,
                MentalHealthIndicatorSubcategory,
                MentalHealthIndicator,
                false,
                this.fetchCancelToken,
                this.props.month
            ),
            ...optionTypes.map(o => fetchOptions(this.fetchCancelToken, o))
        ]).then(([
            {indicatorMap: indicators},
            {indicatorMap: dentalHealthIndicators},
            {indicatorMap: mentalHealthIndicators},
            {optionMap: morbidities},
            {optionMap: medicalProcedures},
            {optionMap: medications},
            {optionMap: causesOfDeath}
        ]) => {
            this.setState({
                isProcessing: false,
                optionsMap: {
                    indicators,
                    dentalHealthIndicators,
                    mentalHealthIndicators,
                    morbidities,
                    medicalProcedures,
                    medications,
                    causesOfDeath
                }
            })
        });
    };

    handleExit = () => {
        this.setState({
            show: false,
            isProcessing: false,
            file: null,
            view: 'import',
            missing: {},
            partialSelected: null,
            partialOptions: [],
        });
    };

    handleCloseClick = () => {
        const {isProcessing} = this.state;
        if (!isProcessing) {
            this.setState({show: false});
        }
    };

    handleImportFileChange = file => {
        this.setState({
            file,
            errors: {}
        });
    };

    handleImportClick = () => {
        const {file} = this.state;
        if (!file) {
            this.setInvalidFileError();
            return;
        }

        this.setState({isProcessing: true});
        const reader = new FileReader();

        reader.onload = e => {
            // Get file contents
            const data = e.target.result;

            // Create workbook from file contents
            import('xlsx').then(({default: XLSX}) => {
                let workbook;
                try {
                    workbook = XLSX.read(data, {type: 'binary'});
                } catch {}

                if (workbook) {
                    validateWorkbook(workbook)
                        .then(workbook => this.processWorkbook(workbook))
                        .then(missing => {
                            const missingAttributes = Object.keys(missing);
                            if (missingAttributes.length > 0) {
                                const partialOptions = missingAttributes.map(a => ({
                                    label: this.partialOptionsMap[a],
                                    value: a
                                }));
                                this.setState({
                                    isProcessing: false,
                                    view: 'partial',
                                    missing,
                                    partialSelected: missingAttributes[0],
                                    partialOptions
                                });
                            } else {
                                this.setState({
                                    isProcessing: false,
                                    show: false,
                                });
                                notifySuccess("Import All Sheets", "Import successful.");
                            }
                        })
                        .catch(err => {
                            this.setInvalidFileError();
                            this.setState({isProcessing: false});
                        });
                } else {
                    this.setInvalidFileError();
                    this.setState({isProcessing: false});
                }

            });
        };

        reader.readAsBinaryString(file);
    };

    processWorkbook = workbook => {
        const {optionsMap} = this.state;
        const {onAttributesReplace, onDeathRecordsReplace} = this.props;
        const attributes = [
            {name: 'indicators', map: 'indicators'},
            {name: 'entryMorbidities', map: 'morbidities'},
            {name: 'dentalHealthIndicators', map: 'dentalHealthIndicators'},
            {name: 'mentalHealthIndicators', map: 'mentalHealthIndicators'},
            {name: 'medicalProcedures', map: 'medicalProcedures'},
            {name: 'detentionMorbidities', map: 'morbidities'},
            {name: 'medications', map: 'medications'},
            {name: 'causesOfDeath', map: 'causesOfDeath'},
        ];

        return Promise.all([
            processWorksheet(workbook, 0, 5, "B", "D", "E", "i-"),
            processWorksheet(workbook, 1, 6, "B", "D", "E"),
            processWorksheet(workbook, 2, 5, "B", "D", "E", "i-"),
            processWorksheet(workbook, 3, 5, "B", "D", "E", "i-"),
            ...Array.from(Array(4).keys()).map(k => (
                processWorksheet(workbook, k + 4, 6, "B", "D", "E")
            )),
            baseProcessMortalityWorksheet(workbook)
        ]).then(results => {
            const missing = {};
            const mortalityResults = results.pop();

            results.forEach((items, i) => {
                const attribute = attributes[i];
                const currentOptionsMap = optionsMap[attribute.map];
                const importItems = {};
                const missingItems = [];
                Object.keys(currentOptionsMap).forEach(id => {
                    if (!items.hasOwnProperty(id)) {
                        const option = currentOptionsMap[id];
                        let name;
                        if (attribute.name === 'indicators') {
                            name = option.name + (option.subcategory.name ? ` (${option.subcategory.name})` : "");
                        } else {
                            name = option.name;
                        }
                        missingItems.push({id, name});
                    } else {
                        importItems[id] = items[id];
                    }
                });
                if (missingItems.length > 0) {
                    if (attribute.name === 'indicators') {
                        missingItems.sort((a, b) => (
                            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                        ));
                    }

                    missing[attribute.name] = missingItems;
                }

                onAttributesReplace(attribute.name, importItems);
            });

            const {items, errors} = mortalityResults;
            onDeathRecordsReplace(items);
            if (errors.length) {
                missing['deathRecords'] = errors.map((e, i) => ({
                    id: i,
                    name: e
                }));
            }

            return missing;
        });
    };

    setInvalidFileError = () => {
        notifyError("Import All Sheets", "Unable to process your request. Invalid file.");
        this.setState({
            errors: {
                file: ["Invalid file."]
            }
        })
    };

    handlePartialChange = (_, value) => {
        this.setState({partialSelected: value});
    };

    renderBody = () => {
        const {view, file, errors, missing, partialSelected, partialOptions} = this.state;
        if (view === 'import') {
            return (
                <FileInput label="File" value={file} name="file" errors={errors}
                           onChange={this.handleImportFileChange}/>
            );
        } else if (view === 'partial') {
            return (
                <div className="j-import-modal-partial">
                    <p>File partially imported. Click <kbd>Save</kbd> on the main screen to save the changes.</p>
                    <h3>Missing values</h3>
                    <SelectInput name="partialSelected" value={partialSelected}
                                 onChange={this.handlePartialChange} options={partialOptions} simpleValue/>
                    <ul>{missing[partialSelected].map(m => <li key={m.id}>{m.name}</li>)}</ul>
                </div>
            );
        }
    };

    renderFooter = () => {
        const {view, isProcessing} = this.state;
        if (view === 'import') {
            return (<>
                <button className="btn btn-default pull-left" onClick={this.handleCloseClick}
                        disabled={isProcessing}>
                    Cancel
                </button>
                <button className="btn btn-primary pull-right" onClick={this.handleImportClick}
                        disabled={isProcessing}>
                    <i className="fa fa-upload margin-r-5"/>Import
                </button>
            </>);
        } else if (view === 'partial') {
            return (
                <button className="btn btn-default pull-right" onClick={this.handleCloseClick}
                        disabled={isProcessing}>
                    Close
                </button>
            );
        }
    }

    render() {
        const {isProcessing, show} = this.state;

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick}
                   onEnter={this.handleEnter} isLoading={isProcessing}>
                <Modal.Header>Import All Sheets</Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>{this.renderFooter()}</Modal.Footer>
            </Modal>
        );
    }
}

ImportAllModal.defaultProps = {
    onAttributesReplace: noop
};

ImportAllModal.propTypes = {
    onAttributesReplace: PropTypes.func.isRequired,
    onDeathRecordsReplace: PropTypes.func.isRequired,
    registerOpenModal: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired
};

export default ImportAllModal;