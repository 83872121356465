import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {MedicalProcedure} from "@/records/MedicalProcedure";

const attributes = {
    total: PropTypes.number
};

const toOneRelationships = {
    medicalProcedure: () => MedicalProcedure
};

export class MedicalProcedureAggregate extends JsonApiRecord('medical-procedure-aggregates', '',
    attributes, toOneRelationships) {
    static get modelName() {
        return 'MedicalProcedureAggregate';
    }
}