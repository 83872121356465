import React from "react";
import InfoBox from "@/components/InfoBox";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_JAIL_POP = 'default_2';
const TAG_TB_NOTIFIED = 'default_62';
const TAG_DSTB_BC_NEW = 'default_63';
const TAG_DSTB_BC_ALL = 'default_65';
const TAG_DRTB_BC_NEW = 'default_67';
const TAG_DRTB_BC_ALL = 'default_69';
const TAG_TB_ONGOING = "default_93";
const TAG_TB_CURED = "default_96"
const TAG_TB_COMPLETED = "default_97";
const TAG_TB_FAILED = "default_98";
const TAG_TB_DIED = "default_99";
const TAG_TB_LTFU = "default_100";
const TAG_TB_NOT_EVALUATED = "default_101";
const TAG_TB_REFERRED_RHU = "default_102";

class Prevalence extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prevalence: 0,
            pPrevalence: 0,
            notified: 0,
            pNotified: 0,
            bc: 0,
            pBc: 0,
            ongoing: 0,
            pOngoing: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const jailPop = getAggregateByTag(TAG_JAIL_POP);
        const ongoing = getAggregateByTag(TAG_TB_ONGOING);
        const cured = getAggregateByTag(TAG_TB_CURED);
        const completed = getAggregateByTag(TAG_TB_COMPLETED);
        const failed = getAggregateByTag(TAG_TB_FAILED);
        const died = getAggregateByTag(TAG_TB_DIED);
        const ltfu = getAggregateByTag(TAG_TB_LTFU);
        const notEvaluated = getAggregateByTag(TAG_TB_NOT_EVALUATED);
        const referredRhu = getAggregateByTag(TAG_TB_REFERRED_RHU);

        const notified = getAggregateByTag(TAG_TB_NOTIFIED);
        const dstbBcNew = getAggregateByTag(TAG_DSTB_BC_NEW);
        const dstbBcAll = getAggregateByTag(TAG_DSTB_BC_ALL);
        const drtbBcNew = getAggregateByTag(TAG_DRTB_BC_NEW);
        const drtbBcAll = getAggregateByTag(TAG_DRTB_BC_ALL);

        const prevalence = ongoing.reportTotal + cured.reportTotal + completed.reportTotal + failed.reportTotal
            + died.reportTotal + ltfu.reportTotal + notEvaluated.reportTotal + referredRhu.reportTotal;
        const bc = dstbBcNew.reportTotal + dstbBcAll.reportTotal + drtbBcNew.reportTotal + drtbBcAll.reportTotal;

        this.setState({
            prevalence,
            pPrevalence: pctTotalOver(prevalence, jailPop.reportTotal),
            notified: notified.reportTotal,
            pNotified: pctTotalOver(notified.reportTotal, jailPop.reportTotal),
            bc,
            pBc: pctTotalOver(bc, notified.reportTotal),
            ongoing: ongoing.reportTotal,
            pOngoing: pctTotalOver(ongoing.reportTotal, prevalence)
        });
    };

    render() {
        const {
            prevalence, pPrevalence, notified, pNotified,
            bc, pBc, ongoing, pOngoing
        } = this.state;

        return (
            <div className="row">
                <div className="col-xs-6 col-lg-3">
                    <InfoBox className="simple-info-box bg-yellow"
                             title="Prevalence Proportion of TB cases" value={prevalence}
                             progressDescription={`${pPrevalence}% of the population has TB`}
                             progress={pPrevalence} showProgress/>
                </div>
                <div className="col-xs-6 col-lg-3">
                    <InfoBox className="simple-info-box bg-green"
                             title="Notified Cases" value={notified}
                             progressDescription={`${pNotified}% per 1000 incidence rate`}
                             progress={pNotified} showProgress/>
                </div>
                <div className="col-xs-6 col-lg-3">
                    <InfoBox className="simple-info-box bg-maroon"
                             title="Bacteriologically Confirmed" value={bc}
                             progressDescription={`${pBc}% of diagnosed cases`}
                             progress={pBc} showProgress/>
                </div>
                <div className="col-xs-6 col-lg-3">
                    <InfoBox className="simple-info-box bg-blue"
                             title="Ongoing Treatment" value={ongoing}
                             progressDescription={`${pOngoing}% ongoing treatment`}
                             progress={pOngoing} showProgress/>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(Prevalence);