import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {Permission} from "@/records/Permission";
import {RoleType} from "@/records/RoleType";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    "default": PropTypes.bool,
};

const toOneRelationships = {
    roleType: () => RoleType
};

const toManyRelationships = {
    permissions: Permission
};

const meta = {
    inUse: PropTypes.bool
};

export class Role extends JsonApiRecord(
    'rbac-roles',
    `${config.apiBaseUrl}/rbac/roles`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'Role';
    }
}