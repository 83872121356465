import React from 'react';
import PropTypes from 'prop-types';
import Tag from "@/screens/Configuration/Tag";
import {highlightString} from "@/utilities/highlight-string";
import SmoothCollapse from "react-smooth-collapse";

class IndicatorSubcategoryItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {search} = this.props;
        if (search !== prevProps.search && !this.state.expanded) {
            this.toggleExpand();
        }
    }

    toggleExpand = () => {
        this.setState({expanded: !this.state.expanded});
    };

    handleAddChildClick = () => {
        const {indicatorSubcategory, onAddChildClick} = this.props;
        onAddChildClick(indicatorSubcategory)
    };

    handleEditIndicatorSubcategoryClick = () => {
        const {indicatorSubcategory, onEditClick} = this.props;
        onEditClick(indicatorSubcategory);
    };

    handleDeleteIndicatorSubcategoryClick = () => {
        const {indicatorSubcategory, onDeleteClick} = this.props;
        onDeleteClick(indicatorSubcategory);
    };

    render() {
        const {expanded} = this.state;
        const {indicatorSubcategory, search, hasShowTotal, hasQuarterly} = this.props;
        const isLeaf = (indicatorSubcategory.children.length === 0);

        return (
            <li>
                <div className={"j-indicator-subcategories-item" + (isLeaf ? " j-indicator-subcategories-leaf" : "")}>
                    {
                        <span onClick={this.toggleExpand} className="j-indicator-subcategories-expand">
                        {
                            !isLeaf &&
                            <i className={"fa fa-caret-right" + (expanded ? " j-indicator-subcategories-expanded": "")}/>
                        }
                        </span>
                    }
                    <div onClick={this.toggleExpand} className="j-indicator-subcategories-details">
                        <div>{indicatorSubcategory.orderIndex}</div>
                        <div>
                            {
                                indicatorSubcategory.name ?
                                    <div className={"j-indicator-subcategories-name"}>
                                        {highlightString(search, indicatorSubcategory.name)}
                                    </div> :
                                    <div className={"j-indicator-subcategories-no-name"}>&lt;No Label&gt;</div>
                            }
                            <div>
                                {
                                    indicatorSubcategory.inUse &&
                                    <Tag text="In Use" className="j-config-item-tag-in-use"/>
                                }
                                {
                                    !indicatorSubcategory.showHeader &&
                                    <Tag text="No Header" className="j-indicator-subcategories-tag-no-header"/>
                                }
                                {
                                    hasShowTotal && indicatorSubcategory.showTotal &&
                                    <Tag text="Show Total" className="j-indicator-subcategories-tag-show-total"/>
                                }
                                {
                                    hasQuarterly && indicatorSubcategory.quarterly &&
                                    <Tag text="Quarterly" className="j-indicator-subcategories-tag-quarterly"/>
                                }
                                {
                                    !indicatorSubcategory.active &&
                                    <Tag text="Inactive" className="j-config-item-tag-inactive"/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="j-indicator-subcategories-controls">
                        <span className="btn-group">
                            <button className="btn btn-default btn-xs" onClick={this.handleAddChildClick}>
                                <i className="fa fa-plus-circle margin-r-5"/>Add child
                            </button>
                            <button className="btn btn-default btn-xs" onClick={this.handleEditIndicatorSubcategoryClick}>
                                <i className="fa fa-pencil"/>
                            </button>
                            <button className="btn btn-default btn-xs" disabled={indicatorSubcategory.inUse}
                                    onClick={this.handleDeleteIndicatorSubcategoryClick}>
                                <i className="fa fa-trash"/>
                            </button>
                        </span>
                    </div>
                </div>
                {
                    !isLeaf &&
                    <SmoothCollapse expanded={this.state.expanded} heightTransition=".30s ease">
                        <ul>
                            {
                                indicatorSubcategory.children.map(i => (
                                    <IndicatorSubcategoryItem onAddChildClick={this.props.onAddChildClick}
                                                              onEditClick={this.props.onEditClick}
                                                              onDeleteClick={this.props.onDeleteClick}
                                                              key={i.id} search={search}
                                                              indicatorSubcategory={i}
                                                              hasShowTotal={hasShowTotal}
                                                              hasQuarterly={hasQuarterly}/>
                                ))
                            }
                        </ul>
                    </SmoothCollapse>
                }
            </li>
        );
    }
}

IndicatorSubcategoryItem.propTypes = {
    onAddChildClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    hasShowTotal: PropTypes.bool.isRequired,
    hasQuarterly: PropTypes.bool.isRequired
};

export default IndicatorSubcategoryItem;