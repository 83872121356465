import React from 'react';
import OptionsBox from "@/screens/JailLevelReports/JailLevelReportEntry/OptionsBox";
import {CauseOfDeath} from "@/records/CauseOfDeath";

class CausesOfDeath extends React.Component {
    render() {
        return (
            <OptionsBox boxTitle="Causes of Death (J008)"
                        headerTitle="Underlying Causes"
                        attributeName="causesOfDeath"
                        optionType={CauseOfDeath}
                        onAttributeChange={this.props.onAttributeChange}
                        onAttributesReplace={this.props.onAttributesReplace}
                        onAttributeRevert={this.props.onAttributeRevert}
                        attributesChanged={this.props.attributesChanged}
                        isLoading={this.props.isLoading}
                        onSaveClick={this.props.onSaveClick}
                        isEditMode={this.props.isEditMode}
                        sheetIndex={6}
                        values={this.props.values}/>
        );
    }
}

export default CausesOfDeath;