import React from 'react';
import PropTypes from 'prop-types';

import BaseIndicatorReport from "@/components/IndicatorReport";
import {processWorksheet} from "@/screens/JailLevelReports/JailLevelReportEntry/utilities";
import {DentalHealthIndicator} from "@/records/DentalHealthIndicator";
import {DentalHealthIndicatorSubcategory} from "@/records/DentalHealthIndicatorSubcategory";
import {DentalHealthIndicatorCategory} from "@/records/DentalHealthIndicatorCategory";

class DentalHealthIndicators extends React.Component {
    handleSaveClick = () => {
        this.props.onSaveClick('dentalHealthIndicators');
    };

    render() {
        const {
            indicators, month,
            onIndicatorsReplace, onIndicatorChange, onIndicatorsRevert,
            isLoading, isEditMode, indicatorsChanged
        } = this.props;

        return (
            <BaseIndicatorReport
                title="Dental Health (J003)"
                categoryResourceType={DentalHealthIndicatorCategory}
                subcategoryResourceType={DentalHealthIndicatorSubcategory}
                indicatorResourceType={DentalHealthIndicator}
                hasDataSource={false}
                worksheetProcessor={processWorksheet}
                indicators={indicators}
                onIndicatorsReplace={onIndicatorsReplace}
                onIndicatorChange={onIndicatorChange}
                isLoading={isLoading}
                isEditMode={isEditMode}
                onSaveClick={this.handleSaveClick}
                onIndicatorsRevert={onIndicatorsRevert}
                indicatorsChanged={indicatorsChanged}
                month={month}
                showFooter
                worksheetIndex={2}
            />
        );
    }
}

DentalHealthIndicators.propTypes = {
    indicators: PropTypes.object.isRequired,
    onIndicatorChange: PropTypes.func.isRequired,
    onIndicatorsReplace: PropTypes.func.isRequired,
    onIndicatorsRevert: PropTypes.func.isRequired,
    indicatorsChanged: PropTypes.bool.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    month: PropTypes.number.isRequired
};

export default DentalHealthIndicators;