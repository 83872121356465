import React from "react";
import {Morbidity} from "@/records/Morbidity";
import OptionsIndex from "@/screens/Configuration/Options/OptionsIndex";

class Morbidities extends React.Component {
    render() {
        return (
            <OptionsIndex title="Morbidities"
                          itemSingular="Morbidity"
                          optionType={Morbidity}
                          />
        );
    }
}

export default Morbidities;