import React from "react";
import {Bar} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {createHorizontalChartOptions} from "@/utilities/dashboard";

class TopCausesOfDeathChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createHorizontalChartOptions("Top causes of death");
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.dataSet !== nextProps.dataSet;
    }

    render() {
        const {dataSet} = this.props;

        return <Bar data={dataSet} options={this.chartOptions} plugins={[ChartDataLabels]}/>;
    }
}

export default TopCausesOfDeathChart;