import React from 'react';

import Box from '@vallarj/react-adminlte/Box';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import {
    createCancelToken,
    fetchResourceCollection,
    waitForAllRequests
} from "@/utilities/jsonapi";
import {Role} from "@/records/Role";
import {Permission} from "@/records/Permission";
import RoleIndexItem from "@/screens/AccessManagement/Roles/RoleIndexItem";
import {roleOrderComparator} from "@/utilities/permissions";
import {escapeRegExp} from "@/utilities/highlight-string";
import RoleModal from "@/screens/AccessManagement/Roles/RoleModal";
import {RoleType} from "@/records/RoleType";

class Roles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search: "",
            isLoading: false,
            roles: [],
            roleTypes: {},
            permissions: {}
        };

        this.scrollRef = React.createRef();
        this.fetchCancelToken = createCancelToken();
        this.openModal = () => {};
    }

    componentDidMount() {
        this.setState({isLoading: true});
        waitForAllRequests([
            fetchResourceCollection(Role),
            fetchResourceCollection(Permission),
            fetchResourceCollection(RoleType)
        ]).onSuccess(([roles, permissions, roleTypes]) => {
            // Rearrange roles
            roles.sort((a, b) => roleOrderComparator(a.id, b.id));

            this.setState({
                roles,
                roleTypes: roleTypes.reduce((a, b) => ({...a, [b.id]: b}), {}),
                permissions: permissions.reduce((a, b) => ({...a, [b.id]: b}), {}),
                isLoading: false
            })
        }).execute(this.fetchCancelToken);
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});
    };

    handleEditItemClick = role => {
        this.openModal('edit', role);
    };

    handleDeleteItemClick = role => {
        this.openModal('delete', role);
    };

    handleCreateRoleClick = () => {
        this.openModal('create', new Role());
    };

    handleCreateRoleSuccess = role => {
        this.setState({
            roles: [...this.state.roles, role]
        });
        this.scrollRef.current.scrollTop = this.scrollRef.current.scrollHeight;
    };

    handleEditRoleSuccess = role => {
        this.setState({
            roles: this.state.roles.map(r => r.id === role.id ? role : r)
        });
    };

    handleDeleteRoleSuccess = role => {
        this.setState({
            roles: this.state.roles.filter(r => r.id !== role.id)
        });
    };

    filterRoles = () => {
        const {roles} = this.state;
        let search = (this.state.search && this.state.search.trim()) || "";

        if (!search) {
            return roles;
        }

        const escapedSearch = escapeRegExp(search);
        return roles.filter(r => r.name.match(RegExp(escapedSearch, "i")));
    };

    render() {
        const {isLoading, search, permissions, roleTypes} = this.state;
        const roles = this.filterRoles();

        return (<>
            <RoleModal permissions={permissions} openModalRef={this.registerOpenModal} roleTypes={roleTypes}
                       onCreate={this.handleCreateRoleSuccess}
                       onEdit={this.handleEditRoleSuccess}
                       onDelete={this.handleDeleteRoleSuccess}/>
            <Box theme="box-primary" isLoading={isLoading}>
                <Box.Header title="Roles"/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..." value={search}
                               onChange={this.handleSearchChange}/>
                    <div ref={this.scrollRef} className="j-box-scroll">
                        <div className="j-roles-header">
                            <span>Role</span>
                            <span>Role Visibility</span>
                            <span>Permissions</span>
                        </div>
                        {
                            roles.map(r => (
                                <RoleIndexItem role={r} permissions={permissions} roleTypes={roleTypes}
                                               onEditClick={this.handleEditItemClick}
                                               onDeleteClick={this.handleDeleteItemClick}
                                               search={search} key={r.id}/>
                            ))
                        }
                        { roles.length === 0 && !isLoading && <div className="j-roles-item">No results found.</div> }
                    </div>
                </Box.Body>
                <Box.Footer>
                    <button className="btn btn-primary pull-right" onClick={this.handleCreateRoleClick}>
                        <i className="fa fa-plus-circle margin-r-5"/>Create New Role
                    </button>
                </Box.Footer>
            </Box>
        </>);
    }
}

export default Roles;