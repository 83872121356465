import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {config} from "@/config";

const attributes = {
    description: PropTypes.string
};

export class Permission extends JsonApiRecord(
    'rbac-permissions',
    `${config.apiBaseUrl}/rbac/permissions`,
    attributes
) {
    static get modelName() {
        return 'Permission';
    }
}