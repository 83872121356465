import React from 'react';
import PropTypes from 'prop-types';
import {noop} from "@/utilities/noop";

class ModalFooter extends React.Component {
    render() {
        const {view, isProcessing, onCreateClick, onSaveClick, onDeleteClick, onCloseClick} = this.props;

        switch (view) {
            case 'create':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={onCloseClick}
                                disabled={isProcessing}>
                            Cancel
                        </button>
                        <button className="btn btn-primary pull-right" onClick={onCreateClick}
                                disabled={isProcessing}>
                            <i className="fa fa-plus-circle margin-r-5"/>Create
                        </button>
                    </>
                );
            case 'edit':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={onCloseClick}
                                disabled={isProcessing}>
                            Cancel
                        </button>
                        <button className="btn btn-primary pull-right" onClick={onSaveClick}
                                disabled={isProcessing}>
                            <i className="fa fa-save margin-r-5"/>Save
                        </button>
                    </>
                );
            case 'delete':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={onCloseClick}
                                disabled={isProcessing}>
                            Cancel
                        </button>
                        <button className="btn btn-danger pull-right" onClick={onDeleteClick}
                                disabled={isProcessing}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </>
                );
            default:
                return null;
        }
    }
}

ModalFooter.defaultProps = {
    onCreateClick: noop,
    onSaveClick: noop,
    onDeleteClick: noop,
    onCloseClick: noop
};

ModalFooter.propTypes = {
    view: PropTypes.string,
    isProcessing: PropTypes.bool,
    onCreateClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onCloseClick: PropTypes.func
};

export default ModalFooter;