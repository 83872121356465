import React from 'react';
import PropTypes from 'prop-types';
import BasicResourceModal from "@/components/BasicResourceModal";
import Form, {SelectInput} from "@vallarj/react-adminlte/Form";
import {asFormComponent} from "@vallarj/react-adminlte/Form/asFormComponent";
import Checkbox from "@vallarj/react-adminlte/Form/CheckBox";
import {noop} from "@/utilities/noop";
import ValueButton from "@vallarj/react-adminlte/ValueButton";

class BaseSumOf extends React.Component {
    constructor(props) {
        super(props);
        if (props.registerResetSumOf) {
            props.registerResetSumOf(this.reset);
        }
        this.state = {
            selected: null,
            indicators: []
        };
    }

    getIndicatorLabel = option => option.name + (option.subcategory.name ? ` (${option.subcategory.name})` : "");
    getValueById = option => option.id;

    componentDidMount() {
        this.refreshIndicators();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {indicators, value} = this.props;

        if (
            indicators !== prevProps.indicators
            || value !== prevProps.value
        ) {
            this.refreshIndicators();
        }
    }

    refreshIndicators() {
        const {indicators, value: dependencies} = this.props;
        this.setState({
            indicators: indicators.filter(i => !dependencies.includes(i))
        });
    }

    handleAddClick = () => {
        const {onChange, value} = this.props;
        const {selected} = this.state;
        onChange('dependencies', [...value, selected]);
        this.setState({selected: null});
    };

    handleRemoveClick = indicator => {
        const {onChange, value} = this.props;
        onChange('dependencies', value.filter(i => i !== indicator));
    };

    handleIndicatorChange = (_, value) => {
        this.setState({selected: value});
    };

    reset = () => {
        this.setState({selected: null});
    };

    render() {
        const {selected, indicators} = this.state;
        const {value: dependencies} = this.props;

        return (
            <div className="j-indicator-sum-modal-sum-of">
                <div className="j-indicator-sum-modal-sum-of-select">
                    <div>
                        <SelectInput label="Sum Of" name="indicator" value={selected}
                                     options={indicators} onChange={this.handleIndicatorChange}
                                     getOptionLabel={this.getIndicatorLabel}
                                     getOptionValue={this.getValueById}
                                     searchable clearable/>
                    </div>
                    <div>
                        <button className="btn btn-default btn-block" onClick={this.handleAddClick}
                                disabled={!selected}>
                            <i className="fa fa-plus-circle margin-r-5"/>Add
                        </button>
                    </div>
                </div>
                <div className="j-indicator-sum-modal-sum-of-list">
                    {
                        dependencies.map(d => (
                            <div key={d.id} className="j-indicator-sum-modal-sum-of-list-item">
                                <div>
                                    {d.name} {d.subcategory.name ? ` (${d.subcategory.name})` : ""}
                                </div>
                                <div>
                                    <ValueButton className="btn btn-xs btn-danger" value={d}
                                                 onClick={this.handleRemoveClick}>
                                        <i className="fa fa-trash margin-r-5"/>Remove
                                    </ValueButton>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

const SumOf = asFormComponent(BaseSumOf);

class IndicatorSumModal extends React.Component {
    constructor(props) {
        super(props);

        this.resetSumOf = noop;
    }

    handleExit = () => {
        this.resetSumOf();
    };

    registerResetSumOf = reset => {
        this.resetSumOf = reset;
    };

    handleItemChangeNotify = (field, value, item) => {
        return item.update(field, value);
    };

    getIndicatorLabel = option => option.name + (option.subcategory.name ? ` (${option.subcategory.name})` : "");
    getValueById = option => option.id;

    renderItemView = (view, item, errors, notifyItemChange) => {
        const {indicators} = this.props;
        return (
            <Form onChange={notifyItemChange} errors={errors}>
                <SelectInput label="Indicator" name="indicator" options={indicators}
                             value={item.indicator}
                             getOptionLabel={this.getIndicatorLabel}
                             getOptionValue={this.getValueById} searchable disabled={view === 'edit'}/>
                <SumOf registerReset={this.registerResetSumOf} indicators={indicators}
                       value={item.dependencies}/>
                {
                    view === 'edit' &&
                    <>
                        <h3>Options</h3>
                        <Checkbox label="Active" name="active" checked={item.active}/>
                    </>
                }
            </Form>
        );
    };

    renderConfirmDeleteView = item => {
        return (
            <div className="j-config-modal-confirm-delete">
                <p>Are you sure you want to delete this indicator sum?{" "}
                    <strong>This action cannot be undone.</strong>
                </p>
                <table>
                    <tbody>
                    <tr>
                        <td>Indicator</td>
                        <td>{item.indicator.name}</td>
                    </tr>
                    <tr>
                        <td>Sum of</td>
                        <td>
                            <ul>
                                {
                                    item.dependencies.map(d => (
                                        <li key={d.id}>
                                            {d.name}{" "}
                                            {d.subcategory.name ? `(${d.subcategory.name})` : ""}
                                        </li>
                                    ))
                                }
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        return (
            <BasicResourceModal
                handleExit={this.handleExit}
                openModalRef={this.props.openModalRef}
                onCreate={this.props.onCreateSuccess}
                onEdit={this.props.onEditSuccess}
                onDelete={this.props.onDeleteSuccess}
                itemSingular="Indicator Sum"
                renderItemView={this.renderItemView}
                renderConfirmDeleteView={this.renderConfirmDeleteView}
                onItemChangeNotify={this.handleItemChangeNotify}
                />
        );
    }
}

IndicatorSumModal.propTypes = {
    indicators: PropTypes.array.isRequired,
    openModalRef: PropTypes.func.isRequired,
    onCreateSuccess: PropTypes.func.isRequired,
    onEditSuccess: PropTypes.func.isRequired,
    onDeleteSuccess: PropTypes.func.isRequired
};

export default IndicatorSumModal;