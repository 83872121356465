import React from 'react';
import moment from "moment";

import Content from "@vallarj/react-adminlte/Content";
import BreadcrumbItem from "@vallarj/react-adminlte/Content/BreadcrumbItem";
import SelectInput from "@vallarj/react-adminlte/Form/SelectInput";
import Box from "@vallarj/react-adminlte/Box";
import DataTable from "@vallarj/react-adminlte/DataTable";
import {createCancelToken, fetchResourceCollection, patchResource} from "@/utilities/jsonapi";
import {DiseaseMonitoringReport} from "@/records/DiseaseMonitoringReport";
import {AbilityContext, Can} from "@/ability";
import {getCurrentUser} from "@/actions/authActions";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import {resolveCurrentSystemTime} from "@/actions/globalActions";
import {Office} from "@/records/Office";
import ReportStatsTag from "@/components/ReportStatsTag";
import {Link} from "react-router-dom";
import PrepareReportModal from "@/screens/DiseaseSurveillanceReports/PrepareReportModal";
import {noop} from "@/utilities/noop";
import {getFileRequest} from "@/utilities/axios";
import {config} from "@/config";
import {notifySuccess} from "@/utilities/notifications";
import ValueButton from "@vallarj/react-adminlte/ValueButton";

const START_YEAR = 2021;

class DiseaseSurveillanceReportsIndex extends React.Component {
    constructor(props) {
        super(props);
        const user = getCurrentUser();
        const officeType = user.office['office_type'];

        this.columnDefs = [
            {
                header: 'Report Date',
                renderValue: item => {
                    let tags = [];
                    if (item.finalized) {
                        if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
                            tags.push(<ReportStatsTag key="submitted" type={ReportStatsTag.Submitted}/>);
                        } else {
                            tags.push(<ReportStatsTag key="finalized" type={ReportStatsTag.Finalized}/>);
                        }
                    } else {
                        tags.push(<ReportStatsTag key={item.isComplete ? "complete" : "incomplete"}
                                                  type={item.isComplete ? ReportStatsTag.Complete : ReportStatsTag.Incomplete}/>);
                        if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
                            tags.push(<ReportStatsTag key="not-submitted" type={ReportStatsTag.NotSubmitted}/>);
                        } else {
                            tags.push(<ReportStatsTag key="unfinalized" type={ReportStatsTag.Unfinalized}/>);
                        }
                    }

                    return (
                        <>
                            <span>{item.reportDate.format('MMMM D, YYYY')}</span>
                            {tags}
                        </>
                    )
                }
            },
            {
                header: '',
                renderValue: item => {
                    let openButton;

                    if (item.finalized) {
                        const {unfinalizing} = this.state;

                        openButton = (
                            <>
                                <Link to={`/disease-surveillance-reports/view/${item.id}`}
                                      className="btn btn-default btn-xs">
                                    <i className="fa fa-eye margin-r-5"/>View
                                </Link>
                                <Can I="unfinalize" a="JailLevelReport">
                                    <ValueButton className="btn btn-default btn-xs" value={item} disabled={!!unfinalizing}
                                                 onClick={this.handleUnfinalizeClick}>
                                        {
                                            unfinalizing && item === unfinalizing ?
                                                <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                                <i className="fa fa-undo margin-r-5"/>
                                        }
                                        Return for Changes
                                    </ValueButton>
                                </Can>
                            </>
                        );
                    } else {
                        openButton = (
                            <>
                                <Link to={`/disease-surveillance-reports/view/${item.id}`}
                                      className="btn btn-default btn-xs">
                                    <i className="fa fa-eye margin-r-5"/>View
                                </Link>
                                <Can I="update" a="JailLevelReport">
                                    <Link to={`/disease-surveillance-reports/edit/${item.id}`}
                                          className="btn btn-default btn-xs">
                                        <i className="fa fa-pencil margin-r-5"/>Edit
                                    </Link>
                                </Can>
                            </>
                        );
                    }

                    return (
                        <span className="btn-group pull-right">
                            {openButton}
                        </span>
                    );
                }
            }
        ];

        this.state = {
            yearOptions: [],
            monthOptions: [],
            jailLevelOffices: [],
            offices: [],
            regions: [],
            selectedYear: null,
            selectedMonth: null,
            office: null,
            region: [],
            isLoading: true,
            reports: [],
            exportTemplateLoading: false,
            unfinalizing: null
        };

        this.fetchCancelToken = createCancelToken();
        this.prepareCancelToken = createCancelToken();
        this.unfinalizeCancelToken = createCancelToken();

        this.openModal = noop;
    }

    componentDidMount() {
        const yearOptions = this.resolveYearOptions();
        const selectedYear = yearOptions[0].value;
        const monthOptions = this.resolveMonthOptions(selectedYear);
        const selectedMonth = monthOptions[0].value;

        // Resolve office options
        const user = getCurrentUser();
        const officeType = user.office['office_type'];
        const offices = [];

        if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
            offices.push((new Office()).set('id', user.office.id));
            this.fetchYearMonthReports(selectedYear, selectedMonth, offices[0].id);
            this.setState({
                yearOptions,
                monthOptions,
                selectedYear,
                selectedMonth,
                offices,
                office: offices[0]
            });
        } else {
            fetchResourceCollection(Office)
                .onSuccess(offices => {
                    const jailLevelOffices = offices.filter(o => o.officeType === OFFICE_TYPE_JAIL_LEVEL);

                    let regions;
                    if (officeType === OFFICE_TYPE_REGIONAL) {
                        regions = offices.filter(o => o.id === user.office.id);
                    } else {
                        regions = offices.filter(o => o.officeType === OFFICE_TYPE_REGIONAL);
                    }
                    const region = regions[0];
                    offices = jailLevelOffices.filter(o => o.parent && o.parent.id === region.id);

                    if (offices[0]) {
                        this.fetchYearMonthReports(selectedYear, selectedMonth, offices[0].id);
                    } else {
                        this.setState({isLoading: false, reports: []});
                    }
                    this.setState({
                        yearOptions,
                        monthOptions,
                        selectedYear,
                        selectedMonth,
                        jailLevelOffices,
                        offices,
                        office: offices[0],
                        regions,
                        region
                    });
                })
                .execute(this.fetchCancelToken)
        }
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
        this.prepareCancelToken.cancel();
        this.unfinalizeCancelToken.cancel();
    }

    fetchYearMonthReports(year, month, officeId) {
        const firstDay = moment(`${year}-${month}-01`, "YYYY-MM-DD");
        const lastDay = firstDay.clone().endOf('month');

        const params = {
            f: firstDay.format('YYYY-MM-DD'),
            t: lastDay.format('YYYY-MM-DD'),
            office: officeId
        };

        this.setState({isLoading: true});
        this.fetchCancelToken.cancel();
        this.fetchCancelToken = createCancelToken();
        fetchResourceCollection(DiseaseMonitoringReport, params)
            .onSuccess(reports => {
                this.setState({
                    isLoading: false,
                    reports: reports
                })
            })
            .execute(this.fetchCancelToken);
    }

    resolveYearOptions = () => {
        const sysTime = resolveCurrentSystemTime();
        const currentYear = sysTime.year()
        const yearOptions = [];

        let year = currentYear;
        while (year >= START_YEAR) {
            yearOptions.push({label: `${year}`, value: year});
            year--;
        }

        return yearOptions;
    };

    resolveMonthOptions = year => {
        const sysTime = resolveCurrentSystemTime();
        const currentYear = sysTime.year();
        const monthOptions = [];

        let startMonth = 12;
        if (year === currentYear) {
            startMonth = sysTime.month() + 1;
        }

        for (let i = startMonth; i > 0; i--) {
            monthOptions.push({label: moment.months(i - 1), value: i})
        }

        return monthOptions;
    };

    handleYearChange = (_, year) => {
        const months = this.resolveMonthOptions(year);
        const latestMonth = months[0];
        const {office} = this.state;
        if (office) {
            this.fetchYearMonthReports(year, latestMonth.value, office.id);
        } else {
            this.setState({reports: []});
        }
        this.setState({
            selectedYear: year,
            monthOptions: months,
            selectedMonth: months[0].value
        });
    };

    handleMonthChange = (_, month) => {
        const {selectedYear, office} = this.state;
        if (office) {
            this.fetchYearMonthReports(selectedYear, month, office.id);
        } else {
            this.setState({reports: []});
        }
        this.setState({
            selectedMonth: month
        });
    };

    handleOfficeChange = (_, office) => {
        const {selectedYear, selectedMonth} = this.state;
        if (office) {
            this.fetchYearMonthReports(selectedYear, selectedMonth, office.id);
        } else {
            this.setState({reports: []});
        }

        this.setState({
            office
        });
    }

    handleRegionChange = (_, value) => {
        const {selectedYear, selectedMonth, jailLevelOffices} = this.state;
        const offices = jailLevelOffices.filter(o => o.parent.id === value.id);
        const office = offices[0];

        this.setState({
            region: value,
            offices,
            office: offices[0],
        });

        if (office) {
            this.fetchYearMonthReports(selectedYear, selectedMonth, office.id);
        } else {
            this.setState({reports: []});
        }
    };

    handlePrepareNewReportClick = () => {
        this.openModal();
    };

    handlePrepareSuccess = reportId => {
        this.props.history.push(`/disease-surveillance-reports/edit/${reportId}`);
    };

    handleExportTemplateClick = () => {
        this.setState({exportTemplateLoading: true});
        getFileRequest(`${config.apiBaseUrl}/templates/disease-surveillance`, 'BJMP-DHS-Disease-Surveillance-Template.xlsx')
            .then(() => {
                this.setState({exportTemplateLoading: false});
                notifySuccess("Generate Disease Surveillance Report Template", "Template successfully generated");
            });
    };

    handleUnfinalizeClick = item => {
        const {office, selectedYear, selectedMonth} = this.state;
        this.setState({
            unfinalizing: item
        });

        const report = new DiseaseMonitoringReport().set('id', item.id)
            .update('finalized', false);

        patchResource(report)
            .onSuccess(() => {
                this.fetchYearMonthReports(selectedYear, selectedMonth, office.id);
                notifySuccess("Unfinalize Report", "Report unfinalized.");
                this.setState({
                    unfinalizing: null
                });
            })
            .execute(this.unfinalizeCancelToken);
    };

    getLabelByName = option => option.name;
    getValueById = option => option.id;

    registerOpenModal = openModal => {
        this.openModal = openModal
    };

    renderBody = () => {
        const {
            yearOptions, monthOptions, offices, selectedYear, selectedMonth,
            office, regions, region, reports, isLoading, exportTemplateLoading
        } = this.state;
        const user = getCurrentUser();
        const officeType = user.office['office_type'];

        return (<>
            <div className="row">
                <div className="col-xs-3">
                    <SelectInput name="year" label="Year" value={selectedYear}
                                 onChange={this.handleYearChange} options={yearOptions} simpleValue/>
                    <SelectInput name="month" label="Month" value={selectedMonth}
                                 onChange={this.handleMonthChange} options={monthOptions} simpleValue/>
                    {
                        officeType === OFFICE_TYPE_HQ &&
                        <SelectInput name="region" label="Region" value={region} onChange={this.handleRegionChange}
                                     options={regions} getOptionValue={this.getValueById}
                                     getOptionLabel={this.getLabelByName} searchable/>
                    }
                    {
                        officeType !== OFFICE_TYPE_JAIL_LEVEL &&
                        <SelectInput name="office" label="Office" value={office} onChange={this.handleOfficeChange}
                                     options={offices} getOptionValue={this.getValueById}
                                     getOptionLabel={this.getLabelByName} searchable/>
                    }
                    <Can I="update" a="JailLevelReport">
                        <div className="btn-group-vertical btn-block">
                            <button className="btn btn-primary btn-block" onClick={this.handlePrepareNewReportClick}>
                                <i className="fa fa-plus-circle margin-r-5"/>Prepare New Report
                            </button>
                            <button className="btn btn-default btn-block" onClick={this.handleExportTemplateClick}
                                    disabled={exportTemplateLoading}>
                                {
                                    exportTemplateLoading ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-download margin-r-5"/>
                                }
                                Generate Disease Surveillance XLSX Template
                            </button>
                        </div>
                    </Can>
                </div>
                <div className="col-xs-9">
                    <Box theme='box-primary' isLoading={isLoading}>
                        <Box.Header title="Reports"/>
                        <Box.Body>
                            <div className="j-box-scroll j-disease-surveillance-reports-index">
                                <DataTable className="j-disease-surveillance-reports-index-table"
                                           data={reports} columnDefs={this.columnDefs}
                                           emptyMessage="No reports."/>
                            </div>
                        </Box.Body>
                    </Box>
                </div>
            </div>
        </>);
    };

    render() {
        return (
            <Content>
                <Content.Header title="Disease Surveillance Reports"/>
                <Content.Breadcrumb>
                    <BreadcrumbItem label="Disease Surveillance Reports" iconClass="fa fa-book" active/>
                </Content.Breadcrumb>
                <Content.Body>
                    <PrepareReportModal registerOpenModal={this.registerOpenModal}
                                        onPrepareSuccess={this.handlePrepareSuccess}/>
                    {this.renderBody()}
                </Content.Body>
            </Content>
        );
    }
}

DiseaseSurveillanceReportsIndex.contextType = AbilityContext;
export default DiseaseSurveillanceReportsIndex;