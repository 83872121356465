import React from 'react';
import {withAggregateTagMap} from "@/screens/DiseaseSurveillanceDashboard/utilities";
import DashboardComponent from "@/components/DashboardComponent";

class CustomDSDashboardComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            chartType: null,
            forceBreak: null,
            values: []
        }
    }

    onAggregatesLoad = () => {
        const {component, getAggregateById} = this.props;
        this.setState({
            title: component.title,
            chartType: component.chartType,
            forceBreak: component.forceBreak,
            values: component.indicators.map(ci => ({
                id: ci.indicator,
                label: ci.label,
                value: getAggregateById(ci.indicator).reportTotal
            }))
        })
    };

    render() {
        const {title, values, chartType, forceBreak} = this.state;
        const {isLoading} = this.props;
        return (
            <DashboardComponent title={title} values={values} chartType={chartType}
                                forceBreak={forceBreak} isLoading={isLoading}/>
        )
    }
}

export default withAggregateTagMap(CustomDSDashboardComponent);