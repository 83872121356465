import React from "react";
import PropTypes from 'prop-types';
import {createCancelToken} from "@/utilities/jsonapi";
import {fetchOptions} from "@/screens/JailLevelReports/JailLevelReportEntry/utilities";
import {Morbidity} from "@/records/Morbidity";
import {MedicalProcedure} from "@/records/MedicalProcedure";
import {Medication} from "@/records/Medication";
import {CauseOfDeath} from "@/records/CauseOfDeath";
import Modal from "@vallarj/react-adminlte/Modal";
import {fetchIndicatorStructure} from "@/utilities/indicator-report";
import {IndicatorCategory} from "@/records/IndicatorCategory";
import {IndicatorSubcategory} from "@/records/IndicatorSubcategory";
import {Indicator} from "@/records/Indicator";
import {DentalHealthIndicatorCategory} from "@/records/DentalHealthIndicatorCategory";
import {DentalHealthIndicatorSubcategory} from "@/records/DentalHealthIndicatorSubcategory";
import {DentalHealthIndicator} from "@/records/DentalHealthIndicator";
import {MentalHealthIndicatorCategory} from "@/records/MentalHealthIndicatorCategory";
import {MentalHealthIndicatorSubcategory} from "@/records/MentalHealthIndicatorSubcategory";
import {MentalHealthIndicator} from "@/records/MentalHealthIndicator";

class SubmitErrorModal extends React.Component {
    constructor(props) {
        super(props);
        props.registerOpenModal(this.openModal);

        this.state = {
            isLoading: false,
            errorCode: null,
            baseCode: null,
            error: null,
            indicators: null,
            morbidities: null,
            dentalHealthIndicators: null,
            mentalHealthIndicators: null,
            medicalProcedures: null,
            medications: null,
            causesOfDeath: null,
            show: false,
        };
        this.dependencyMap = {
            J001: 'indicators',
            J002: 'morbidities',
            J003: 'dentalHealthIndicators',
            J004: 'mentalHealthIndicators',
            J005: 'medicalProcedures',
            J006: 'morbidities',
            J007: 'medications',
            J008: 'causesOfDeath'
        };

        this.fetchCancelToken = createCancelToken();
    }

    loadDependency = dependency => {
        if (this.state[dependency] === null) {
            let promise;
            switch (dependency) {
                case 'indicators':
                    promise = fetchIndicatorStructure(
                        IndicatorCategory,
                        IndicatorSubcategory,
                        Indicator,
                        true,
                        this.fetchCancelToken,
                        this.props.month
                    ).then(({indicatorMap}) => ({optionMap: indicatorMap}));
                    break;
                case 'morbidities':
                    promise = fetchOptions(this.fetchCancelToken, Morbidity);
                    break;
                case 'dentalHealthIndicators':
                    promise = fetchIndicatorStructure(
                        DentalHealthIndicatorCategory,
                        DentalHealthIndicatorSubcategory,
                        DentalHealthIndicator,
                        false,
                        this.fetchCancelToken,
                        this.props.month
                    ).then(({indicatorMap}) => ({optionMap: indicatorMap}));
                    break;
                case 'mentalHealthIndicators':
                    promise = fetchIndicatorStructure(
                        MentalHealthIndicatorCategory,
                        MentalHealthIndicatorSubcategory,
                        MentalHealthIndicator,
                        false,
                        this.fetchCancelToken,
                        this.props.month
                    ).then(({indicatorMap}) => ({optionMap: indicatorMap}));
                    break;
                case 'medicalProcedures':
                    promise = fetchOptions(this.fetchCancelToken, MedicalProcedure);
                    break;
                case 'medications':
                    promise = fetchOptions(this.fetchCancelToken, Medication);
                    break;
                case 'causesOfDeath':
                    promise = fetchOptions(this.fetchCancelToken, CauseOfDeath);
                    break;
                default:
                    return;
            }

            promise.then(({optionMap}) => {
                this.setState({
                    isLoading: false,
                    [dependency]: optionMap
                });
            });
        } else {
            this.setState({isLoading: false});
        }
    };

    openModal = error => {
        const {code} = error;
        const baseCode = code.split('-')[0].toUpperCase();
        this.setState({
            show: true,
            errorCode: code,
            baseCode,
            error,
            isLoading: true
        });
        this.loadDependency(this.dependencyMap[baseCode]);
    };

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    handleExit = () => {
        this.setState({
            show: false,
            isLoading: false
        });
    };

    handleCloseClick = () => {
        const {isLoading} = this.state;
        if (!isLoading) {
            this.setState({show: false});
        }
    };

    resolveFormName = () => {
        const {baseCode} = this.state;
        switch (baseCode) {
            case "J001":
                return "Indicator Reporting Tool (J001)";
            case "J002":
                return "Morbidities Detected Upon Entry (J002)";
            case "J003":
                return "Dental Health (J003)";
            case "J004":
                return "Mental Health (J004)";
            case "J005":
                return "Medical Procedures Requested (J005)";
            case "J006":
                return "Morbidities During Detention (J006)";
            case "J007":
                return "Medications (J007)";
            case "J008":
                return "Causes of Death (J008)";
            default:
                return "";
        }
    }

    renderBody = () => {
        const {errorCode} = this.state;

        if (errorCode === "J001-02") {
            // Indicator sum error
            return this.renderSumError();
        } else {
            // Missing attribute error
            return this.renderMissingAttributeError();
        }
    };

    renderOptionName = option => {
        const subcategory = option.subcategory && option.subcategory.name;
        return option.name + (subcategory ? ` (${subcategory})` : "");
    }

    renderSumError = () => {
        const {isLoading, indicators, error} = this.state;
        if (!error || isLoading) {
            return null;
        }

        const {source, targets} = error.meta;
        const sourceName = this.renderOptionName(indicators[source]);
        return (
            <div className="j-import-modal-partial">
                <p>
                    Errors were encountered in {this.resolveFormName()}:{" "}
                    <strong>{sourceName}</strong>
                </p>
                <h3>Must be {targets.length > 1 ? "the sum of" : "equal to"}:</h3>
                <ul>{targets.map(id => <li key={id}>{this.renderOptionName(indicators[id])}</li>)}</ul>
            </div>
        );
    };

    renderMissingAttributeError = () => {
        const {isLoading, baseCode, error} = this.state;
        if (!error) {
            return null;
        }

        const missingIds = isLoading ? [] : error.meta.missing;
        const options = this.state[this.dependencyMap[baseCode]];
        return (
            <div className="j-import-modal-partial">
                <p>Errors were encountered in {this.resolveFormName()}:</p>
                <h3>Missing Values</h3>
                <ul>
                    {
                        missingIds.map(i => {
                            const option = options[i];
                            const name = this.renderOptionName(option);

                            return <li key={i}>{name}</li>;
                        })
                    }
                </ul>
            </div>
        );
    };

    render() {
        const {isLoading, show} = this.state;

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick}
                   isLoading={isLoading}>
                <Modal.Header>Submission Errors</Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-default pull-right" onClick={this.handleCloseClick}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

SubmitErrorModal.propTypes = {
    registerOpenModal: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired
};

export default SubmitErrorModal;