import React from 'react';
import BaseIndicators from "@/screens/Configuration/Indicators";
import {DiseaseMonitoringIndicatorCategory} from "@/records/DiseaseMonitoringIndicatorCategory";
import {DiseaseMonitoringIndicatorSubcategory} from "@/records/DiseaseMonitoringIndicatorSubcategory";
import {DiseaseMonitoringIndicator} from "@/records/DiseaseMonitoringIndicator";

class Indicators extends React.Component {
    render() {
        return (
            <BaseIndicators
                categoryResourceType={DiseaseMonitoringIndicatorCategory}
                subcategoryResourceType={DiseaseMonitoringIndicatorSubcategory}
                indicatorResourceType={DiseaseMonitoringIndicator}
                title="Disease Surveillance Indicators"
                hasSpecial={false}
                hasDataSource={false}
            />
        );
    }
}

export default Indicators;