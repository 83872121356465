import React from "react";
import InfoBox from "@/components/InfoBox";
import ConsultationsChart from "@/screens/Dashboard/Treatment/Consultations/ConsultationsChart";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_NURSE = 'default_107';
const TAG_DOCTOR = 'default_108';
const TAG_TELEMED = 'default_109';

class Consultations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nurse: 0,
            pNurse: 0,
            doctor: 0,
            pDoctor: 0,
            telemed: 0,
            pTelemed: 0,
            total: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const nurse = getAggregateByTag(TAG_NURSE).reportTotal;
        const doctor = getAggregateByTag(TAG_DOCTOR).reportTotal;
        const telemed = getAggregateByTag(TAG_TELEMED).reportTotal;
        const total = nurse + doctor + telemed;

        this.setState({
            nurse,
            pNurse: pctTotalOver(nurse, total),
            doctor,
            pDoctor: pctTotalOver(doctor, total),
            telemed,
            pTelemed: pctTotalOver(telemed, total),
            total
        });
    };

    render() {
        const {
            nurse, pNurse, doctor, pDoctor,
            telemed, pTelemed, total
        } = this.state;

        return (
            <div className="j-dash-box-subgroup">
                <h3>Consultations and Interventions</h3>
                <div className="row">
                    <div className="col-xs-5 col-lg-12">
                        <div>
                            <InfoBox className="simple-info-box"
                                     title="Done by Nurse" value={nurse}
                                     progressDescription={`${pNurse}% of consultations`}
                                     progress={pNurse} showProgress/>
                            <div className="j-dash-tabular-group">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Count</th>
                                        <th>%</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Done By Nurse</td>
                                        <td>{nurse}</td>
                                        <td>{pNurse}%</td>
                                    </tr>
                                    <tr>
                                        <td>Done By Doctor (Face to Face)</td>
                                        <td>{doctor}</td>
                                        <td>{pDoctor}%</td>
                                    </tr>
                                    <tr>
                                        <td>Telemedicine</td>
                                        <td>{telemed}</td>
                                        <td>{pTelemed}%</td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td>{total}</td>
                                        <td>100.00%</td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-7 col-lg-12">
                        <ConsultationsChart {...this.state}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(Consultations);