import React from 'react';
import {Medication} from "@/records/Medication";
import OptionsIndex from "@/screens/Configuration/Options/OptionsIndex";

class Medications extends React.Component {
    render() {
        return (
            <OptionsIndex title="Medications"
                          itemSingular="Medication"
                          optionType={Medication}
                          />
        );
    }
}

export default Medications;