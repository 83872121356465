import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {DiseaseMonitoringIndicatorCategory} from "@/records/DiseaseMonitoringIndicatorCategory";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    showHeader: PropTypes.bool,
    active: PropTypes.bool
};

const toOneRelationships = {
    category: () => DiseaseMonitoringIndicatorCategory,
    parent: () => DiseaseMonitoringIndicatorSubcategory
};

const toManyRelationships = {
    children: () => DiseaseMonitoringIndicatorSubcategory
};

const meta = {
    inUse: PropTypes.bool
};

export class DiseaseMonitoringIndicatorSubcategory extends JsonApiRecord(
    'disease-monitoring-indicator-subcategories',
    `${config.apiBaseUrl}/disease-monitoring-indicator-subcategories`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'DiseaseMonitoringIndicatorSubcategory';
    }
}