import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Link, Redirect, Route, Switch} from "react-router-dom";
import {initSession} from "@/utilities/auth";
import {routes} from "@/routes";

import Layout from '@vallarj/react-adminlte/Layout';
import SidebarItem from '@vallarj/react-adminlte/Layout/SidebarItem';
import SidebarTree from '@vallarj/react-adminlte/Layout/SidebarTree';
import UserMenu from '@vallarj/react-adminlte/Layout/UserMenu';
import Content from "@vallarj/react-adminlte/Content";
import {AbilityContext} from "@/ability";
import AccountSettings from "@/screens/AccountSettings";

class UserLayout extends React.Component {
    constructor(props) {
        super(props);

        this.userMenu = React.createRef();
        this.appName = <><strong>Health Reporting</strong></>;
        this.appNameShort = <strong>HS</strong>;
    }

    componentDidMount() {
        const {checkedAuth} = this.props;
        if (!checkedAuth) {
            initSession().then(() => {});
        }
    }

    handleSidebarItemClick = path => {
        // TODO: Temporary fix
        this.props.history.push(path);
    };

    handleAccountSettingsClick = () => {
        this.userMenu.current.closeUserMenu();
    };

    renderNavMenu = () => {
        const {user} = this.props;
        const primaryRole = (user["roles"][0] && user["roles"][0]["name"]) || "Regular User";

        return (
            <Layout.NavMenu>
                <UserMenu firstName={user["first_name"]}
                          lastName={user["last_name"]}
                          title={user["first_name"] + ' ' + user["last_name"]}
                          subTitle={primaryRole}
                          ref={this.userMenu}
                >
                    <UserMenu.Footer>
                        <div className="pull-left">
                            <Link to='/account' className="btn btn-default btn-flat"
                                  onClick={this.handleAccountSettingsClick}>
                                Account Settings
                            </Link>
                        </div>
                        <div className="pull-right">
                            <a href={`${process.env.PUBLIC_URL}/logout`} className="btn btn-default btn-flat">Logout</a>
                        </div>
                    </UserMenu.Footer>
                </UserMenu>
            </Layout.NavMenu>
        );
    };

    renderSidebar = routes => {
        const currentPath = this.props.location.pathname;

        return routes.map(r => {
            const enabled = r.hasOwnProperty('enabled') ? r.enabled : true;
            if(!enabled) {
                return null;
            }

            if(r.type === 'item') {
                return <SidebarItem key={r.path}
                                    onClick={this.handleSidebarItemClick}
                                    active={r.path === '/' ? r.path === currentPath : currentPath.indexOf(r.path) === 0}
                                    {...r}
                                    id={r.path}/>
            } else if(r.type === 'tree') {
                return (
                    <SidebarTree key={r.id} iconClass={r.iconClass} label={r.label}>
                        {this.renderSidebar(r.items)}
                    </SidebarTree>
                );
            } else return null;
        });
    };

    renderPermissionlessScreen = () => {
        return (
            <Content>
                <Content.Header title="Access Restricted"/>
                <Content.Body>
                    <h4>You don't have permission to access this page.</h4>
                    <p>
                        Please contact your local accounts administrator to obtain the necessary permissions. Thank you.
                    </p>
                </Content.Body>
            </Content>
        );
    };

    resolveEnabledRoutes = routes => {
        let flatRoutes = [];
        routes = routes.filter(r => r.hasOwnProperty('enabled') ? r.enabled : true);

        routes.forEach(r => {
            if (r.type === 'tree') {
                flatRoutes = [...flatRoutes, ...this.resolveEnabledRoutes(r.items)];
            } else if (r.type === 'item') {
                flatRoutes.push(r);
            }
        });

        return flatRoutes;
    };

    renderBody = routes => {
        const enabledRoutes = this.resolveEnabledRoutes(routes);
        const {user} = this.props;
        const usesTemporaryPassword = user['uses_temporary_password'];

        if (user.permissions.length === 0) {
            return this.renderPermissionlessScreen();
        }

        return (
            <Switch>
                <Route exact path="/account" render={props => (
                    <AccountSettings {...props} usesTemporaryPassword={usesTemporaryPassword}/>
                )}/>
                {
                    usesTemporaryPassword &&
                    <Redirect to="/account"/>
                }
                {enabledRoutes.map(r => (
                    <Route key={r.path}
                           exact={!!r.exact}
                           path={r.path}
                           component={r.component}
                    />
                ))}
                <Redirect to={(enabledRoutes[0] || {path: ""}).path}/>
            </Switch>
        )
    };

    render() {
        const {checkedAuth, loggedIn, user} = this.props;
        if (!checkedAuth || !loggedIn || !user) {
            return null;
        }

        const rts = routes(this.context);
        const year = moment().format("YYYY");

        return (
            <>
                <Layout appName={this.appName} appNameShort={this.appNameShort}>
                    { this.renderNavMenu() }
                    <Layout.Sidebar>
                        { this.renderSidebar(rts) }
                    </Layout.Sidebar>
                    <Layout.Body>
                        { this.renderBody(rts) }
                    </Layout.Body>
                    <Layout.Footer>
                        <strong>Copyright &copy; 2021{year === "2021" ? " " : ` - ${year} `}
                            <a href="https://bjmp.gov.ph">Bureau of Jail Management and Penology</a>.
                        </strong> All rights reserved.
                    </Layout.Footer>
                </Layout>
            </>
        );
    }
}

const mapStateToProps = ({auth}) => auth;

UserLayout.contextType = AbilityContext;
export default connect(mapStateToProps)(UserLayout);