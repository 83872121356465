import React from "react";
import Article from "@/screens/Docs/Article";
import ArticleImage from "@/screens/Docs/Article/ArticleImage";
import od01 from "./images/od-01.jpg";
import od02 from "./images/od-02.jpg";
import od03 from "./images/od-03.jpg";
import od04 from "./images/od-04.jpg";
import od05 from "./images/od-05.jpg";
import od06 from "./images/od-06.jpg";
import od07 from "./images/od-07.jpg";
import od08 from "./images/od-08.jpg";
import {createPageDefinition} from "@/screens/Docs/utilities";
import ArticleTitle from "@/screens/Docs/Article/ArticleTitle";

const P_PAGE = 'overall';
const H_OVERVIEW = createPageDefinition(P_PAGE, 'overview', 'Overview');
const H_FILTERS = createPageDefinition(P_PAGE, 'filters', 'Filters');
const H_FILTERS_SOURCE_DATA = createPageDefinition(P_PAGE, 'filters-source-data', 'Source Data');
const H_FILTERS_DATE_RANGE = createPageDefinition(P_PAGE, 'filters-date-range', 'Date Range');
const H_ACTIONS = createPageDefinition(P_PAGE, 'actions', 'Actions');
const H_ACTIONS_NATIONAL_CONSOLIDATED = createPageDefinition(P_PAGE, 'actions-generate-national-consolidated', 'Generating a National Consolidated XLSX Report');
const H_ACTIONS_REGIONAL_CONSOLIDATED = createPageDefinition(P_PAGE, 'actions-generate-regional-consolidated', 'Generating a Regional Consolidated XLSX Report');
const H_ACTIONS_JAIL_XLSX = createPageDefinition(P_PAGE, 'actions-generate-jail-level', 'Generating a Jail Level XLSX Report');
const H_ACTIONS_DASHBOARD_PDF = createPageDefinition(P_PAGE, 'actions-export-dashboard', 'Exporting the Dashboard as PDF');

const contents = [
    H_OVERVIEW,
    {
        ...H_FILTERS,
        contents: [
            H_FILTERS_SOURCE_DATA,
            H_FILTERS_DATE_RANGE
        ]
    },
    {
        ...H_ACTIONS,
        contents: [
            H_ACTIONS_NATIONAL_CONSOLIDATED,
            H_ACTIONS_REGIONAL_CONSOLIDATED,
            H_ACTIONS_JAIL_XLSX,
            H_ACTIONS_DASHBOARD_PDF
        ]
    }
];

class OverallDashboard extends React.Component {
    render() {
        return (
            <div className="row">
                <Article title="Overall Dashboard" id={P_PAGE}
                         contentTitle="When you log in, the overall dashboard appears as your home page. It displays data and charts from submitted monthly reports."
                         contents={contents}>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_OVERVIEW}/>
                        <ArticleImage src={od01} alt="Overview"/>
                        <ol>
                            <li>
                                <strong>Region</strong>
                                <p>Select the region from which to display data</p>
                            </li>
                            <li>
                                <strong>Data</strong>
                                <p>Here you can filter the data for the entire region or for a specific jail office.</p>
                            </li>
                            <li>
                                <strong>Date Range</strong>
                                <p>Choose a date range for the dashboard data to be generated</p>
                            </li>
                            <li>
                                <strong>Actions Button</strong>
                                <p>Controls for generating XLSX reports and exporting the dashboard as PDF</p>
                            </li>
                        </ol>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_FILTERS}/>
                        <p>You can filter the data generated in the dashboard using the filter controls.</p>
                        <ArticleImage src={od02} alt="Filter controls"/>
                        <ArticleTitle level={4} pageDef={H_FILTERS_SOURCE_DATA}/>
                        <p>
                            You can view data from a specific office or region to which you have access.
                            To choose a data source:
                        </p>
                        <ul>
                            <li>Select a region from the <code>Region</code> dropdown menu</li>
                            <li>Select either the entire region or office under the <code>Data</code> dropdown</li>
                        </ul>
                        <ArticleImage src={od03} alt="Data source"/>
                        <ArticleTitle level={4} pageDef={H_FILTERS_DATE_RANGE}/>
                        <p>
                            You can specify a date range for the data that will be displayed in the dashboard.
                            Note that only the months matter for the date ranges you've chosen.
                            To view dashboard data for a specific date range:
                        </p>
                        <ul>
                            <li>Select a start date in the <code>From</code> date picker</li>
                            <li>Select an end date in the <code>To</code> date picker</li>
                        </ul>
                        <ArticleImage src={od04} alt="Date range"/>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_ACTIONS}/>
                        <p>The <code>Actions</code> dropdown button contains options for generating report files.</p>
                        <ArticleTitle level={4} pageDef={H_ACTIONS_NATIONAL_CONSOLIDATED}/>
                        <p>
                            You can generate a national consolidated XLSX report only if you have headquarters view permissions.
                            To generate a national consolidated XLSX report:
                        </p>
                        <ol>
                            <li>Select "All Regions" and "All Jails" in the data source selection filters</li>
                            <li>Choose a date range for the report to be generated</li>
                            <li>Click on the <code>Actions</code> dropdown button</li>
                            <li>Click <code>Generate National Consolidated XLSX Report</code> menu item</li>
                            <li>
                                It may take a few seconds for the process to complete. Depending on your browser,
                                the file may be saved automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                        </ol>
                        <ArticleImage src={od08} alt="Generating a National Consolidated XLSX Report"/>
                        <ArticleTitle level={4} pageDef={H_ACTIONS_REGIONAL_CONSOLIDATED}/>
                        <p>
                            You can generate a regional consolidated XLSX report only if you have regional view permissions.
                            To generate a regional consolidated XLSX report:
                        </p>
                        <ol>
                            <li>Select a region in the data source selection filters</li>
                            <li>Choose a date range for the report to be generated</li>
                            <li>Click on the <code>Actions</code> dropdown button</li>
                            <li>Click <code>Generate Regional Consolidated XLSX Report</code> menu item</li>
                            <li>
                                It may take a few seconds for the process to complete. Depending on your browser,
                                the file may be saved automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                        </ol>
                        <ArticleImage src={od05} alt="Generating a Regional Consolidated XLSX Report"/>
                        <ArticleTitle level={4} pageDef={H_ACTIONS_JAIL_XLSX}/>
                        <p>
                            To generate a jail level XLSX report:
                        </p>
                        <ol>
                            <li>Select a jail office in the data source selection filters</li>
                            <li>Choose a date range for the report to be generated</li>
                            <li>Click on the <code>Actions</code> dropdown button</li>
                            <li>Click <code>Generate Jail Level XLSX Report</code> menu item</li>
                            <li>
                                It may take a few seconds for the process to complete. Depending on your browser,
                                the file may be saved automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                        </ol>
                        <ArticleImage src={od06} alt="Generating a Jail Level XLSX Report"/>
                        <ArticleTitle level={4} pageDef={H_ACTIONS_DASHBOARD_PDF}/>
                        <p>To export the currently displayed dashboard data to a Portable Document File (PDF):</p>
                        <ol>
                            <li>Click on the <code>Actions</code> dropdown button</li>
                            <li>Click <code>Export Dashboard as PDF</code> menu item</li>
                            <li>
                                It may take a few seconds for the process to complete. Depending on your browser,
                                the file may be saved automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                            <li>
                                Note: When printing the PDF, always remember to enable the "Fit to Page" setting in the print setup.
                            </li>
                        </ol>
                        <ArticleImage src={od07} alt="Exporting the Dashboard as PDF"/>
                    </section>
                </Article>
            </div>
        );
    }
}

OverallDashboard.contents = contents;
OverallDashboard.pageId = P_PAGE;
export default OverallDashboard;