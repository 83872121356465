import React from "react";
import HepatitisMetrics from "@/screens/Dashboard/DiseaseScreening/CommunicableDiseases/HepatitisMetrics";
import HIVMetrics from "@/screens/Dashboard/DiseaseScreening/CommunicableDiseases/HIVMetrics";
import STDMetrics from "@/screens/Dashboard/DiseaseScreening/CommunicableDiseases/STDMetrics";

class CommunicableDiseases extends React.Component {
    render() {
        return (
            <div className="j-dash-communicable j-dash-box-subgroup">
                <h3>Communicable Disease Profile</h3>
                <div className="row">
                    <div className="col-lg-6">
                        <HIVMetrics/>
                    </div>
                    <div className="col-lg-6">
                        <STDMetrics/>
                        <div className="j-dash-print-force-br">
                            <HepatitisMetrics/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommunicableDiseases;