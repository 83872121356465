import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {config} from "@/config";

const attributes = {
    title: PropTypes.string,
    chartType: PropTypes.string,
    orderIndex: PropTypes.number,
    forceBreak: PropTypes.bool,
    indicators: PropTypes.array
};

export class DiseaseSurveillanceDashboardComponent extends JsonApiRecord(
    'disease-surveillance-dashboard-components',
    `${config.apiBaseUrl}/disease-surveillance-dashboard-components`,
    attributes
) {
    static get modelName() {
        return 'DiseaseSurveillanceDashboardComponent';
    }
}