import React from 'react';
import BaseIndicatorSubcategories from "@/screens/Configuration/IndicatorSubcategories";
import {IndicatorCategory} from "@/records/IndicatorCategory";
import {IndicatorSubcategory} from "@/records/IndicatorSubcategory";

class IndicatorSubcategories extends React.Component {
    render() {
        return (
            <BaseIndicatorSubcategories
                categoryResourceType={IndicatorCategory}
                subcategoryResourceType={IndicatorSubcategory}
                title="Indicator Subcategories"
                hasShowTotal
                hasQuarterly
            />
        );
    }
}

export default IndicatorSubcategories;