import {SET_CLIENT_INIT_TIME, SET_SYSTEM_TIME, SET_TIME_DIFF} from "@/actions/actionTypes";

const initialState = {
    systemTime: null,
    clientInitTime: null,
    timeDiff: 0
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_SYSTEM_TIME:
            return {...state, systemTime: action.payload};
        case SET_CLIENT_INIT_TIME:
            return {...state, clientInitTime: action.payload};
        case SET_TIME_DIFF:
            return {...state, timeDiff: action.payload};
        default:
            return state;
    }
}

export default reducer;