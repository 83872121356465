import React from "react";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import InfoBox from "@/components/InfoBox";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_DSTB_NEW_BC = 'default_63';
const TAG_DSTB_NEW_CD = 'default_64';
const TAG_DSTB_RET_BC = 'default_65';
// const TAG_DSTB_RET_CD = 'default_66';
const TAG_DRTB_NEW_BC = 'default_67';
const TAG_DRTB_NEW_CD = 'default_68';
const TAG_DRTB_RET_BC = 'default_69';
// const TAG_DRTB_RET_CD = 'default_70';

class BacteriologicallyConfirmed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pulmonaryNew: 0,
            pulmonaryRelapse: 0,
            pulmonaryTotal: 0,
            pulmonaryPct: 0,
            extraPulmonaryNew: 0,
            extraPulmonaryRelapse: 0,
            extraPulmonaryTotal: 0,
            extraPulmonaryPct: 0,
            totalNew: 0,
            totalRelapse: 0,
            total: 0,
            totalNewDSTB: 0,
            pctNewDSTB: 0,
            totalNewDRTB: 0,
            pctNewDRTB: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const dstbNewBc = getAggregateByTag(TAG_DSTB_NEW_BC);
        const dstbNewCd = getAggregateByTag(TAG_DSTB_NEW_CD);
        const dstbRetBc = getAggregateByTag(TAG_DSTB_RET_BC);
        // const dstbRetCd = getAggregateByTag(TAG_DSTB_RET_CD);
        const drtbNewBc = getAggregateByTag(TAG_DRTB_NEW_BC);
        const drtbNewCd = getAggregateByTag(TAG_DRTB_NEW_CD);
        const drtbRetBc = getAggregateByTag(TAG_DRTB_RET_BC);
        // const drtbRetCd = getAggregateByTag(TAG_DRTB_RET_CD);

        const pulmonaryNew = dstbNewBc.reportTotal + drtbNewBc.reportTotal;
        const pulmonaryRelapse = dstbRetBc.reportTotal + drtbRetBc.reportTotal;

        const extraPulmonaryNew = 0;
        const extraPulmonaryRelapse = 0;

        const pulmonaryTotal = pulmonaryNew + pulmonaryRelapse;
        const extraPulmonaryTotal = extraPulmonaryNew + extraPulmonaryRelapse;
        const total = pulmonaryTotal + extraPulmonaryTotal;

        const totalNewDSTB = dstbNewBc.reportTotal + dstbNewCd.reportTotal;
        const totalNewDRTB = drtbNewBc.reportTotal + drtbNewCd.reportTotal;
        const totalNewDSTBDRTB = totalNewDSTB + totalNewDRTB;

        this.setState({
            pulmonaryNew,
            pulmonaryRelapse,
            pulmonaryTotal,
            pulmonaryPct: pctTotalOver(pulmonaryTotal, total),
            extraPulmonaryNew,
            extraPulmonaryRelapse,
            extraPulmonaryTotal,
            extraPulmonaryPct: pctTotalOver(extraPulmonaryTotal, total),
            totalNew: pulmonaryNew + extraPulmonaryNew,
            totalRelapse: pulmonaryRelapse + extraPulmonaryRelapse,
            total,
            totalNewDSTB,
            pctNewDSTB: pctTotalOver(totalNewDSTB, totalNewDSTBDRTB),
            totalNewDRTB,
            pctNewDRTB: pctTotalOver(totalNewDRTB, totalNewDSTBDRTB)
        });
    };

    render() {
        const {
            pulmonaryNew, pulmonaryRelapse, pulmonaryTotal, pulmonaryPct,
            extraPulmonaryNew, extraPulmonaryRelapse, extraPulmonaryTotal, extraPulmonaryPct,
            totalNew, totalRelapse, total, totalNewDSTB, pctNewDSTB, totalNewDRTB, pctNewDRTB
        } = this.state;

        return (
            <div>
                <div className="j-dash-tabular-group">
                    <h4>Bacteriologically Confirmed DSTB and DRTB Cases By Registration Groups</h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Classification</th>
                            <th>New</th>
                            <th>Relapse</th>
                            <th>Total</th>
                            <th>Proportion</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Pulmonary</td>
                            <td>{pulmonaryNew}</td>
                            <td>{pulmonaryRelapse}</td>
                            <td>{pulmonaryTotal}</td>
                            <td>{pulmonaryPct}%</td>
                        </tr>
                        <tr>
                            <td>Extra Pulmonary</td>
                            <td>{extraPulmonaryNew}</td>
                            <td>{extraPulmonaryRelapse}</td>
                            <td>{extraPulmonaryTotal}</td>
                            <td>{extraPulmonaryPct}%</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{totalNew}</td>
                            <td>{totalRelapse}</td>
                            <td>{total}</td>
                            <td>100.00%</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="row">
                    <div className="col-xs-6">
                        <InfoBox className="simple-info-box"
                                 title="Proportion of Drug Susceptible TB" value={totalNewDSTB}
                                 progressDescription={`${pctNewDSTB}% of new cases are Drug Susceptible`}
                                 progress={pctNewDSTB} showProgress/>
                    </div>
                    <div className="col-xs-6">
                        <InfoBox className="simple-info-box"
                                 title="Proportion of Drug Resistant TB" value={totalNewDRTB}
                                 progressDescription={`${pctNewDRTB}% of new cases are Drug Resistant`}
                                 progress={pctNewDRTB} showProgress/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(BacteriologicallyConfirmed);