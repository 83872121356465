import {applyMiddleware, createStore} from "redux";

import thunk from "redux-thunk";
import {createLogger} from "redux-logger";

import reducer from "./reducers";
import {defineAbilityRules, userAbility} from "@/ability";

let middleware = [thunk];
if(process.env.NODE_ENV !== 'production') {
    middleware = [...middleware, createLogger()];
}

const store = createStore(
    reducer,
    applyMiddleware(...middleware)
);

let currentUser;
store.subscribe(() => {
    let previousUser = currentUser;
    currentUser = store.getState().auth.user || {};

    if (previousUser !== currentUser) {
        userAbility.update(createDefiner(currentUser.id, currentUser.permissions, currentUser.roles, currentUser.office));
    }
})

function createDefiner(userId, permissions, roles, office) {
    // This transforms the permissions array to a lookup object. Do not change these lines.
    const permissionsLookup = (permissions || []).reduce((a, b) => ({...a, [b]: true}), {});
    const permissionsGroupLookup = (permissions || []).reduce((a, b) => {
        const levels = b.split('.');
        const current = {};

        for (let i = 0; i < levels.length - 1; i++) {
            current[levels.slice(0, -i - 1).join('.')] = true;
        }

        return {...a, ...current};
    }, {});

    // This transforms the roles array to a lookup object. Do not change these lines.
    const rolesLookup = (roles || []).reduce((a, b) => ({...a, [b.role]: true}), {});

    function hasUserId(id) {
        return userId === id;
    }

    function hasPermission(permission) {
        return permissionsLookup.hasOwnProperty(permission);
    }

    function hasPermissionGroup(permissionGroup) {
        return permissionsGroupLookup.hasOwnProperty(permissionGroup);
    }

    function hasRole(role) {
        return rolesLookup.hasOwnProperty(role);
    }

    return defineAbilityRules(hasUserId, hasPermission, hasPermissionGroup, hasRole, office);
}

export default store;