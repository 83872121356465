import React from 'react';
import {NavLink} from "react-router-dom";
import ReportStatsTag from "@/components/ReportStatsTag";

class Menu extends React.Component {
    render() {
        const {
            path, indicatorsChanged, entryMorbiditiesChanged, dentalHealthIndicatorsChanged, 
            mentalHealthIndicatorsChanged, medicalProceduresChanged, detentionMorbiditiesChanged, medicationsChanged,
            causesOfDeathChanged, deathRecordsChanged,
            isEditMode, canManageEntry, submitting, finalized
        } = this.props;

        const anyChanged = (
            indicatorsChanged || entryMorbiditiesChanged || dentalHealthIndicatorsChanged
            || mentalHealthIndicatorsChanged || medicalProceduresChanged || detentionMorbiditiesChanged
            || medicationsChanged || causesOfDeathChanged || deathRecordsChanged
        );

        return (
            <div className="col-xs-3 config-menu">
                <div>
                    <h4>Reporting</h4>
                    <nav>
                        <ul>
                            <li>
                                <NavLink to={`${path}/indicators`}>
                                    Indicator Reporting Tool (J001)
                                    {
                                        indicatorsChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${path}/entry-morbidities`}>
                                    Morbidities Detected Upon Entry (J002)
                                    {
                                        entryMorbiditiesChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${path}/dental-health-indicators`}>
                                    Dental Health (J003)
                                    {
                                        dentalHealthIndicatorsChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${path}/mental-health-indicators`}>
                                    Mental Health (J004)
                                    {
                                        mentalHealthIndicatorsChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${path}/medical-procedures`}>
                                    Medical Procedures Requested (J005)
                                    {
                                        medicalProceduresChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${path}/detention-morbidities`}>
                                    Morbidities During Detention (J006)
                                    {
                                        detentionMorbiditiesChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${path}/medications`}>
                                    Medications (J007)
                                    {
                                        medicationsChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${path}/causes-of-death`}>
                                    Causes of Death (J008)
                                    {
                                        causesOfDeathChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`${path}/mortality-registry`}>
                                    Mortality Registry (J009)
                                    {
                                        deathRecordsChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    {
                        isEditMode &&
                        <div className="btn-block btn-group-vertical">
                            <button className="btn btn-default" onClick={this.props.onImportAllClick}>
                                <i className="fa fa-upload margin-r-5"/>Import All
                            </button>
                            <button className="btn btn-primary"
                                    disabled={!anyChanged} onClick={this.props.onSaveAllClick}>
                                <i className="fa fa-save margin-r-5"/>Save All
                            </button>
                            <button className="btn btn-success" disabled={anyChanged || submitting}
                                    onClick={this.props.onSubmitClick}>
                                {
                                    submitting ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-check margin-r-5"/>
                                }Submit
                            </button>
                        </div>
                    }
                    {
                        !isEditMode && canManageEntry && !finalized &&
                        <div className="btn-group-vertical btn-block">
                            <NavLink to={`${path.replace('view', 'edit')}`}
                                className="btn btn-default">
                                <i className="fa fa-pencil margin-r-5"/>Edit Report
                            </NavLink>
                            <button className="btn btn-success" disabled={anyChanged || submitting}
                                    onClick={this.props.onSubmitClick}>
                                {
                                    submitting ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-check margin-r-5"/>
                                }Submit
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Menu;