import React from "react";
import PropTypes from "prop-types";
import IndicatorReportItem from "./IndicatorReportItem";

class IndicatorReportGroup extends React.Component {
    render() {
        const {
            item, onIndicatorChange, isEditMode,
            notifyFocusChange, getPosition, currentFocus,
            indicatorValues, hasDataSource
        } = this.props;
        const {title, indicators, children, showTitle, quarterly} = item;

        const classNamePrefix = (hasDataSource ? 'j-indicator-report' : 'j-base-indicator-report');
        const className = `${classNamePrefix}-group` + (quarterly ? " quarterly" : "");
        return (
            <div className={className}>
                {showTitle && <h4>{title}</h4>}
                {
                    indicators.length > 0 &&
                    indicators.map(i => (
                        <IndicatorReportItem key={i.id} item={i} isEditMode={isEditMode}
                                             notifyFocusChange={notifyFocusChange}
                                             getPosition={getPosition} currentFocus={currentFocus}
                                             value={indicatorValues[i.id] || null}
                                             onIndicatorChange={onIndicatorChange}
                                             hasDataSource={hasDataSource}/>
                    ))
                }
                {
                    children.map(c => (
                        <IndicatorReportGroup indicatorValues={indicatorValues} key={c.id} item={c}
                                              notifyFocusChange={notifyFocusChange}
                                              getPosition={getPosition} currentFocus={currentFocus}
                                              onIndicatorChange={onIndicatorChange} isEditMode={isEditMode}
                                              hasDataSource={hasDataSource}/>
                    ))
                }
            </div>
        );
    }
}

IndicatorReportGroup.defaultProps = {
    indicatorValues: {}
};

IndicatorReportGroup.propTypes = {
    item: PropTypes.object.isRequired,
    onIndicatorChange: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    notifyFocusChange: PropTypes.func.isRequired,
    getPosition: PropTypes.func.isRequired,
    currentFocus: PropTypes.number,
    indicatorValues: PropTypes.object,
    hasDataSource: PropTypes.bool.isRequired
};

export default IndicatorReportGroup;