import React from "react";
import {createCancelToken, fetchResourceCollection, waitForAllRequests} from "@/utilities/jsonapi";
import {config} from "@/config";
import {Morbidity} from "@/records/Morbidity";
import {DetentionMorbidityAggregate} from "@/records/DetentionMorbidityAggregate";
import TopDetentionMorbiditiesChart
    from "@/screens/Dashboard/Treatment/DetentionMorbidities/TopDetentionMorbiditiesChart";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import {buildAttributeChartDataSet} from "@/utilities/dashboard";

class DetentionMorbidities extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            morbidityMap: null,
            topDetentionMorbiditiesDataSet: buildAttributeChartDataSet([], 'morbidity', "Number of Sick PDLS", '#3b8cb9')
        };

        this.fetchCancelToken = createCancelToken();
    }

    componentDidMount() {
        this.fetchDetentionMorbidities();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fromDate, toDate, office} = this.props;

        if (prevProps.office !== office || prevProps.fromDate !== fromDate || prevProps.toDate !== toDate) {
            this.fetchDetentionMorbidities();
        }
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    fetchDetentionMorbidities = () => {
        const {morbidityMap} = this.state;
        const {fromDate, toDate, office} = this.props;

        if (!fromDate || !toDate || !office) {
            return null;
        }

        const params = {
            f: fromDate.format('YYYY-MM-DD'),
            t: toDate.format('YYYY-MM-DD')
        };

        let endpointSubPath;
        switch (office.officeType) {
            case OFFICE_TYPE_HQ:
                endpointSubPath = 'headquarters';
                break;
            case OFFICE_TYPE_REGIONAL:
                endpointSubPath = 'region';
                break;
            case OFFICE_TYPE_JAIL_LEVEL:
                endpointSubPath = 'jail-level-office';
                break;
            default:
                return;
        }
        const detentionMorbiditiesEndpoint = `${config.apiBaseUrl}/reports/detention-morbidity-aggregates` +
            `/${endpointSubPath}/${office.id}`;

        this.fetchCancelToken.cancel();
        this.fetchCancelToken = createCancelToken();
        if (!morbidityMap) {
            waitForAllRequests([
                fetchResourceCollection(Morbidity),
                fetchResourceCollection(DetentionMorbidityAggregate, params, detentionMorbiditiesEndpoint)
            ]).onSuccess(([morbidities, aggregates]) => {
                const morbidityMap = {};
                morbidities.forEach(m => {
                    morbidityMap[m.id] = m;
                });

                this.setState({morbidityMap});
                this.setDataSet(aggregates, morbidityMap);
            }).execute(this.fetchCancelToken);
        } else {
            fetchResourceCollection(DetentionMorbidityAggregate, params, detentionMorbiditiesEndpoint)
                .onSuccess(aggregates => this.setDataSet(aggregates, morbidityMap))
                .execute(this.fetchCancelToken);
        }
    };

    setDataSet = (aggregates, morbidityMap) => {
        aggregates = aggregates.map(a => a.set('morbidity', morbidityMap[a.morbidity.id]));
        this.setState({
            topDetentionMorbiditiesDataSet: buildAttributeChartDataSet(aggregates, 'morbidity', "Number of Sick PDLs", '#77a1ca')
        });
    };

    render() {
        const {topDetentionMorbiditiesDataSet} = this.state;

        return (
            <div className="j-dash-box-subgroup">
                <h3>Morbidities During Detention</h3>
                <div className="row">
                    <div className="col-lg-10 col-xs-12 col-lg-offset-1">
                        <TopDetentionMorbiditiesChart data={topDetentionMorbiditiesDataSet}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetentionMorbidities;