import React from 'react';
import Box from '@vallarj/react-adminlte/Box';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import OfficeIndexItem from "@/screens/AccessManagement/Offices/OfficeIndexItem";
import {noop} from "@/utilities/noop";
import {createCancelToken, fetchResourceCollection} from "@/utilities/jsonapi";
import {Office} from "@/records/Office";
import {escapeRegExp} from "@/utilities/highlight-string";
import OfficeModal from "@/screens/AccessManagement/Offices/OfficeModal";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import SelectInput from "@vallarj/react-adminlte/Form/SelectInput";

class Offices extends React.Component {
    constructor(props) {
        super(props);

        this.officeTypes = {
            headquarters: "Headquarters",
            regional: "Regional Office",
            jail_level: "Jail Level"
        };

        this.state = {
            offices: [],
            regions: [],
            headquarters: null,
            isLoading: true,
            search: null
        };

        this.officeTypeOptions = [
            {label: 'Regional Office', value: OFFICE_TYPE_REGIONAL},
            {label: 'Jail Level', value: OFFICE_TYPE_JAIL_LEVEL}
        ];

        this.openModal = noop;
        this.fetchCancelToken = createCancelToken();
    }

    componentDidMount() {
        this.fetchOffices();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    fetchOffices = updatedItem => {
        this.setState({isLoading: true});
        fetchResourceCollection(Office)
            .onSuccess(collection => {
                const regions = collection.filter(o => o.officeType === OFFICE_TYPE_REGIONAL);
                const headquarters = collection.find(o => o.officeType === OFFICE_TYPE_HQ);

                let officeType, region;
                if (updatedItem) {
                    officeType = updatedItem.officeType;
                    if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
                        region = regions.find(r => r.id === updatedItem.parent.id);
                    }
                } else {
                    officeType = OFFICE_TYPE_REGIONAL;
                    region = regions[0];
                }

                this.setState({
                    offices: collection,
                    officeType,
                    region,
                    regions,
                    headquarters,
                    isLoading: false
                });
            }).execute(this.fetchCancelToken);
    };

    handleCreateOfficeClick = () => {
        this.openModal('create', new Office());
    };

    handleEditOfficeClick = office => {
        this.openModal('edit', office);
    };

    handleDeleteOfficeClick = office => {
        this.openModal('delete', office);
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});
    };

    handleRegionChange = (_, value) => {
        this.setState({region: value});
    };

    handleOfficeTypeChange = (_, value) => {
        this.setState({officeType: value});

        if (value === OFFICE_TYPE_JAIL_LEVEL) {
            this.setState({region: this.state.regions[0]});
        }
    };

    filterOffices = () => {
        const {officeType, region} = this.state;
        let offices = [];
        if (officeType === OFFICE_TYPE_REGIONAL) {
            offices = this.state.offices.filter(o => o.officeType === OFFICE_TYPE_REGIONAL);
        } else {
            if (!region) {
                return [];
            }

            offices = this.state.offices.filter(o => o.parent && o.parent.id === region.id);
        }
        const search = (this.state.search && this.state.search.trim()) || "";

        if (!search) {
            return offices;
        }

        return offices.filter(o => o.name.match(RegExp(escapeRegExp(search), "i")));
    };

    getLabelByName = option => option.name;
    getValueById = option => option.id;

    render() {
        const {search, isLoading, regions, headquarters, region, officeType} = this.state;
        const offices = this.filterOffices();

        return (<>
            <OfficeModal openModalRef={this.registerOpenModal}
                         onSuccess={this.fetchOffices}
                         officeTypes={this.officeTypes}
                         regions={regions}
                         headquarters={headquarters}/>
            <Box theme="box-primary" isLoading={isLoading}>
                <Box.Header title="Offices"/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..."
                               value={search} onChange={this.handleSearchChange}/>
                    {
                        officeType === OFFICE_TYPE_JAIL_LEVEL ?
                        <div className="row">
                            <div className="col-lg-6">
                                <SelectInput name="officeType" label="Office Type" value={officeType}
                                             onChange={this.handleOfficeTypeChange}
                                             options={this.officeTypeOptions} simpleValue/>
                            </div>
                            <div className="col-lg-6">
                                <SelectInput name="region" label="Region" value={region} options={regions}
                                             onChange={this.handleRegionChange}
                                             getOptionLabel={this.getLabelByName}
                                             getOptionValue={this.getValueById}/>
                            </div>
                        </div>
                        :
                        <SelectInput name="officeType" label="Office Type" value={officeType}
                                     onChange={this.handleOfficeTypeChange}
                                     options={this.officeTypeOptions} simpleValue/>
                    }
                    <div className="j-offices j-box-scroll">
                        <div className="j-offices-header">
                            <span>Order Index</span>
                            <span>Office</span>
                        </div>
                        {
                            offices.map(o => (
                                <OfficeIndexItem key={o.id} office={o} officeTypes={this.officeTypes}
                                                 onEditClick={this.handleEditOfficeClick}
                                                 onDeleteClick={this.handleDeleteOfficeClick}
                                                 search={search} />
                            ))
                        }
                    </div>
                </Box.Body>
                <Box.Footer>
                    <button className="btn btn-primary pull-right" onClick={this.handleCreateOfficeClick}>
                        <i className="fa fa-plus-circle margin-r-5"/>Create New Office
                    </button>
                </Box.Footer>
            </Box>
        </>);
    }
}

export default Offices;