import {fetchAndFilterResourceCollection, fetchResourceCollection, waitForAllRequests} from "@/utilities/jsonapi";
import {DataSource} from "@/records/DataSource";

export function fetchIndicatorStructure(
    categoryResourceType,
    subcategoryResourceType,
    indicatorResourceType,
    hasDataSource,
    cancelToken,
    month
) {
    return new Promise(resolve => {
        waitForAllRequests([
            fetchResourceCollection(categoryResourceType),
            fetchAndFilterResourceCollection(subcategoryResourceType, item => !(item.relationships || {}).parent),
            fetchResourceCollection(indicatorResourceType),
            ...hasDataSource ? [fetchResourceCollection(DataSource)] : []
        ]).onSuccess(([categories, subcategories, indicators, dataSources]) => {
            dataSources = dataSources || [];

            // Restructure subcategories for each category
            const mappedSubcategories = {};
            const activeSubcategories = getActiveSubcategories(subcategories, month);
            subcategories.forEach(s => {
                if (activeSubcategories.hasOwnProperty(s.id)) {
                    if (!mappedSubcategories.hasOwnProperty(s.category.id)) {
                        mappedSubcategories[s.category.id] = [];
                    }

                    mappedSubcategories[s.category.id].push(s);
                }
            });

            // Restructure indicators for each subcategory
            const mappedIndicators = {};
            const indicatorMap = {};
            indicators.forEach(i => {
                if (i.active && activeSubcategories.hasOwnProperty(i.subcategory.id)) {
                    if (!mappedIndicators.hasOwnProperty(i.subcategory.id)) {
                        mappedIndicators[i.subcategory.id] = [];
                    }

                    mappedIndicators[i.subcategory.id].push(i);
                    indicatorMap[i.id] = i;
                }
            });

            // Restructure data sources
            const dataSourceMap = {};
            dataSources.forEach(d => {
                dataSourceMap[d.id] = d;
            });

            const structure = [];
            categories.forEach((c, i) => {
                if (c.active) {
                    structure.push(buildStructureItem(
                        c.id,
                        `${i+1}. ${c.name}`,
                        true,
                        false,
                        [],
                        mappedSubcategories[c.id] || [],
                        mappedIndicators,
                        hasDataSource,
                        dataSourceMap
                    ));
                }
            });

            resolve({structure, indicatorMap});
        }).execute(cancelToken);
    });
}

export function resolveNumericValueFromCell(cell) {
    if (!cell || typeof cell.v !== 'number') {
        return undefined;
    }

    return cell.v;
}

function getActiveSubcategories(subcategories, month) {
    let activeSubcategories = {};
    subcategories.forEach(s => {
        if (s.active && (!s.quarterly || (month % 3 === 0))) {
            activeSubcategories[s.id] = true;
            if (s.children.length > 0) {
                activeSubcategories = {...activeSubcategories, ...getActiveSubcategories(s.children)};
            }
        }
    });
    return activeSubcategories;
}

function buildStructureItem(id, title, showTitle, quarterly, indicators, subcategories, indicatorMap, hasDataSource, dataSourceMap) {
    const item = {id, title, showTitle, indicators, quarterly, children: []};

    subcategories.forEach(s => {
        if (s.active) {
            item.children.push(buildStructureItem(
                s.id,
                s.name,
                s.showHeader,
                !!s.quarterly,
                (indicatorMap[s.id] || []).map(i => ({
                    id: i.id,
                    name: i.name,
                    special: !!i.special,
                    dataSources: hasDataSource ? i.dataSources.map(ds => dataSourceMap[ds.id].name).join('/') : ""
                })),
                s.children,
                indicatorMap,
                hasDataSource,
                dataSourceMap
            ));
        }
    });

    return item;
}

export function processWorksheet(workbook, sheetIndex, startRow, idCol, maleCol, femaleCol, idPrefix = "") {
    return new Promise(resolve => {
        const sheetName = workbook.SheetNames[sheetIndex];
        const worksheet = workbook.Sheets[sheetName];
        const items = {};

        for (let i = startRow, idCell = `${idCol}${i}`; worksheet.hasOwnProperty(idCell); i++, idCell = `${idCol}${i}`) {
            const {v: idVal} = worksheet[idCell];
            if (!worksheet[idCell].v) {
                break;
            }

            if (idVal.startsWith(idPrefix)) {
                const maleCell = `${maleCol}${i}`;
                const femaleCell = `${femaleCol}${i}`;
                const maleVal = resolveNumericValueFromCell(worksheet[maleCell]);
                const femaleVal = resolveNumericValueFromCell(worksheet[femaleCell]);

                if (maleVal !== undefined || femaleVal !== undefined) {
                    items[idVal.slice(idPrefix.length)] = {
                        male: maleVal || 0,
                        female: femaleVal || 0
                    };
                }
            }
        }

        resolve(items);
    });
}