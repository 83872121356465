import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {MentalHealthIndicatorSubcategory} from "@/records/MentalHealthIndicatorSubcategory";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    summaryType: PropTypes.string,
    active: PropTypes.bool
};

const toOneRelationships = {
    subcategory: () => MentalHealthIndicatorSubcategory
};

const meta = {
    inUse: PropTypes.bool,
    tag: PropTypes.string
};

export class MentalHealthIndicator extends JsonApiRecord(
    'mental-health-indicators',
    `${config.apiBaseUrl}/mental-health-indicators`,
    attributes,
    toOneRelationships,
    {},
    meta
) {
    static get modelName() {
        return 'MentalHealthIndicator';
    }
}