import React from "react";

class Distribution extends React.Component {
    render() {
        const {
            pcrTested, pcrConfirmed, pctPcrConfirmed,
            ratTested, ratPositive, pctRatPositive,
            totalTested, totalPositive, pctTotalPositive
        } = this.props;

        return (
            <div className="j-dash-tabular-group">
                <h4>Distribution of Tested Detainees</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Classification</th>
                        <th>PCR</th>
                        <th>RAT</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Total Tested</td>
                        <td>{pcrTested}</td>
                        <td>{ratTested}</td>
                        <td>{totalTested}</td>
                    </tr>
                    <tr>
                        <td>Confirmed/Positive</td>
                        <td>{pcrConfirmed}</td>
                        <td>{ratPositive}</td>
                        <td>{totalPositive}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>% Confirmed</td>
                        <td>{pctPcrConfirmed}%</td>
                        <td>{pctRatPositive}%</td>
                        <td>{pctTotalPositive}%</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}

export default Distribution;