import React from 'react';

import Modal from '@vallarj/react-adminlte/Modal';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import CheckBox from '@vallarj/react-adminlte/Form/CheckBox';
import SelectInput from '@vallarj/react-adminlte/Form/SelectInput';
import {permissionOrderComparator} from "@/utilities/permissions";
import transformFormErrors from "@/utilities/transform-form-errors";
import {notifyFormError, notifyItemRequestSuccess, notifyUnableToProcess} from "@/utilities/notifications";
import {PERM_JRS_USERS_MANAGE_ROLES} from "@/permissions";
import {createCancelToken, deleteResource, patchResource, postResource} from "@/utilities/jsonapi";

class RoleModal extends React.Component {
    constructor(props) {
        super(props);

        if (props.openModalRef) {
            props.openModalRef(this.handleOpenModal)
        }

        this.nullRoleType = {
            id: "__NULL",
            name: "All"
        };

        this.state = {
            show: false,
            isProcessing: false,
            view: 'create',
            role: {},
            sortedPermissions: this.sortPermissions(props.permissions || {}),
            roleTypeOptions: this.getRoleTypeOptions(props.roleTypes || {}),
            errors: {}
        };

        this.requestCancelToken = createCancelToken();
    }

    componentWillUnmount() {
        this.requestCancelToken.cancel();
    }

    handleExit = () => {
        this.setState({
            show: false,
            isProcessing: false,
            view: 'create',
            role: {},
            errors: {}
        });
    };

    sortPermissions = permissions => {
        const orderedPermissions = Object.values(permissions)
            .sort((a, b) => permissionOrderComparator(a.id, b.id));

        const groups = {
            'jrs.configuration': [],
            'jrs.offices': [],
            'jrs.jail_level_reports': [],
            'jrs.users.headquarters': [],
            'jrs.users.regional' : [],
            'jrs.users.jail_level': [],
            'jrs.reports': [],
            others: []
        };

        orderedPermissions.forEach(p => {
            const groupSplit = p.id.split('.');
            const group = groupSplit.slice(0, 2).join('.');
            const specificGroup = group + '.' + groupSplit.slice(-1);

            if (p.id === PERM_JRS_USERS_MANAGE_ROLES) {
                groups['jrs.users.headquarters'].push(p);
            } else if (groups.hasOwnProperty(group)) {
                groups[group].push(p);
            } else if (groups.hasOwnProperty(specificGroup)) {
                groups[specificGroup].push(p);
            } else {
                groups.others.push(p);
            }
        });

        return groups;
    };

    getRoleTypeOptions = roleTypes => {
        const roleTypeOptions = [this.nullRoleType];

        Object.keys(roleTypes).forEach(r => {
            roleTypeOptions.push(roleTypes[r]);
        });

        return roleTypeOptions;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {permissions, roleTypes} = this.props;

        if (permissions !== prevProps.permissions) {
            this.setState({sortedPermissions: this.sortPermissions(permissions)});
        }

        if (roleTypes !== prevProps.roleTypes) {
            this.setState({roleTypeOptions: this.getRoleTypeOptions(roleTypes)});
        }
    }

    handleOpenModal = (view, role) => {
        this.setState({
            show: true,
            view,
            role
        });
    };

    handleCloseClick = () => {
        const {isProcessing} = this.state;
        if (!isProcessing) {
            this.setState({show: false});
        }
    };

    handleNameChange = (_, value) => {
        this.setState({
            role: this.state.role.update('name', value)
        });
    };

    handleRoleTypeChange = (_, value) => {
        if (value === this.nullRoleType) {
            value = null;
        }

        this.setState({
            role: this.state.role.update('roleType', value)
        });
    };

    handlePermissionsChange = (permissionId, value) => {
        const {role} = this.state;
        const {permissions} = this.props;

        const newPermissions = role.permissions.filter(p => p.id !== permissionId);

        if (value) {
            newPermissions.push(permissions[permissionId]);
        }

        this.setState({
            role: role.update('permissions', newPermissions)
        });
    };

    handleCreateClick = () => {
        const {onCreate} = this.props;
        let role = this.state.role;
        const id = (role.name ? role.name.trim() : "").replace(/\W+/g, "_").toLowerCase();

        this.setState({isProcessing: true});
        postResource(role.update('id', id), true)
            .onSuccess(role => {
                onCreate(role);
                notifyItemRequestSuccess("Create Role", "Role successfully created",
                    `[${role.id}] ${role.name}`);
                this.setState({show: false});
            })
            .onError(err => {
                this.setState({isProcessing: false});
                if (err.isValidationError) {
                    this.setState({errors: err.errors});
                    notifyFormError("Create Role");
                } else {
                    throw err;
                }
            })
            .setValidationErrorTransformer(errors => {
                return transformFormErrors(errors, pointer => {
                    if (pointer === '/data/id' && id) {
                        return {
                            key: 'name',
                            detail: 'Role name already exists.'
                        };
                    }
                });
            })
            .execute(this.requestCancelToken);
    };

    handleSaveClick = () => {
        const {onEdit} = this.props;
        let role = this.state.role;

        this.setState({isProcessing: true});
        patchResource(role)
            .onSuccess(() => {
                onEdit(role);
                notifyItemRequestSuccess("Edit Role", "Role successfully updated.");
                this.setState({show: false});
            })
            .onError(err => {
                this.setState({isProcessing: false});
                if (err.isValidationError) {
                    this.setState({errors: err.errors});
                    notifyFormError("Edit Role");
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    handleDeleteClick = () => {
        const {onDelete} = this.props;
        const {role} = this.state;

        this.setState({isProcessing: true});
        deleteResource(role)
            .onSuccess(() => {
                onDelete(this.state.role);
                notifyItemRequestSuccess("Delete Role", "Role successfully deleted");
                this.setState({show: false});
            })
            .onError(err => {
                notifyUnableToProcess("Delete Role");
                this.setState({show: false});
            })
            .execute(this.requestCancelToken);
    };

    getValueById = option => option.id;
    getLabelByName = option => option.name;

    renderRoleView = () => {
        const {role, sortedPermissions, roleTypeOptions, errors, view} = this.state;

        const rolePermissions = {};
        (role.permissions || []).forEach(p => {rolePermissions[p.id] = true});
        const roleType = role.roleType ? roleTypeOptions.find(rt => rt.id === role.roleType.id) : this.nullRoleType;

        return (<>
            <TextInput label="Role Name" name="name" value={role.name}
                       errors={errors} onChange={this.handleNameChange}/>
            <SelectInput label="Role Visibility" name="roleType"
                         value={roleType}
                         getOptionValue={this.getValueById}
                         getOptionLabel={this.getLabelByName}
                         options={roleTypeOptions}
                         errors={errors} onChange={this.handleRoleTypeChange} disabled={view !== 'create'}/>
            <h3>Configuration Permissions</h3>
            <div>
                {
                    sortedPermissions['jrs.configuration'].map(p => (
                        <CheckBox key={p.id} label={p.description} name={p.id}
                                  onChange={this.handlePermissionsChange} checked={rolePermissions[p.id]}/>
                    ))
                }
            </div>
            <h3>Office Management Permissions</h3>
            <div>
                {
                    sortedPermissions['jrs.offices'].map(p => (
                        <CheckBox key={p.id} label={p.description} name={p.id}
                                  onChange={this.handlePermissionsChange} checked={rolePermissions[p.id]}/>
                    ))
                }
            </div>
            <h3>HQ Access Management Permissions</h3>
            <div>
                {
                    sortedPermissions['jrs.users.headquarters'].map(p => (
                        <CheckBox key={p.id} label={p.description} name={p.id}
                                  onChange={this.handlePermissionsChange} checked={rolePermissions[p.id]}/>
                    ))
                }
            </div>
            <h3>Regional Office Access Management Permissions</h3>
            <div>
                {
                    sortedPermissions['jrs.users.regional'].map(p => (
                        <CheckBox key={p.id} label={p.description} name={p.id}
                                  onChange={this.handlePermissionsChange} checked={rolePermissions[p.id]}/>
                    ))
                }
            </div>
            <h3>Jail Level Access Management Permissions</h3>
            <div>
                {
                    sortedPermissions['jrs.users.jail_level'].map(p => (
                        <CheckBox key={p.id} label={p.description} name={p.id}
                                  onChange={this.handlePermissionsChange} checked={rolePermissions[p.id]}/>
                    ))
                }
            </div>
            <h3>Jail Level Report Permissions</h3>
            <div>
                {
                    sortedPermissions['jrs.jail_level_reports'].map(p => (
                        <CheckBox key={p.id} label={p.description} name={p.id}
                                  onChange={this.handlePermissionsChange} checked={rolePermissions[p.id]}/>
                    ))
                }
            </div>
            <h3>Dashboard and Report Generation</h3>
            <div>
                {
                    sortedPermissions['jrs.reports'].map(p => (
                        <CheckBox key={p.id} label={p.description} name={p.id}
                                  onChange={this.handlePermissionsChange} checked={rolePermissions[p.id]}/>
                    ))
                }
            </div>
            {
                sortedPermissions.others && sortedPermissions.others.length > 0 &&
                <>
                    <h3>Other Permissions</h3>
                    <div>
                        {
                            sortedPermissions.others.map(p => (
                                <CheckBox key={p.id} label={p.description} name={p.id}
                                          onChange={this.handlePermissionsChange} checked={rolePermissions[p.id]}/>
                            ))
                        }
                    </div>
                </>
            }
        </>);
    };

    renderConfirmDeleteView = () => {
        const {role} = this.state;
        const {permissions} = this.props;

        const orderedPermissions = [...role.permissions]
            .sort((a, b) => permissionOrderComparator(a.id, b.id))
            .map(p => permissions[p.id]);

        return (
            <div className="j-role-modal-confirm-delete">
                <p>Are you sure you want to delete this role? <strong>This action cannot be undone.</strong></p>
                <table>
                    <tbody>
                    <tr>
                        <td>Role Name</td>
                        <td>{role.name}</td>
                    </tr>
                    <tr>
                        <td>Permissions</td>
                        <td>
                            {
                                !role.permissions.length ? "No Permissions" :
                                <ul>
                                    {
                                        orderedPermissions.map(p => (
                                            <li key={p.id}>{p.description}</li>
                                        ))
                                    }
                                </ul>
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    renderHeader = () => {
        const {view} = this.state;

        switch (view) {
            case 'create':
                return 'Create New Role';
            case 'edit':
                return 'Edit Role';
            case 'delete':
                return 'Confirm Delete Role';
            default:
                return '';
        }
    };

    renderBody = () => {
        const {view} = this.state;

        switch (view) {
            case 'create':
            case 'edit':
                return this.renderRoleView();
            case 'delete':
                return this.renderConfirmDeleteView();
            default:
                return null;
        }
    };

    renderFooter = () => {
        const {view, isProcessing} = this.state;

        switch (view) {
            case 'create':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={this.handleCloseClick}
                                disabled={isProcessing}>
                            Cancel
                        </button>
                        <button className="btn btn-primary pull-right" onClick={this.handleCreateClick}
                                disabled={isProcessing}>
                            <i className="fa fa-plus-circle margin-r-5"/>Create
                        </button>
                    </>
                );
            case 'edit':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={this.handleCloseClick}
                                disabled={isProcessing}>
                            Cancel
                        </button>
                        <button className="btn btn-primary pull-right" onClick={this.handleSaveClick}
                                disabled={isProcessing}>
                            <i className="fa fa-save margin-r-5"/>Save
                        </button>
                    </>
                );
            case 'delete':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={this.handleCloseClick}
                                disabled={isProcessing}>
                            Cancel
                        </button>
                        <button className="btn btn-danger pull-right" onClick={this.handleDeleteClick}
                                disabled={isProcessing}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </>
                );
            default:
                return null;
        }
    };

    render() {
        const {isProcessing, show} = this.state;

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick}
                   isLoading={isProcessing} className="j-role-modal" fixedScroll>
                <Modal.Header>{this.renderHeader()}</Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>{this.renderFooter()}</Modal.Footer>
            </Modal>
        );
    }
}

export default RoleModal;