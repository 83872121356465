import React from 'react';
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_JAIL_POP = 'default_2';
const TAG_HIV_SCREENED = 'default_40';
const TAG_HIV_TESTED = 'default_41';
const TAG_HIV_ON_TREATMENT = 'default_103';
const TAG_HIV_ON_IPT = 'default_104';
const TAG_PREVALENCE = 'default_43';

class HIVMetrics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hivScreened: 0,
            hivTested: 0,
            hivOnTreatment: 0,
            hivOnIPT: 0,
            prevalence: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const jailPop = getAggregateByTag(TAG_JAIL_POP);
        const hivScreened = getAggregateByTag(TAG_HIV_SCREENED);
        const hivTested = getAggregateByTag(TAG_HIV_TESTED);
        const hivOnTreatment = getAggregateByTag(TAG_HIV_ON_TREATMENT);
        const hivOnIPT = getAggregateByTag(TAG_HIV_ON_IPT);
        const prevalence = getAggregateByTag(TAG_PREVALENCE);

        this.setState({
            hivScreened: hivScreened.reportTotal,
            hivTested: pctTotalOver(hivTested.reportTotal, hivScreened.reportTotal),
            hivOnTreatment: hivOnTreatment.reportTotal,
            hivOnIPT: hivOnIPT.reportTotal,
            prevalence: pctTotalOver(prevalence.reportTotal, jailPop.reportTotal)
        });
    };

    render() {
        const {hivScreened, hivTested, hivOnTreatment, hivOnIPT, prevalence} = this.state;

        return (
            <div className="j-dash-tabular-group two-column">
                <table>
                    <thead>
                    <tr>
                        <th colSpan={2} className="text-center">HIV</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Number Screened</td>
                        <td>{hivScreened}</td>
                    </tr>
                    <tr>
                        <td>% Tested</td>
                        <td>{hivTested}%</td>
                    </tr>
                    <tr>
                        <td>On Treatment</td>
                        <td>{hivOnTreatment}</td>
                    </tr>
                    <tr>
                        <td>On IPT</td>
                        <td>{hivOnIPT}</td>
                    </tr>
                    <tr>
                        <td>Prevalence</td>
                        <td>{prevalence}%</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withAggregateTagMap(HIVMetrics);