import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {Role} from "@/records/Role";
import {Office} from "@/records/Office";
import {config} from "@/config";

const attributes = {
    username: PropTypes.string,
    oldPassword: PropTypes.string,
    password: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    resetPassword: PropTypes.bool,
    active: PropTypes.bool,
    temporaryPassword: PropTypes.string,
    usesTemporaryPassword: PropTypes.bool,
};

const toOneRelationships = {
    office: () => Office
};

const toManyRelationships = {
    roles: Role
};

export class User extends JsonApiRecord(
    'users',
    `${config.apiBaseUrl}/users`,
    attributes,
    toOneRelationships,
    toManyRelationships
) {
    static get modelName() {
        return 'User';
    }

    get displayName() {
        let name = "";
        const firstName = this.firstName && this.firstName.trim();
        const middleName = this.middleName && this.middleName.trim();
        const lastName = this.lastName && this.lastName.trim();

        if (firstName) {
            name += firstName;
        }

        if (middleName) {
            name += ` ${middleName}`;
        }

        if (lastName) {
            name += ` ${lastName}`;
        }

        return name;
    }
}