import React from "react";
import Article from "@/screens/Docs/Article";
import ArticleImage from "@/screens/Docs/Article/ArticleImage";
import ReportStatsTag from "@/components/ReportStatsTag";
import dsr01 from './images/dsr-01.jpg';
import dsr02 from './images/dsr-02.jpg';
import dsr03 from './images/dsr-03.jpg';
import dsr04 from './images/dsr-04.jpg';
import dsr05 from './images/dsr-05.jpg';
import dsr06 from './images/dsr-06.jpg';
import dsr07 from './images/dsr-07.jpg';
import dsr08 from './images/dsr-08.jpg';
import dsr09 from './images/dsr-09.jpg';
import dsr10 from './images/dsr-10.jpg';
import dsr11 from './images/dsr-11.jpg';
import dsr12 from './images/dsr-12.jpg';
import dsr13 from './images/dsr-13.jpg';
import dsr14 from './images/dsr-14.jpg';
import dsr15 from './images/dsr-15.jpg';
import {createPageDefinition} from "@/screens/Docs/utilities";
import ArticleTitle from "@/screens/Docs/Article/ArticleTitle";

const P_DS_REPORTS = 'ds-reports';
const H_INDEX_PAGE = createPageDefinition(P_DS_REPORTS, 'index-page', 'Index Page');
const H_INDEX_PAGE_REPORT_LIST = createPageDefinition(P_DS_REPORTS, 'index-page-list-selection', 'Report List Selection');
const H_INDEX_PAGE_TEMPLATE = createPageDefinition(P_DS_REPORTS, 'index-page-template', 'Disease Surveillance XLSX Template');
const H_INDEX_PAGE_STATUS_TAGS = createPageDefinition(P_DS_REPORTS, 'index-page-status-tags', 'Status Tags');
const H_INDEX_PAGE_VIEW = createPageDefinition(P_DS_REPORTS, 'index-page-view', 'Viewing a Disease Surveillance Report Entry');
const H_INDEX_PAGE_PREPARE = createPageDefinition(P_DS_REPORTS, 'index-page-prepare', 'Preparing a Disease Surveillance Report');
const H_INDEX_PAGE_EDIT = createPageDefinition(P_DS_REPORTS, 'index-page-edit', 'Editing a Disease Surveillance Report');
const H_INDEX_PAGE_RETURN = createPageDefinition(P_DS_REPORTS, 'index-page-return', 'Returning a Report for Changes');
const H_VIEW = createPageDefinition(P_DS_REPORTS, 'view', 'View Disease Surveillance Report');
const H_EDIT = createPageDefinition(P_DS_REPORTS, 'edit', 'Edit Disease Surveillance Report');
const H_EDIT_OVERVIEW = createPageDefinition(P_DS_REPORTS, 'edit-overview', 'Overview');
const H_EDIT_FORM_VIEW = createPageDefinition(P_DS_REPORTS, 'edit-form-view', 'Form View');
const H_EDIT_IMPORT = createPageDefinition(P_DS_REPORTS, 'edit-import', 'Importing Reports using the XLSX Template');
const H_EDIT_SAVE = createPageDefinition(P_DS_REPORTS, 'edit-save', 'Saving a Disease Surveillance Report');
const H_EDIT_SUBMIT = createPageDefinition(P_DS_REPORTS, 'edit-submit', 'Submitting a Disease Surveillance Report');
const H_EDIT_ERRORS = createPageDefinition(P_DS_REPORTS, 'edit-errors', 'Errors Encountered When Submitting Disease Surveillance Reports');

const contents = [
    {
        ...H_INDEX_PAGE,
        contents: [
            H_INDEX_PAGE_REPORT_LIST,
            H_INDEX_PAGE_TEMPLATE,
            H_INDEX_PAGE_STATUS_TAGS,
            H_INDEX_PAGE_VIEW,
            H_INDEX_PAGE_PREPARE,
            H_INDEX_PAGE_EDIT,
            H_INDEX_PAGE_RETURN
        ]
    },
    H_VIEW,
    {
        ...H_EDIT,
        contents: [
            H_EDIT_OVERVIEW,
            H_EDIT_FORM_VIEW,
            H_EDIT_IMPORT,
            H_EDIT_SAVE,
            H_EDIT_SUBMIT,
            H_EDIT_ERRORS
        ]
    }
];

class DiseaseSurveillanceReports extends React.Component {
    render() {
        return (
            <div className="row">
                <Article title="Disease Surveillance Reports" id={P_DS_REPORTS}
                         contentTitle="You can prepare, manage, and submit jail office daily disease surveillance reports on the Disease Surveillance Reports page."
                         contents={contents}>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_INDEX_PAGE}/>
                        <p>
                            The index page lists all prepared daily disease surveillance reports by year and month,
                            along with their current statuses.
                        </p>
                        <ArticleImage src={dsr01} alt="Index Page"/>
                        <h4>Notes</h4>
                        <ul>
                            <li>Users at the jail level would only be able to view reports from their office</li>
                            <li>Regional level users would be able to view reports from jail offices under their region</li>
                            <li>HQ level users would be able to view reports from all jail offices</li>
                        </ul>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_REPORT_LIST}/>
                        <p>You can choose which reports to display in the reports list by using the dropdown selectors.</p>
                        <ol>
                            <li>Select the year in the <code>Year</code> dropdown</li>
                            <li>Select the month in the <code>Month</code> dropdown</li>
                            <li>Select the jail office in the <code>Office</code> dropdown <em>(Regional and HQ Users only)</em></li>
                            <li>Select the region in the <code>Region</code> dropdown <em>(HQ Users only)</em></li>
                        </ol>
                        <ArticleImage src={dsr02} alt="Report List Selection"/>

                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_TEMPLATE}>
                            {" "}<em>(Jail Level Encoder)</em>
                        </ArticleTitle>
                        <p>
                            You can generate a report XLSX template which can be used to import a disease surveillance
                            report into the system. To generate an XLSX report template:
                        </p>
                        <ul>
                            <li>Click on the <code>Generate Disease Surveillance XLSX Template</code> button</li>
                            <li>
                                Wait for the process to complete. Depending on your browser, the file may be saved
                                automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                        </ul>
                        <ArticleImage src={dsr03} alt="Disease Surveillance XLSX Template"/>

                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_STATUS_TAGS}/>
                        <p>The status tags indicate the current status of a disease surveillance report entry.</p>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Status</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <ReportStatsTag type={ReportStatsTag.Finalized}/>
                                    <ReportStatsTag type={ReportStatsTag.Submitted}/>
                                </td>
                                <td>Indicates that a monthly report has been submitted</td>
                            </tr>
                            <tr>
                                <td>
                                    <ReportStatsTag type={ReportStatsTag.Unfinalized}/>
                                    <ReportStatsTag type={ReportStatsTag.NotSubmitted}/>
                                </td>
                                <td>Indicates that a monthly report has not yet been submitted</td>
                            </tr>
                            <tr>
                                <td>
                                    <ReportStatsTag type={ReportStatsTag.Complete}/>
                                </td>
                                <td>Indicates that a monthly report has been completed</td>
                            </tr>
                            <tr>
                                <td>
                                    <ReportStatsTag type={ReportStatsTag.Incomplete}/>
                                </td>
                                <td>Indicates that some field values for a monthly report are missing</td>
                            </tr>
                            </tbody>
                        </table>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_VIEW}/>
                        <p>
                            To view a disease surveillance report entry, click on the <code>View</code> button on
                            the report date row you want to view.
                        </p>
                        <ArticleImage src={dsr04} alt="Viewing a Disease Surveillance Report Entry"/>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_PREPARE}>
                            {" "}<em>(Jail Level Encoder)</em>
                        </ArticleTitle>
                        <p>To prepare a new disease surveillance report entry:</p>
                        <ol>
                            <li>Click the <code>Prepare New Report</code> button on the index page</li>
                            <li>
                                On the modal window that opens, select a date in the <code>Report Date</code> date picker.
                                It should be noted that only one report can be prepared for any given report date.
                            </li>
                            <li>Click <code>Prepare</code> to confirm the report preparation</li>
                            <li>
                                You will then be redirected to the {" "}
                                <a href={`#${H_EDIT.id}`}>
                                    Edit Disease Surveillance Report
                                </a> page.
                            </li>
                        </ol>
                        <ArticleImage src={dsr05} alt="Preparing a Disease Surveillance Report"/>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_EDIT}>
                            {" "}<em>(Jail Level Encoder)</em>
                        </ArticleTitle>
                        <p>
                            To edit a disease surveillance report, click on the <code>Edit</code> button
                            on the row of the report date you want to edit. Note that the <code>Edit</code> button
                            will only appear for disease surveillance reports that haven't been submitted yet.
                        </p>
                        <ArticleImage src={dsr06} alt="Editing a Disease Surveillance Report"/>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_RETURN}>
                            {" "}<em>(Regional Administrator)</em>
                        </ArticleTitle>
                        <p>
                            A regional administrator may return a submitted disease surveillance report if they believe it
                            is incorrect or if a jail level office requests it. To return a disease surveillance report,
                            click the <code>Return for changes</code> button on the row of the report date you want to
                            return.
                        </p>
                        <ArticleImage src={dsr07} alt="Returning a Report for Changes"/>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_VIEW}/>
                        <p>
                            The current state of a disease surveillance report is displayed on the view disease
                            surveillance report page. Note that you won't be able to change report values in this view.
                        </p>
                        <p>
                            Click the <code>Edit Report</code> button to make changes to a report that has not yet been submitted.
                            Only jail level encoders will see the <code>Edit Report</code> button.
                        </p>
                        <p>
                            Click the <code>Submit</code> button to submit the disease surveillance report. Only jail level
                            encoders will see this button.
                        </p>
                        <ArticleImage src={dsr08} alt="View Disease Surveillance Report"/>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_EDIT}/>
                        <p>
                            On the Edit Disease Surveillance Report page, you can make changes to a disease surveillance
                            report. Only jail level encoders have access to this page.
                        </p>

                        <ArticleTitle level={4} pageDef={H_EDIT_OVERVIEW}/>
                        <ArticleImage src={dsr09} alt="Edit Disease Surveillance Report"/>
                        <ol>
                            <li>
                                <strong>Status Tags</strong>
                                <p>Indicate the status of the disease surveillance report</p>
                            </li>
                            <li>
                                <strong>Form View</strong>
                                <p>The page section where you can directly encode report data</p>
                            </li>
                            <li>
                                <strong>Form Action Buttons</strong>
                                <p>Action buttons for saving and submitting the report</p>
                            </li>
                        </ol>

                        <ArticleTitle level={4} pageDef={H_EDIT_FORM_VIEW}/>
                        <p>
                            The form view contains form fields where you can manually encode the report data.
                            The cursor keys can be used to navigate through the form fields.
                        </p>
                        <p>
                            Notice that indicators that have missing values are labeled with an <code>incomplete*</code> tag.
                        </p>
                        <ArticleImage src={dsr10} alt="Form View"/>

                        <ArticleTitle level={4} pageDef={H_EDIT_IMPORT}/>
                        <p>
                            You may also import reports using the XLSX Template, which can be generated in the{" "}
                            <a href={`#${H_INDEX_PAGE.id}`}>Index Page</a>
                        </p>
                        <p>
                            Note: Only XLSX template files generated from the system are supported for importing.
                            Also, only XLSX files saved using Excel 2010+ version are supported by the system.
                        </p>
                        <ol>
                            <li>Click the <code>Import File</code> button on the form actions section</li>
                            <li>Select the XLSX file you want to import</li>
                            <li>Click <code>Import</code></li>
                        </ol>
                        <ArticleImage src={dsr11} alt="Importing Reports using the XLSX Template"/>

                        <ArticleTitle level={4} pageDef={H_EDIT_SAVE}/>
                        <p>
                            It's a good idea to save reports whenever you make changes. Saving does not result
                            in the submission of a report: it does not make your report final. It simply allows you
                            to resume where you left off.
                        </p>
                        <p>
                            You can tell if there are changed values that haven't saved yet if you see an <code>Unsaved</code>
                            tag in the navigation page
                        </p>
                        <ArticleImage src={dsr12} alt="Unsaved"/>
                        <p>
                            To save the disease surveillance report, click on the <code>Save</code> button
                        </p>
                        <ArticleImage src={dsr13} alt="Saving a Disease Surveillance Report"/>

                        <ArticleTitle level={4} pageDef={H_EDIT_SUBMIT}/>
                        <p>
                            After ensuring that all required fields have been completed, you may now submit your report.
                            When you submit a report, it is considered finalized. A report can only be submitted if it
                            has been completely saved. Reports that have been submitted will be
                            used to generate report and dashboard data.
                        </p>
                        <p>
                            To submit the disease surveillance report, click on the <code>Submit</code> button
                        </p>
                        <ArticleImage src={dsr14} alt="Submitting a Disease Surveillance Report"/>

                        <ArticleTitle level={4} pageDef={H_EDIT_ERRORS}/>
                        <p>
                            Here is a list of errors that you may encounter when submitting disease surveillance
                            reports, as well as instructions on how to resolve them.
                        </p>
                        <h5>Missing Values</h5>
                        <p>
                            If your report is incomplete or contains missing values, this error message will appear.
                            Simply fill in the missing values to fix this error. Also, if a value is supposed to be
                            zero, remember that you must specify it explicitly, as leaving it blank will mark it as
                            incomplete.
                        </p>
                        <ArticleImage src={dsr15} alt="Error - Missing Values"/>
                    </section>
                </Article>
            </div>
        );
    }
}

DiseaseSurveillanceReports.contents = contents;
DiseaseSurveillanceReports.pageId = P_DS_REPORTS;
export default DiseaseSurveillanceReports;