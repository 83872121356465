export function pctTotalOver(dividend, divisor, fixed = 2) {
    if (divisor === 0) {
        return 0;
    }

    let result = dividend / divisor * 100;

    if (!isNaN(fixed)) {
        result = result.toFixed(fixed);
    }

    return result;
}

export function buildAttributeChartDataSet(attributes, optionName, dataSetTitle, color) {
    const labels = [];
    const data = [];

    attributes.forEach(attr => {
        labels.push(attr[optionName].name);
        data.push(attr.total);
    });

    return buildChartDataSet(labels, data, dataSetTitle, [color || '#f39c12'], [color || '#f39c12']);
}

export function buildChartDataSet(labels, data, dataSetTitle, backgroundColors, borderColors, borderWidth = 2) {
    return {
        labels,
        datasets: [
            {
                label: dataSetTitle,
                data,
                borderColor: borderColors,
                backgroundColor: backgroundColors,
                borderWidth
            }
        ]
    };
}

export function createPieChartOptions(title, legendPosition = 'top') {
    let offsets = [];

    function resolveOffsets(context) {
        const {data} = context.dataset;

        // Check indexes less than 5%
        const THRESHOLD = 0.05;
        const DEFAULT_OFFSET = -8;
        const lessThresholdIndexes = [];
        const total = data.reduce((a, b) => a + parseFloat(b), 0);
        data.forEach((d, i) => {
            if (total === 0 || (d / total) < THRESHOLD) {
                lessThresholdIndexes.push(i);
            }
        });

        // Resolve lessThreshold offsets
        const lessThreshold = {};
        if (lessThresholdIndexes.length > 1) {
            const DISPLACEMENT = 7;
            const mid = (lessThresholdIndexes.length - 1) / 2;
            lessThresholdIndexes.forEach((item, i) => {
                if (i < mid) {
                    lessThreshold[item] = (i - mid) * DISPLACEMENT + DEFAULT_OFFSET;
                } else if (i === mid) {
                    lessThreshold[item] = DEFAULT_OFFSET;
                } else {
                    lessThreshold[item] = (mid + i) * DISPLACEMENT + DEFAULT_OFFSET;
                }
            });
        }

        offsets = data.map((d, i) => {
            if (lessThreshold.hasOwnProperty(i)) {
                return lessThreshold[i];
            } else {
                return DEFAULT_OFFSET;
            }
        });
    }

    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: legendPosition,
                labels: {
                    filter: item => item.text !== undefined,
                    font: {
                        weight: 'bold'
                    }
                }
            },
            tooltip: {
                filter: context => !!context.label
            },
            title: {
                display: true,
                padding: {
                    bottom: 10
                },
                color: '#333',
                font: {
                    size: '14px'
                },
                text: title
            },
            datalabels: {
                color: '#000',
                font: {
                    weight: 'bold'
                },
                align: 'start',
                offset: function (context) {
                    if (context.dataIndex === 0) {
                        resolveOffsets(context);
                    }

                    return offsets[context.dataIndex];
                },
                formatter: function (value, context) {
                    if (context.chart.legend.legendItems[context.dataIndex] === undefined) {
                        return 0;
                    }

                    if (parseInt(value) === 0) {
                        return '';
                    }

                    return value;
                }
            }
        }
    };
}

export function createHorizontalChartOptions(title) {
    let alignments = null, offsets = null;
    const THRESHOLD = 0.07;

    function resolvePositions(context) {
        const {data} = context.dataset;

        const max = data.reduce((a, b) => {
            b = parseFloat(b);
            return a > b ? a : b
        }, 0);

        alignments = data.map(d => {
            if (max === 0 || (d / max) < THRESHOLD) {
                return 'end';
            } else {
                return 'center';
            }
        });
    }

    function resolveOffsets(context) {
        const {data} = context.dataset;

        const max = data.reduce((a, b) => {
            b = parseFloat(b);
            return a > b ? a : b
        }, 0);

        offsets = data.map(d => {
            if (max === 0 || (d / max) < THRESHOLD) {
                return 8;
            } else {
                return 0;
            }
        });
    }

    return {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    boxWidth: 14
                }
            },
            title: {
                display: true,
                padding: {
                    bottom: 10
                },
                color: '#333',
                font: {
                    size: '14px',
                },
                text: title,
            },
            datalabels: {
                color: 'black',
                borderRadius: 2,
                padding: 1,
                align: function (context) {
                    if (context.dataIndex === 0) {
                        resolvePositions(context);
                    }

                    return alignments[context.dataIndex];
                },
                anchor: function (context) {
                    if (context.dataIndex === 0) {
                        resolvePositions(context);
                    }

                    return alignments[context.dataIndex];
                },
                offset: function (context) {
                    if (context.dataIndex === 0) {
                        resolveOffsets(context);
                    }

                    return offsets[context.dataIndex];
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    callback: function (val) {
                        let label = this.getLabelForValue(val);
                        if (label.length > 50) {
                            label = label.slice(0, 50) + "...";
                        }
                        return label;
                    },
                    font: {
                        weight: 'bold'
                    }
                }
            }
        }
    };
}

export function createVerticalChartOptions(title) {
    let alignments = null, offsets = null;
    const THRESHOLD = 0.085;

    function resolvePositions(context) {
        const {data} = context.dataset;

        const {max} = context.chart.scales.y;
        alignments = data.map(d => {
            if (max === 0 || (d / max) < THRESHOLD) {
                return 'end';
            } else {
                return 'center';
            }
        });
    }

    function resolveOffsets(context) {
        const {data} = context.dataset;

        const {max} = context.chart.scales.y;
        offsets = data.map(d => {
            if (max === 0 || (d / max) < THRESHOLD) {
                return 6;
            } else {
                return 0;
            }
        });
    }

    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    boxWidth: 14
                }
            },
            title: {
                display: true,
                padding: {
                    bottom: 10
                },
                color: '#333',
                font: {
                    size: '14px',
                },
                text: title,
            },
            datalabels: {
                color: 'black',
                borderRadius: 2,
                padding: 1,
                align: function (context) {
                    if (context.dataIndex === 0) {
                        resolvePositions(context);
                    }

                    return alignments[context.dataIndex];
                },
                anchor: function (context) {
                    if (context.dataIndex === 0) {
                        resolvePositions(context);
                    }

                    return alignments[context.dataIndex];
                },
                offset: function (context) {
                    if (context.dataIndex === 0) {
                        resolveOffsets(context);
                    }

                    return offsets[context.dataIndex];
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: function (val) {
                        let label = this.getLabelForValue(val);
                        if (label.length > 18) {
                            label = label.slice(0, 20) + "...";
                        }
                        return label;
                    },
                    font: {
                        weight: 'bold'
                    }
                }
            }
        }
    };
}