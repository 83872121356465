import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    active: PropTypes.bool
};

const meta = {
    inUse: PropTypes.bool
};

export class MentalHealthIndicatorCategory extends JsonApiRecord(
    'mental-health-indicator-categories',
    `${config.apiBaseUrl}/mental-health-indicator-categories`,
    attributes,
    {},
    {},
    meta
) {
    static get modelName() {
        return 'MentalHealthIndicatorCategory';
    }
}