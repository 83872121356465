import React from "react";

export function withEmptyPie(Component) {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                data: null
            };
        }

        componentDidMount() {
            this.refreshData();
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            const {data} = this.props;
            if (data !== prevProps.data) {
                this.refreshData();
            }
        }

        refreshData = () => {
            const {data} = this.props;
            if (!data) {
                this.setState({data: data});
                return;
            }

            // Check if data is all zeroes
            let dataset = {...data.datasets[0]}
            if (dataset.data.every(el => parseFloat(el) === 0)) {
                dataset.data = [...dataset.data, 1];
                dataset.backgroundColor = [...dataset.backgroundColor, '#e3e3e3'];
                dataset.borderColor = [...dataset.borderColor, '#e3e3e3'];
                this.setState({
                    data: {...data, datasets: [dataset]}
                });
            } else {
                this.setState({data: data});
            }
        };

        render() {
            const {data: d, ...props} = this.props;
            const {data} = this.state;

            return <Component data={data} {...props}/>;
        }
    }
}