import React from 'react';
import PropTypes from "prop-types";
import Box from '@vallarj/react-adminlte/Box';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import IndicatorCategoryIndexItem from "./IndicatorCategoryIndexItem";
import {noop} from "@/utilities/noop";
import {createCancelToken, fetchResourceCollection} from "@/utilities/jsonapi";
import {escapeRegExp} from "@/utilities/highlight-string";
import IndicatorCategoryModal from "./IndicatorCategoryModal";

class IndicatorCategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indicatorCategories: [],
            isLoading: true,
            search: null
        };

        this.fetchCancelToken = createCancelToken();
        this.openModal = noop;
    }

    componentDidMount() {
        this.fetchIndicatorCategories();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    fetchIndicatorCategories = () => {
        const {resourceType} = this.props;

        this.setState({isLoading: true});
        fetchResourceCollection(resourceType)
            .onSuccess(collection => {
                this.setState({
                    indicatorCategories: collection,
                    isLoading: false
                });
            })
            .execute(this.fetchCancelToken);
    };

    handleAddIndicatorCategoryClick = () => {
        const {resourceType, hasParentSubcategoryNumbered} = this.props;

        let indicatorCategory = (new resourceType())
            .update('active', true);
        if (hasParentSubcategoryNumbered) {
            indicatorCategory = indicatorCategory.update('parentSubcategoryNumbered', false);
        }
        this.openModal('create', indicatorCategory);
    };

    handleEditIndicatorCategoryClick = indicatorCategory => {
        this.openModal('edit', indicatorCategory);
    };

    handleDeleteIndicatorCategoryClick = indicatorCategory => {
        this.openModal('delete', indicatorCategory);
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});
    };

    filterIndicatorCategories = () => {
        const {indicatorCategories} = this.state;
        const search = (this.state.search && this.state.search.trim()) || "";

        if (!search) {
            return indicatorCategories;
        }

        return indicatorCategories.filter(i => i.name.match(RegExp(escapeRegExp(search), "i")));
    };

    render() {
        const {search, isLoading} = this.state;
        const {title, hasParentSubcategoryNumbered} = this.props;
        const indicatorCategories = this.filterIndicatorCategories();

        return (<>
            <IndicatorCategoryModal hasParentSubcategoryNumbered={hasParentSubcategoryNumbered}
                                    openModalRef={this.registerOpenModal}
                                    onSuccess={this.fetchIndicatorCategories}/>
            <Box theme='box-primary' isLoading={isLoading}>
                <Box.Header title={title}/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..."
                               value={search} onChange={this.handleSearchChange}/>
                    <div className="j-indicator-categories j-box-scroll">
                        <div className="j-config-list-header j-indicator-categories-header">
                            <span>Order</span>
                            <span>Category</span>
                        </div>
                        {
                            indicatorCategories.map(i => (
                                <IndicatorCategoryIndexItem key={i.id} indicatorCategory={i}
                                                            hasParentSubcategoryNumbered={hasParentSubcategoryNumbered}
                                                            onEditClick={this.handleEditIndicatorCategoryClick}
                                                            onDeleteClick={this.handleDeleteIndicatorCategoryClick}
                                                            search={search} />
                            ))
                        }
                    </div>
                </Box.Body>
                <Box.Footer>
                    <button className="btn btn-primary pull-right" onClick={this.handleAddIndicatorCategoryClick}>
                        <i className="fa fa-plus-circle margin-r-5"/>Add Indicator Category
                    </button>
                </Box.Footer>
            </Box>
        </>);
    }
}

IndicatorCategories.propTypes = {
    resourceType: PropTypes.func.isRequired,
    hasParentSubcategoryNumbered: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
};

export default IndicatorCategories;