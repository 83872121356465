import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Doughnut from "@/components/Charts/Doughnut";
import {buildChartDataSet, createPieChartOptions} from "@/utilities/dashboard";

class DistributionScreenedChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createPieChartOptions("Distribution by Screening Methods");
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const propNames = ['entry', 'ss', 'sr', 'tbms'];

        return propNames.some(p => this.props[p] !== nextProps[p]);
    }

    buildDataSet = () => {
        const {entry, ss, sr, tbms} = this.props;

        return buildChartDataSet(["Entry", "SS", "SR", "TBMS"],
            [entry, ss, sr, tbms],
            "Distribution",
            ["#f59fad", "#f5df9b", "#75a0c6", "#baded1"],
            ['#fff', '#fff', '#fff', '#fff'])
    };

    render() {
        const dataSet = this.buildDataSet();

        return (
            <div className="j-dash-distribution-screened-chart">
                <Doughnut className="chart" data={dataSet} options={this.chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    }
}

export default DistributionScreenedChart;