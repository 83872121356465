import React from 'react';
import BaseIndicators from "@/screens/Configuration/Indicators";
import {MentalHealthIndicatorCategory} from "@/records/MentalHealthIndicatorCategory";
import {MentalHealthIndicatorSubcategory} from "@/records/MentalHealthIndicatorSubcategory";
import {MentalHealthIndicator} from "@/records/MentalHealthIndicator";

class MentalHealthIndicators extends React.Component {
    render() {
        return (
            <BaseIndicators
                categoryResourceType={MentalHealthIndicatorCategory}
                subcategoryResourceType={MentalHealthIndicatorSubcategory}
                indicatorResourceType={MentalHealthIndicator}
                title="Mental Health Indicators"
                hasSpecial={false}
                hasDataSource={false}
            />
        );
    }
}

export default MentalHealthIndicators;