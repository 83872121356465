import React from "react";
import Modal from "@vallarj/react-adminlte/Modal";

class ConfirmDeleteModal extends React.Component {
    constructor(props) {
        super(props);

        props.registerOpenModal(this.handleOpenModal);

        this.state = {
            show: false,
            item: null
        };
    }

    handleOpenModal = item => {
        this.setState({
            show: true,
            item
        });
    };

    handleCloseClick = () => {
        this.setState({show: false});
    };

    handleDeleteClick = () => {
        this.props.onConfirmDelete(this.state.item);
        this.setState({show: false});
    };

    render() {
        const item = this.state.item || {};
        const {show} = this.state;

        return (
            <Modal show={show} onCloseClick={this.handleCloseClick}>
                <Modal.Header>Confirm Delete Dashboard Component</Modal.Header>
                <Modal.Body>
                    <div className="j-config-modal-confirm-delete">
                        <p>Are you sure you want to delete this component?{" "}
                            <strong>This action cannot be undone.</strong>
                        </p>
                        <table>
                            <tbody>
                            <tr>
                                <td>Title</td>
                                <td>{item.title}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-default pull-left" onClick={this.handleCloseClick}>
                        Cancel
                    </button>
                    <button className="btn btn-danger pull-right" onClick={this.handleDeleteClick}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmDeleteModal;