import React from 'react';
import {connect} from 'react-redux';
import {v4 as uuidv4} from 'uuid';

import BreadcrumbItem from '@vallarj/react-adminlte/Content/BreadcrumbItem';
import Content from '@vallarj/react-adminlte/Content';
import {createCancelToken, fetchSingleResource, patchResource} from "@/utilities/jsonapi";
import {JailLevelReport} from "@/records/JailLevelReport";
import Menu from "@/screens/JailLevelReports/JailLevelReportEntry/Menu";
import {Redirect, Route, Switch} from "react-router-dom";
import IndicatorReport from "@/screens/JailLevelReports/JailLevelReportEntry/IndicatorReport";
import EntryMorbidities from "@/screens/JailLevelReports/JailLevelReportEntry/EntryMorbidities";
import DentalHealthIndicators from "@/screens/JailLevelReports/JailLevelReportEntry/DentalHealthIndicators";
import MentalHealthIndicators from "@/screens/JailLevelReports/JailLevelReportEntry/MentalHealthIndicators";
import MedicalProcedures from "@/screens/JailLevelReports/JailLevelReportEntry/MedicalProcedures";
import DetentionMorbidities from "@/screens/JailLevelReports/JailLevelReportEntry/DetentionMorbidities";
import Medications from "@/screens/JailLevelReports/JailLevelReportEntry/Medications";
import CausesOfDeath from "@/screens/JailLevelReports/JailLevelReportEntry/CausesOfDeath";
import {notifySuccess} from "@/utilities/notifications";
import {AbilityContext} from "@/ability";
import {monthName} from "@/utilities/month-name";
import ImportAllModal from "@/screens/JailLevelReports/JailLevelReportEntry/ImportAllModal";
import {noop} from "@/utilities/noop";
import SubmitErrorModal from "@/screens/JailLevelReports/JailLevelReportEntry/SubmitErrorModal";
import MortalityRegistry from "@/screens/JailLevelReports/JailLevelReportEntry/MortalityRegistry";
import {OFFICE_TYPE_JAIL_LEVEL} from "@/permissions";
import ReportStatsTag from "@/components/ReportStatsTag";

class JailLevelReportEntry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            submitting: false,
            entry: null,
            indicators: {},
            indicatorsChanged: {},
            entryMorbidities: {},
            entryMorbiditiesChanged: {},
            dentalHealthIndicators: {},
            dentalHealthIndicatorsChanged: {},
            mentalHealthIndicators: {},
            mentalHealthIndicatorsChanged: {},
            medicalProcedures: {},
            medicalProceduresChanged: {},
            detentionMorbidities: {},
            detentionMorbiditiesChanged: {},
            medications: {},
            medicationsChanged: {},
            causesOfDeath: {},
            causesOfDeathChanged: {},
            entryDeathRecords: {},
            deathRecords: {},
            deathRecordsChanged: {}
        };

        this.requestCancelToken = createCancelToken();
        this.openModal = noop;
        this.submitOpenModal = noop;
    }

    componentDidMount() {
        this.fetchAndResetJailLevelReport();
    }

    componentWillUnmount() {
        this.requestCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    registerSubmitOpenModal = openModal => {
        this.submitOpenModal = openModal;
    };

    fetchAndResetJailLevelReport = () => {
        this.fetchJailLevelReport([
            'indicators',
            'entryMorbidities',
            'dentalHealthIndicators',
            'mentalHealthIndicators',
            'medicalProcedures',
            'detentionMorbidities',
            'medications',
            'causesOfDeath',
            'deathRecords'
        ]);
    };

    fetchJailLevelReport = resetAttributes => {
        const {match} = this.props;
        const {id} = match.params;

        const resetDeathRecords = resetAttributes.includes('deathRecords');
        resetAttributes = resetAttributes.filter(ra => ra !== 'deathRecords');

        this.setState({isLoading: true});
        fetchSingleResource(JailLevelReport, id)
            .onSuccess(report => {
                this.setState({
                    entry: report,
                    isLoading: false,
                });

                if (resetDeathRecords) {
                    const entryDeathRecords = {};
                    report.deathRecords.forEach(dr => {
                        entryDeathRecords[uuidv4()] = dr;
                    });

                    this.setState({
                        entryDeathRecords,
                        deathRecords: entryDeathRecords,
                        deathRecordsChanged: {}
                    });
                }

                resetAttributes.forEach(attr => {
                    this.setState({
                        [attr]: report[attr],
                        [`${attr}Changed`]: {}
                    });
                });
            })
            .onError(err => {
                // If forbidden, redirect
                const response = (err.response || {});
                if (response.status === 403 || response.status === 500) {
                    this.props.history.replace('/jail-level-reports');
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    handleSaveAttribute = attribute => {
        this.saveAttributes([attribute]);
    };

    saveAttributes = attributes => {
        let {entry, deathRecords} = this.state;

        const saveDeathRecords = attributes.includes('deathRecords');
        const restAttributes = attributes.filter(a => a !== 'deathRecords');

        if (saveDeathRecords) {
            entry = entry.update('deathRecords', Object.values(deathRecords))
        }

        restAttributes.forEach(attribute => {
            const updateAttribute = {...this.state[attribute]};
            Object.keys(updateAttribute).forEach(u => {

                // noinspection EqualityComparisonWithCoercionJS
                if (updateAttribute[u].male == undefined && updateAttribute[u].female == undefined) { // eslint-disable-line
                    delete updateAttribute[u];
                } else {
                    updateAttribute[u] = {male: updateAttribute[u].male || 0, female: updateAttribute[u].female || 0}
                }
            });

            entry = entry.update(attribute, updateAttribute);
        });

        this.setState({isLoading:true});
        patchResource(entry)
            .onSuccess(() => {
                notifySuccess("Jail Level Reports", "Report saved.");
                this.fetchJailLevelReport(attributes);
            })
            .execute(this.requestCancelToken);
    };

    handleSaveAllClick = () => {
        const attributes = [
            'indicators',
            'entryMorbidities',
            'dentalHealthIndicators',
            'mentalHealthIndicators',
            'medicalProcedures',
            'detentionMorbidities',
            'medications',
            'causesOfDeath',
            'deathRecords'
        ].filter((a) => Object.keys(this.state[`${a}Changed`]).length > 0);

        this.saveAttributes(attributes);
    };

    handleImportAllClick = () => {
        this.openModal();
    };

    handleSubmitClick = () => {
        let {entry} = this.state;
        entry = entry.update('finalized', true);
        this.setState({submitting: true});
        patchResource(entry)
            .onSuccess(() => {
                this.setState({
                    submitting: false,
                    entry
                });
                const {url} = this.props.match;
                this.props.history.replace(url.replace('edit', 'view'));
                notifySuccess("Jail Level Report", "Jail level report successfully submitted");
            })
            .onError(err => {
                if (err.isValidationError) {
                    const {errors} = err.baseError.response.data;
                    this.submitOpenModal(errors[0]);
                    this.setState({submitting: false});
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    isEditMode = () => {
        const {match} = this.props;
        const {action} = match.params;

        return action === "edit";
    };

    handleIndicatorChange = (indicatorId, type, count) => {
        this.handleAttributeChange('indicators', indicatorId, type, count);
    };

    handleIndicatorsReplace = indicators => {
        this.handleAttributesReplace('indicators', indicators);
    };

    handleIndicatorsRevert = () => {
        this.handleAttributeRevert('indicators');
    };

    handleDentalHealthIndicatorChange = (indicatorId, type, count) => {
        this.handleAttributeChange('dentalHealthIndicators', indicatorId, type, count);
    };

    handleDentalHealthIndicatorsReplace = indicators => {
        this.handleAttributesReplace('dentalHealthIndicators', indicators);
    };

    handleDentalHealthIndicatorsRevert = () => {
        this.handleAttributeRevert('dentalHealthIndicators');
    };

    handleMentalHealthIndicatorChange = (indicatorId, type, count) => {
        this.handleAttributeChange('mentalHealthIndicators', indicatorId, type, count);
    };

    handleMentalHealthIndicatorsReplace = indicators => {
        this.handleAttributesReplace('mentalHealthIndicators', indicators);
    };

    handleMentalHealthIndicatorsRevert = () => {
        this.handleAttributeRevert('mentalHealthIndicators');
    };

    handleAttributeChange = (attribute, attributeId, type, count) => {
        const attributes = this.state[attribute];

        const attributesChangedName = `${attribute}Changed`;
        const newValue = {...(attributes[attributeId]) || {}, [type]: count};
        let attributesChanged = this.state[attributesChangedName];
        if (this.checkSameValues(attribute, attributeId, newValue)) {
            if (attributesChanged.hasOwnProperty(attributeId)) {
                const {[attributeId]: z, ...rest} = attributesChanged;
                attributesChanged = rest;
            }
        } else {
            attributesChanged = {...attributesChanged, [attributeId]: true};
        }

        this.setState({
            [attribute]: {
                ...attributes,
                [attributeId]: newValue
            },
            [attributesChangedName]: attributesChanged
        });
    };

    handleAttributesReplace = (attribute, attributeValues) => {
        const {entry} = this.state;
        const attributesChanged = {};
        Object.keys(attributeValues).forEach(id => {
            if (!this.checkSameValues(attribute, id, attributeValues[id])) {
                attributesChanged[id] = true;
            }
        });
        Object.keys(entry[attribute]).forEach(id => {
            if (!attributeValues.hasOwnProperty(id)) {
                attributesChanged[id] = true;
            }
        });

        this.setState({
            [attribute]: attributeValues,
            [`${attribute}Changed`]: attributesChanged
        });
    };

    handleAttributeRevert = attribute => {
        this.setState({
            [attribute]: this.state.entry[attribute],
            [`${attribute}Changed`]: {}
        })
    };

    handleDeathRecordAdd = (itemId, item) => {
        const {deathRecords, deathRecordsChanged} = this.state;

        this.setState({
            deathRecords: {...deathRecords, [itemId]: item},
            deathRecordsChanged: {...deathRecordsChanged, [itemId]: true}
        })
    };

    handleDeathRecordEdit = (itemId, item) => {
        const {deathRecords, deathRecordsChanged} = this.state;

        let newDeathRecordsChanged;
        if (this.hasDeathRecordChanged(itemId, item)) {
            newDeathRecordsChanged = {...deathRecordsChanged, [itemId]: true};
        } else {
            let {[itemId]: omit, ...rest} = deathRecordsChanged;
            newDeathRecordsChanged = rest;
        }

        this.setState({
            deathRecords: {...deathRecords, [itemId]: item},
            deathRecordsChanged: newDeathRecordsChanged
        });
    };

    handleDeathRecordDelete = itemId => {
        const {deathRecords, entryDeathRecords, deathRecordsChanged} = this.state;
        const {[itemId]: omit, ...newDeathRecords} = deathRecords;

        let newDeathRecordsChanged;
        if (!entryDeathRecords.hasOwnProperty(itemId)) {
            const {[itemId]: omit, ...rest} = deathRecordsChanged;
            newDeathRecordsChanged = rest;
        } else {
            newDeathRecordsChanged = {...deathRecordsChanged, [itemId]: true};
        }

        this.setState({
            deathRecords: newDeathRecords,
            deathRecordsChanged: newDeathRecordsChanged
        });
    };

    handleDeathRecordsReplace = deathRecords => {
        const {entryDeathRecords} = this.state;
        const newDeathRecords = {};
        const newDeathRecordsChanged = {};

        // Since we're replacing everything, mark all current as changed
        Object.keys(entryDeathRecords).forEach(id => {
            newDeathRecordsChanged[id] = true;
        });

        // New death records from import
        deathRecords.forEach(d => {
            const id = uuidv4();
            newDeathRecords[id] = d;
            newDeathRecordsChanged[id] = true;
        });

        this.setState({
            deathRecords: newDeathRecords,
            deathRecordsChanged: newDeathRecordsChanged
        });
    };

    revertDeathRecords = () => {
        const {entryDeathRecords} = this.state;

        this.setState({
            deathRecords: entryDeathRecords,
            deathRecordsChanged: {}
        });
    };

    hasDeathRecordChanged = (itemId, item) => {
        const {entryDeathRecords} = this.state;
        if (!entryDeathRecords.hasOwnProperty(itemId)) {
            return true;
        }

        const currentDeathRecord = entryDeathRecords[itemId];
        const fields = [
            'name',
            'age',
            'sex',
            'immediateCause',
            'antecedentCause',
            'underlyingCause',
            'dateOfDeath',
            'criminalOffense'
        ];

        return fields.some(f => currentDeathRecord[f] !== item[f]);
    };

    checkSameValues = (attribute, attributeId, modifiedAttrValue) => {
        const {entry} = this.state;
        const currentAttrValue = entry[attribute][attributeId] || {};

        // noinspection EqualityComparisonWithCoercionJS
        return currentAttrValue.male == modifiedAttrValue.male // eslint-disable-line
            && currentAttrValue.female == modifiedAttrValue.female; // eslint-disable-line
    }

    renderSubtitle = () => {
        const {entry} = this.state;
        const {officeType} = this.props;

        let tags = [];
        if (entry.finalized) {
            if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
                tags.push(<ReportStatsTag key="submitted" type={ReportStatsTag.Submitted}/>);
            } else {
                tags.push(<ReportStatsTag key="finalized" type={ReportStatsTag.Finalized}/>);
            }
        } else {
            tags.push(<ReportStatsTag key={entry.isComplete ? "complete" : "incomplete"}
                                      type={entry.isComplete ? ReportStatsTag.Complete : ReportStatsTag.Incomplete}/>);
            if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
                tags.push(<ReportStatsTag key="not-submitted" type={ReportStatsTag.NotSubmitted}/>);
            } else {
                tags.push(<ReportStatsTag key="unfinalized" type={ReportStatsTag.Unfinalized}/>);
            }
        }

        return tags;
    };

    render() {
        const {
            entry, isLoading, indicators, entryMorbidities, dentalHealthIndicators, mentalHealthIndicators,
            medicalProcedures, detentionMorbidities, medications, causesOfDeath, deathRecords,
            indicatorsChanged, entryMorbiditiesChanged, dentalHealthIndicatorsChanged,
            mentalHealthIndicatorsChanged, medicalProceduresChanged,
            detentionMorbiditiesChanged, medicationsChanged, causesOfDeathChanged, submitting,
            deathRecordsChanged
        } = this.state;
        const {path, url} = this.props.match;
        if (!entry) return null;

        const hasIndicatorsChanged = Object.keys(indicatorsChanged).length > 0;
        const hasEntryMorbiditiesChanged = Object.keys(entryMorbiditiesChanged).length > 0;
        const hasDentalHealthIndicatorsChanged = Object.keys(dentalHealthIndicatorsChanged).length > 0;
        const hasMentalHealthIndicatorsChanged = Object.keys(mentalHealthIndicatorsChanged).length > 0;
        const hasMedicalProceduresChanged = Object.keys(medicalProceduresChanged).length > 0;
        const hasDetentionMorbiditiesChanged = Object.keys(detentionMorbiditiesChanged).length > 0;
        const hasMedicationsChanged = Object.keys(medicationsChanged).length > 0;
        const hasCausesOfDeathChanged = Object.keys(causesOfDeathChanged).length > 0;
        const hasDeathRecordsChanged = Object.keys(deathRecordsChanged).length > 0;

        const isEditMode = this.isEditMode();
        const ability = this.context;
        const canManageEntry = ability.can('update', entry);
        if (isEditMode) {
            if (!canManageEntry || entry.finalized) {
                return <Redirect to='/jail-level-reports'/>;
            }
        }

        const month = monthName(entry.month);
        const mainClassName = "row j-jail-level-report" + (entry.finalized ? " finalized" : "");
        return (<Content>
            <Content.Header title={`Report - ${entry.office.name} (${month} ${entry.year})`}
                            subtitle={this.renderSubtitle()}/>
            <Content.Breadcrumb>
                <BreadcrumbItem label="Monthly Reports"
                                iconClass="fa fa-file-text"
                                path='/jail-level-reports'
                />
                <BreadcrumbItem label={`${entry.office.name}`}/>
                <BreadcrumbItem label={`${month} ${entry.year}`} active/>
            </Content.Breadcrumb>
            <Content.Body>
                <SubmitErrorModal registerOpenModal={this.registerSubmitOpenModal} month={entry.month}/>
                <ImportAllModal onAttributesReplace={this.handleAttributesReplace}
                                onDeathRecordsReplace={this.handleDeathRecordsReplace}
                                registerOpenModal={this.registerOpenModal}
                                month={entry.month}/>
                <div className={mainClassName}>
                    <Menu path={url}
                          indicatorsChanged={hasIndicatorsChanged}
                          entryMorbiditiesChanged={hasEntryMorbiditiesChanged}
                          dentalHealthIndicatorsChanged={hasDentalHealthIndicatorsChanged}
                          mentalHealthIndicatorsChanged={hasMentalHealthIndicatorsChanged}
                          medicalProceduresChanged={hasMedicalProceduresChanged}
                          detentionMorbiditiesChanged={hasDetentionMorbiditiesChanged}
                          medicationsChanged={hasMedicationsChanged}
                          causesOfDeathChanged={hasCausesOfDeathChanged}
                          deathRecordsChanged={hasDeathRecordsChanged}
                          isEditMode={isEditMode}
                          canManageEntry={canManageEntry}
                          onImportAllClick={this.handleImportAllClick}
                          onSaveAllClick={this.handleSaveAllClick}
                          onSubmitClick={this.handleSubmitClick}
                          submitting={submitting}
                          finalized={entry.finalized}/>
                    <div className="col-xs-9">
                        <Switch>
                            <Route exact path={`${path}/indicators`} render={routeProps => (
                                <IndicatorReport {...routeProps} indicators={indicators}
                                                 onIndicatorChange={this.handleIndicatorChange}
                                                 onIndicatorsReplace={this.handleIndicatorsReplace}
                                                 onIndicatorsRevert={this.handleIndicatorsRevert}
                                                 indicatorsChanged={hasIndicatorsChanged}
                                                 onSaveClick={this.handleSaveAttribute}
                                                 isEditMode={isEditMode}
                                                 isLoading={isLoading}
                                                 month={entry.month}/>
                            )}/>
                            <Route exact path={`${path}/entry-morbidities`} render={routeProps => (
                                <EntryMorbidities {...routeProps} values={entryMorbidities}
                                                  onAttributeChange={this.handleAttributeChange}
                                                  onAttributesReplace={this.handleAttributesReplace}
                                                  onAttributeRevert={this.handleAttributeRevert}
                                                  attributesChanged={hasEntryMorbiditiesChanged}
                                                  onSaveClick={this.handleSaveAttribute}
                                                  isEditMode={isEditMode}
                                                  isLoading={isLoading}/>
                            )}/>
                            <Route exact path={`${path}/dental-health-indicators`} render={routeProps => (
                                <DentalHealthIndicators {...routeProps} indicators={dentalHealthIndicators}
                                                        onIndicatorChange={this.handleDentalHealthIndicatorChange}
                                                        onIndicatorsReplace={this.handleDentalHealthIndicatorsReplace}
                                                        onIndicatorsRevert={this.handleDentalHealthIndicatorsRevert}
                                                        indicatorsChanged={hasDentalHealthIndicatorsChanged}
                                                        onSaveClick={this.handleSaveAttribute}
                                                        isEditMode={isEditMode}
                                                        isLoading={isLoading}
                                                        month={entry.month}/>
                            )}/>
                            <Route exact path={`${path}/mental-health-indicators`} render={routeProps => (
                                <MentalHealthIndicators {...routeProps} indicators={mentalHealthIndicators}
                                                        onIndicatorChange={this.handleMentalHealthIndicatorChange}
                                                        onIndicatorsReplace={this.handleMentalHealthIndicatorsReplace}
                                                        onIndicatorsRevert={this.handleMentalHealthIndicatorsRevert}
                                                        indicatorsChanged={hasMentalHealthIndicatorsChanged}
                                                        onSaveClick={this.handleSaveAttribute}
                                                        isEditMode={isEditMode}
                                                        isLoading={isLoading}
                                                        month={entry.month}/>
                            )}/>
                            <Route exact path={`${path}/medical-procedures`} render={routeProps => (
                                <MedicalProcedures {...routeProps} values={medicalProcedures}
                                                   onAttributeChange={this.handleAttributeChange}
                                                   onAttributesReplace={this.handleAttributesReplace}
                                                   onAttributeRevert={this.handleAttributeRevert}
                                                   attributesChanged={hasMedicalProceduresChanged}
                                                   onSaveClick={this.handleSaveAttribute}
                                                   isEditMode={isEditMode}
                                                   isLoading={isLoading}/>
                            )}/>
                            <Route exact path={`${path}/detention-morbidities`} render={routeProps => (
                                <DetentionMorbidities {...routeProps} values={detentionMorbidities}
                                                      onAttributeChange={this.handleAttributeChange}
                                                      onAttributesReplace={this.handleAttributesReplace}
                                                      onAttributeRevert={this.handleAttributeRevert}
                                                      attributesChanged={hasDetentionMorbiditiesChanged}
                                                      onSaveClick={this.handleSaveAttribute}
                                                      isEditMode={isEditMode}
                                                      isLoading={isLoading}/>
                            )}/>
                            <Route exact path={`${path}/medications`} render={routeProps => (
                                <Medications {...routeProps} values={medications}
                                             onAttributeChange={this.handleAttributeChange}
                                             onAttributesReplace={this.handleAttributesReplace}
                                             onAttributeRevert={this.handleAttributeRevert}
                                             attributesChanged={hasMedicationsChanged}
                                             onSaveClick={this.handleSaveAttribute}
                                             isEditMode={isEditMode}
                                             isLoading={isLoading}/>
                            )}/>
                            <Route exact path={`${path}/causes-of-death`} render={routeProps => (
                                <CausesOfDeath {...routeProps} values={causesOfDeath}
                                               onAttributeChange={this.handleAttributeChange}
                                               onAttributesReplace={this.handleAttributesReplace}
                                               onAttributeRevert={this.handleAttributeRevert}
                                               attributesChanged={hasCausesOfDeathChanged}
                                               onSaveClick={this.handleSaveAttribute}
                                               isEditMode={isEditMode}
                                               isLoading={isLoading}/>
                            )}/>
                            <Route exact path={`${path}/mortality-registry`} render={routeProps => (
                                <MortalityRegistry {...routeProps} isLoading={isLoading}
                                                   onAddItem={this.handleDeathRecordAdd}
                                                   onEditItem={this.handleDeathRecordEdit}
                                                   onDeleteItem={this.handleDeathRecordDelete}
                                                   onRevertClick={this.revertDeathRecords}
                                                   onSaveClick={this.handleSaveAttribute}
                                                   onDeathRecordsReplace={this.handleDeathRecordsReplace}
                                                   deathRecords={deathRecords}
                                                   deathRecordsChanged={hasDeathRecordsChanged}
                                                   isEditMode={isEditMode}
                                                   />
                            )}/>
                            <Redirect to={`${url}/indicators`}/>
                        </Switch>
                    </div>
                </div>
            </Content.Body>
        </Content>);
    }
}

function mapStateToProps({auth}) {
    return {officeType: auth.user.office['office_type']};
}

JailLevelReportEntry.contextType = AbilityContext;
export default connect(mapStateToProps)(JailLevelReportEntry);