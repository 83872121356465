export default function transformFormErrors(errorDocument, handler) {
    let errors = {};
    let handlerIsFunction = (typeof handler === 'function');

    errorDocument.forEach(e => {
        if(e.source && e.source.pointer) {
            const pointer = e.source.pointer;
            let handled = false;
            if (handlerIsFunction) {
                const result = handler(pointer);

                if (result && result.hasOwnProperty('key') && result.hasOwnProperty('detail')) {
                    if (!errors.hasOwnProperty(result.key)) {
                        errors[result.key] = [result.detail];
                    } else {
                        errors[result.key].push(result.detail);
                    }
                    handled = true;
                }
            }

            if (!handled) {
                if(pointer.startsWith('/data/attributes/')) {
                    const attribute = pointer.slice(17);
                    if(!errors.hasOwnProperty(attribute)) {
                        errors[attribute] = [ e.detail ];
                    } else {
                        errors[attribute].push(e.detail);
                    }
                } else if(pointer.startsWith('/data/relationships/')) {
                    const relationship = pointer.slice(20);
                    if(!errors.hasOwnProperty(relationship)) {
                        errors[relationship] = [ e.detail ];
                    } else {
                        errors[relationship].push(e.detail);
                    }
                }
            }
        }
    });

    return errors;
}
