import React from 'react';
import {withAggregateTagMap} from "@/screens/DiseaseSurveillanceDashboard/utilities";
import InfoBox from "@/components/InfoBox";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_PDL_ACTIVE = 'dm_default_1';
const TAG_PDL_RECOVERED = 'dm_default_2';
const TAG_PDL_RELEASED = 'dm_default_3';
const TAG_PDL_DEATH = 'dm_default_4';
const TAG_PERSONNEL_ACTIVE = 'dm_default_5';
const TAG_PERSONNEL_RECOVERED = 'dm_default_6';
const TAG_PERSONNEL_DEATH = 'dm_default_7';

class CovidCases extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            personnelActive: 0,
            personnelRecovered: 0,
            personnelDeath: 0,
            personnelTotal: 0,
            pdlActive: 0,
            pdlRecovered: 0,
            pdlDeath: 0,
            pdlReleased: 0,
            pdlTotal: 0,
            totalConfirmed: 0,
            totalActive: 0,
            totalDeath: 0,
            totalRecovered: 0,
            newActive: 0,
            newRecoveries: 0,
            newDeaths: 0,
            pctNewActive: 0,
            pctNewRecoveries: 0,
            pctNewDeaths: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const pdlActive = getAggregateByTag(TAG_PDL_ACTIVE);
        const pdlRecovered = getAggregateByTag(TAG_PDL_RECOVERED);
        const pdlReleased = getAggregateByTag(TAG_PDL_RELEASED);
        const pdlDeath = getAggregateByTag(TAG_PDL_DEATH);
        const personnelActive = getAggregateByTag(TAG_PERSONNEL_ACTIVE);
        const personnelRecovered = getAggregateByTag(TAG_PERSONNEL_RECOVERED);
        const personnelDeath = getAggregateByTag(TAG_PERSONNEL_DEATH);

        const realPersonnelActive = personnelActive.reportTotal - personnelRecovered.reportTotal - personnelDeath.reportTotal;
        const realPdlActive = pdlActive.reportTotal - pdlRecovered.reportTotal - pdlDeath.reportTotal - pdlReleased.reportTotal;

        const newActive = pdlActive.latestTotal + personnelActive.latestTotal;
        const newRecoveries = pdlRecovered.latestTotal + personnelRecovered.latestTotal;
        const newDeaths = pdlDeath.latestTotal + personnelDeath.latestTotal;

        const totalConfirmed = personnelActive.reportTotal + pdlActive.reportTotal;
        const totalActive = realPersonnelActive + realPdlActive;
        const totalDeath = personnelDeath.reportTotal + pdlDeath.reportTotal;

        this.setState({
            personnelActive: realPersonnelActive,
            personnelRecovered: personnelRecovered.reportTotal,
            personnelDeath: personnelDeath.reportTotal,
            personnelTotal: personnelActive.reportTotal,
            pdlActive: realPdlActive,
            pdlRecovered: pdlRecovered.reportTotal,
            pdlDeath: pdlDeath.reportTotal,
            pdlReleased: pdlReleased.reportTotal,
            pdlTotal: pdlActive.reportTotal,
            totalConfirmed,
            totalActive,
            totalDeath,
            totalRecovered: personnelRecovered.reportTotal + pdlRecovered.reportTotal,
            newActive,
            newRecoveries,
            newDeaths,
            pctNewActive: pctTotalOver(newActive, totalConfirmed),
            pctNewRecoveries: pctTotalOver(newRecoveries, totalConfirmed),
            pctNewDeaths: pctTotalOver(newDeaths, totalConfirmed)
        });
    };

    render() {
        const {
            personnelActive, personnelRecovered, personnelDeath, personnelTotal,
            pdlActive, pdlRecovered, pdlDeath, pdlReleased, pdlTotal,
            totalConfirmed, totalActive, totalDeath, totalRecovered,
            newActive, newRecoveries, newDeaths,
            pctNewActive, pctNewRecoveries, pctNewDeaths
        } = this.state;

        return (
            <div className="j-disease-dash-covid-cases j-dash-box-subgroup">
                <h3>COVID-19 Cases</h3>
                <div className="row">
                    <div className="col-xs-4">
                        <InfoBox className="simple-info-box bg-blue"
                                 title="New Active Cases" value={newActive}
                                 progressDescription={`${pctNewActive}% of total confirmed cases`}
                                 progress={pctNewActive} showProgress/>
                    </div>
                    <div className="col-xs-4">
                        <InfoBox className="simple-info-box bg-green"
                                 title="New Recoveries" value={newRecoveries}
                                 progressDescription={`${pctNewRecoveries}% of total confirmed cases`}
                                 progress={pctNewActive} showProgress/>
                    </div>
                    <div className="col-xs-4">
                        <InfoBox className="simple-info-box bg-maroon"
                                 title="New Deaths" value={newDeaths}
                                 progressDescription={`${pctNewDeaths}% of total confirmed cases`}
                                 progress={pctNewActive} showProgress/>
                    </div>
                </div>
                <div className="j-dash-tabular-group">
                    <table>
                        <thead>
                        <tr>
                            <th>Classification</th>
                            <th>Personnel</th>
                            <th>PDL</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Total no. of confirmed cases</td>
                            <td>{personnelTotal}</td>
                            <td>{pdlTotal}</td>
                            <td>{totalConfirmed}</td>
                        </tr>
                        <tr>
                            <td>Active cases</td>
                            <td>{personnelActive}</td>
                            <td>{pdlActive}</td>
                            <td>{totalActive}</td>
                        </tr>
                        <tr>
                            <td>Recovered</td>
                            <td>{personnelRecovered}</td>
                            <td>{pdlRecovered}</td>
                            <td>{totalRecovered}</td>
                        </tr>
                        <tr>
                            <td>Death</td>
                            <td>{personnelDeath}</td>
                            <td>{pdlDeath}</td>
                            <td>{totalDeath}</td>
                        </tr>
                        <tr>
                            <td>Released</td>
                            <td>N/A</td>
                            <td>{pdlReleased}</td>
                            <td>{pdlReleased}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(CovidCases);