import React from "react";
import PropTypes from 'prop-types';
import ArticleTableOfContents from "@/screens/Docs/Article/ArticleTableOfContents";

class Article extends React.Component {
    render() {
        const {title, contentTitle, contents, id} = this.props;

        return (
            <>
                <div className="col-lg-8">
                    <article className="help">
                        <section className="help-content-header">
                            <h1 id={id}>{title}</h1>
                        </section>
                        <section className="content">
                            <h2 className="content-title">
                                {contentTitle}
                            </h2>
                            <ArticleTableOfContents contents={contents} innerMode/>
                            {this.props.children}
                        </section>
                    </article>
                </div>
                <ArticleTableOfContents contents={contents}/>
            </>
        );
    }
}

Article.propTypes = {
    title: PropTypes.string.isRequired,
    contentTitle: PropTypes.string
};

export default Article;