import React from 'react';

import Modal from '@vallarj/react-adminlte/Modal';
import Form from "@vallarj/react-adminlte/Form";
import TextInput from "@vallarj/react-adminlte/Form/TextInput";
import SelectInput from '@vallarj/react-adminlte/Form/SelectInput';
import {permissionOrderComparator, roleOrderComparator} from "@/utilities/permissions";
import RoleItem from "@/screens/AccessManagement/Users/UserModal/RoleItem";
import {User} from "@/records/User";
import {AbilityContext, Can} from "@/ability";
import {
    notifyFormError,
    notifyItemRequestSuccess,
    notifySuccess,
    notifyUnableToProcess
} from "@/utilities/notifications";
import {
    OFFICE_TYPE_HQ,
    OFFICE_TYPE_JAIL_LEVEL,
    OFFICE_TYPE_REGIONAL,
    ROLE_TYPE_HQ, ROLE_TYPE_JAIL_LEVEL,
    ROLE_TYPE_REGIONAL
} from "@/permissions";
import {createCancelToken, fetchSingleResource, patchResource, postResource} from "@/utilities/jsonapi";

class UserModal extends React.Component {
    constructor(props) {
        super(props);

        if (props.openModalRef) {
            props.openModalRef(this.handleOpenModal);
        }

        this.state = {
            show: false,
            isProcessing: false,
            view: 'create',
            currentUser: null,
            viewUser: {},
            orderedRoles: [],
            errors: {},
            isResettingPassword: false,
            isChangingStatus: false,
            offices: [],
            officeTypes: [],
            officeType: null,
            regions: [],
            region: null
        };

        this.officeTypes = {
            [OFFICE_TYPE_HQ]: "Headquarters",
            [OFFICE_TYPE_REGIONAL]: "Regional Office",
            [OFFICE_TYPE_JAIL_LEVEL]: "Jail Level"
        };
        this.modalRef = React.createRef();
        this.requestCancelToken = createCancelToken();
    }

    handleExit = () => {
        this.setState({
            isProcessing: false,
            currentUser: null,
            viewUser: {},
            errors: {},
            isResettingPassword: false,
            isChangingStatus: false,
            officeType: null
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {roles, offices} = this.props;
        const {view} = this.state;

        if (roles !== prevProps.roles) {
            const orderedRoles = Object.values(roles).sort((a, b) => roleOrderComparator(a.id, b.id));

            this.setState({orderedRoles});
        }

        if (offices !== prevProps.offices) {
            const officeTypeMap = {};
            const regionMap = {};
            offices.forEach(o => {
                officeTypeMap[o.officeType] = true;
                if (o.officeType === OFFICE_TYPE_JAIL_LEVEL) {
                    regionMap[o.parent.id] = o.parent;
                }
            });

            const regions = Object.keys(regionMap).map(k => regionMap[k])
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            this.setState({
                offices,
                officeTypes: Object.keys(officeTypeMap).map(k => ({
                    label: this.officeTypes[k],
                    value: k
                })),
                regions,
                region: regions.length === 1 ? regions[0] : null
            });
        }

        if (view !== prevState.view && view === 'create_success') {
            // This is a bit hackish, it forces the modal size to default.
            this.modalRef.current.updateDimensions();
        }
    }

    componentWillUnmount() {
        this.requestCancelToken.cancel();
    }

    handleOpenModal = (view, user) => {
        if (view === 'create') {
            const {offices} = this.state;
            if (offices.length === 1) {
                this.setState({officeType: offices[0].officeType});
                user = user.update('office', offices[0]);
            }
        }

        this.setState({
            show: true,
            view,
            viewUser: user,
            currentUser: user
        });
    };

    handleCloseClick = () => {
        const {isProcessing} = this.state;
        if (!isProcessing) {
            this.setState({show: false});
        }
    };

    renderHeader = () => {
        const {view} = this.state;

        switch (view) {
            case 'edit':
                return `Edit User`;
            default:
                return `Create New User`;
        }
    }

    handleFormChange = (field, value) => {
        const {viewUser} = this.state;

        switch (field) {
            case 'officeType':
                this.setState({
                    officeType: value,
                    viewUser: viewUser.update('office', null)
                });
                break;
            case 'region':
                this.setState({
                    region: value,
                    viewUser: viewUser.update('office', null)
                });
                break;
            default:
                this.setState({
                    viewUser: viewUser.update(field, value)
                });
                break;
        }
    };

    handleRolesChange = (roleId, value) => {
        const {viewUser} = this.state;
        const {roles} = this.props;

        let newRoles;
        newRoles = viewUser.roles.filter(r => r.id !== roleId);

        if (value) {
            newRoles.push(roles[roleId]);
        }

        this.setState({
            viewUser: viewUser.update('roles', newRoles)
        });
    };

    renderRolesSection = canUpdateDetails => {
        const {view} = this.state;
        let {orderedRoles, officeType} = this.state;
        let viewUser = this.state.viewUser || {};
        let currentUser = this.state.currentUser || {};

        if (view === 'edit') {
            officeType = viewUser.office && viewUser.office.officeType;
        }
        if (officeType) {
            if (officeType === OFFICE_TYPE_HQ) {
                orderedRoles = orderedRoles.filter(r => !r.roleType || r.roleType.id === ROLE_TYPE_HQ);
            } else if (officeType === OFFICE_TYPE_REGIONAL) {
                orderedRoles = orderedRoles.filter(r => !r.roleType || r.roleType.id === ROLE_TYPE_REGIONAL);
            } else if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
                orderedRoles = orderedRoles.filter(r => !r.roleType || r.roleType.id === ROLE_TYPE_JAIL_LEVEL);
            }
        }

        const {permissions} = this.props;
        const userRoles = {};
        (viewUser.roles || []).forEach(r => { userRoles[r.id] = true; });

        let originalRoles = {};
        if (view === 'edit') {
            (currentUser.roles || []).forEach(r => { originalRoles[r.id] = true});
        } else {
            originalRoles = userRoles;
        }

        return (
            <div>
                {
                    orderedRoles.map(r => (
                        <RoleItem key={r.id} role={r} permissions={permissions} onChange={this.handleRolesChange}
                                  checked={userRoles.hasOwnProperty(r.id)} disabled={!canUpdateDetails}
                                  originalValue={originalRoles.hasOwnProperty(r.id)}/>
                    ))
                }
            </div>
        );
    };

    renderPermissionsSection = () => {
        const {viewUser, view} = this.state;
        let currentUser = this.state.currentUser || {};
        const {permissions, roles} = this.props;

        const effectivePermissions = {};
        const userRoles = viewUser.roles || [];

        const office = viewUser.office || {};
        let roleType;
        if (office.officeType === OFFICE_TYPE_HQ) {
            roleType = ROLE_TYPE_HQ;
        } else if (office.officeType === OFFICE_TYPE_REGIONAL) {
            roleType = ROLE_TYPE_REGIONAL;
        } else if (office.officeType === OFFICE_TYPE_JAIL_LEVEL) {
            roleType = ROLE_TYPE_JAIL_LEVEL;
        }

        userRoles.forEach(r => {
            if (!r.roleType || r.roleType.id === roleType) {
                roles[r.id].permissions.forEach(p => {
                    effectivePermissions[p.id] = true;
                })
            }
        });

        let originalPermissions = {}, allPermissions = {};
        if (view === 'edit') {
            (currentUser.roles || []).forEach(r => {
                roles[r.id].permissions.forEach(p => {
                    originalPermissions[p.id] = true;
                });
            });
            allPermissions = {...effectivePermissions, ...originalPermissions};
        } else {
            originalPermissions = allPermissions = effectivePermissions;
        }

        if (Object.keys(allPermissions).length) {
            const orderedPermissions = Object.keys(allPermissions).sort(permissionOrderComparator);

            return (
                <ul className="j-user-modal-effective-permissions">
                    {
                        orderedPermissions.map(p => {
                            let className;
                            if (!originalPermissions.hasOwnProperty(p)) {
                                className = "added";
                            } else if (!effectivePermissions.hasOwnProperty(p)) {
                                className = "removed";
                            }

                            return <li className={className} key={p}>{permissions[p].description}</li>;
                        })
                    }
                </ul>
            );
        } else {
            return <span className="j-user-modal-no-permission">No effective permissions.</span>;
        }
    }

    renderPasswordManagement = () => {
        const {viewUser: user, isResettingPassword} = this.state;

        return (
            <>
                <h3>Password Management</h3>
                <div className="j-user-modal-password-management">
                    {
                        !isResettingPassword &&
                        (
                            user.usesTemporaryPassword ?
                                <>
                                    <p>This account uses a temporary password.</p>
                                    <dl>
                                        <dt>Temporary Password</dt>
                                        <dd><code>{user.temporaryPassword}</code></dd>
                                    </dl>
                                </> :
                                <>
                                    <p>
                                        Reset and generate a temporary password for this account.
                                        This will logout all of the user's active sessions.
                                    </p>
                                    <button onClick={this.handleResetPasswordClick}
                                            className="btn btn-primary btn-sm btn-block">
                                        Reset Password
                                    </button>
                                </>
                        )
                    }
                    {
                        isResettingPassword &&
                        <>
                            <p>Are you sure you want to reset the password?</p>
                            <div className="row no-margin">
                                <button onClick={this.handleCancelResetPasswordClick}
                                        className="btn btn-default btn-sm pull-left">
                                    Cancel
                                </button>
                                <button onClick={this.handleConfirmResetPasswordClick}
                                        className="btn btn-primary btn-sm pull-right">
                                    Yes, reset password.
                                </button>
                            </div>
                        </>
                    }
                </div>
            </>
        );
    };

    handleResetPasswordClick = () => {
        this.setState({isResettingPassword: true});
    }

    handleCancelResetPasswordClick = () => {
        this.setState({isResettingPassword: false});
    }

    handleConfirmResetPasswordClick = () => {
        const {onEdit} = this.props;
        const {viewUser: user} = this.state;

        this.setState({isProcessing: true});
        patchResource(user.update('resetPassword', true))
            .onSuccess(document => {
                const {data} = document;
                const updated = user.resetModifiedProperties().merge({
                    resetPassword: false,
                    usesTemporaryPassword: true,
                    temporaryPassword: data.attributes.temporaryPassword
                });
                onEdit(updated);
                this.setState({
                    isProcessing: false,
                    viewUser: updated,
                    currentUser: updated,
                    isResettingPassword: false
                });
                notifySuccess("Reset Password", "Password reset successful.");
            })
            .onError(err => {
                this.setState({isProcessing: false});
                notifyUnableToProcess("Reset Password", "Unable to process your request.");
            })
            .execute(this.requestCancelToken);
    };

    handleToggleAccountStatusClick = () => {
        this.setState({isChangingStatus: true});
    };

    handleCancelToggleAccountStatusClick = () => {
        this.setState({isChangingStatus: false});
    };

    handleConfirmToggleAccountStatusClick = () => {
        const {onEdit} = this.props;
        let user = this.state.viewUser;
        user = user.update('active', !user.active);

        this.setState({isProcessing: true});
        patchResource(user)
            .onSuccess(() => {
                user = user.resetModifiedProperties();
                onEdit(user);
                this.setState({
                    isProcessing: false,
                    viewUser: user,
                    currentUser: user,
                    isChangingStatus: false
                });
                notifySuccess(
                    (user.active ? "Activate" : "Deactivate") + " Account",
                    "Account " + (user.active ? "" : "de") + "activation successful."
                );
            })
            .onError(err => {
                this.setState({
                    isProcessing: false
                });
                notifyUnableToProcess((user.active ? "Activate" : "Deactivate") + " Account");
            })
            .execute(this.requestCancelToken);
    };

    handleCreateClick = () => {
        const {onCreate} = this.props;
        let user = this.state.viewUser;
        const {office} = user;

        this.setState({isProcessing: true});
        postResource(user)
            .onSuccess(user => {
                onCreate(user.set('office', office));
                notifyItemRequestSuccess(
                    "Create User",
                    "Account successfully added.",
                    `[${user.username}] ${user.displayName}`
                );
                this.setState({
                    view: 'create_success',
                    viewUser: user,
                    isProcessing: false
                });
            })
            .onError(err => {
                this.setState({isProcessing: false});
                if (err.isValidationError) {
                    this.setState({errors: err.errors});
                    notifyFormError("Create User");
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    handleSaveClick = () => {
        const {onEdit, offices} = this.props;
        let user = this.state.viewUser;

        this.setState({isProcessing: true});
        patchResource(user)
            .onSuccess(() => {
                fetchSingleResource(User, user.id)
                    .onSuccess(user => {
                        onEdit(user.set('office', offices.find(o => user.office.id === o.id)));
                        this.setState({
                            show: false,
                            isProcessing: false
                        });
                    })
                    .execute(this.requestCancelToken);
                notifySuccess("Edit User", "Account details successfully updated.");
            })
            .onError(err => {
                this.setState({isProcessing: false});
                if (err.isValidationError) {
                    this.setState({errors: err.errors});
                    notifyFormError("Edit User");
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    renderAccountStatus = () => {
        const {viewUser: user, isChangingStatus} = this.state;

        return (
            <>
                <h3>Account Status</h3>
                <div>
                    {
                        !isChangingStatus &&
                        (
                            user.active ?
                                <>
                                    <p>This account is active.</p>
                                    <Can I="update" this={user} field="deactivate">
                                        <button onClick={this.handleToggleAccountStatusClick}
                                                className="btn btn-danger btn-sm btn-block">
                                            Deactivate Account
                                        </button>
                                    </Can>
                                </> :
                                <>
                                    <p>This account is inactive.</p>
                                    <Can I="update" this={user} field="activate">
                                        <button onClick={this.handleToggleAccountStatusClick}
                                                className="btn btn-success btn-sm btn-block">
                                            Activate Account
                                        </button>
                                    </Can>
                                </>
                        )
                    }
                    {
                        isChangingStatus &&
                        <>
                            <p>Are you sure you want to {user.active ? "deactivate" : "activate"} this account?</p>
                            <div className="row no-margin">
                                <button onClick={this.handleCancelToggleAccountStatusClick}
                                        className="btn btn-default btn-sm pull-left">
                                    Cancel
                                </button>
                                <button onClick={this.handleConfirmToggleAccountStatusClick}
                                        className={`btn ${user.active ? "btn-danger" : "btn-success"} btn-sm pull-right`}>
                                    Yes, {user.active ? "deactivate" : "activate"}.
                                </button>
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }

    getLabelByName = option => option.name;
    getValueById = option => option.id;

    resolveOffices = () => {
        const {offices, officeType, region} = this.state;
        return offices.filter(o =>
            o.officeType === officeType
            && (
                officeType !== OFFICE_TYPE_JAIL_LEVEL ||
                (region && o.parent.id === region.id)
            )
        );
    };

    renderUserDetails = () => {
        const {errors, viewUser: user, view, officeTypes, officeType, regions, region} = this.state;
        const ability = this.context;
        const canUpdateDetails = view === 'create' || ability.can('update', user, 'details');

        const offices = this.resolveOffices();

        return (<>
            <div className="row">
                <div className="col-md-3">
                    <h3>User Account Details</h3>
                    <Form onChange={this.handleFormChange} errors={errors} disabled={!canUpdateDetails}>
                        <TextInput label="Username" name="username"
                                   value={user.username} disabled={view === 'edit'} maxLength={50}/>
                        <TextInput label="First Name" name="firstName" value={user.firstName} maxLength={150}/>
                        <TextInput label="Middle Name" name="middleName" value={user.middleName} maxLength={150}/>
                        <TextInput label="Last Name" name="lastName" value={user.lastName} maxLength={150}/>
                        {
                            officeTypes.length > 1 &&
                            <SelectInput label="Office Type" name="officeType" value={officeType || (view === 'edit' && user.office && user.office.officeType)}
                                         options={officeTypes} simpleValue disabled={view === 'edit'}/>
                        }
                        {
                            officeType === OFFICE_TYPE_JAIL_LEVEL && regions.length > 1 &&
                            <SelectInput label="Region" name="region" value={region}
                                         options={regions} getOptionLabel={this.getLabelByName}
                                         getOptionValue={this.getValueById} disabled={view === 'edit'}/>
                        }
                        <SelectInput label="Office" name="office" value={user.office}
                                     options={offices} getOptionLabel={this.getLabelByName}
                                     getOptionValue={this.getValueById} disabled={view === 'edit'}/>
                    </Form>
                    {
                        view === 'edit' &&
                        <>
                            <Can I="update" this={user} field="password">
                                {this.renderPasswordManagement()}
                            </Can>
                            {this.renderAccountStatus()}
                        </>
                    }
                </div>
                <div className="col-md-5">
                    <h3>Roles</h3>
                    {this.renderRolesSection(canUpdateDetails)}
                </div>
                <div className="col-md-4">
                    <h3>Effective Permissions</h3>
                    {this.renderPermissionsSection()}
                </div>
            </div>
        </>);
    };

    renderCreateSuccess = () => {
        const {viewUser} = this.state;

        return (
            <div className="j-user-modal-create-success">
                <p>Account successfully created. Please advise user to change password immediately.</p>
                <table>
                    <tbody>
                    <tr>
                        <td>Username</td>
                        <td>{viewUser.username}</td>
                    </tr>
                    <tr>
                        <td>Password</td>
                        <td><code>{viewUser.temporaryPassword}</code></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    };

    renderBody = () => {
        const {view} = this.state;

        switch(view) {
            case 'create':
            case 'edit':
                return this.renderUserDetails();
            case 'create_success':
                return this.renderCreateSuccess();
            default:
                return null;
        }
    };

    renderFooter = () => {
        const {view, isProcessing} = this.state;

        switch (view) {
            case 'create':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={this.handleCloseClick}
                                disabled={isProcessing}>
                            Cancel
                        </button>
                        <button className="btn btn-primary pull-right" onClick={this.handleCreateClick}
                                disabled={isProcessing}>
                            <i className="fa fa-plus-circle margin-r-5"/>Create
                        </button>
                    </>
                );
            case 'edit':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={this.handleCloseClick}
                                disabled={isProcessing}>
                            Cancel
                        </button>
                        <button className="btn btn-primary pull-right" onClick={this.handleSaveClick}
                                disabled={isProcessing}>
                            <i className="fa fa-save margin-r-5"/>Save
                        </button>
                    </>
                );
            case 'create_success':
                return (
                    <button className="btn btn-primary center-block" onClick={this.handleCloseClick}>
                        OK
                    </button>
                );
            default:
                return null;
        }
    }

    render() {
        const {isProcessing, show, view} = this.state;

        let size;
        switch (view) {
            case 'create_success':
                size = "default";
                break;
            default:
                size = "large";
                break;
        }

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick} isLoading={isProcessing}
                   className="j-user-modal" size={size} fixedScroll ref={this.modalRef}>
                <Modal.Header>{this.renderHeader()}</Modal.Header>
                <Modal.Body>
                    {this.renderBody()}
                </Modal.Body>
                <Modal.Footer>
                    {this.renderFooter()}
                </Modal.Footer>
            </Modal>
        );
    }
}

UserModal.contextType = AbilityContext;
export default UserModal;