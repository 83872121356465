import React from 'react';

export const DiseaseSurveillanceIndicatorAggregateContext = React.createContext({
    indicatorMap: {},
    indicatorTagMap: {},
    indicatorAggregateMap: {}
});

function diseaseSurveillanceIndicatorAggregateContextSubscriber(Component) {
    return class extends React.Component {
        render() {
            return (
                <DiseaseSurveillanceIndicatorAggregateContext.Consumer>
                    {
                        ({indicatorTagMap, indicatorAggregateMap}) => (
                            <Component indicatorTagMap={indicatorTagMap}
                                       indicatorAggregateMap={indicatorAggregateMap}
                                       {...this.props}
                            />
                        )
                    }
                </DiseaseSurveillanceIndicatorAggregateContext.Consumer>
            );
        }
    };
}

export function withAggregateTagMap(Component) {
    class AggregateTagMapWrapper extends React.Component {
        constructor(props) {
            super(props);

            this.wrappedRef = React.createRef();
        }

        componentDidMount() {
            const {indicatorTagMap} = this.props;
            if (Object.keys(indicatorTagMap).length > 0) {
                this.wrappedRef.current.onAggregatesLoad();
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            const {indicatorTagMap, indicatorAggregateMap, indicatorMap} = this.props;
            if (
                indicatorMap !== prevProps.indicatorMap
                || indicatorTagMap !== prevProps.indicatorTagMap
                || indicatorAggregateMap !== prevProps.indicatorAggregateMap
            ) {
                this.wrappedRef.current.onAggregatesLoad();
            }
        }

        getEmptyAggregate = () => ({
            maleSum: 0,
            femaleSum: 0,
            maleTotalAverage: 0,
            femaleTotalAverage: 0,
            maleLatest: 0,
            femaleLatest: 0,
            reportMale: 0,
            reportFemale: 0,
            reportTotal: 0,
            latestTotal: 0,
        });

        getAggregateByTag = tag => {
            const {indicatorTagMap, indicatorAggregateMap} = this.props;
            const indicator = indicatorTagMap[tag];

            if (!indicator) {
                return this.getEmptyAggregate();
            }

            return indicatorAggregateMap[indicator.id] || this.getEmptyAggregate();
        };

        getAggregateById = id => {
            const {indicatorAggregateMap} = this.props;
            return indicatorAggregateMap[id] || this.getEmptyAggregate();
        };

        render() {
            const {indicatorTagMap, indicatorAggregateMap, ...props} = this.props;
            return <Component ref={this.wrappedRef} getAggregateById={this.getAggregateById}
                              getAggregateByTag={this.getAggregateByTag} {...props}/>;
        }
    }
    return diseaseSurveillanceIndicatorAggregateContextSubscriber(AggregateTagMapWrapper);
}