import React from 'react';
import PropTypes from "prop-types";
import {highlightString} from "@/utilities/highlight-string";
import Tag from "@/screens/Configuration/Tag";

class IndicatorIndexItem extends React.Component {
    handleEditClick = () => {
        const {onEditClick, indicator} = this.props;
        onEditClick(indicator);
    };

    handleDeleteClick = () => {
        const {onDeleteClick, indicator} = this.props;
        onDeleteClick(indicator);
    };

    render() {
        const {indicator, search, dataSources, hasDataSource, hasSpecial} = this.props;
        const usedDataSources = [];
        dataSources.forEach(d => {
            if (indicator.dataSources.find(ids => ids.id === d.id)) {
                usedDataSources.push(d);
            }
        });
        const dataSourcesText = usedDataSources.map(u => u.name).join('/');
        const className = hasDataSource ? 'j-indicators-item' : 'j-base-indicators-item';

        return (
            <div className={`j-config-list-item ${className}`}>
                <div>
                    <span>{indicator.orderIndex}</span>
                </div>
                <div>
                    <span>{highlightString(search, indicator.name)}</span>
                    {
                        indicator.inUse &&
                        <Tag text="In Use" className="j-config-item-tag-in-use"/>
                    }
                    {
                        !indicator.active &&
                        <Tag text="Inactive" className="j-config-item-tag-inactive"/>
                    }
                    {
                        hasSpecial && indicator.special &&
                        <Tag text="Special" className="j-indicators-item-tag-special"/>
                    }
                </div>
                <div>
                    <span>{indicator.summaryType.charAt(0).toUpperCase() + indicator.summaryType.slice(1)}</span>
                </div>
                {
                    hasDataSource &&
                    <div>
                        <span>{dataSourcesText || <>&nbsp;</>}</span>
                    </div>
                }
                <div>
                    <span className="btn-group pull-right">
                        <button className="btn btn-default btn-xs" onClick={this.handleEditClick}>
                            <i className="fa fa-pencil margin-r-5"/>Edit
                        </button>
                        <button className="btn btn-default btn-xs" onClick={this.handleDeleteClick}
                                disabled={indicator.inUse}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

IndicatorIndexItem.propTypes = {
    dataSources: PropTypes.array.isRequired,
    hasDataSource: PropTypes.bool.isRequired,
    hasSpecial: PropTypes.bool.isRequired
};

export default IndicatorIndexItem;