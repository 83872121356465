import React from 'react'
import Box from "@vallarj/react-adminlte/Box";
import {Link} from "react-router-dom";
import TextInput from "@vallarj/react-adminlte/Form/TextInput";
import {createCancelToken, deleteResource, fetchResourceCollection} from "@/utilities/jsonapi";
import {noop} from "@/utilities/noop";
import {DiseaseSurveillanceDashboardComponent} from "@/records/DiseaseSurveillanceDashboardComponent";
import CustomDashboardComponentIndexItem
    from "@/screens/Configuration/DiseaseMonitoring/CustomDashboardComponents/CustomDashboardComponentIndexItem";
import {escapeRegExp} from "@/utilities/highlight-string";
import ConfirmDeleteModal from "@/screens/Configuration/DiseaseMonitoring/CustomDashboardComponents/ConfirmDeleteModal";
import {notifySuccess} from "@/utilities/notifications";

class CustomDashboardComponents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            components: [],
            isLoading: true,
            search: null
        };

        this.fetchCancelToken = createCancelToken();
        this.openModal = noop;
    }

    componentDidMount() {
        this.fetchDashboardComponents();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    fetchDashboardComponents = () => {
        this.setState({isLoading: true});
        fetchResourceCollection(DiseaseSurveillanceDashboardComponent)
            .onSuccess(collection => {
                this.setState({
                    components: collection,
                    isLoading: false
                });
            })
            .execute(this.fetchCancelToken);
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});
    };

    filterComponents = () => {
        const {components} = this.state;
        const search = (this.state.search && this.state.search.trim()) || "";

        if (!search) {
            return components;
        }

        return components.filter(c => c.title.match(RegExp(escapeRegExp(search), "i")));
    };

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    handleDeleteClick = component => {
        this.openModal(component);
    };

    handleConfirmDelete = component => {
        this.setState({isLoading: true});
        deleteResource(component)
            .onSuccess(() => {
                this.fetchDashboardComponents();
                notifySuccess("Delete Dashboard Component", "Component deleted successfully");
            })
            .execute(this.fetchCancelToken);
    };

    render() {
        const {search, isLoading} = this.state;
        const components = this.filterComponents();

        return (<>
            <ConfirmDeleteModal registerOpenModal={this.registerOpenModal}
                                onConfirmDelete={this.handleConfirmDelete}/>
            <Box theme='box-primary' isLoading={isLoading}>
                <Box.Header title="Custom Dashboard Components"/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..."
                               value={search} onChange={this.handleSearchChange}/>
                    <div className="j-custom-dashboard-components j-box-scroll">
                        <div className="j-config-list-header j-custom-dashboard-components-header">
                            <span>Order</span>
                            <span>Title</span>
                            <span></span>
                        </div>
                        {
                            components.map(c => (
                                <CustomDashboardComponentIndexItem key={c.id} component={c}
                                                                   onDeleteClick={this.handleDeleteClick}
                                                                   search={search}/>
                            ))
                        }
                    </div>
                </Box.Body>
                <Box.Footer>
                    <Link to="/config/disease-monitoring/custom-dashboard-components/create"
                          className="btn btn-primary pull-right">
                        <i className="fa fa-plus-circle margin-r-5"/>Create Component
                    </Link>
                </Box.Footer>
            </Box>
        </>);
    }
}

export default CustomDashboardComponents;