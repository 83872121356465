import React from 'react';
import BaseIndicatorCategories from "@/screens/Configuration/IndicatorCategories";
import {IndicatorCategory} from "@/records/IndicatorCategory";

class IndicatorCategories extends React.Component {
    render() {
        return (
            <BaseIndicatorCategories
                resourceType={IndicatorCategory}
                hasParentSubcategoryNumbered
                title="Indicator Categories"/>
        );
    }
}

export default IndicatorCategories;