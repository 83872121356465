import React from "react";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import MedicalDentalChart from "@/screens/Dashboard/Treatment/MedicalDental/MedicalDentalChart";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_BJMP_MEDICAL = 'default_114';
const TAG_BJMP_DENTAL = 'default_115';
const TAG_EXTERNAL_MEDICAL = 'default_116';
const TAG_EXTERNAL_DENTAL = 'default_117';

class MedicalDental extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bjmpMedical: 0,
            bjmpDental: 0,
            bjmpTotal: 0,
            pctBjmp: 0,
            externalMedical: 0,
            externalDental: 0,
            externalTotal: 0,
            pctExternal: 0,
            totalMedical: 0,
            totalDental: 0,
            totalAll: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const bjmpMedical = getAggregateByTag(TAG_BJMP_MEDICAL).reportTotal;
        const bjmpDental = getAggregateByTag(TAG_BJMP_DENTAL).reportTotal;
        const externalMedical = getAggregateByTag(TAG_EXTERNAL_MEDICAL).reportTotal;
        const externalDental = getAggregateByTag(TAG_EXTERNAL_DENTAL).reportTotal;

        const bjmpTotal = bjmpMedical + bjmpDental;
        const externalTotal = externalMedical + externalDental;
        const totalMedical = bjmpMedical + externalMedical;
        const totalDental = bjmpDental + externalDental;
        const totalAll = totalMedical + totalDental;

        this.setState({
            bjmpMedical,
            bjmpDental,
            bjmpTotal,
            pctBjmp: pctTotalOver(bjmpTotal, totalAll),
            externalMedical,
            externalDental,
            externalTotal,
            pctExternal: pctTotalOver(externalTotal, totalAll),
            totalMedical,
            totalDental,
            totalAll,
        });
    };

    render() {
        const {
            bjmpMedical, bjmpDental, bjmpTotal, pctBjmp, externalMedical, externalDental,
            externalTotal, pctExternal, totalMedical, totalDental, totalAll,
        } = this.state;

        return (
            <div className="j-dash-box-subgroup">
                <h3>Medical-Dental Mission</h3>
                <div className="row">
                    <div className="col-xs-5 col-lg-12">
                        <div className="j-dash-tabular-group">
                            <table>
                                <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Medical</th>
                                    <th>Dental</th>
                                    <th>Total</th>
                                    <th>%</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>BJMP Led</td>
                                    <td>{bjmpMedical}</td>
                                    <td>{bjmpDental}</td>
                                    <td>{bjmpTotal}</td>
                                    <td>{pctBjmp}%</td>
                                </tr>
                                <tr>
                                    <td>External Partners</td>
                                    <td>{externalMedical}</td>
                                    <td>{externalDental}</td>
                                    <td>{externalTotal}</td>
                                    <td>{pctExternal}%</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{totalMedical}</td>
                                    <td>{totalDental}</td>
                                    <td>{totalAll}</td>
                                    <td>100.00%</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="col-xs-7 col-lg-12">
                        <MedicalDentalChart {...this.state}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAggregateTagMap(MedicalDental);