import React from 'react';

import Box from '@vallarj/react-adminlte/Box';
import BreadcrumbItem from '@vallarj/react-adminlte/Content/BreadcrumbItem';
import Content from '@vallarj/react-adminlte/Content';
import Form, {TextInput} from '@vallarj/react-adminlte/Form';
import {User} from "@/records/User";
import {notifyFormError, notifySuccess} from "@/utilities/notifications";
import {createCancelToken, patchResource} from "@/utilities/jsonapi";

class AccountSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPassword: "",
            password: "",
            confirmPassword: "",
            isProcessing: false,
            disableControls: false,
            errors: {}
        };
        this.requestCancelToken = createCancelToken();
    }

    componentWillUnmount() {
        this.requestCancelToken.cancel();
    }

    handlePasswordFormChange = (field, value) => {
        this.setState({[field]: value});
    };

    handlePasswordFormSubmit = () => {
        const {oldPassword, password, confirmPassword} = this.state;
        this.setState({errors: {}});

        // Check if password is equal to confirmPassword
        if (password !== confirmPassword) {
            this.setState({errors: {confirmPassword: ['Passwords do not match.']}});
            return;
        }

        this.setState({isProcessing: true});
        const user = (new User())
            .set('id', 'me')
            .mergeUpdate({oldPassword, password: password});

        patchResource(user)
            .onSuccess(() => {
                notifySuccess("Change Password",
                    "Password successfully changed. You will now be redirected to the login page.");

                this.setState({
                    isProcessing: false,
                    disableControls: true
                });
                setTimeout(() => { window.location = `${process.env.PUBLIC_URL}/logout` }, 2000);
            })
            .onError(err => {
                this.setState({isProcessing: false});
                if (err.isValidationError) {
                    this.setState({errors: err.errors});
                    notifyFormError("Change Password");
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    render() {
        const {
            oldPassword, password, confirmPassword,
            errors, isProcessing, disableControls
        } = this.state;
        const {usesTemporaryPassword} = this.props;

        const title = usesTemporaryPassword ? "Change Temporary Password" : "Change Password";
        const oldPasswordLabel = usesTemporaryPassword ? "Temporary Password" : "Old Password";

        return (
            <Content>
                <Content.Header title="Account Settings"/>
                <Content.Breadcrumb>
                    <BreadcrumbItem label="Account Settings" iconClass="fa fa-gears" active/>
                </Content.Breadcrumb>
                <Content.Body>
                    <div className="row">
                        <div className="col-lg-offset-2 col-lg-8">
                            <Box isLoading={isProcessing}>
                                <Box.Header title={title}/>
                                <Box.Body>
                                    <div className="row">
                                        <div className="col-lg-5">
                                            {
                                                !!usesTemporaryPassword &&
                                                <p><strong>
                                                    You are currently using a temporary password. Please change your
                                                    password to gain full access to the system.
                                                </strong></p>
                                            }
                                            <p>
                                                You will be automatically redirected to the login page
                                                once you have successfully changed your password. All active sessions
                                                in other devices will be invalidated as well.
                                            </p>
                                        </div>
                                        <div className="col-lg-7">
                                            <Form onChange={this.handlePasswordFormChange} errors={errors}
                                                  onEnterKey={this.handlePasswordFormSubmit} disabled={disableControls}>
                                                <div className="row">
                                                    <TextInput value={oldPassword} label={oldPasswordLabel} gridClass="col-xs-12"
                                                               type="password" name="oldPassword"/>
                                                    <TextInput value={password} label="New Password" gridClass="col-xs-12"
                                                               type="password" name="password"/>
                                                    <TextInput value={confirmPassword} label="Confirm Password" gridClass="col-xs-12"
                                                               type="password" name="confirmPassword"/>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Box.Body>
                                <Box.Footer>
                                    <button type="button" onClick={this.handlePasswordFormSubmit}
                                            className="btn btn-default pull-right"
                                            disabled={disableControls}>
                                        Submit
                                    </button>
                                </Box.Footer>
                            </Box>
                        </div>
                    </div>
                </Content.Body>
            </Content>
        );
    }
}

export default AccountSettings;