import React from "react";
import DropdownButton from "@vallarj/react-adminlte/DropdownButton";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import {getFileRequest} from "@/utilities/axios";
import {notifySuccess} from "@/utilities/notifications";
import {config} from "@/config";

class ActionsDropdownButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    handleGenerateNationalClick = () => {
        const {office, fromDate, toDate} = this.props;
        const params = {
            f: fromDate.format('YYYY-MM-DD'),
            t: toDate.format('YYYY-MM-DD')
        };

        this.setState({isLoading: true});
        getFileRequest(
            `${config.apiBaseUrl}/reports/headquarters-disease-surveillance-consolidated/${office.id}`,
            `National_DS_Report.xlsx`,
            {params}
        ).then(() => {
            this.setState({isLoading: false});
            notifySuccess(`Generate National Disease Surveillance Consolidated XLSX Report`, "Report successfully generated.");
        });
    };

    handleGenerateRegionalClick = () => {
        const {office, fromDate, toDate} = this.props;
        const params = {
            f: fromDate.format('YYYY-MM-DD'),
            t: toDate.format('YYYY-MM-DD')
        };

        this.setState({isLoading: true});
        getFileRequest(
            `${config.apiBaseUrl}/reports/regional-disease-surveillance-consolidated/${office.id}`,
            `${office.name}_DS_Report.xlsx`,
            {params}
        ).then(() => {
            this.setState({isLoading: false});
            notifySuccess(`Generate Regional Disease Surveillance Consolidated XLSX Report`, "Report successfully generated.");
        });
    };

    handleGenerateJailLevelClick = () => {
        const {office, fromDate, toDate} = this.props;
        const params = {
            f: fromDate.format('YYYY-MM-DD'),
            t: toDate.format('YYYY-MM-DD')
        };

        this.setState({isLoading: true});
        getFileRequest(
            `${config.apiBaseUrl}/reports/jail-level-disease-surveillance-aggregated/${office.id}`,
            `${office.name}_DS_Report.xlsx`,
            {params}
        ).then(() => {
            this.setState({isLoading: false});
            notifySuccess(`Generate Jail Level Disease Surveillance XLSX Report`, "Report successfully generated.");
        });
    };

    handleExportDashboardClick = () => {
        const {office, fromDate, toDate} = this.props;
        const params = {
            f: fromDate.format('YYYY-MM-DD'),
            t: toDate.format('YYYY-MM-DD'),
            office: office.id
        };

        this.setState({isLoading: true});
        getFileRequest(
            `/export-disease-surveillance-dashboard`,
            `${office.name}_DS_Dashboard.pdf`,
            {params}
        ).then(() => {
            this.setState({isLoading: false});
            notifySuccess(`Export Disease Surveillance Dashboard to PDF`, "Dashboard successfully exported.");
        });
    };

    createGenerateNationalAction = () => {
        const {office} = this.props;
        if (!office || office.officeType !== OFFICE_TYPE_HQ) {
            return null;
        }

        return <DropdownButton.MenuItem label="Generate National Consolidated Disease Surveillance XLSX Report"
                                        onClick={this.handleGenerateNationalClick}/>
    };

    createGenerateRegionalAction = () => {
        const {office} = this.props;
        if (!office || office.officeType !== OFFICE_TYPE_REGIONAL) {
            return null;
        }

        return <DropdownButton.MenuItem label="Generate Regional Consolidated Disease Surveillance XLSX Report"
                                        onClick={this.handleGenerateRegionalClick}/>
    };

    createGenerateJailLevelAction = () => {
        const {office} = this.props;
        if (!office || office.officeType !== OFFICE_TYPE_JAIL_LEVEL) {
            return null;
        }

        return <DropdownButton.MenuItem label="Generate Jail Level Disease Surveillance XLSX Report"
                                        onClick={this.handleGenerateJailLevelClick}/>
    };

    createExportDashboardAction = () => {
        return <DropdownButton.MenuItem label="Export Disease Surveillance Dashboard as PDF"
                                        onClick={this.handleExportDashboardClick}/>
    };

    renderButtonLabel = label => {
        const {isLoading} = this.state;

        return (
            <span>
                {
                    isLoading &&
                    <i className="fa fa-spin fa-spinner margin-r-5"/>
                }
                {label} <i className="caret"/>
            </span>
        );
    };

    render() {
        const {isLoading} = this.state;

        const generateNationalAction = this.createGenerateNationalAction();
        const generateRegionalAction = this.createGenerateRegionalAction();
        const generateJailLevelAction = this.createGenerateJailLevelAction();
        const exportDashboardAction = this.createExportDashboardAction();

        return (
            <DropdownButton label="Actions" className="btn btn-default btn-block" menuAlignment="right"
                            renderButtonLabel={this.renderButtonLabel}>
                {!isLoading && generateNationalAction}
                {!isLoading && generateRegionalAction}
                {!isLoading && generateJailLevelAction}
                <DropdownButton.Divider/>
                {!isLoading && exportDashboardAction}
            </DropdownButton>
        );
    }
}

export default ActionsDropdownButton;