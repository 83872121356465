import React from 'react';
import OptionsBox from "@/screens/JailLevelReports/JailLevelReportEntry/OptionsBox";
import {Medication} from "@/records/Medication";

class Medications extends React.Component {
    render() {
        return (
            <OptionsBox boxTitle="Medications (J007)"
                        headerTitle="Medications"
                        attributeName="medications"
                        optionType={Medication}
                        onAttributeChange={this.props.onAttributeChange}
                        onAttributesReplace={this.props.onAttributesReplace}
                        onAttributeRevert={this.props.onAttributeRevert}
                        attributesChanged={this.props.attributesChanged}
                        isLoading={this.props.isLoading}
                        onSaveClick={this.props.onSaveClick}
                        isEditMode={this.props.isEditMode}
                        sheetIndex={5}
                        values={this.props.values}/>
        );
    }
}

export default Medications;