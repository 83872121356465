import React from "react";
import {connect} from "react-redux";
import {createCancelToken, fetchSingleResource, patchResource} from "@/utilities/jsonapi";
import {noop} from "@/utilities/noop";
import {DiseaseMonitoringReport} from "@/records/DiseaseMonitoringReport";
import {Redirect, Route, Switch} from "react-router-dom";
import Content from "@vallarj/react-adminlte/Content";
import {AbilityContext} from "@/ability";
import {OFFICE_TYPE_JAIL_LEVEL} from "@/permissions";
import ReportStatsTag from "@/components/ReportStatsTag";
import BreadcrumbItem from "@vallarj/react-adminlte/Content/BreadcrumbItem";
import Menu from "@/screens/DiseaseSurveillanceReports/DiseaseSurveillanceReportEntry/Menu";
import IndicatorReport from "@/screens/DiseaseSurveillanceReports/DiseaseSurveillanceReportEntry/IndicatorReport";
import {notifySuccess} from "@/utilities/notifications";
import SubmitErrorModal from "@/screens/DiseaseSurveillanceReports/DiseaseSurveillanceReportEntry/SubmitErrorModal";

class DiseaseSurveillanceReportEntry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            submitting: false,
            entry: null,
            indicatorMap: {},
            indicators: {},
            indicatorsChanged: {}
        };

        this.requestCancelToken = createCancelToken();
        this.importOpenModal = noop;
        this.submitOpenModal = noop;
    }

    componentDidMount() {
        this.fetchDiseaseSurveillanceReport();
    }

    componentWillUnmount() {
        this.requestCancelToken.cancel();
    }

    registerImportOpenModal = openModal => {
        this.importOpenModal = openModal;
    };

    registerSubmitOpenModal = openModal => {
        this.submitOpenModal = openModal;
    };

    fetchDiseaseSurveillanceReport = () => {
        const {match} = this.props;
        const {id} = match.params;

        this.setState({isLoading: true});
        fetchSingleResource(DiseaseMonitoringReport, id)
            .onSuccess(report => {
                this.setState({
                    entry: report,
                    isLoading: false,
                    indicators: report.indicators,
                    indicatorsChanged: {}
                });
            })
            .onError(err => {
                // If forbidden, redirect
                const response = (err.response || {});
                if (response.status === 403 || response.status === 500) {
                    this.props.history.replace('/disease-surveillance-reports');
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    isEditMode = () => {
        const {match} = this.props;
        const {action} = match.params;

        return action === 'edit';
    };

    renderSubtitle = () => {
        const {entry} = this.state;
        const {officeType} = this.props;

        let tags = [];
        if (entry.finalized) {
            if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
                tags.push(<ReportStatsTag key="submitted" type={ReportStatsTag.Submitted}/>);
            } else {
                tags.push(<ReportStatsTag key="finalized" type={ReportStatsTag.Finalized}/>);
            }
        } else {
            tags.push(<ReportStatsTag key={entry.isComplete ? "complete" : "incomplete"}
                                      type={entry.isComplete ? ReportStatsTag.Complete : ReportStatsTag.Incomplete}/>);
            if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
                tags.push(<ReportStatsTag key="not-submitted" type={ReportStatsTag.NotSubmitted}/>);
            } else {
                tags.push(<ReportStatsTag key="unfinalized" type={ReportStatsTag.Unfinalized}/>);
            }
        }

        return tags;
    };

    handleIndicatorChange = (indicatorId, type, count) => {
        const {indicators} = this.state;

        const newValue = {...(indicators[indicatorId] || {}), [type]: count};
        let indicatorsChanged = this.state.indicatorsChanged;

        if (this.checkSameValues(indicatorId, newValue)) {
            const {[indicatorId]: z, ...rest} = indicatorsChanged;
            indicatorsChanged = rest;
        } else {
            indicatorsChanged = {...indicatorsChanged, [indicatorId]: true};
        }

        this.setState({
            indicators: {
                ...indicators,
                [indicatorId]: newValue
            },
            indicatorsChanged
        });
    };

    handleIndicatorsReplace = indicators => {
        const indicatorsChanged = {};
        Object.keys(indicators).forEach(id => {
            if (!this.checkSameValues(id, indicators[id])) {
                indicatorsChanged[id] = true;
            }
        });

        this.setState({
            indicators,
            indicatorsChanged
        });
    };

    checkSameValues = (indicatorId, modifiedValue) => {
        const {entry} = this.state;
        const currentValue = entry.indicators[indicatorId] || {};

        // noinspection EqualityComparisonWithCoercionJS
        return currentValue.male == modifiedValue.male // eslint-disable-line
            && currentValue.female == modifiedValue.female // eslint-disable-line
    };

    handleImportClick = () => {
        this.importOpenModal();
    };

    handleSaveClick = () => {
        let {entry} = this.state;

        const updateIndicators = {...this.state.indicators};
        Object.keys(updateIndicators).forEach(u => {
            if (updateIndicators[u].male == null && updateIndicators[u].female == null) {
                delete updateIndicators[u];
            } else {
                updateIndicators[u] = {
                    male: updateIndicators[u].male || 0,
                    female: updateIndicators[u].female || 0
                };
            }
        });

        entry = entry.update('indicators', updateIndicators);

        this.setState({isLoading: true});
        patchResource(entry)
            .onSuccess(() => {
                notifySuccess("Disease Surveillance Reports", "Report saved.");
                this.fetchDiseaseSurveillanceReport();
            })
            .execute(this.requestCancelToken);
    };

    handleRevertClick = () => {
        this.setState({
            indicators: this.state.entry.indicators,
            indicatorsChanged: {}
        });
    };

    handleSubmitClick = () => {
        let {entry} = this.state;
        entry = entry.update('finalized', true);
        this.setState({submitting: true});
        patchResource(entry)
            .onSuccess(() => {
                this.setState({
                    submitting: false,
                    entry
                });
                const {url} = this.props.match;
                this.props.history.replace(url.replace('edit', 'view'));
                notifySuccess("Disease Surveillance Report", "Report successfully submitted.");
            })
            .onError(err => {
                if (err.isValidationError) {
                    const {errors} = err.baseError.response.data;
                    this.submitOpenModal(errors[0]);
                    this.setState({submitting: false});
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    render() {
        const {entry, isLoading, indicators, indicatorsChanged, submitting} = this.state;
        const {path, url} = this.props.match;

        if (!entry) return null;

        const hasIndicatorsChanged = Object.keys(indicatorsChanged).length > 0;
        const isEditMode = this.isEditMode();
        const ability = this.context;
        const canManageEntry = ability.can('update', entry);

        if (isEditMode) {
            if (!canManageEntry || entry.finalized) {
                return <Redirect to='/disease-surveillance-reports'/>;
            }
        }

        const dateLabel = entry.reportDate.format('MMMM D, YYYY');
        const mainClassName = "row j-disease-surveillance-report" + (entry.finalized ? " finalized" : "");
        return (<Content>
            <Content.Header title={`Report - ${entry.office.name} (${dateLabel})`}
                            subtitle={this.renderSubtitle()}/>
            <Content.Breadcrumb>
                <BreadcrumbItem label="Disease Surveillance Reports"
                                iconClass="fa fa-book"
                                path='/disease-surveillance-reports'
                />
                <BreadcrumbItem label={`${entry.office.name}`}/>
                <BreadcrumbItem label={dateLabel} active/>
            </Content.Breadcrumb>
            <Content.Body>
                <SubmitErrorModal registerOpenModal={this.registerSubmitOpenModal}/>
                <div className={mainClassName}>
                    <Menu path={url}
                          indicatorsChanged={hasIndicatorsChanged}
                          isEditMode={isEditMode}
                          canManageEntry={canManageEntry}
                          onImportClick={this.handleImportClick}
                          onSaveClick={this.handleSaveClick}
                          onSubmitClick={this.handleSubmitClick}
                          onRevertClick={this.handleRevertClick}
                          submitting={submitting}
                          finalized={entry.finalized}/>
                    <div className="col-xs-9">
                        <Switch>
                            <Route exact path={`${path}/indicators`} render={routeProps => (
                                <IndicatorReport {...routeProps} indicators={indicators}
                                                 onIndicatorChange={this.handleIndicatorChange}
                                                 onIndicatorsReplace={this.handleIndicatorsReplace}
                                                 registerOpenModal={this.registerImportOpenModal}
                                                 isEditMode={isEditMode}
                                                 isLoading={isLoading}/>
                            )}/>
                            <Redirect to={`${url}/indicators`}/>
                        </Switch>
                    </div>
                </div>
            </Content.Body>
        </Content>);
    }
}

function mapStateToProps({auth}) {
    return {officeType: auth.user.office['office_type']};
}

DiseaseSurveillanceReportEntry.contextType = AbilityContext;
export default connect(mapStateToProps)(DiseaseSurveillanceReportEntry);