import React from "react";
import {highlightString} from "@/utilities/highlight-string";
import {Link} from "react-router-dom";

class CustomDashboardComponentIndexItem extends React.Component {
    handleDeleteClick = () => {
        this.props.onDeleteClick(this.props.component);
    };

    render() {
        const {component, search} = this.props;

        return (
            <div className="j-config-list-item j-custom-dashboard-components-item">
                <div>
                    <span>{component.orderIndex}</span>
                </div>
                <div>
                    <span>{highlightString(search, component.title)}</span>
                </div>
                <div>
                    <span className="btn-group pull-right">
                        <Link to={`/config/disease-monitoring/custom-dashboard-components/${component.id}`}
                              className="btn btn-default btn-xs">
                            <i className="fa fa-pencil margin-r-5"/>Edit
                        </Link>
                        <button className="btn btn-default btn-xs" onClick={this.handleDeleteClick}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

export default CustomDashboardComponentIndexItem;