import React from 'react';
import Box from "@vallarj/react-adminlte/Box";
import CovidCases from "@/screens/DiseaseSurveillanceDashboard/Covid/CovidCases";
import PersonnelVaccination from "@/screens/DiseaseSurveillanceDashboard/Covid/PersonnelVaccination";
import PDLVaccination from "@/screens/DiseaseSurveillanceDashboard/Covid/PDLVaccination";

class Covid extends React.Component {
    render() {
        const {isLoading, fromDate, toDate} = this.props;

        return (
            <Box theme="box-primary" isLoading={isLoading} collapsible>
                <Box.Header title="COVID-19 Tracker"/>
                <Box.Body>
                    <div className="row">
                        <div className="col-xs-12">
                            <CovidCases/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <PersonnelVaccination fromDate={fromDate} toDate={toDate}/>
                        </div>
                        <div className="col-xs-6">
                            <PDLVaccination fromDate={fromDate} toDate={toDate}/>
                        </div>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default Covid;