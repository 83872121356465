import React from 'react';

class ReportStatsTag extends React.Component {
    getTagClassName = () => {
        const {type} = this.props;

        if (!type) {
            return "";
        } else {
            return " j-stats-item-tag-" + type;
        }
    };

    getTagLabel = () => {
        const {type} = this.props;
        switch (type) {
            case ReportStatsTag.Submitted:
                return "Submitted";
            case ReportStatsTag.NotSubmitted:
                return "Not Submitted";
            case ReportStatsTag.NotPrepared:
                return "No Report";
            case ReportStatsTag.Unfinalized:
                return "Unfinalized";
            case ReportStatsTag.Finalized:
                return "Finalized";
            case ReportStatsTag.Incomplete:
                return "Incomplete";
            case ReportStatsTag.Complete:
                return "Complete";
            case ReportStatsTag.Changed:
                return "Unsaved";
            default:
                return null;
        }
    };

    render() {
        const className = "j-stats-item-tag" + this.getTagClassName();
        return <span className={className}>{this.getTagLabel()}</span>
    }
}

ReportStatsTag.Submitted = "submitted";
ReportStatsTag.NotSubmitted = "not-submitted";
ReportStatsTag.NotPrepared = "not-prepared";
ReportStatsTag.Unfinalized = "unfinalized";
ReportStatsTag.Finalized = "finalized";
ReportStatsTag.Incomplete = "incomplete";
ReportStatsTag.Complete = "complete";
ReportStatsTag.Changed = "changed";

export default ReportStatsTag;