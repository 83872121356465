import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Doughnut from "@/components/Charts/Doughnut";
import {buildChartDataSet, createPieChartOptions} from "@/utilities/dashboard";

class RecoveredDeathChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createPieChartOptions("Proportion of Recoveries to Deaths", 'left')
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {totalDeaths, totalRecovered} = this.props;

        return totalDeaths !== nextProps.totalDeaths || totalRecovered !== nextProps.totalRecovered;
    }

    buildDataSet = () => {
        const {totalRecovered, totalDeaths} = this.props;

        return buildChartDataSet(["Recoveries", "Deaths"],
            [totalRecovered, totalDeaths],
            "Proportion",
            ["#baded1", "#f59fad"],
            ["#fff", "#fff"]);
    };

    render() {
        const dataSet = this.buildDataSet();

        return (
            <div className="j-dash-covid-chart">
                <Doughnut className="chart" data={dataSet} options={this.chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    }
}

export default RecoveredDeathChart;