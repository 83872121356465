import React from 'react';
import {withAggregateTagMap} from "@/screens/DiseaseSurveillanceDashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_PDL_POP = 'dm_default_8';
const TAG_S_WITHOUT_CM_FIRST = 'dm_default_9';
const TAG_S_WITHOUT_CM_SECOND = 'dm_default_10';
const TAG_S_WITHOUT_CM_SINGLE = 'dm_default_11';
const TAG_S_WITH_CM_FIRST = 'dm_default_12';
const TAG_S_WITH_CM_SECOND = 'dm_default_13';
const TAG_S_WITH_CM_SINGLE = 'dm_default_14';
const TAG_B_WITHOUT_CM_FIRST = 'dm_default_15';
const TAG_B_WITHOUT_CM_SECOND = 'dm_default_16';
const TAG_B_WITHOUT_CM_SINGLE = 'dm_default_17';
const TAG_B_WITH_CM_FIRST = 'dm_default_18';
const TAG_B_WITH_CM_SECOND = 'dm_default_19';
const TAG_B_WITH_CM_SINGLE = 'dm_default_20';
const TAG_VAC_SINOVAC = 'dm_default_21';
const TAG_VAC_ASTRA = 'dm_default_22';
const TAG_VAC_PFIZER = 'dm_default_23';
const TAG_VAC_SPUTNIK = 'dm_default_24';
const TAG_VAC_MODERNA = 'dm_default_25';
const TAG_VAC_SINOPHARM = 'dm_default_26';
const TAG_VAC_JANSSEN = 'dm_default_27';

class PDLVaccination extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            totalPopulation: 0,
            currentFirst: 0,
            currentSecond: 0,
            currentSingle: 0,
            currentFully: 0,
            pctCurrentFirst: 0,
            pctCurrentSecond: 0,
            pctCurrentSingle: 0,
            pctCurrentFully: 0,
            sinovac: 0,
            astra: 0,
            pfizer: 0,
            sputnik: 0,
            moderna: 0,
            sinopharm: 0,
            janssen: 0,
            totalVaccinated: 0,
            totalFully: 0,
            remainingVaccinees: 0,
            pctTotalVaccinated: 0,
            pctTotalFully: 0,
            pctRemainingVaccinees: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const totalPopulation = getAggregateByTag(TAG_PDL_POP).reportTotal;
        const sWithoutCmFirst = getAggregateByTag(TAG_S_WITHOUT_CM_FIRST);
        const sWithoutCmSecond = getAggregateByTag(TAG_S_WITHOUT_CM_SECOND);
        const sWithoutCmSingle = getAggregateByTag(TAG_S_WITHOUT_CM_SINGLE);
        const sWithCmFirst = getAggregateByTag(TAG_S_WITH_CM_FIRST);
        const sWithCmSecond = getAggregateByTag(TAG_S_WITH_CM_SECOND);
        const sWithCmSingle = getAggregateByTag(TAG_S_WITH_CM_SINGLE);
        const bWithoutCmFirst = getAggregateByTag(TAG_B_WITHOUT_CM_FIRST);
        const bWithoutCmSecond = getAggregateByTag(TAG_B_WITHOUT_CM_SECOND);
        const bWithoutCmSingle = getAggregateByTag(TAG_B_WITHOUT_CM_SINGLE);
        const bWithCmFirst = getAggregateByTag(TAG_B_WITH_CM_FIRST);
        const bWithCmSecond = getAggregateByTag(TAG_B_WITH_CM_SECOND);
        const bWithCmSingle = getAggregateByTag(TAG_B_WITH_CM_SINGLE);
        const sinovac = getAggregateByTag(TAG_VAC_SINOVAC).reportTotal;
        const astra = getAggregateByTag(TAG_VAC_ASTRA).reportTotal;
        const pfizer = getAggregateByTag(TAG_VAC_PFIZER).reportTotal;
        const sputnik = getAggregateByTag(TAG_VAC_SPUTNIK).reportTotal;
        const moderna = getAggregateByTag(TAG_VAC_MODERNA).reportTotal;
        const sinopharm = getAggregateByTag(TAG_VAC_SINOPHARM).reportTotal;
        const janssen = getAggregateByTag(TAG_VAC_JANSSEN).reportTotal;

        const currentFirst = sWithoutCmFirst.latestTotal + sWithCmFirst.latestTotal + bWithoutCmFirst.latestTotal + bWithCmFirst.latestTotal;
        const currentSecond = sWithoutCmSecond.latestTotal + sWithCmSecond.latestTotal + bWithoutCmSecond.latestTotal + bWithCmSecond.latestTotal;
        const currentSingle = sWithoutCmSingle.latestTotal + sWithCmSingle.latestTotal + bWithoutCmSingle.latestTotal + bWithCmSingle.latestTotal;

        const totalVaccinated = sinovac + astra + pfizer + sputnik + moderna + sinopharm + janssen;
        const totalFully = sWithoutCmSecond.reportTotal + sWithCmSecond.reportTotal + bWithoutCmSecond.reportTotal + bWithCmSecond.reportTotal
            + sWithoutCmSingle.reportTotal + sWithCmSingle.reportTotal + bWithoutCmSingle.reportTotal + bWithCmSingle.reportTotal;
        const remainingVaccinees = totalPopulation - totalVaccinated;

        this.setState({
            totalPopulation,
            currentFirst,
            currentSecond,
            currentSingle,
            currentFully: currentSecond + currentSingle,
            pctCurrentFirst: pctTotalOver(currentFirst, totalPopulation),
            pctCurrentSecond: pctTotalOver(currentSecond, totalPopulation),
            pctCurrentSingle: pctTotalOver(currentSingle, totalPopulation),
            pctCurrentFully: pctTotalOver(currentSecond + currentSingle, totalPopulation),
            sinovac,
            astra,
            pfizer,
            sputnik,
            moderna,
            sinopharm,
            janssen,
            totalVaccinated,
            totalFully,
            remainingVaccinees,
            pctTotalVaccinated: pctTotalOver(totalVaccinated, totalPopulation),
            pctTotalFully: pctTotalOver(totalFully, totalPopulation),
            pctRemainingVaccinees: pctTotalOver(remainingVaccinees, totalPopulation)
        });
    };

    render() {
        const {
            totalPopulation, sinovac, astra,
            pfizer, sputnik, moderna, sinopharm, janssen, totalVaccinated, totalFully
        } = this.state;
        const {fromDate, toDate} = this.props;

        return (
            <div className="j-disease-dash-covid-pdl j-dash-box-subgroup">
                <h3>COVID-19 Vaccination of PDLs</h3>
                <div className="j-dash-tabular-group two-column">
                    <table>
                        <tbody>
                            <tr>
                                <td>Total Population</td>
                                <td>{totalPopulation}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="j-dash-tabular-group">
                    <h4>Vaccines Used ({toDate && `${fromDate.format('YYYY/MM/DD')} - ${toDate.format('YYYY/MM/DD')}`})</h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Vaccine</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Sinovac</td>
                            <td>{sinovac}</td>
                        </tr>
                        <tr>
                            <td>Astrazeneca</td>
                            <td>{astra}</td>
                        </tr>
                        <tr>
                            <td>Pfizer</td>
                            <td>{pfizer}</td>
                        </tr>
                        <tr>
                            <td>Sputnik</td>
                            <td>{sputnik}</td>
                        </tr>
                        <tr>
                            <td>Moderna</td>
                            <td>{moderna}</td>
                        </tr>
                        <tr>
                            <td>Sinopharm</td>
                            <td>{sinopharm}</td>
                        </tr>
                        <tr>
                            <td>Janssen</td>
                            <td>{janssen}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="j-dash-tabular-group j-dash-print-force-br">
                    <h4>Vaccinees ({toDate && `${fromDate.format('YYYY/MM/DD')} - ${toDate.format('YYYY/MM/DD')}`})</h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Total Vaccines Administered</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Total Vaccinated</td>
                            <td>{totalVaccinated}</td>
                        </tr>
                        <tr>
                            <td>Fully Vaccinated (2nd + Single Dose)</td>
                            <td>{totalFully}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(PDLVaccination);