import React from 'react';
import {connect} from 'react-redux';
import {withRouter, NavLink} from 'react-router-dom';

class ConfigurationMenu extends React.Component {
    render() {
        return (
            <div className="col-xs-3 config-menu">
                <div>
                    <h4>Monthly Reports</h4>
                    <nav>
                        <ul>
                            <li><NavLink to='/config/jail-level-report/indicator-categories'>Indicator Categories</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/indicator-subcategories'>Indicator Subcategories</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/indicators'>Indicators</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/indicator-sum'>Indicator Sum Validation</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/data-sources'>Data Sources</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/morbidities'>Morbidities</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/medical-procedures'>Medical Procedures</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/medications'>Medications</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/causes-of-death'>Causes of Death</NavLink></li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <h4>Dental Health Reports</h4>
                    <nav>
                        <ul>
                            <li><NavLink to='/config/jail-level-report/dental-health-indicator-categories'>Dental Health Indicator Categories</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/dental-health-indicator-subcategories'>Dental Health Indicator Subcategories</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/dental-health-indicators'>Dental Health Indicators</NavLink></li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <h4>Mental Health Reports</h4>
                    <nav>
                        <ul>
                            <li><NavLink to='/config/jail-level-report/mental-health-indicator-categories'>Mental Health Indicator Categories</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/mental-health-indicator-subcategories'>Mental Health Indicator Subcategories</NavLink></li>
                            <li><NavLink to='/config/jail-level-report/mental-health-indicators'>Mental Health Indicators</NavLink></li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <h4>Disease Surveillance and Monitoring</h4>
                    <nav>
                        <ul>
                            <li><NavLink to='/config/disease-monitoring/indicator-categories'>Indicator Categories</NavLink></li>
                            <li><NavLink to='/config/disease-monitoring/indicator-subcategories'>Indicator Subcategories</NavLink></li>
                            <li><NavLink to='/config/disease-monitoring/indicators'>Indicators</NavLink></li>
                            <li><NavLink to='/config/disease-monitoring/custom-dashboard-components'>Custom Dashboard Components</NavLink></li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default withRouter(connect()(ConfigurationMenu));