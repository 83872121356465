import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {IndicatorCategory} from "@/records/IndicatorCategory";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    showHeader: PropTypes.bool,
    showTotal: PropTypes.bool,
    quarterly: PropTypes.bool,
    active: PropTypes.bool
};

const toOneRelationships = {
    category: () => IndicatorCategory,
    parent: () => IndicatorSubcategory
};

const toManyRelationships = {
    children: () => IndicatorSubcategory
};

const meta = {
    inUse: PropTypes.bool
};

export class IndicatorSubcategory extends JsonApiRecord(
    'indicator-subcategories',
    `${config.apiBaseUrl}/indicator-subcategories`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'IndicatorSubcategory';
    }
}