import React from "react";
import pdf from '../assets/manual-1.2.0-may2022.pdf';

class ArticleTableOfContents extends React.Component {
    renderList = contents => {
        return (
            <ul>
                {contents.map(c => (
                    <li key={c.id}>
                        <a href={`#${c.id}`}>{c.title}</a>
                        {
                            !!c.contents && c.contents.length > 0 &&
                            this.renderList(c.contents)
                        }
                    </li>
                ))}
            </ul>
        )
    };

    render() {
        const {contents, innerMode, hidden} = this.props;

        if (hidden || !contents) {
            return null;
        }

        return (
            innerMode ?
            <div className="hidden-lg help-toc-inner">
                <div>
                    <h2>Contents</h2>
                    <a href={pdf} download>
                        <i className="fa fa-file-pdf-o margin-r-5"/>Download PDF Manual
                    </a>
                </div>
                {this.renderList(contents)}
            </div>
            :
            <div className="col-lg-4 visible-lg help-toc">
                <div>
                    <h2>Contents</h2>
                    <a href={pdf} download>
                        <i className="fa fa-file-pdf-o margin-r-5"/>Download PDF Manual
                    </a>
                </div>
                {this.renderList(contents)}
            </div>
        );
    }
}

export default ArticleTableOfContents;