import React from 'react';
import OptionsBox from "@/screens/JailLevelReports/JailLevelReportEntry/OptionsBox";
import {Morbidity} from "@/records/Morbidity";

class DetentionMorbidities extends React.Component {
    render() {
        return (
            <OptionsBox boxTitle="Morbidities During Detention (J006)"
                        headerTitle="Working Impression/Diagnosis"
                        attributeName="detentionMorbidities"
                        optionType={Morbidity}
                        onAttributeChange={this.props.onAttributeChange}
                        onAttributesReplace={this.props.onAttributesReplace}
                        onAttributeRevert={this.props.onAttributeRevert}
                        attributesChanged={this.props.attributesChanged}
                        isLoading={this.props.isLoading}
                        onSaveClick={this.props.onSaveClick}
                        isEditMode={this.props.isEditMode}
                        sheetIndex={4}
                        values={this.props.values}/>
        );
    }
}

export default DetentionMorbidities;