import React from 'react';
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";

const TAG_HPN_ONGOING = 'default_79';
const TAG_HPN_CONTROLLED = 'default_80';
const TAG_DM_ONGOING = 'default_83';
const TAG_DM_CONTROLLED = 'default_84';

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hpnOngoing: 0,
            hpnControlled: 0,
            dmOngoing: 0,
            dmControlled: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const hpnOngoing = getAggregateByTag(TAG_HPN_ONGOING).reportTotal;
        const hpnControlled = getAggregateByTag(TAG_HPN_CONTROLLED).reportTotal;
        const dmOngoing = getAggregateByTag(TAG_DM_ONGOING).reportTotal;
        const dmControlled = getAggregateByTag(TAG_DM_CONTROLLED).reportTotal;

        this.setState({
            hpnOngoing,
            hpnControlled,
            dmOngoing,
            dmControlled
        });
    };

    render() {
        const {hpnOngoing, hpnControlled, dmOngoing, dmControlled} = this.state;

        return (
            <div className="j-dash-tabular-group">
                <h4>Profile</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Disease</th>
                        <th>Ongoing Treatment</th>
                        <th>Controlled</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>HPN</td>
                        <td>{hpnOngoing}</td>
                        <td>{hpnControlled}</td>
                    </tr>
                    <tr>
                        <td>DM</td>
                        <td>{dmOngoing}</td>
                        <td>{dmControlled}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withAggregateTagMap(Profile);