import React from 'react';

class Tag extends React.Component {
    render() {
        let {type, label} = this.props;
        let className = `j-roles-item-tag${type ? ` j-roles-item-tag-type-${type}` : ""}`;

        return <span className={className}>{label}</span>
    }
}

export default Tag;