import React from "react";

export const highlightString = (search, string) => {
    search = (search && search.trim()) || "";
    if (!search) {
        return string;
    }

    string = string || "";

    const match = string.match(RegExp(escapeRegExp(search), "i"));

    if (!match) {
        return string;
    }

    return (<>
        {string.slice(0, match.index)}
        <span className="j-highlight">{string.slice(match.index, match.index + search.length)}</span>
        {string.slice(match.index + search.length)}
    </>);
};

export const escapeRegExp = string => {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}