import React from 'react';
import {MedicalProcedure} from "@/records/MedicalProcedure";
import OptionsIndex from "@/screens/Configuration/Options/OptionsIndex";

class MedicalProcedures extends React.Component {
    render() {
        return (
            <OptionsIndex title="Medical Procedures"
                          itemSingular="Medical Procedure"
                          optionType={MedicalProcedure}
                          />
        );
    }
}

export default MedicalProcedures;