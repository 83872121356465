import React from 'react';
import BaseIndicatorCategories from "@/screens/Configuration/IndicatorCategories";
import {DiseaseMonitoringIndicatorCategory} from "@/records/DiseaseMonitoringIndicatorCategory";

class IndicatorCategories extends React.Component {
    render() {
        return (
            <BaseIndicatorCategories
                resourceType={DiseaseMonitoringIndicatorCategory}
                hasParentSubcategoryNumbered={false}
                title="Disease Surveillance Indicator Categories"/>
        );
    }
}

export default IndicatorCategories;