import React from 'react';
import {permissionOrderComparator} from "@/utilities/permissions";
import CheckBox from "@vallarj/react-adminlte/Form/CheckBox";
import SmoothCollapse from "react-smooth-collapse";

class RoleItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            permissionsShown: false
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {role, permissions, checked} = this.props;
        const {permissionsShown} = this.state;

        return (
            role !== nextProps.role
            || permissions !== nextProps.permissions
            || checked !== nextProps.checked
            || permissionsShown !== nextState.permissionsShown
        );
    }

    handleTogglePermissionsClick = () => {
        this.setState({permissionsShown: !this.state.permissionsShown});
    };

    render() {
        const {role, permissions, checked, originalValue} = this.props;
        const {permissionsShown} = this.state;

        const rolePerms = role.permissions.sort((a, b) => permissionOrderComparator(a.id, b.id))
            .map(p => permissions[p.id]);

        let changedClass;
        if (checked && !originalValue) {
            changedClass = " added";
        } else if (!checked && originalValue) {
            changedClass = " removed";
        } else {
            changedClass = "";
        }

        return (
            <div className={`j-user-modal-role-item${changedClass}`}>
                <div>
                    <CheckBox label={role.name} name={role.id} checked={checked} disabled={this.props.disabled}
                              onChange={this.props.onChange}/>
                    <button className="btn btn-xs btn-link" onClick={this.handleTogglePermissionsClick}>
                        {
                            permissionsShown ?
                            <><i className="fa fa-minus margin-r-5"/>Hide Permissions</> :
                            <><i className="fa fa-plus margin-r-5"/>Show Permissions</>
                        }
                    </button>
                </div>
                <SmoothCollapse expanded={permissionsShown} heightTransition=".30s ease">
                    <ul>
                        {rolePerms.map(p => <li key={p.id}>{p.description}</li>)}
                    </ul>
                </SmoothCollapse>
            </div>
        )
    }
}

export default RoleItem;