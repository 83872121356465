import React from 'react';
import Box from "@vallarj/react-adminlte/Box";
import TextInput from "@vallarj/react-adminlte/Form/TextInput";
import SelectInput from "@vallarj/react-adminlte/Form/SelectInput";
import NumericInput from "@/components/NumericInput";
import Form from "@vallarj/react-adminlte/Form";
import {
    createCancelToken,
    fetchAndFilterResourceCollection,
    fetchResourceCollection, fetchSingleResource, patchResource, postResource,
    waitForAllRequests
} from "@/utilities/jsonapi";
import {DiseaseMonitoringIndicatorCategory} from "@/records/DiseaseMonitoringIndicatorCategory";
import {DiseaseMonitoringIndicatorSubcategory} from "@/records/DiseaseMonitoringIndicatorSubcategory";
import {DiseaseMonitoringIndicator} from "@/records/DiseaseMonitoringIndicator";
import {noop} from "@/utilities/noop";
import IndicatorModal from "@/screens/Configuration/DiseaseMonitoring/CustomDashboardComponents/IndicatorModal";
import {DiseaseSurveillanceDashboardComponent} from "@/records/DiseaseSurveillanceDashboardComponent";
import DashboardComponent from "@/components/DashboardComponent";
import {notifyFormError, notifySuccess} from "@/utilities/notifications";

class IndicatorItem extends React.Component {
    handleEditClick = () => {
        const {onEditClick, item} = this.props;
        onEditClick(item);
    };

    handleDeleteClick = () => {
        const {onDeleteClick, item} = this.props;
        onDeleteClick(item);
    };

    render() {
        const {indicatorMap, indicatorSubcategoryMap, item} = this.props;
        const indicator = indicatorMap[item.indicator];
        const subcategory = indicatorSubcategoryMap[indicator.subcategory.id];

        return (
            <div className="j-config-list-item j-custom-dashboard-indicators-list-item">
                <div>
                    {indicator.name} ({subcategory.name})
                </div>
                <div>
                    {item.label}
                </div>
                <div>
                    <span className="btn-group pull-right">
                        <button className="btn btn-default btn-xs" onClick={this.handleEditClick}>
                            <i className="fa fa-pencil margin-r-5"/>Edit
                        </button>
                        <button className="btn btn-default btn-xs" onClick={this.handleDeleteClick}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

class ManageComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            indicatorCategories: [],
            indicatorSubcategories: [],
            indicatorSubcategoryMap: {},
            indicators: [],
            indicatorMap: {},
            component: null,
            sampleValues: [],
            errors: {}
        };

        this.chartTypeOptions = [
            {label: "None", value: null},
            {label: "Pie", value: 'pie'},
            {label: "Doughnut", value: 'doughnut'},
            {label: "Horizontal Bar", value: 'horizontal_bar'},
            {label: "Vertical Bar", value: 'vertical_bar'}
        ];

        this.forceBreakOptions = [
            {label: "Yes", value: true},
            {label: "No", value: false}
        ];

        this.fetchCancelToken = createCancelToken();
        this.requestCancelToken = createCancelToken();
        this.openModal = noop;
    }

    componentDidMount() {
        this.fetchDependencies();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    fetchDependencies = () => {
        const {match} = this.props;
        const {id} = match.params;

        this.setState({isLoading: true});

        const requests = [
            fetchResourceCollection(DiseaseMonitoringIndicatorCategory),
            fetchAndFilterResourceCollection(DiseaseMonitoringIndicatorSubcategory, item => !(item.relationships || {}).parent),
            fetchResourceCollection(DiseaseMonitoringIndicator)
        ];
        if (id !== 'create') {
            requests.push(fetchSingleResource(DiseaseSurveillanceDashboardComponent, id))
        }

        waitForAllRequests(requests).onSuccess(([categories, subcategories, indicators, component]) => {
            const indicatorSubcategoryMap = {};
            subcategories = this.flattenSubcategories(subcategories);
            subcategories.forEach(s => {
                indicatorSubcategoryMap[s.id] = s;
            });

            const indicatorMap = {};
            indicators.forEach(i => {
                indicatorMap[i.id] = i;
            });

            this.setState({
                indicatorCategories: categories,
                indicatorSubcategories: subcategories,
                indicatorSubcategoryMap,
                indicators,
                indicatorMap,
                isLoading: false
            });

            if (component) {
                this.setState({
                    component,
                    sampleValues: this.generateSampleValues(component)
                });
            } else {
                this.setState({
                    component: (new DiseaseSurveillanceDashboardComponent().update('forceBreak', false)),
                    sampleValues: []
                });
            }

        }).execute(this.fetchCancelToken);
    };

    flattenSubcategories = subcategories => {
        let flattened = [];
        subcategories.forEach(s => {
            flattened = [...flattened, s, ...this.flattenSubcategories(s.children)];
        });
        return flattened;
    };

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    handleAddIndicatorClick = () => {
        this.openModal('create', {});
    };

    handleEditIndicatorClick = indicator => {
        this.openModal('edit', indicator);
    };

    handleDeleteIndicatorClick = indicator => {
        const {component} = this.state;
        this.setState({
            component: component.update('indicators', component.indicators.filter(ci => ci !== indicator))
        });
        console.log(indicator);
    };

    handleFormChange = (field, value) => {
        if (field === 'orderIndex') {
            if (value === '') {
                value = null;
            } else {
                value = parseInt(value, 10);
            }
        }

        this.setState({
            component: this.state.component.update(field, value)
        });
    };

    handleSaveIndicatorSuccess = (item, view) => {
        let {component} = this.state;

        if (view === 'create') {
            component = component.update(
                'indicators',
                [...component.indicators, item]
            );
        } else {
            component = component.update(
                'indicators',
                component.indicators.map(i => i.indicator === item.indicator ? item : i)
            );
        }

        this.setState({
            component,
            sampleValues: this.generateSampleValues(component)
        });
    };

    handleSaveClick = () => {
        const {id} = this.props.match.params;
        const {component} = this.state;

        this.setState({isLoading: true});
        if (id === 'create') {
            postResource(component)
                .onSuccess(() => {
                    this.props.history.push('/config/disease-monitoring/custom-dashboard-components');
                    notifySuccess("Create Component", "Component created successfully")
                })
                .onError(err => {
                    this.setState({isLoading: false});
                    if (err.isValidationError) {
                        this.setState({errors: err.errors});
                        notifyFormError("Create Component");
                    } else {
                        throw err;
                    }
                })
                .execute(this.requestCancelToken);
        } else {
            patchResource(component)
                .onSuccess(() => {
                    this.props.history.push('/config/disease-monitoring/custom-dashboard-components');
                    notifySuccess("Edit Component", "Component updated successfully")
                })
                .onError(err => {
                    this.setState({isLoading: false});
                    if (err.isValidationError) {
                        this.setState({errors: err.errors});
                        notifyFormError("Create Component");
                    } else {
                        throw err;
                    }
                })
                .execute(this.requestCancelToken);
        }
    };

    handleCancelClick = () => {
        this.props.history.push('/config/disease-monitoring/custom-dashboard-components');
    };

    generateRandom = () => {
        return Math.floor(Math.random() * 70 + 30);
    };

    generateSampleValues = component => {
        return (component.indicators || []).map((ci, i) => ({
            id: ci.indicator,
            label: ci.label,
            value: this.generateRandom()
        }));
    };

    render() {
        const {
            indicatorCategories, indicatorSubcategories, indicators,
            indicatorMap, isLoading, indicatorSubcategoryMap, sampleValues,
            errors
        } = this.state;
        const component = this.state.component || {};
        const {id} = this.props.match.params;

        return (<>
            <IndicatorModal indicatorCategories={indicatorCategories}
                            indicatorSubcategories={indicatorSubcategories}
                            indicators={indicators}
                            currentIndicators={component.indicators || []}
                            registerOpenModal={this.registerOpenModal}
                            onSaveSuccess={this.handleSaveIndicatorSuccess} />
            <Box theme='box-primary' isLoading={isLoading}>
                <Box.Header title={`${id === 'create' ? 'Create' : 'Edit'} Component`}/>
                <Box.Body>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="j-custom-dashboard-indicators">
                                <div className="j-custom-dashboard-indicators-list">
                                    <div className="j-config-list-header j-custom-dashboard-indicators-list-header">
                                        <span>Indicator</span>
                                        <span>Label</span>
                                        <span></span>
                                    </div>
                                    {
                                        (component.indicators || []).map(i => (
                                            <IndicatorItem indicatorMap={indicatorMap}
                                                           indicatorSubcategoryMap={indicatorSubcategoryMap}
                                                           onEditClick={this.handleEditIndicatorClick}
                                                           onDeleteClick={this.handleDeleteIndicatorClick}
                                                           item={i} key={i.indicator}/>
                                        ))
                                    }
                                </div>
                                <button className="btn btn-block btn-default"
                                        onClick={this.handleAddIndicatorClick}>
                                    <i className="fa fa-plus-circle margin-r-5"/>Add Indicator
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <Form onChange={this.handleFormChange} errors={errors}>
                                <TextInput label="Title" name="title" value={component.title}/>
                                <SelectInput label="Chart Type" name="chartType" value={component.chartType}
                                             options={this.chartTypeOptions} simpleValue/>
                                <NumericInput label="Dashboard Order Index" value={component.orderIndex}
                                              name="orderIndex" numberFormatProps={{allowNegative: false}}/>
                                <SelectInput label="Force Page Break in PDF" value={component.forceBreak}
                                             options={this.forceBreakOptions} name="forceBreak" simpleValue/>
                            </Form>
                        </div>
                    </div>
                </Box.Body>
                <Box.Footer>
                    <div className="pull-right">
                        <button className="btn btn-default margin-r-5" onClick={this.handleCancelClick}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={this.handleSaveClick}>
                            <i className="fa fa-save margin-r-5"/>Save
                        </button>
                    </div>
                </Box.Footer>
            </Box>
            <DashboardComponent title={`${component.title || ""} (Preview)`}
                                forceBreak={component.forceBreak}
                                chartType={component.chartType}
                                values={sampleValues}/>
        </>);
    }
}

export default ManageComponent;