import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import JailLevelReportsIndex from "@/screens/JailLevelReports/JailLevelReportsIndex";
import JailLevelReportEntry from "@/screens/JailLevelReports/JailLevelReportEntry";

class JailLevelReports extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/jail-level-reports" component={JailLevelReportsIndex}/>
                <Route path="/jail-level-reports/:action(view|edit)/:id" component={JailLevelReportEntry}/>
                <Redirect to="/jail-level-reports"/>
            </Switch>
        );
    }
}

export default JailLevelReports;