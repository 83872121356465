import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import BreadcrumbItem from '@vallarj/react-adminlte/Content/BreadcrumbItem';
import Content from '@vallarj/react-adminlte/Content';
import ConfigurationMenu from "@/screens/Configuration/ConfigurationMenu";
import Indicators from "@/screens/Configuration/Reporting/Indicators";
import IndicatorCategories from "@/screens/Configuration/Reporting/IndicatorCategories";
import IndicatorSubcategories from "@/screens/Configuration/Reporting/IndicatorSubcategories";
import Morbidities from "@/screens/Configuration/Options/Morbidities";
import MedicalProcedures from "@/screens/Configuration/Options/MedicalProcedures";
import Medications from "@/screens/Configuration/Options/Medications";
import CausesOfDeath from "@/screens/Configuration/Options/CausesOfDeath";
import DataSources from "@/screens/Configuration/Options/DataSources";
import IndicatorSum from "@/screens/Configuration/ReportValidation/IndicatorSum";
import DiseaseMonitoringIndicatorCategories from "@/screens/Configuration/DiseaseMonitoring/IndicatorCategories";
import DiseaseMonitoringIndicatorSubcategories from "@/screens/Configuration/DiseaseMonitoring/IndicatorSubcategories";
import DiseaseMonitoringIndicators from "@/screens/Configuration/DiseaseMonitoring/Indicators";
import CustomDashboardComponents from "@/screens/Configuration/DiseaseMonitoring/CustomDashboardComponents";
import ManageComponent from "@/screens/Configuration/DiseaseMonitoring/CustomDashboardComponents/ManageComponent";
import DentalHealthIndicatorCategories from "@/screens/Configuration/Reporting/DentalHealthIndicatorCategories";
import DentalHealthIndicatorSubcategories from "@/screens/Configuration/Reporting/DentalHealthIndicatorSubcategories";
import DentalHealthIndicators from "@/screens/Configuration/Reporting/DentalHealthIndicators";
import MentalHealthIndicatorCategories from "@/screens/Configuration/Reporting/MentalHealthIndicatorCategories";
import MentalHealthIndicatorSubcategories from "@/screens/Configuration/Reporting/MentalHealthIndicatorSubcategories";
import MentalHealthIndicators from "@/screens/Configuration/Reporting/MentalHealthIndicators";

class Configuration extends React.Component {
    render() {
        const redirect = "/config/jail-level-report/indicator-categories";

        return (
            <Content>
                <Content.Header title="Configuration"/>
                <Content.Breadcrumb>
                    <BreadcrumbItem label="Configuration" iconClass="fa fa-gears" active/>
                </Content.Breadcrumb>
                <Content.Body>
                    <div className="row">
                        <ConfigurationMenu/>
                        <div className="col-xs-9">
                            <Switch>
                                <Route exact path='/config/jail-level-report/indicator-categories' component={IndicatorCategories}/>
                                <Route exact path='/config/jail-level-report/indicator-subcategories' component={IndicatorSubcategories}/>
                                <Route exact path='/config/jail-level-report/indicators' component={Indicators}/>
                                <Route exact path='/config/jail-level-report/data-sources' component={DataSources}/>
                                <Route exact path='/config/jail-level-report/morbidities' component={Morbidities}/>
                                <Route exact path='/config/jail-level-report/dental-health-indicator-categories' component={DentalHealthIndicatorCategories}/>
                                <Route exact path='/config/jail-level-report/dental-health-indicator-subcategories' component={DentalHealthIndicatorSubcategories}/>
                                <Route exact path='/config/jail-level-report/dental-health-indicators' component={DentalHealthIndicators}/>
                                <Route exact path='/config/jail-level-report/mental-health-indicator-categories' component={MentalHealthIndicatorCategories}/>
                                <Route exact path='/config/jail-level-report/mental-health-indicator-subcategories' component={MentalHealthIndicatorSubcategories}/>
                                <Route exact path='/config/jail-level-report/mental-health-indicators' component={MentalHealthIndicators}/>
                                <Route exact path='/config/jail-level-report/medical-procedures' component={MedicalProcedures}/>
                                <Route exact path='/config/jail-level-report/medications' component={Medications}/>
                                <Route exact path='/config/jail-level-report/causes-of-death' component={CausesOfDeath}/>
                                <Route exact path='/config/jail-level-report/indicator-sum' component={IndicatorSum}/>
                                <Route exact path='/config/disease-monitoring/indicator-categories' component={DiseaseMonitoringIndicatorCategories}/>
                                <Route exact path='/config/disease-monitoring/indicator-subcategories' component={DiseaseMonitoringIndicatorSubcategories}/>
                                <Route exact path='/config/disease-monitoring/indicators' component={DiseaseMonitoringIndicators}/>
                                <Route exact path='/config/disease-monitoring/custom-dashboard-components' component={CustomDashboardComponents}/>
                                <Route exact path='/config/disease-monitoring/custom-dashboard-components/:id' component={ManageComponent}/>
                                <Redirect to={redirect}/>
                            </Switch>
                        </div>
                    </div>
                </Content.Body>
            </Content>
        );
    }
}

export default Configuration;