export const PERM_GROUP_JRS_CONFIGURATION = 'jrs.configuration';
export const PERM_JRS_CONFIGURATION_MANAGE = PERM_GROUP_JRS_CONFIGURATION + '.manage';

export const PERM_JRS_OFFICES_MANAGE = 'jrs.offices.manage';

export const PERM_GROUP_JRS_USERS = 'jrs.users';
export const PERM_GROUP_JRS_USERS_READ = PERM_GROUP_JRS_USERS + '.read';
export const PERM_GROUP_JRS_USERS_CREATE = PERM_GROUP_JRS_USERS + '.create';
export const PERM_GROUP_JRS_USERS_UPDATE = PERM_GROUP_JRS_USERS + '.update';
export const PERM_GROUP_JRS_USERS_ACTIVATE = PERM_GROUP_JRS_USERS + '.activate';
export const PERM_GROUP_JRS_USERS_DEACTIVATE = PERM_GROUP_JRS_USERS + '.deactivate';
export const PERM_GROUP_JRS_USERS_RESET_PASSWORD = PERM_GROUP_JRS_USERS + '.reset_password';

export const PERM_JRS_USERS_MANAGE_ROLES = PERM_GROUP_JRS_USERS + '.manage_roles';
export const PERM_JRS_USERS_READ_HQ = PERM_GROUP_JRS_USERS_READ + '.headquarters';
export const PERM_JRS_USERS_CREATE_HQ = PERM_GROUP_JRS_USERS_CREATE + '.headquarters';
export const PERM_JRS_USERS_UPDATE_HQ = PERM_GROUP_JRS_USERS_UPDATE + '.headquarters';
export const PERM_JRS_USERS_ACTIVATE_HQ = PERM_GROUP_JRS_USERS_ACTIVATE + '.headquarters';
export const PERM_JRS_USERS_DEACTIVATE_HQ = PERM_GROUP_JRS_USERS_DEACTIVATE + '.headquarters';
export const PERM_JRS_USERS_RESET_PASSWORD_HQ = PERM_GROUP_JRS_USERS_RESET_PASSWORD + '.headquarters';

export const PERM_JRS_USERS_READ_REGIONAL = PERM_GROUP_JRS_USERS_READ + '.regional';
export const PERM_JRS_USERS_CREATE_REGIONAL = PERM_GROUP_JRS_USERS_CREATE + '.regional';
export const PERM_JRS_USERS_UPDATE_REGIONAL = PERM_GROUP_JRS_USERS_UPDATE + '.regional';
export const PERM_JRS_USERS_ACTIVATE_REGIONAL = PERM_GROUP_JRS_USERS_ACTIVATE + '.regional';
export const PERM_JRS_USERS_DEACTIVATE_REGIONAL = PERM_GROUP_JRS_USERS_DEACTIVATE + '.regional';
export const PERM_JRS_USERS_RESET_PASSWORD_REGIONAL = PERM_GROUP_JRS_USERS_RESET_PASSWORD + '.regional';

export const PERM_JRS_USERS_READ_JAIL_LEVEL = PERM_GROUP_JRS_USERS_READ + '.jail_level';
export const PERM_JRS_USERS_CREATE_JAIL_LEVEL = PERM_GROUP_JRS_USERS_CREATE + '.jail_level';
export const PERM_JRS_USERS_UPDATE_JAIL_LEVEL = PERM_GROUP_JRS_USERS_UPDATE + '.jail_level';
export const PERM_JRS_USERS_ACTIVATE_JAIL_LEVEL = PERM_GROUP_JRS_USERS_ACTIVATE + '.jail_level';
export const PERM_JRS_USERS_DEACTIVATE_JAIL_LEVEL = PERM_GROUP_JRS_USERS_DEACTIVATE + '.jail_level';
export const PERM_JRS_USERS_RESET_PASSWORD_JAIL_LEVEL = PERM_GROUP_JRS_USERS_RESET_PASSWORD + '.jail_level';

export const PERM_GROUP_JRS_JAIL_LEVEL_REPORTS = 'jrs.jail_level_reports';
export const PERM_JRS_JAIL_LEVEL_REPORTS_READ = PERM_GROUP_JRS_JAIL_LEVEL_REPORTS + '.read';
export const PERM_JRS_JAIL_LEVEL_REPORTS_MANAGE = PERM_GROUP_JRS_JAIL_LEVEL_REPORTS + '.manage';
export const PERM_JRS_JAIL_LEVEL_REPORTS_UNFINALIZE = PERM_GROUP_JRS_JAIL_LEVEL_REPORTS + '.unfinalize';

export const PERM_GROUP_JRS_REPORTS = 'jrs.reports';
export const PERM_JRS_REPORTS_CONSOLIDATED_REGION = PERM_GROUP_JRS_REPORTS + '.consolidated_region';
export const PERM_JRS_REPORTS_AGGREGATED_JAIL_LEVEL = PERM_GROUP_JRS_REPORTS + '.aggregated_jail_level';
export const PERM_JRS_REPORTS_REGIONAL = PERM_GROUP_JRS_REPORTS + '.region';
export const PERM_JRS_REPORTS_JAIL_LEVEL = PERM_GROUP_JRS_REPORTS + '.jail-level';

export const OFFICE_TYPE_HQ = 'headquarters';
export const OFFICE_TYPE_REGIONAL = 'regional';
export const OFFICE_TYPE_JAIL_LEVEL = 'jail_level';

export const ROLE_TYPE_HQ = 'headquarters';
export const ROLE_TYPE_REGIONAL = 'regional';
export const ROLE_TYPE_JAIL_LEVEL = 'jail_level';

export const permissionOrder = [
    PERM_JRS_CONFIGURATION_MANAGE,
    PERM_JRS_OFFICES_MANAGE,
    PERM_JRS_USERS_MANAGE_ROLES,
    PERM_JRS_USERS_READ_HQ,
    PERM_JRS_USERS_CREATE_HQ,
    PERM_JRS_USERS_UPDATE_HQ,
    PERM_JRS_USERS_ACTIVATE_HQ,
    PERM_JRS_USERS_DEACTIVATE_HQ,
    PERM_JRS_USERS_RESET_PASSWORD_HQ,
    PERM_JRS_USERS_READ_REGIONAL,
    PERM_JRS_USERS_CREATE_REGIONAL,
    PERM_JRS_USERS_UPDATE_REGIONAL,
    PERM_JRS_USERS_ACTIVATE_REGIONAL,
    PERM_JRS_USERS_DEACTIVATE_REGIONAL,
    PERM_JRS_USERS_RESET_PASSWORD_REGIONAL,
    PERM_JRS_USERS_READ_JAIL_LEVEL,
    PERM_JRS_USERS_CREATE_JAIL_LEVEL,
    PERM_JRS_USERS_UPDATE_JAIL_LEVEL,
    PERM_JRS_USERS_ACTIVATE_JAIL_LEVEL,
    PERM_JRS_USERS_DEACTIVATE_JAIL_LEVEL,
    PERM_JRS_USERS_RESET_PASSWORD_JAIL_LEVEL,
    PERM_JRS_JAIL_LEVEL_REPORTS_READ,
    PERM_JRS_JAIL_LEVEL_REPORTS_MANAGE,
    PERM_JRS_JAIL_LEVEL_REPORTS_UNFINALIZE,
    PERM_JRS_REPORTS_CONSOLIDATED_REGION,
    PERM_JRS_REPORTS_REGIONAL,
    PERM_JRS_REPORTS_AGGREGATED_JAIL_LEVEL,
    PERM_JRS_REPORTS_JAIL_LEVEL
];

export const roleOrder = [
    '_super_admin',
    'hq_admin',
    'hq_viewer',
    'regional_admin',
    'regional_viewer',
    'jail_level_admin',
    'jail_level_encoder',
    'jail_level_viewer'
];