import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import DiseaseSurveillanceReportsIndex from "@/screens/DiseaseSurveillanceReports/DiseaseSurveillanceReportsIndex";
import DiseaseSurveillanceReportEntry from "@/screens/DiseaseSurveillanceReports/DiseaseSurveillanceReportEntry";


class DiseaseSurveillanceReports extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/disease-surveillance-reports" component={DiseaseSurveillanceReportsIndex}/>
                <Route path='/disease-surveillance-reports/:action(view|edit)/:id' component={DiseaseSurveillanceReportEntry}/>
                <Redirect to='/disease-surveillance-reports'/>
            </Switch>
        );
    }
}

export default DiseaseSurveillanceReports;