import React from 'react';
import PropTypes from 'prop-types';

import BaseIndicatorReport from "@/components/IndicatorReport";
import {processWorksheet} from "@/screens/JailLevelReports/JailLevelReportEntry/utilities";
import {MentalHealthIndicator} from "@/records/MentalHealthIndicator";
import {MentalHealthIndicatorSubcategory} from "@/records/MentalHealthIndicatorSubcategory";
import {MentalHealthIndicatorCategory} from "@/records/MentalHealthIndicatorCategory";

class MentalHealthIndicators extends React.Component {
    handleSaveClick = () => {
        this.props.onSaveClick('mentalHealthIndicators');
    };

    render() {
        const {
            indicators, month,
            onIndicatorsReplace, onIndicatorChange, onIndicatorsRevert,
            isLoading, isEditMode, indicatorsChanged
        } = this.props;

        return (
            <BaseIndicatorReport
                title="Mental Health (J004)"
                categoryResourceType={MentalHealthIndicatorCategory}
                subcategoryResourceType={MentalHealthIndicatorSubcategory}
                indicatorResourceType={MentalHealthIndicator}
                hasDataSource={false}
                worksheetProcessor={processWorksheet}
                indicators={indicators}
                onIndicatorsReplace={onIndicatorsReplace}
                onIndicatorChange={onIndicatorChange}
                isLoading={isLoading}
                isEditMode={isEditMode}
                onSaveClick={this.handleSaveClick}
                onIndicatorsRevert={onIndicatorsRevert}
                indicatorsChanged={indicatorsChanged}
                month={month}
                showFooter
                worksheetIndex={3}
            />
        );
    }
}

MentalHealthIndicators.propTypes = {
    indicators: PropTypes.object.isRequired,
    onIndicatorChange: PropTypes.func.isRequired,
    onIndicatorsReplace: PropTypes.func.isRequired,
    onIndicatorsRevert: PropTypes.func.isRequired,
    indicatorsChanged: PropTypes.bool.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    month: PropTypes.number.isRequired
};

export default MentalHealthIndicators;