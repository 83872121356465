import React from 'react';

class Tag extends React.Component {
    render() {
        const {active} = this.props;
        const className = `j-users-item-tag j-users-item-tag-${active ? "active" : "inactive"}`;
        return <span className={className}>{active ? "Active" : "Inactive"}</span>
    }
}

export default Tag;