import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {Morbidity} from "@/records/Morbidity";

const attributes = {
    total: PropTypes.number
};

const toOneRelationships = {
    morbidity: () => Morbidity
};

export class DetentionMorbidityAggregate extends JsonApiRecord('detention-morbidity-aggregates', '',
    attributes, toOneRelationships) {
    static get modelName() {
        return 'DetentionMorbidityAggregate';
    }
}