import {Record} from 'immutable'
import moment from 'moment'

const string = 'string',
      bool = 'bool',
      number = 'number',
      datetime = 'datetime',
      array = 'array',
      object = 'object';

const findIncludedResource = (haystack, relationship) => {
    return haystack.findIndex(item => {
        const {type, id} = relationship;
        return item.type === type && item.id === id;
    });
};

export class PropTypes {
    static get string() {
        return string;
    }

    static get bool() {
        return bool;
    }

    static get datetime() {
        return datetime;
    }

    static get number() {
        return number;
    }

    static get array() {
        return array;
    }

    static get object() {
        return object;
    }
}

export const JsonApiRecord = (type, endpoint, attributeDefinitions = {}, toOneRelationshipDefinitions = {},
                              toManyRelationshipDefinitions = {}, metaDefinitions = {}) => {
    // Initialize attributes
    let attributeValues = {};
    Object.keys(attributeDefinitions).forEach(key => {
        switch(attributeDefinitions[key]) {
            case PropTypes.string:
            case PropTypes.datetime:
            case PropTypes.number:
                attributeValues = { ...attributeValues, [key]: null};
                break;
            case PropTypes.bool:
                attributeValues = { ...attributeValues, [key]: false};
                break;
            case PropTypes.array:
                attributeValues = { ...attributeValues, [key]: []};
                break;
            case PropTypes.object:
                attributeValues = {...attributeValues, [key]: {}};
                break;
        }
    });

    // Initialize to-one relationships
    let toOneRelationshipItems = {};
    Object.keys(toOneRelationshipDefinitions).forEach(key => {
        toOneRelationshipItems[key] = null;
    });

    // Initialize to-many relationships
    let toManyRelationshipItems = {};
    Object.keys(toManyRelationshipDefinitions).forEach(key => {
        toManyRelationshipItems[key] = [];
    });

    // Initialize meta
    let metaProps = {};
    Object.keys(metaDefinitions).forEach(key => {
        switch(metaDefinitions[key]) {
            case PropTypes.string:
                metaProps = { ...metaProps, [key]: ""};
                break;
            case PropTypes.bool:
                metaProps = { ...metaProps, [key]: false};
                break;
            case PropTypes.datetime:
            case PropTypes.number:
                metaProps = { ...metaProps, [key]: null};
                break;
            case PropTypes.array:
                metaProps = { ...metaProps, [key]: []};
                break;
            case PropTypes.object:
                metaProps = { ...metaProps, [key]: {}};
                break;
        }
    });

    const defaultValues = { ...attributeValues, ...toOneRelationshipItems, ...toManyRelationshipItems, ...metaProps };

    return class extends Record({
        _modifiedProperties: [],
        _type: type,
        _attributes: Object.keys(attributeDefinitions),
        _toOneRelationships: Object.keys(toOneRelationshipDefinitions),
        _toManyRelationships: Object.keys(toManyRelationshipDefinitions),
        id: null,
        ...defaultValues
    }) {
        constructor(data = {}, included = []) {
            const {id, attributes, relationships, meta} = data;

            // Attribute values
            let attributeValues = {};
            if(attributes) {
                Object.keys(attributes).forEach(key => {
                    if(attributeDefinitions[key]) {
                        // TODO: Do prop type checking
                        switch(attributeDefinitions[key]) {
                            case PropTypes.datetime:
                                attributeValues = {
                                    ...attributeValues,
                                    [key]: attributes[key] && moment(attributes[key])
                                };
                                break;
                            default:
                                attributeValues = { ...attributeValues, [key]: attributes[key]};
                        }
                    }
                });
            }

            // Relationship items
            let toOneRelationshipItems = {}, toManyRelationshipItems = {};
            if(relationships && included) {
                Object.keys(relationships).forEach(key => {
                    if(toOneRelationshipDefinitions[key]) {
                        let cls;
                        if(toOneRelationshipDefinitions[key].constructor === Array) {
                            // Assume array of classes
                            for(let i = 0; i < toOneRelationshipDefinitions[key].length; i++) {
                                let testCls;
                                if(toOneRelationshipDefinitions[key][i].prototype instanceof Record) {
                                    testCls = toOneRelationshipDefinitions[key][i];
                                } else {
                                    testCls = toOneRelationshipDefinitions[key][i]();
                                }

                                if(relationships[key].data.type === testCls.getType()) {
                                    cls = testCls;
                                    break;
                                }
                            }
                        } else if(toOneRelationshipDefinitions[key].prototype instanceof Record) {
                            cls = toOneRelationshipDefinitions[key];
                        } else {
                            cls = toOneRelationshipDefinitions[key]();
                        }

                        const {data} = relationships[key];
                        // To-one relationship
                        // If definition exists, check if it is included
                        const includedIndex = findIncludedResource(included, data);

                        let relationship;
                        if(includedIndex >= 0) {
                            const includedRelationship = included[includedIndex];
                            const includedExcept = [
                                ...included.slice(0, includedIndex),
                                ...included.slice(includedIndex + 1)
                            ]
                            relationship = new cls(includedRelationship, includedExcept);
                        } else {
                            relationship = new cls(data);
                        }

                        // If it is included
                        toOneRelationshipItems = {
                            ...toOneRelationshipItems,
                            [key]: relationship
                        }
                    } else if(toManyRelationshipDefinitions[key]) {
                        // To-many relationship
                        let classes;
                        if(toManyRelationshipDefinitions[key].constructor === Array) {
                            classes = toManyRelationshipDefinitions[key];
                        } else if(toManyRelationshipDefinitions[key].prototype instanceof Record) {
                            classes = [ toManyRelationshipDefinitions[key] ];
                        } else {
                            classes = [ toManyRelationshipDefinitions[key]() ];
                        }

                        const {data} = relationships[key];
                        if(data.constructor === Array) {
                            let relationship = [];
                            for(let i = 0; i < data.length; i++) {
                                // Get compatible class
                                let cls;
                                for(let j = 0; j < classes.length; j++) {
                                    if(data[i].type === classes[j].getType()) {
                                        cls = classes[j];
                                        break;
                                    }
                                }

                                // If definition exists, check if it is included
                                const includedIndex = findIncludedResource(included, data[i]);

                                if(includedIndex >= 0) {
                                    const includedRelationship = included[includedIndex];
                                    const includedExcept = [
                                        ...included.slice(0, includedIndex),
                                        ...included.slice(includedIndex + 1)
                                    ];
                                    relationship = [...relationship, new cls(includedRelationship, includedExcept)];
                                } else {
                                    relationship = [...relationship, new cls(data[i])];
                                }
                            }

                            toManyRelationshipItems = {
                                ...toManyRelationshipItems,
                                [key]: relationship
                            }
                        }
                    }
                });
            }

            // Meta props
            let metaProps = {};
            if(meta) {
                Object.keys(meta).forEach(key => {
                    if(metaDefinitions[key]) {
                        // TODO: Do prop type checking
                        switch(metaDefinitions[key]) {
                            case PropTypes.datetime:
                                metaProps = { ...metaProps, [key]: moment(meta[key])};
                                break;
                            default:
                                metaProps = { ...metaProps, [key]: meta[key]};
                        }
                    }
                });
            }

            const props = {
                id,
                ...attributeValues,
                ...toOneRelationshipItems,
                ...toManyRelationshipItems,
                ...metaProps
            };

            super(props);
        }

        static getType() { return type; }

        static getEndpoint() { return endpoint; }

        update(key, value) {
            let _this = this;
            if (this._modifiedProperties.indexOf(key) === -1) {
                _this = this.set('_modifiedProperties', [...this._modifiedProperties, key]);
            }

            return _this.set(key, value);
        }

        mergeUpdate(object) {
            let _this = this.set('_modifiedProperties', [
                ...this._modifiedProperties,
                ...Object.keys(object).filter(key => this._modifiedProperties.indexOf(key) === -1)
            ]);

            return _this.merge(object);
        }

        get hasModifiedProperties() {
            return this._modifiedProperties.length > 0;
        }

        resetModifiedProperties() {
            return this.set('_modifiedProperties', []);
        }

        toRequestObjectIdentifier() {
            return {
                type: this._type,
                id: this.id
            };
        }

        toRequestObject(includeId = false) {
            const {_modifiedProperties} = this;
            let attributes = {}, relationships = {};
            for (let i = 0; i < _modifiedProperties.length; i++) {
                const key = _modifiedProperties[i];
                const prop = this[key];

                if(this._attributes.indexOf(key) !== -1) {
                    // Property is an attribute.
                    if(moment.isMoment(prop)) {
                        // Property is a date attribute
                        attributes = { ...attributes, [key]: prop.format() };
                    } else {
                        // Property is a regular attribute
                        attributes = { ...attributes, [key]: prop};
                    }
                } else if(this._toOneRelationships.indexOf(key) !== -1) {
                    // Property is a to-one relationship
                    let relationship = { [key]: {data: null} };

                    if(prop && prop._type && prop.id) {
                        relationship = {
                            [key]: {
                                data: {
                                    type: prop._type,
                                    id: prop.id
                                }
                            }
                        };
                    }

                    relationships = {
                        ...relationships,
                        ...relationship
                    };
                } else if(this._toManyRelationships.indexOf(key) !== -1) {
                    // Property is a to-many relationship
                    let data = [];

                    for(let i = 0; i < prop.length; i++) {
                        data = [...data, {type: prop[i]._type, id: prop[i].id}];
                    }

                    relationships = {...relationships, [key]: {data}};
                }
            }

            return {
                data: {
                    type: this._type,
                    ...includeId && {id: this.id},
                    attributes,
                    relationships
                }
            };
        }
    };
};