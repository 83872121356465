import React from "react";
import {Bar} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {createVerticalChartOptions} from "@/utilities/dashboard";

class TopDiagnosticsChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createVerticalChartOptions("Top Medical Procedures");
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {data} = this.props;
        return data !== nextProps.data;
    }

    render() {
        const {data} = this.props;

        return (
            <div className="j-dash-top-diagnostics-chart">
                <Bar data={data} options={this.chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    }
}

export default TopDiagnosticsChart;