import React from "react";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_JAIL_POP = 'default_2';
const TAG_DIAGNOSED_ENTRY = 'default_37';
const TAG_DIAGNOSED_DETENTION = 'default_38';
const TAG_FURTHER_NEURO = 'default_39';

class MentalDisorders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mentalDisorders: 0,
            diagnosedEntry: 0,
            diagnosedDetention: 0,
            furtherNeuro: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;
        const jailPop = getAggregateByTag(TAG_JAIL_POP);
        const diagnosedEntry = getAggregateByTag(TAG_DIAGNOSED_ENTRY);
        const diagnosedDetention = getAggregateByTag(TAG_DIAGNOSED_DETENTION);
        const furtherNeuro = getAggregateByTag(TAG_FURTHER_NEURO);

        this.setState({
            mentalDisorders: pctTotalOver(diagnosedEntry.reportTotal + diagnosedDetention.reportTotal + furtherNeuro.reportTotal, jailPop.reportTotal),
            diagnosedEntry: diagnosedEntry.reportTotal,
            diagnosedDetention: diagnosedDetention.reportTotal,
            furtherNeuro: furtherNeuro.reportTotal
        });
    };

    render() {
        const {mentalDisorders, diagnosedEntry, diagnosedDetention, furtherNeuro} = this.state;

        return (
            <div className="j-dash-mental-disorders j-dash-box-subgroup">
                <h3>Mental Disorders</h3>
                <div className="j-dash-tabular-group two-column">
                    <table>
                        <tbody>
                        <tr>
                            <td>Mental Disorders (%)</td>
                            <td>{mentalDisorders}</td>
                        </tr>
                        <tr>
                            <td>Diagnosed Upon Entry</td>
                            <td>{diagnosedEntry}</td>
                        </tr>
                        <tr>
                            <td>Diagnosed During Detention</td>
                            <td>{diagnosedDetention}</td>
                        </tr>
                        <tr>
                            <td>For Further Neuro-Psychiatric Evaluation</td>
                            <td>{furtherNeuro}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(MentalDisorders);