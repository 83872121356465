import React from 'react';
import {CauseOfDeath} from "@/records/CauseOfDeath";
import OptionsIndex from "@/screens/Configuration/Options/OptionsIndex";

class CausesOfDeath extends React.Component {
    render() {
        return (
            <OptionsIndex title="Causes of Death"
                          itemSingular="Cause of Death"
                          optionType={CauseOfDeath}
                          />
        );
    }
}

export default CausesOfDeath;