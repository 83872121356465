import React from 'react';
import {Box} from "@vallarj/react-adminlte";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";

const TAG_PRACTITIONER_STRENGTH = 'default_14';
const TAG_ACTIVE_PHIC = 'default_11';
const TAG_JAIL_POP = 'default_2';
const TAG_PHYSICIANS = 'default_15';
const TAG_NURSES = 'default_20';
const TAG_PERSONNEL = 'default_13'

class HealthcareSystem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            practitionerStrength: 0,
            activePHIC: 0,
            doctorToPDL: 0,
            nurseToPDL: 0,
            personnelToPDL: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const practitionerStrength = getAggregateByTag(TAG_PRACTITIONER_STRENGTH);
        const activePHIC = getAggregateByTag(TAG_ACTIVE_PHIC);
        const jailPop = getAggregateByTag(TAG_JAIL_POP);
        const physicians = getAggregateByTag(TAG_PHYSICIANS);
        const nurses = getAggregateByTag(TAG_NURSES);
        const personnel = getAggregateByTag(TAG_PERSONNEL);

        this.setState({
            practitionerStrength: practitionerStrength.reportTotal,
            activePHIC: activePHIC.reportTotal,
            doctorToPDL: Math.round(jailPop.reportTotal / physicians.reportTotal),
            nurseToPDL: Math.round(jailPop.reportTotal / nurses.reportTotal),
            personnelToPDL: Math.round(jailPop.reportTotal / (personnel.reportTotal / 3))
        });
    };

    render() {
        const {practitionerStrength, activePHIC, doctorToPDL, nurseToPDL, personnelToPDL} = this.state;

        return (
            <Box theme="box-primary" collapsible>
                <Box.Header title="Healthcare System"/>
                <Box.Body>
                    <div className="j-dash-tabular-group single-row">
                        <table>
                            <thead>
                            <tr>
                                <th>Practitioner Strength</th>
                                <th>Active PHIC Members</th>
                                <th>1 Doctor for</th>
                                <th>1 Nurse for</th>
                                <th>1 Personnel for</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{practitionerStrength}</td>
                                <td>{activePHIC}</td>
                                <td>{doctorToPDL} PDL</td>
                                <td>{nurseToPDL} PDL</td>
                                <td>{personnelToPDL} PDL</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default withAggregateTagMap(HealthcareSystem);