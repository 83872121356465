import React from 'react';
import BaseIndicatorCategories from "@/screens/Configuration/IndicatorCategories";
import {DentalHealthIndicatorCategory} from "@/records/DentalHealthIndicatorCategory";

class DentalHealthIndicatorCategories extends React.Component {
    render() {
        return (
            <BaseIndicatorCategories
                resourceType={DentalHealthIndicatorCategory}
                hasParentSubcategoryNumbered={false}
                title="Dental Health Indicator Categories"/>
        );
    }
}

export default DentalHealthIndicatorCategories;