import React from "react";
import {Box} from "@vallarj/react-adminlte";
import Consultations from "@/screens/Dashboard/Treatment/Consultations";
import MedicalDental from "@/screens/Dashboard/Treatment/MedicalDental/MedicalDental";
import DetentionMorbidities from "@/screens/Dashboard/Treatment/DetentionMorbidities";
import Interventions from "@/screens/Dashboard/Treatment/Interventions";
import Medications from "@/screens/Dashboard/Treatment/Medications";

class Treatment extends React.Component {
    render() {
        const {fromDate, toDate, office, isLoading} = this.props;

        return (
            <Box theme="box-primary" isLoading={isLoading} collapsible>
                <Box.Header title="Treatment | Consultations | Interventions | Medical-Dental Mission"/>
                <Box.Body>
                    <div className="row">
                        <div className="col-lg-6">
                            <Consultations/>
                        </div>
                        <div className="col-lg-6">
                            <MedicalDental/>
                        </div>
                    </div>
                    <DetentionMorbidities office={office} fromDate={fromDate} toDate={toDate}/>
                    <div className="row">
                        <div className="col-lg-12 j-dash-print-force-br">
                            <Interventions/>
                        </div>
                        <div className="col-lg-12">
                            <Medications office={office} fromDate={fromDate} toDate={toDate}/>
                        </div>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default Treatment;