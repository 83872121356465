import React from 'react';
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";

const TAG_JAIL_POPULATION = "default_2";
const TAG_TOTAL_DEATHS = 'default_134';
const TAG_DEATHS_DRUG = 'default_135';

class DeathMetrics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pPregnant: 0,
            deaths: 0,
            deathRateSphere: 0,
            deathRateRegular: 0,
            deathDrugs: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;
        let {fromDate, toDate} = this.props;

        const jailPop = getAggregateByTag(TAG_JAIL_POPULATION);
        const totalDeaths = getAggregateByTag(TAG_TOTAL_DEATHS);
        const deathDrugs = getAggregateByTag(TAG_DEATHS_DRUG);

        // Number of days
        fromDate = fromDate.clone().startOf('month');
        toDate = toDate.clone().endOf('month');
        const numberOfDays = toDate.diff(fromDate, 'days') + 1;

        this.setState({
            deaths: totalDeaths.reportTotal,
            deathRateSphere: (totalDeaths.reportTotal / jailPop.reportTotal / numberOfDays * 10000).toFixed(2),
            deathRateRegular: ((totalDeaths.reportTotal / jailPop.reportTotal) * 1000).toFixed(2),
            deathDrugs: deathDrugs.reportTotal
        });
    };

    render() {
        const {deaths, deathRateSphere, deathRateRegular, deathDrugs} = this.state;

        return (
            <div className="j-dash-tabular-group two-column">
                <h4>Deaths</h4>
                <table>
                    <tbody>
                    <tr>
                        <td>Total deaths</td>
                        <td>{deaths}</td>
                    </tr>
                    <tr>
                        <td>Death Rate (sphere)</td>
                        <td>{deathRateSphere} per 10000/day</td>
                    </tr>
                    <tr>
                        <td>Death Rate (regular)</td>
                        <td>{deathRateRegular} per 1000 pop</td>
                    </tr>
                    <tr>
                        <td>Deaths with Drug Cases</td>
                        <td>{deathDrugs}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withAggregateTagMap(DeathMetrics);