import React from 'react';
import PropTypes from "prop-types";
import {highlightString} from "@/utilities/highlight-string";
import Tag from "@/screens/Configuration/Tag";

class IndicatorCategoryIndexItem extends React.Component {
    handleEditClick = () => {
        this.props.onEditClick(this.props.indicatorCategory);
    };

    handleDeleteClick = () => {
        this.props.onDeleteClick(this.props.indicatorCategory);
    };

    render() {
        const {indicatorCategory, search, hasParentSubcategoryNumbered} = this.props;

        return (
            <div className="j-config-list-item j-indicator-categories-item">
                <div>
                    <span>{indicatorCategory.orderIndex}</span>
                </div>
                <div>
                    <span>{highlightString(search, indicatorCategory.name)}</span>
                    {
                        indicatorCategory.inUse &&
                        <Tag text="In Use" className="j-config-item-tag-in-use"/>
                    }
                    {
                        hasParentSubcategoryNumbered && indicatorCategory.parentSubcategoryNumbered &&
                        <Tag text="Subcategory Numbered"/>
                    }
                    {
                        !indicatorCategory.active &&
                        <Tag text="Inactive" className="j-config-item-tag-inactive"/>
                    }
                </div>
                <div>
                    <span className="btn-group pull-right">
                        <button className="btn btn-default btn-xs" onClick={this.handleEditClick}>
                            <i className="fa fa-pencil margin-r-5"/>Edit
                        </button>
                        <button className="btn btn-default btn-xs" onClick={this.handleDeleteClick}
                                disabled={indicatorCategory.inUse}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

IndicatorCategoryIndexItem.propTypes = {
    indicatorCategory: PropTypes.object.isRequired,
    search: PropTypes.string,
    hasParentSubcategoryNumbered: PropTypes.bool.isRequired
};

export default IndicatorCategoryIndexItem;