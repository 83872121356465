import React from 'react';

class Tag extends React.Component {
    render() {
        const {className, text} = this.props;
        const tagClassName = `j-config-item-tag ${className}`;
        return <span className={tagClassName}>{text}</span>
    }
}

Tag.defaultProps = {
    className: '',
    text: ''
};

export default Tag;