import React from 'react';
import PropTypes from 'prop-types';
import Form from '@vallarj/react-adminlte/Form';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import Checkbox from '@vallarj/react-adminlte/Form/CheckBox';
import BasicResourceModal from "@/components/BasicResourceModal";
import NumericInput from "@/components/NumericInput";

class IndicatorCategoryModal extends React.Component {
    handleItemChangeNotify = (field, value, item) => {
        if (field === 'orderIndex') {
            if (value === '') {
                value = null;
            } else {
                value = parseInt(value, 10);
            }
        }

        return item.update(field, value);
    };

    renderItemView = (view, item, errors, notifyItemChange) => {
        const {hasParentSubcategoryNumbered} = this.props;

        return (
            <Form onChange={notifyItemChange} errors={errors}>
                <TextInput label="Indicator Category" name="name" value={item.name} maxLength={255}/>
                <NumericInput label="Order Index" name="orderIndex" value={item.orderIndex}/>
                {
                    (view === 'edit' || hasParentSubcategoryNumbered) &&
                    <h3>Options</h3>
                }
                {
                    view === 'edit' &&
                    <Checkbox label="Active" name="active" checked={item.active}/>
                }
                {
                    hasParentSubcategoryNumbered &&
                    <Checkbox label="Subcategory Numbered" name="parentSubcategoryNumbered"
                              checked={item.parentSubcategoryNumbered}/>
                }
            </Form>
        )
    };

    renderConfirmDeleteView = item => {
        return (
            <div className="j-config-modal-confirm-delete">
                <p>Are you sure you want to delete this indicator category?{" "}
                    <strong>This action cannot be undone.</strong>
                </p>
                <table>
                    <tbody>
                    <tr>
                        <td>Indicator Category</td>
                        <td>{item.name}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        return (
            <BasicResourceModal openModalRef={this.props.openModalRef}
                                onCreate={this.props.onSuccess}
                                onEdit={this.props.onSuccess}
                                onDelete={this.props.onSuccess}
                                itemSingular="Indicator Category"
                                renderConfirmDeleteView={this.renderConfirmDeleteView}
                                renderItemView={this.renderItemView}
                                onItemChangeNotify={this.handleItemChangeNotify}
            />
        );
    }
}

IndicatorCategoryModal.propTypes = {
    openModalRef: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    hasParentSubcategoryNumbered: PropTypes.bool.isRequired,
};

export default IndicatorCategoryModal;