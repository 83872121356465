import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Doughnut from "@/components/Charts/Doughnut";
import {buildChartDataSet, createPieChartOptions} from "@/utilities/dashboard";

class PCRChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createPieChartOptions("Proportion of Confirmed Amongst Tested", 'left')
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {pcrTested, pcrConfirmed} = this.props;

        return pcrTested !== nextProps.pcrTested || pcrConfirmed !== nextProps.pcrConfirmed;
    }

    buildDataSet = () => {
        const {pcrTested, pcrConfirmed} = this.props;

        return buildChartDataSet(["Confirmed/Positive", "Negative"],
            [pcrConfirmed, pcrTested - pcrConfirmed],
            "Proportion",
            ["#f59fad", "#f5df9b"],
            ["#fff", "#fff"]);
    };

    render() {
        const dataSet = this.buildDataSet();

        return (
            <div className="j-dash-covid-chart">
                <Doughnut className="chart" data={dataSet} options={this.chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    }
}

export default PCRChart;