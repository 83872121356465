import React from "react";
import {createCancelToken, fetchResourceCollection, waitForAllRequests} from "@/utilities/jsonapi";
import {noop} from "@/utilities/noop";
import {Indicator} from "@/records/Indicator";
import {IndicatorSumDependency} from "@/records/IndicatorSumDependency";
import {escapeRegExp} from "@/utilities/highlight-string";
import {Box} from "@vallarj/react-adminlte";
import TextInput from "@vallarj/react-adminlte/Form/TextInput";
import IndicatorSumIndexItem from "@/screens/Configuration/ReportValidation/IndicatorSum/IndicatorSumIndexItem";
import IndicatorSumModal from "@/screens/Configuration/ReportValidation/IndicatorSum/IndicatorSumModal";

class IndicatorSum extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indicators: [],
            indicatorMap: {},
            indicatorSumDependencies: [],
            isLoading: true,
            search: null,
            changed: null
        };

        this.scrollRef = React.createRef();
        this.fetchCancelToken = createCancelToken();
        this.openModal = noop;
    }

    componentDidMount() {
        this.fetchDependencies();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    fetchDependencies = () => {
        this.setState({isLoading: true});
        waitForAllRequests([
            fetchResourceCollection(Indicator),
            fetchResourceCollection(IndicatorSumDependency)
        ]).onSuccess(([indicators, indicatorSumDependencies]) => {
            const indicatorMap = {};
            indicators.forEach(i => {indicatorMap[i.id] = i});
            indicators.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

            const mappedDependencies = indicatorSumDependencies.map(i => (
                this.remapSumDependencyProperties(i, indicatorMap)
            ));

            this.setState({
                isLoading: false,
                indicators,
                indicatorMap,
                indicatorSumDependencies: mappedDependencies
            });
        }).execute(this.fetchCancelToken);
    };

    remapSumDependencyProperties = (indicatorSumDependency, indicatorMap) => (
        indicatorSumDependency.set('indicator', indicatorMap[indicatorSumDependency.indicator.id])
            .set('dependencies', indicatorSumDependency.dependencies.map(d => indicatorMap[d.id]))
    );

    handleAddIndicatorSumDependencyClick = () => {
        this.openModal('create', new IndicatorSumDependency().update('active', true));
    };

    handleEditIndicatorSumDependencyClick = indicatorSumDependency => {
        this.openModal('edit', indicatorSumDependency);
    };

    handleDeleteIndicatorSumDependencyClick = indicatorSumDependency => {
        this.openModal('delete', indicatorSumDependency);
    };

    handleCreateSuccess = indicatorSumDependency => {
        this.setState({
            search: "",
            changed: indicatorSumDependency,
            indicatorSumDependencies: [
                ...this.state.indicatorSumDependencies,
                this.remapSumDependencyProperties(indicatorSumDependency, this.state.indicatorMap)
            ]
        })
    };

    handleEditSuccess = indicatorSumDependency => {
        indicatorSumDependency = this.remapSumDependencyProperties(indicatorSumDependency, this.state.indicatorMap)
        this.setState({
            search: "",
            changed: indicatorSumDependency,
            indicatorSumDependencies: this.state.indicatorSumDependencies.map(i => (
                i.id === indicatorSumDependency.id ? indicatorSumDependency : i
            ))
        })
    };

    handleDeleteSuccess = indicatorSumDependency => {
        this.setState({
            indicatorSumDependencies: this.state.indicatorSumDependencies.filter(i => (
                indicatorSumDependency.id !== i.id
            ))
        })
    };

    handleChangeCommitted = scrollTop => {
        this.scrollRef.current.scrollTop = scrollTop - 25;
        this.setState({changed: null});
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});
    };

    filterIndicatorSumDependencies = () => {
        const {indicatorSumDependencies} = this.state;
        const search = (this.state.search && this.state.search.trim()) || "";

        if (!search) {
            return indicatorSumDependencies;
        }

        return indicatorSumDependencies.filter(i => (
            i.indicator.name.match(RegExp(escapeRegExp(search), "i"))
            || i.dependencies.some(d => d.name.match(RegExp(escapeRegExp(search), "i")))
        ));
    };

    render() {
        const {search, isLoading, indicators, changed} = this.state;
        const indicatorSumDependencies = this.filterIndicatorSumDependencies();

        return (<>
            <IndicatorSumModal indicators={indicators}
                               openModalRef={this.registerOpenModal}
                               onCreateSuccess={this.handleCreateSuccess}
                               onEditSuccess={this.handleEditSuccess}
                               onDeleteSuccess={this.handleDeleteSuccess}
                               />
            <Box theme='box-primary' isLoading={isLoading}>
                <Box.Header title="Indicator Sum"/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..."
                               value={search} onChange={this.handleSearchChange}/>
                    <div className="j-indicator-sum j-box-scroll" ref={this.scrollRef}>
                        <div className="j-config-list-header j-indicator-sum-header">
                            <span>Indicator</span>
                            <span>Sum of</span>
                        </div>
                        {
                            indicatorSumDependencies.map(i => (
                                <IndicatorSumIndexItem key={i.id} indicatorSumDependency={i}
                                                       onChangeCommitted={this.handleChangeCommitted}
                                                       onEditClick={this.handleEditIndicatorSumDependencyClick}
                                                       onDeleteClick={this.handleDeleteIndicatorSumDependencyClick}
                                                       changed={changed} search={search}/>
                            ))
                        }
                    </div>
                </Box.Body>
                <Box.Footer>
                    <button className="btn btn-primary pull-right" onClick={this.handleAddIndicatorSumDependencyClick}>
                        <i className="fa fa-plus-circle margin-r-5"/>Add Indicator Sum
                    </button>
                </Box.Footer>
            </Box>
        </>);
    }
}

export default IndicatorSum;