import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    active: PropTypes.bool
};

const meta = {
    inUse: PropTypes.bool
};

export class DataSource extends JsonApiRecord(
    'data-sources',
    `${config.apiBaseUrl}/data-sources`,
    attributes,
    {},
    {},
    meta
) {
    static get modelName() {
        return 'DataSource';
    }
}