import React from 'react';
import {connect} from 'react-redux';
import {withRouter, NavLink} from "react-router-dom";
import {Can} from "@/ability";

class AccessManagementMenu extends React.Component {
    render() {
        return (
            <div className="col-xs-3 config-menu">
                <div>
                    <h4>General</h4>
                    <nav>
                        <ul>
                            <Can I="view" a="User">
                                <li><NavLink to='/access-management/users'>Users</NavLink></li>
                            </Can>
                            <Can I="manage" a="Role">
                                <li><NavLink to='/access-management/roles'>Roles</NavLink></li>
                            </Can>
                            <Can I="manage" an="Office">
                                <li><NavLink to='/access-management/offices'>Offices</NavLink></li>
                            </Can>
                        </ul>
                    </nav>
                </div>
            </div>
        )
    }
}

export default withRouter(connect()(AccessManagementMenu));