import React from 'react';
import {highlightString} from "@/utilities/highlight-string";
import Tag from "@/screens/Configuration/Tag";

class IndicatorSumIndexItem extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            changed: false
        };

        this.changeTimeout = null;
    }

    componentDidMount() {
        if (this.props.changed && this.props.changed.id === this.props.indicatorSumDependency.id) {
            this.handleChanged();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.changed && this.props.changed.id === this.props.indicatorSumDependency.id) {
            this.handleChanged();
        }
    }

    componentWillUnmount() {
        if (this.changeTimeout) {
            window.clearTimeout(this.changeTimeout);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            this.state !== nextState
            || this.props.indicatorSumDependency !== nextProps.indicatorSumDependency
            || this.props.search !== nextProps.search
        ) {
            return true;
        }

        return !!(this.props.changed !== nextProps.changed
            && nextProps.changed
            && nextProps.changed.id === this.props.indicatorSumDependency.id);
    }

    handleChanged = () => {
        this.props.onChangeCommitted(this.containerRef.current.offsetTop);
        this.setState({changed: true});
        this.changeTimeout = window.setTimeout(() => {
            this.setState({changed: false});
        }, 3000);
    };

    handleEditClick = () => {
        this.props.onEditClick(this.props.indicatorSumDependency);
    };

    handleDeleteClick = () => {
        this.props.onDeleteClick(this.props.indicatorSumDependency);
    };

    render() {
        const {indicatorSumDependency, search} = this.props;
        const {changed} = this.state;
        const indicatorSubcategory = indicatorSumDependency.indicator.subcategory;

        const className = "j-config-list-item j-indicator-sum-item" +
            (changed ? " j-config-item-update" : "");
        return (
            <div className={className} ref={this.containerRef}>
                <div>
                    <div>
                        {highlightString(search, indicatorSumDependency.indicator.name)}{" "}
                        {indicatorSubcategory.name ? `(${indicatorSubcategory.name})` : ""}
                    </div>
                    <div>
                    {
                        !indicatorSumDependency.active &&
                        <Tag text="Inactive" className="j-config-item-tag-inactive"/>
                    }
                    </div>
                </div>
                <div>
                    <ul>
                        {
                            indicatorSumDependency.dependencies.map(d => (
                                <li key={d.id}>
                                    {highlightString(search, d.name)}{" "}
                                    {d.subcategory.name ? `(${d.subcategory.name})` : ""}
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div>
                    <span className="btn-group pull-right">
                        <button className="btn btn-default btn-xs" onClick={this.handleEditClick}>
                            <i className="fa fa-pencil margin-r-5"/>Edit
                        </button>
                        <button className="btn btn-default btn-xs" onClick={this.handleDeleteClick}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

export default IndicatorSumIndexItem;