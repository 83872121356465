import React from "react";
import {Box} from "@vallarj/react-adminlte";
import TopCausesOfDeath from "@/screens/Dashboard/PopulationStatistics/TopCausesOfDeath";
import PopulationMetrics from "@/screens/Dashboard/PopulationStatistics/PopulationMetrics";
import PopulationGroups from "@/screens/Dashboard/PopulationStatistics/PopulationGroups";
import DeathMetrics from "@/screens/Dashboard/PopulationStatistics/DeathMetrics";

class PopulationStatistics extends React.Component {
    render() {
        const {office, fromDate, toDate, isLoading} = this.props;

        return (
            <Box theme="box-primary" isLoading={isLoading} collapsible>
                <Box.Header title="Population Statistics"/>
                <Box.Body>
                    <div className="row">
                        <div className="col-xs-12">
                            <PopulationMetrics/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-xs-6 col-lg-12">
                                    <PopulationGroups/>
                                </div>
                                <div className="col-xs-6 col-lg-12">
                                    <DeathMetrics fromDate={fromDate} toDate={toDate}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <TopCausesOfDeath office={office} fromDate={fromDate} toDate={toDate}/>
                        </div>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default PopulationStatistics;