import React from "react";
import {Box} from "@vallarj/react-adminlte";
import MentalDisorders from "@/screens/Dashboard/DiseaseScreening/MentalDisorders";
import CommunicableDiseases from "@/screens/Dashboard/DiseaseScreening/CommunicableDiseases";
import Tuberculosis from "@/screens/Dashboard/DiseaseScreening/Tuberculosis";
import Covid from "@/screens/Dashboard/DiseaseScreening/Covid";
import NonCommunicableDiseases from "@/screens/Dashboard/DiseaseScreening/NonCommunicableDiseases";
import TopDiagnostics from "@/screens/Dashboard/DiseaseScreening/TopDiagnostics/TopDiagnostics";

class DiseaseScreening extends React.Component {
    render() {
        const {fromDate, toDate, office, isLoading} = this.props;

        return (
            <Box theme="box-primary" isLoading={isLoading} collapsible>
                <Box.Header title="Disease Screening"/>
                <Box.Body>
                    <div className="row">
                        <div className="col-lg-4">
                            <MentalDisorders/>
                        </div>
                        <div className="col-lg-8">
                            <CommunicableDiseases/>
                        </div>
                    </div>
                    <Tuberculosis/>
                    <Covid/>
                    <div className="row">
                        <div className="col-lg-12 j-dash-print-force-br">
                            <NonCommunicableDiseases/>
                        </div>
                        <div className="col-lg-12 j-dash-print-force-br">
                            <TopDiagnostics office={office} fromDate={fromDate} toDate={toDate}/>
                        </div>
                    </div>
                </Box.Body>
            </Box>
        );
    }
}

export default DiseaseScreening;