import React from "react";
import {createCancelToken, fetchResourceCollection, waitForAllRequests} from "@/utilities/jsonapi";
import {config} from "@/config";
import {MedicalProcedureAggregate} from "@/records/MedicalProcedureAggregate";
import {MedicalProcedure} from "@/records/MedicalProcedure";
import TopDiagnosticsChart from "@/screens/Dashboard/DiseaseScreening/TopDiagnostics/TopDiagnosticsChart";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import {buildAttributeChartDataSet} from "@/utilities/dashboard";

class TopDiagnostics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            medicalProcedureMap: null,
            topMedicalProceduresDataSet: buildAttributeChartDataSet([], 'medicalProcedure', 'Top Medical Procedures', '#d61d5e')
        };

        this.fetchCancelToken = createCancelToken();
    }

    componentDidMount() {
        this.fetchMedicalProcedures();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fromDate, toDate, office} = this.props;

        if (prevProps.office !== office || prevProps.fromDate !== fromDate || prevProps.toDate !== toDate) {
            this.fetchMedicalProcedures();
        }
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    fetchMedicalProcedures = () => {
        const {medicalProcedureMap} = this.state;
        const {fromDate, toDate, office} = this.props;
        if (!fromDate || !toDate || !office) {
            return null;
        }

        const params = {
            f: fromDate.format('YYYY-MM-DD'),
            t: toDate.format('YYYY-MM-DD')
        };

        let endpointSubPath;
        switch (office.officeType) {
            case OFFICE_TYPE_HQ:
                endpointSubPath = 'headquarters';
                break;
            case OFFICE_TYPE_REGIONAL:
                endpointSubPath = 'region';
                break;
            case OFFICE_TYPE_JAIL_LEVEL:
                endpointSubPath = 'jail-level-office';
                break;
            default:
                return;
        }
        const medicalProceduresEndpoint = `${config.apiBaseUrl}/reports/medical-procedure-aggregates` +
            `/${endpointSubPath}/${office.id}`;

        this.fetchCancelToken.cancel();
        this.fetchCancelToken = createCancelToken();
        if (!medicalProcedureMap) {
            waitForAllRequests([
                fetchResourceCollection(MedicalProcedure),
                fetchResourceCollection(MedicalProcedureAggregate, params, medicalProceduresEndpoint)
            ]).onSuccess(([medicalProcedures, aggregates]) => {
                const medicalProcedureMap = {};
                medicalProcedures.forEach(m => {
                    medicalProcedureMap[m.id] = m;
                });

                this.setState({medicalProcedureMap});
                this.setDataSet(aggregates, medicalProcedureMap);
            }).execute(this.fetchCancelToken);
        } else {
            fetchResourceCollection(MedicalProcedureAggregate, params, medicalProceduresEndpoint)
                .onSuccess(aggregates => this.setDataSet(aggregates, medicalProcedureMap))
                .execute(this.fetchCancelToken);
        }
    };

    setDataSet = (aggregates, medicalProcedureMap) => {
        aggregates = aggregates.map(a => a.set('medicalProcedure', medicalProcedureMap[a.medicalProcedure.id]));
        this.setState({
            topMedicalProceduresDataSet: buildAttributeChartDataSet(aggregates, 'medicalProcedure', 'Top Medical Procedures', '#f59fad')
        });
    }

    render() {
        const {topMedicalProceduresDataSet} = this.state;

        return (
            <div className="j-dash-box-subgroup">
                <h3>Top Diagnostic Procedures Requested</h3>
                <TopDiagnosticsChart data={topMedicalProceduresDataSet}/>
            </div>
        );
    }
}

export default TopDiagnostics;