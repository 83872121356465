import React from "react";
import {Bar} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {createHorizontalChartOptions} from "@/utilities/dashboard";

class TopEntryMorbiditiesChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createHorizontalChartOptions("Morbidities Upon Entry");
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {data} = this.props;
        return data !== nextProps.data;
    }

    render() {
        const {data} = this.props;

        return (
            <div className="col-lg-10 col-xs-12 col-lg-offset-1">
                <div className="j-indicator-top-entry-morbidities-chart">
                    <Bar data={data} options={this.chartOptions} plugins={[ChartDataLabels]}/>
                </div>
            </div>
        );
    }
}

export default TopEntryMorbiditiesChart;