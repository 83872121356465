import React from "react";
import InfoBox from "@/components/InfoBox";

class Prevalence extends React.Component {
    render() {
        const {
            pcrConfirmed, pctPcrConfirmed, totalRecovered,
            pctPrevalence, pctTotalRecovered, totalDeaths, pctTotalDeaths
        } = this.props;

        return (
            <div className="row">
                <div className="col-xs-6 col-lg-3">
                    <InfoBox className="simple-info-box bg-yellow"
                             title="Prevalence Proportion of Covid-19" value={pcrConfirmed}
                             progressDescription={`${pctPrevalence}% of the population has Covid-19`}
                             progress={pctPrevalence} showProgress/>
                </div>
                <div className="col-xs-6 col-lg-3">
                    <InfoBox className="simple-info-box bg-blue"
                             title="Total Confirmed Cases" value={pcrConfirmed}
                             progressDescription={`${pctPcrConfirmed}% positivity rate`}
                             progress={pctPcrConfirmed} showProgress/>
                </div>
                <div className="col-xs-6 col-lg-3">
                    <InfoBox className="simple-info-box bg-green"
                             title="Total Recovered from Covid-19" value={totalRecovered}
                             progressDescription={`${pctTotalRecovered}% recovered from facility isolation`}
                             progress={pctTotalRecovered} showProgress/>
                </div>
                <div className="col-xs-6 col-lg-3">
                    <InfoBox className="simple-info-box bg-maroon"
                             title="Total Deaths from Covid-19" value={totalDeaths}
                             progressDescription={`${pctTotalDeaths}% deaths from confirmed cases`}
                             progress={pctTotalDeaths} showProgress/>
                </div>
            </div>
        );
    }
}

export default Prevalence;