import React from "react";
import Modal from "@vallarj/react-adminlte/Modal";
import Form from "@vallarj/react-adminlte/Form";
import TextInput from "@vallarj/react-adminlte/Form/TextInput";
import SelectInput from "@vallarj/react-adminlte/Form/SelectInput";
import {notifyFormError, notifySuccess} from "@/utilities/notifications";

class IndicatorModal extends React.Component {
    constructor(props) {
        super(props);

        props.registerOpenModal(this.handleOpenModal);

        this.state = {
            view: 'create',
            category: null,
            subcategory: null,
            indicator: null,
            item: null,
            show: false,
            errors: {}
        };
    }

    handleExit = () => {
        this.setState({
            category: null,
            subcategory: null,
            indicator: null,
            show: false,
            errors: {},
            label: null
        });
    };

    handleCloseClick = () => {
        this.setState({
            show: false
        });
    };

    handleOpenModal = (view, item) => {
        this.setState({
            view,
            item,
            show: true
        });

        if (item.indicator) {
            const {indicatorCategories, indicatorSubcategories, indicators} = this.props;
            const indicator = indicators.find(i => item.indicator === i.id);
            const subcategory = indicatorSubcategories.find(is => is.id === indicator.subcategory.id);
            const category = indicatorCategories.find(ic => ic.id === subcategory.category.id);

            this.setState({
                category,
                subcategory,
                indicator,
                label: item.label
            });
        }
    };

    renderHeader = () => {
        const {view} = this.state;
        return `${view === 'create' ? 'Add' : 'Edit'} Indicator`;
    };

    getLabelFromName = option => option.name;
    getValueFromId = option => option.id;

    resolveIndicatorSubcategories = () => {
        const {indicatorSubcategories} = this.props;
        const {category} = this.state;

        if (!category) {
            return [];
        }

        return indicatorSubcategories.filter(is => is.category.id === category.id);
    };

    resolveIndicators = () => {
        const {subcategory, item} = this.state;
        const {currentIndicators, indicators} = this.props;

        if (!subcategory) {
            return [];
        }

        return indicators.filter(i => (
            i.subcategory.id === subcategory.id
            && (
                item.indicator === i.id ||
                !currentIndicators.some(ci => ci.indicator === i.id)
            )
        ));
    };

    handleFormChange = (field, value) => {
        switch (field) {
            case 'category':
                this.setState({
                    subcategory: null,
                    indicator: null,
                });
                break;
            case 'subcategory':
                this.setState({indicator: null});
                break;
        }

        this.setState({[field]: value});
    };

    handleSaveClick = () => {
        const errors = {};
        const checks = [
            'category',
            'subcategory',
            'indicator',
            'label'
        ];

        checks.forEach(c => {
            if (!this.state[c]) {
                errors[c] = ['Field is required'];
            }
        });

        if (Object.keys(errors).length > 0) {
            notifyFormError(this.resolveModalTitle());
            this.setState({errors});
        } else {
            const {onSaveSuccess} = this.props;
            const {indicator, label, view} = this.state;
            onSaveSuccess({
                indicator: indicator.id,
                label
            }, view);
            notifySuccess(this.resolveModalTitle(), "Indicator saved.");
            this.handleCloseClick();
        }
    };

    resolveModalTitle = () => {
        const {view} = this.state;
        switch (view) {
            case 'create':
                return 'Add Indicator';
            case 'edit':
                return 'Edit Indicator';
            default:
                return '';
        }
    };

    renderBody = () => {
        const {indicatorCategories} = this.props;
        const indicatorSubcategories = this.resolveIndicatorSubcategories();
        const indicators = this.resolveIndicators();

        const {view, category, subcategory, indicator, label, errors} = this.state;

        return (
            <Form onChange={this.handleFormChange} errors={errors}>
                <SelectInput options={indicatorCategories} value={category}
                             getOptionLabel={this.getLabelFromName}
                             getOptionValue={this.getValueFromId}
                             label="Indicator Category" name="category"
                             disabled={view !== 'create'}/>
                <SelectInput options={indicatorSubcategories} value={subcategory}
                             getOptionLabel={this.getLabelFromName}
                             getOptionValue={this.getValueFromId}
                             label="Indicator Subcategory" name="subcategory"
                             disabled={view !== 'create'}/>
                <SelectInput options={indicators} value={indicator}
                             getOptionLabel={this.getLabelFromName}
                             getOptionValue={this.getValueFromId}
                             label="Indicator" name="indicator"
                             disabled={view !== 'create'}/>
                <TextInput label="Label" name="label" value={label}/>
            </Form>
        );
    };

    renderFooter = () => {
        return (<>
            <button className="btn btn-default pull-left"
                    onClick={this.handleCloseClick}>
                Cancel
            </button>
            <button className="btn btn-primary pull-right" onClick={this.handleSaveClick}>
                <i className="fa fa-save margin-r-5"/>Save
            </button>
        </>);
    };

    render() {
        const {show} = this.state;

        return (
            <Modal onCloseClick={this.handleCloseClick} show={show}
                   onExit={this.handleExit}>
                <Modal.Header>{this.renderHeader()}</Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>{this.renderFooter()}</Modal.Footer>
            </Modal>
        );
    }
}

export default IndicatorModal;