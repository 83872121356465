import React from "react";
import Pie from "@/components/Charts/Pie";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {buildChartDataSet, createPieChartOptions} from "@/utilities/dashboard";

class ConsultationsChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createPieChartOptions("Consultations");
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const propNames = ['nurse', 'doctor', 'telemed'];

        return propNames.some(pn => this.props[pn] !== nextProps[pn]);
    }

    buildDataSet = () => {
        const {nurse, doctor, telemed} = this.props;

        return buildChartDataSet(["Done by Nurse", "Done by Doctor (Face to Face)", "Telemedicine"],
            [nurse, doctor, telemed],
            "Consultations",
            ["#f59fad", "#f5df9b", "#75a0c6"],
            ["#fff", "#fff", "#fff"],
            0.8
            );
    }

    render() {
        const dataSet = this.buildDataSet();
        return (
            <div className="j-dash-treatment-pie-chart">
                <Pie data={dataSet} options={this.chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    }
}

export default ConsultationsChart;