import React from 'react';
import {withAggregateTagMap} from "@/screens/DiseaseSurveillanceDashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

// Strength
const TAG_POSITION_JOR = 'dm_default_34';
const TAG_POSITION_JNOR = 'dm_default_35';
const TAG_POSITION_TRAINEE = 'dm_default_36';
const TAG_POSITION_NUP = 'dm_default_37';
// Vaccinated
const TAG_HS_FIRST = 'dm_default_28';
const TAG_HS_SECOND = 'dm_default_29';
const TAG_HS_SINGLE = 'dm_default_30';
const TAG_NHS_FIRST = 'dm_default_31';
const TAG_NHS_SECOND = 'dm_default_32';
const TAG_NHS_SINGLE = 'dm_default_33';
// Vaccines Used
const TAG_VAC_SINOVAC = 'dm_default_38';
const TAG_VAC_ASTRA = 'dm_default_39';
const TAG_VAC_PFIZER = 'dm_default_40';
const TAG_VAC_SPUTNIK = 'dm_default_41';
const TAG_VAC_MODERNA = 'dm_default_42';
const TAG_VAC_SINOPHARM = 'dm_default_43';
const TAG_VAC_JANSSEN = 'dm_default_44';

class PersonnelVaccination extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            overallStrength: 0,
            currentFirst: 0,
            currentSecond: 0,
            currentSingle: 0,
            currentFully: 0,
            pctCurrentFirst: 0,
            pctCurrentSecond: 0,
            pctCurrentSingle: 0,
            pctCurrentFully: 0,
            sinovac: 0,
            astra: 0,
            pfizer: 0,
            sputnik: 0,
            moderna: 0,
            sinopharm: 0,
            janssen: 0,
            totalVaccinated: 0,
            totalFully: 0,
            remainingVaccinees: 0,
            pctTotalVaccinated: 0,
            pctTotalFully: 0,
            pctRemainingVaccinees: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const positionJor = getAggregateByTag(TAG_POSITION_JOR);
        const positionJnor = getAggregateByTag(TAG_POSITION_JNOR);
        const positionTrainee = getAggregateByTag(TAG_POSITION_TRAINEE);
        const positionNup = getAggregateByTag(TAG_POSITION_NUP);
        const hsFirst = getAggregateByTag(TAG_HS_FIRST);
        const hsSecond = getAggregateByTag(TAG_HS_SECOND);
        const hsSingle = getAggregateByTag(TAG_HS_SINGLE);
        const nhsFirst = getAggregateByTag(TAG_NHS_FIRST);
        const nhsSecond = getAggregateByTag(TAG_NHS_SECOND);
        const nhsSingle = getAggregateByTag(TAG_NHS_SINGLE);
        const sinovac = getAggregateByTag(TAG_VAC_SINOVAC).reportTotal;
        const astra = getAggregateByTag(TAG_VAC_ASTRA).reportTotal;
        const pfizer = getAggregateByTag(TAG_VAC_PFIZER).reportTotal;
        const sputnik = getAggregateByTag(TAG_VAC_SPUTNIK).reportTotal;
        const moderna = getAggregateByTag(TAG_VAC_MODERNA).reportTotal;
        const sinopharm = getAggregateByTag(TAG_VAC_SINOPHARM).reportTotal;
        const janssen = getAggregateByTag(TAG_VAC_JANSSEN).reportTotal;

        const overallStrength = positionJor.reportTotal + positionJnor.reportTotal + positionTrainee.reportTotal + positionNup.reportTotal;
        const currentFirst = hsFirst.latestTotal + nhsFirst.latestTotal;
        const currentSecond = hsSecond.latestTotal + nhsSecond.latestTotal;
        const currentSingle = hsSingle.latestTotal + nhsSingle.latestTotal;

        const totalVaccinated = sinovac + astra + pfizer + sputnik + moderna + sinopharm + janssen;
        const totalFully = hsSecond.reportTotal + nhsSecond.reportTotal + hsSingle.reportTotal + nhsSingle.reportTotal;
        const remainingVaccinees = overallStrength - totalVaccinated;

        this.setState({
            overallStrength,
            currentFirst,
            currentSecond,
            currentSingle,
            currentFully: currentSecond + currentSingle,
            pctCurrentFirst: pctTotalOver(currentFirst, overallStrength),
            pctCurrentSecond: pctTotalOver(currentSecond, overallStrength),
            pctCurrentSingle: pctTotalOver(currentSingle, overallStrength),
            pctCurrentFully: pctTotalOver(currentSecond + currentSingle, overallStrength),
            sinovac,
            astra,
            pfizer,
            sputnik,
            moderna,
            sinopharm,
            janssen,
            totalVaccinated,
            totalFully,
            remainingVaccinees,
            pctTotalVaccinated: pctTotalOver(totalVaccinated, overallStrength),
            pctTotalFully: pctTotalOver(totalFully, overallStrength),
            pctRemainingVaccinees: pctTotalOver(remainingVaccinees, overallStrength)
        });
    };

    render() {
        const {
            overallStrength, sinovac, astra, pfizer, sputnik,
            moderna, sinopharm, janssen, totalVaccinated, totalFully
        } = this.state;
        const {fromDate, toDate} = this.props;

        return (
            <div className="j-disease-dash-covid-personnel j-dash-box-subgroup">
                <h3>COVID-19 Vaccination of Personnel</h3>
                <div className="j-dash-tabular-group two-column">
                    <table>
                        <tbody>
                        <tr>
                            <td>Over-all Strength</td>
                            <td>{overallStrength}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="j-dash-tabular-group">
                    <h4>Vaccines Used ({toDate && `${fromDate.format('YYYY/MM/DD')} - ${toDate.format('YYYY/MM/DD')}`})</h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Vaccine</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Sinovac</td>
                            <td>{sinovac}</td>
                        </tr>
                        <tr>
                            <td>Astrazeneca</td>
                            <td>{astra}</td>
                        </tr>
                        <tr>
                            <td>Pfizer</td>
                            <td>{pfizer}</td>
                        </tr>
                        <tr>
                            <td>Sputnik</td>
                            <td>{sputnik}</td>
                        </tr>
                        <tr>
                            <td>Moderna</td>
                            <td>{moderna}</td>
                        </tr>
                        <tr>
                            <td>Sinopharm</td>
                            <td>{sinopharm}</td>
                        </tr>
                        <tr>
                            <td>Janssen</td>
                            <td>{janssen}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="j-dash-tabular-group j-dash-print-force-br">
                    <h4>Vaccinees ({toDate && `${fromDate.format('YYYY/MM/DD')} - ${toDate.format('YYYY/MM/DD')}`})</h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Total Vaccines Administered</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Total Vaccinated</td>
                            <td>{totalVaccinated}</td>
                        </tr>
                        <tr>
                            <td>Fully Vaccinated (2nd + Single Dose)</td>
                            <td>{totalFully}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(PersonnelVaccination);