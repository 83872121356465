import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {IndicatorSubcategory} from "@/records/IndicatorSubcategory";
import {DataSource} from "@/records/DataSource";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    special: PropTypes.bool,
    summaryType: PropTypes.string,
    active: PropTypes.bool
};

const toOneRelationships = {
    subcategory: () => IndicatorSubcategory
};

const toManyRelationships = {
    dataSources: () => DataSource
};

const meta = {
    inUse: PropTypes.bool,
    tag: PropTypes.string
};

export class Indicator extends JsonApiRecord(
    'indicators',
    `${config.apiBaseUrl}/indicators`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'Indicator';
    }
}