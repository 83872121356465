import React from 'react';
import PropTypes from 'prop-types';

class ToastMessage extends React.Component {
    render() {
        const {
            title, subtitle, message,
            titleClassName, subtitleClassName, messageClassName
        } = this.props;

        const titleClass = "app-toast-message-title" + (titleClassName ? ` ${titleClassName}` : "");
        const subtitleClass = "app-toast-message-subtitle" + (subtitleClassName ? ` ${subtitleClassName}` : "");
        const messageClass = "app-toast-message-message" + (messageClassName ? ` ${messageClassName}` : "");

        return (<div className="app-toast-message">
            {
                title &&
                <span className={titleClass}>{title}</span>
            }
            {
                subtitle &&
                <span className={subtitleClass}>{subtitle}</span>
            }
            <span className={messageClass}>{message}</span>
        </div>);
    }
}

ToastMessage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    message: PropTypes.string,
    titleClassName: PropTypes.string,
    subtitleClassName: PropTypes.string,
    messageClassName: PropTypes.string
};

export default ToastMessage;