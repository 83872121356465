import React from "react";
import {Bar} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {createHorizontalChartOptions} from "@/utilities/dashboard";

class TopMedicationsChart extends React.Component {
    constructor(props) {
        super(props);

        this.chartOptions = createHorizontalChartOptions("Top Medications Chart");
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {data} = this.props;
        return data !== nextProps.data;
    }

    render() {
        const {data} = this.props;

        return (
            <div className="j-dash-top-medications-chart">
                <Bar data={data} options={this.chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    }
}

export default TopMedicationsChart;