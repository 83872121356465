import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {DentalHealthIndicatorCategory} from "@/records/DentalHealthIndicatorCategory";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    showHeader: PropTypes.bool,
    active: PropTypes.bool
};

const toOneRelationships = {
    category: () => DentalHealthIndicatorCategory,
    parent: () => DentalHealthIndicatorSubcategory
};

const toManyRelationships = {
    children: () => DentalHealthIndicatorSubcategory
};

const meta = {
    inUse: PropTypes.bool
};

export class DentalHealthIndicatorSubcategory extends JsonApiRecord(
    'dental-health-indicator-subcategories',
    `${config.apiBaseUrl}/dental-health-indicator-subcategories`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'DentalHealthIndicatorSubcategory';
    }
}