import {permissionOrder} from '@/permissions';
import {roleOrder} from '@/permissions';

const permissionOrderIndex = permissionOrder.reduce((a, b, i) => ({...a, [b]: i + 1}), {});
const roleOrderIndex = roleOrder.reduce((a, b, i) => ({...a, [b]: i + 1}), {});

export function permissionOrderComparator(a, b) {
    const indexA = permissionOrderIndex[a] || permissionOrder.length + 1;
    const indexB = permissionOrderIndex[b] || permissionOrder.length + 1;

    return indexA - indexB;
}

export function roleOrderComparator(a, b) {
    const indexA = roleOrderIndex[a] || roleOrder.length + 1;
    const indexB = roleOrderIndex[b] || roleOrder.length + 1;

    return indexA - indexB;
}