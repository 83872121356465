import React from 'react';
import BaseIndicators from "@/screens/Configuration/Indicators";
import {IndicatorCategory} from "@/records/IndicatorCategory";
import {IndicatorSubcategory} from "@/records/IndicatorSubcategory";
import {Indicator} from "@/records/Indicator";

class Indicators extends React.Component {
    render() {
        return (
            <BaseIndicators
                categoryResourceType={IndicatorCategory}
                subcategoryResourceType={IndicatorSubcategory}
                indicatorResourceType={Indicator}
                title="Indicators"
                hasSpecial
                hasDataSource
            />
        );
    }
}

export default Indicators;