import {AUTH_SET_ACCESS_TOKEN, AUTH_SET_CHECKED_AUTH, AUTH_SET_LOGGED_IN, AUTH_SET_USER} from "@/actions/actionTypes";
import store from "@/store";

export const authSetCheckedAuth = payload => ({
    type: AUTH_SET_CHECKED_AUTH,
    payload
});

export const authSetLoggedIn = payload => ({
    type: AUTH_SET_LOGGED_IN,
    payload
});

export const authSetAccessToken = (token, expires) => ({
    type: AUTH_SET_ACCESS_TOKEN,
    payload: {
        token, expires
    }
});

export const authSetUser = payload => ({
    type: AUTH_SET_USER,
    payload
});

export function getAuth() {
    const {auth} = store.getState();
    return auth;
}

export function getCurrentUser() {
    const {user} = getAuth();
    return user;
}