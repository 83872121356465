import React from 'react';
import {connect} from 'react-redux';

import Box from '@vallarj/react-adminlte/Box';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import {
    createCancelToken,
    fetchResourceCollection,
    waitForAllRequests
} from "@/utilities/jsonapi";
import {User} from "@/records/User";
import {Role} from "@/records/Role";
import {Permission} from "@/records/Permission";
import UserIndexItem from "@/screens/AccessManagement/Users/UserIndexItem";
import UserModal from "@/screens/AccessManagement/Users/UserModal";
import {AbilityContext} from "@/ability";
import {Office} from "@/records/Office";

const USERS_PER_PAGE = 15;

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dependenciesLoaded: false,
            isLoading: true,
            roles: {},
            offices: [],
            officeIndex: {},
            permissions: {},
            users: [],
            search: "",
            currentSearch: "",
            hasMoreResults: false
        };

        this.searchCancelToken = createCancelToken();
        this.fetchCancelToken = createCancelToken();
        this.searchTimeoutId = null;
        this.scrollRef = React.createRef();
        this.openModal = () => {};
    }

    componentDidMount() {
        const ability = this.context;

        this.setState({isLoading: true});
        waitForAllRequests([
            fetchResourceCollection(User, {n: USERS_PER_PAGE}),
            fetchResourceCollection(Role),
            fetchResourceCollection(Permission),
            fetchResourceCollection(Office)
        ]).onSuccess(([users, roles, permissions, offices]) => {
            const filteredOffices = [];
            const officeIndex = {};
            offices.forEach(o => {
                if (ability.can('view', o)) {
                    filteredOffices.push(o);
                    officeIndex[o.id] = o;
                }
            });

            const filteredRoles = [];
            roles.forEach(r => {
                if (ability.can('view', r)) {
                    filteredRoles.push(r);
                }
            });

            users = users.map(u => u.set('office', officeIndex[u.office.id]));

            this.setState({
                users,
                offices: filteredOffices,
                officeIndex,
                roles: filteredRoles.reduce((a, b) => ({...a, [b.id]: b}), {}),
                permissions: permissions.reduce((a, b) => ({...a, [b.id]: b}), {}),
                isLoading: false,
                dependenciesLoaded: true,
                hasMoreResults: users.length === USERS_PER_PAGE
            });
        }).execute(this.fetchCancelToken);
    }

    componentWillUnmount() {
        this.searchCancelToken.cancel();
        this.fetchCancelToken.cancel();
    }

    fetchUsers = (search = null, lastId = null) => {
        const {officeIndex} = this.state;
        const params = {
            ...search && {s: search},
            ...lastId && {l: lastId},
            n: USERS_PER_PAGE
        };

        this.searchCancelToken.cancel();
        this.searchCancelToken = createCancelToken();
        this.setState({isLoading: true});
        fetchResourceCollection(User, params)
            .onSuccess(users => {
                users = users.map(u => u.set('office', officeIndex[u.office.id]));
                this.setState(state => ({
                    users: lastId ? [...state.users, ...users] : users,
                    hasMoreResults: users.length === USERS_PER_PAGE,
                    isLoading: false,
                    currentSearch: search
                }));
            })
            .execute(this.searchCancelToken);
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});

        if (this.searchTimeoutId) {
            window.clearTimeout(this.searchTimeoutId);
        }

        this.searchTimeoutId = window.setTimeout(() => {
            this.fetchUsers(value);
        }, 500);
    };

    handleMoreResultsClick = () => {
        const {search, users} = this.state;

        // Get last user ID
        const last = users[users.length - 1];
        this.fetchUsers(search, last.id);
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    handleCreateUserClick = () => {
        this.openModal('create', new User());
    };

    handleCreateUserSuccess = user => {
        this.setState({
            users: [user, ...this.state.users]
        });
        this.scrollRef.current.scrollTop = 0;
    };

    handleEditUserSuccess = user => {
        this.setState({
            users: this.state.users.map(u => u.id === user.id ? user : u)
        });
    };

    handleEditItemClick = user => {
        this.openModal('edit', user);
    };

    render() {
        const {
            isLoading, users, roles, offices,
            permissions, search, currentSearch,
            hasMoreResults
        } = this.state;
        const ability = this.context;

        return (<>
            <UserModal roles={roles} permissions={permissions} offices={offices}
                       openModalRef={this.registerOpenModal}
                       onCreate={this.handleCreateUserSuccess}
                       onEdit={this.handleEditUserSuccess}/>
            <Box theme="box-primary" isLoading={isLoading}>
                <Box.Header title="Users"/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..." value={search}
                               onChange={this.handleSearchChange}/>
                    <div ref={this.scrollRef} className="j-users j-box-scroll">
                        <div className="j-users-header">
                            <span>User</span>
                            <span>Roles</span>
                            <span>Effective Permissions</span>
                        </div>
                        {
                            users.map(u => (
                                <UserIndexItem user={u} roles={roles} onEditClick={this.handleEditItemClick}
                                               permissions={permissions} search={currentSearch} key={u.id}/>
                            ))
                        }
                        {users.length === 0 && !isLoading && <div className="j-users-item">No results found.</div>}
                        {
                            hasMoreResults && !isLoading &&
                            <button type="button" onClick={this.handleMoreResultsClick}
                                    className="btn btn-default" style={{borderRadius: '15px', width: '180px', outline: "none"}}>
                                <i className="fa fa-chevron-circle-down margin-r-5"/> More Results
                            </button>
                        }
                    </div>
                </Box.Body>
                {
                    ability.can('create', 'User') &&
                    <Box.Footer>
                        <button className="btn btn-primary pull-right" onClick={this.handleCreateUserClick}>
                            <i className="fa fa-plus-circle margin-r-5"/>Create New User
                        </button>
                    </Box.Footer>
                }
            </Box>
        </>);
    }
}

function mapStateToProps({auth}) {
    return {userOffice: auth.office};
}

Users.contextType = AbilityContext;
export default connect(mapStateToProps)(Users);