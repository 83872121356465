import React from "react";
import Article from "@/screens/Docs/Article";
import ArticleImage from "@/screens/Docs/Article/ArticleImage";
import dsd01 from './images/dsd-01.jpg';
import dsd02 from './images/dsd-02.jpg';
import dsd03 from './images/dsd-03.jpg';
import dsd04 from './images/dsd-04.jpg';
import dsd05 from './images/dsd-05.jpg';
import dsd06 from './images/dsd-06.jpg';
import dsd07 from './images/dsd-07.jpg';
import dsd08 from './images/dsd-08.jpg';
import {createPageDefinition} from "@/screens/Docs/utilities";
import ArticleTitle from "@/screens/Docs/Article/ArticleTitle";

const P_DISEASE = 'disease-surveillance';
const H_OVERVIEW = createPageDefinition(P_DISEASE, 'overview', 'Overview');
const H_FILTERS = createPageDefinition(P_DISEASE, 'filters', 'Filters');
const H_FILTERS_SOURCE_DATA = createPageDefinition(P_DISEASE, 'filters-source-data', 'Source Data');
const H_FILTERS_DATE_RANGE = createPageDefinition(P_DISEASE, 'filters-date-range', 'Date Range');
const H_ACTIONS = createPageDefinition(P_DISEASE, 'actions', 'Actions');
const H_ACTIONS_NATIONAL_CONSOLIDATED = createPageDefinition(P_DISEASE, 'actions-generate-national-consolidated', 'Generating a National Consolidated XLSX Report');
const H_ACTIONS_REGIONAL_CONSOLIDATED = createPageDefinition(P_DISEASE, 'actions-generate-regional-consolidated', 'Generating a Regional Consolidated XLSX Report');
const H_ACTIONS_JAIL_XLSX = createPageDefinition(P_DISEASE, 'actions-generate-jail-level', 'Generating a Jail Level XLSX Report');
const H_ACTIONS_DASHBOARD_PDF = createPageDefinition(P_DISEASE, 'actions-export-dashboard', 'Exporting the Dashboard as PDF');

const contents = [
    H_OVERVIEW,
    {
        ...H_FILTERS,
        contents: [
            H_FILTERS_SOURCE_DATA,
            H_FILTERS_DATE_RANGE
        ]
    },
    {
        ...H_ACTIONS,
        contents: [
            H_ACTIONS_NATIONAL_CONSOLIDATED,
            H_ACTIONS_REGIONAL_CONSOLIDATED,
            H_ACTIONS_JAIL_XLSX,
            H_ACTIONS_DASHBOARD_PDF
        ]
    }
];

class DiseaseSurveillanceDashboard extends React.Component {
    render() {
        return (
            <div className="row">
                <Article title="Disease Surveillance Dashboard" id={P_DISEASE}
                         contentTitle="The disease surveillance dashboard displays data and charts from submitted disease surveillance daily reports."
                         contents={contents}>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_OVERVIEW}/>
                        <ArticleImage src={dsd01} alt="Disease Surveillance Dashboard"/>
                        <ol>
                            <li>
                                <strong>Region</strong>
                                <p>Select the region from which to display data</p>
                            </li>
                            <li>
                                <strong>Data</strong>
                                <p>Here you can filter the data for the entire region or for a specific jail office.</p>
                            </li>
                            <li>
                                <strong>Date Range</strong>
                                <p>Choose a date range for the dashboard data to be generated</p>
                            </li>
                            <li>
                                <strong>Actions Button</strong>
                                <p>Controls for generating XLSX reports and exporting the dashboard as PDF</p>
                            </li>
                        </ol>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_FILTERS}/>
                        <p>You can filter the data generated in the dashboard using the filter controls</p>
                        <ArticleImage src={dsd02} alt="Disease Surveillance Dashboard Filters"/>
                        <ArticleTitle level={4} pageDef={H_FILTERS_SOURCE_DATA}/>
                        <p>
                            You can view data from a specific office or region to which you have access.
                            To choose a data source:
                        </p>
                        <ul>
                            <li>Select a region from the <code>Region</code> dropdown menu</li>
                            <li>Select either the entire region or office under the <code>Data</code> dropdown</li>
                        </ul>
                        <ArticleImage src={dsd03} alt="Source Data"/>
                        <ArticleTitle level={4} pageDef={H_FILTERS_DATE_RANGE}/>
                        <p>
                            You can specify a date range for the data that will be displayed in the dashboard.
                            To view dashboard data for a specific date range:
                        </p>
                        <ul>
                            <li>Select a start date in the <code>From</code> date picker</li>
                            <li>Select an end date in the <code>To</code> date picker</li>
                        </ul>
                        <ArticleImage src={dsd04} alt="Date Range"/>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_ACTIONS}/>
                        <p>The <code>Actions</code> dropdown button contains options for generating report files.</p>
                        <ArticleTitle level={4} pageDef={H_ACTIONS_NATIONAL_CONSOLIDATED}/>
                        <p>
                            You can generate a national consolidated XLSX report only if you have headquarters view permissions.
                            To generate a national consolidated XLSX report:
                        </p>
                        <ol>
                            <li>Select "All Regions" and "All Jails" in the data source selection filters</li>
                            <li>Choose a date range for the report to be generated</li>
                            <li>Click on the <code>Actions</code> dropdown button</li>
                            <li>Click <code>Generate National Consolidated Disease Surveillance XLSX Report</code> menu item</li>
                            <li>
                                It may take a few seconds for the process to complete. Depending on your browser,
                                the file may be saved automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                        </ol>
                        <ArticleImage src={dsd08} alt="Generating a National Consolidated XLSX Report"/>
                        <ArticleTitle level={4} pageDef={H_ACTIONS_REGIONAL_CONSOLIDATED}/>
                        <p>
                            You can generate a regional consolidated XLSX report only if you have regional view permissions.
                            To generate a regional consolidated XLSX report:
                        </p>
                        <ol>
                            <li>Select a region in the data source selection filters</li>
                            <li>Choose a date range for the report to be generated</li>
                            <li>Click on the <code>Actions</code> dropdown button</li>
                            <li>Click <code>Generate Regional Consolidated Disease Surveillance XLSX Report</code> menu item</li>
                            <li>
                                It may take a few seconds for the process to complete. Depending on your browser,
                                the file may be saved automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                        </ol>
                        <ArticleImage src={dsd05} alt="Generating a Regional Consolidated XLSX Report"/>
                        <ArticleTitle level={4} pageDef={H_ACTIONS_JAIL_XLSX}/>
                        <p>
                            To generate a jail level XLSX report:
                        </p>
                        <ol>
                            <li>Select a jail office in the data source selection filters</li>
                            <li>Choose a date range for the report to be generated</li>
                            <li>Click on the <code>Actions</code> dropdown button</li>
                            <li>Click <code>Generate Jail Level Disease Surveillance XLSX Report</code> menu item</li>
                            <li>
                                It may take a few seconds for the process to complete. Depending on your browser,
                                the file may be saved automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                        </ol>
                        <ArticleImage src={dsd06} alt="Generating a Jail Level XLSX Report"/>
                        <ArticleTitle level={4} pageDef={H_ACTIONS_DASHBOARD_PDF}/>
                        <p>To export the currently displayed dashboard data to a Portable Document File (PDF):</p>
                        <ol>
                            <li>Click on the <code>Actions</code> dropdown button</li>
                            <li>Click <code>Export Disease Surveillance Dashboard as PDF</code> menu item</li>
                            <li>
                                It may take a few seconds for the process to complete. Depending on your browser,
                                the file may be saved automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                            <li>
                                Note: When printing the PDF, always remember to enable the "Fit to Page" setting in the print setup.
                            </li>
                        </ol>
                        <ArticleImage src={dsd07} alt="Exporting the Dashboard to PDF"/>
                    </section>
                </Article>
            </div>
        );
    }
}

DiseaseSurveillanceDashboard.contents = contents;
DiseaseSurveillanceDashboard.pageId = P_DISEASE;
export default DiseaseSurveillanceDashboard;