import React from "react";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import Prevalence from "@/screens/Dashboard/DiseaseScreening/Covid/Prevalence";
import Distribution from "@/screens/Dashboard/DiseaseScreening/Covid/Distribution";
import PCRChart from "@/screens/Dashboard/DiseaseScreening/Covid/PCRChart";
import RecoveredDeathChart from "@/screens/Dashboard/DiseaseScreening/Covid/RecoveredDeathChart";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_JAIL_POP = 'default_2';
const TAG_TESTED_PCR = 'default_73';
const TAG_TESTED_RAT = 'default_74';
const TAG_CONFIRMED_PCR = 'default_75';
const TAG_POSITIVE_RAT = 'default_76';
const TAG_RECOVERED = 'default_121';
const TAG_DEATH = 'default_122';

class Covid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pcrTested: 0,
            pcrConfirmed: 0,
            pctPcrConfirmed: 0,
            ratTested: 0,
            ratPositive: 0,
            pctRatPositive: 0,
            totalTested: 0,
            totalPositive: 0,
            pctTotalPositive: 0,
            pctPrevalence: 0,
            totalRecovered: 0,
            pctTotalRecovered: 0,
            totalDeaths: 0,
            pctTotalDeaths: 0
        }
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const jailPop = getAggregateByTag(TAG_JAIL_POP);
        const testedPCR = getAggregateByTag(TAG_TESTED_PCR);
        const testedRAT = getAggregateByTag(TAG_TESTED_RAT);
        const confirmedPCR = getAggregateByTag(TAG_CONFIRMED_PCR);
        const positiveRAT = getAggregateByTag(TAG_POSITIVE_RAT);
        const recovered = getAggregateByTag(TAG_RECOVERED);
        const deaths = getAggregateByTag(TAG_DEATH);

        const totalTested = testedPCR.reportTotal + testedRAT.reportTotal;
        const totalPositive = confirmedPCR.reportTotal + positiveRAT.reportTotal;

        this.setState({
            pcrTested: testedPCR.reportTotal,
            pcrConfirmed: confirmedPCR.reportTotal,
            pctPcrConfirmed: pctTotalOver(confirmedPCR.reportTotal, testedPCR.reportTotal),
            ratTested: testedRAT.reportTotal,
            ratPositive: positiveRAT.reportTotal,
            pctRatPositive: pctTotalOver(positiveRAT.reportTotal, testedRAT.reportTotal),
            totalTested,
            totalPositive,
            pctTotalPositive: pctTotalOver(totalPositive, totalTested),
            pctPrevalence: pctTotalOver(confirmedPCR.reportTotal, jailPop.reportTotal),
            totalRecovered: recovered.reportTotal,
            pctTotalRecovered: pctTotalOver(recovered.reportTotal, confirmedPCR.reportTotal),
            totalDeaths: deaths.reportTotal,
            pctTotalDeaths: pctTotalOver(deaths.reportTotal, confirmedPCR.reportTotal)
        });
    };

    render() {
        return (
            <div className="j-dash-covid j-dash-box-subgroup">
                <h3>COVID-19</h3>
                <Prevalence {...this.state}/>
                <div className="row">
                    <div className="col-lg-4">
                        <Distribution {...this.state}/>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-xs-6">
                                <PCRChart {...this.state}/>
                            </div>
                            <div className="col-xs-6">
                                <RecoveredDeathChart {...this.state}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(Covid);