import React from 'react';
import OptionsBox from "@/screens/JailLevelReports/JailLevelReportEntry/OptionsBox";
import {MedicalProcedure} from "@/records/MedicalProcedure";

class MedicalProcedures extends React.Component {
    render() {
        return (
            <OptionsBox boxTitle="Medical Procedures Requested (J005)"
                        headerTitle="Procedures"
                        attributeName="medicalProcedures"
                        optionType={MedicalProcedure}
                        onAttributeChange={this.props.onAttributeChange}
                        onAttributesReplace={this.props.onAttributesReplace}
                        onAttributeRevert={this.props.onAttributeRevert}
                        attributesChanged={this.props.attributesChanged}
                        isLoading={this.props.isLoading}
                        onSaveClick={this.props.onSaveClick}
                        isEditMode={this.props.isEditMode}
                        sheetIndex={3}
                        values={this.props.values}/>
        );
    }
}

export default MedicalProcedures;