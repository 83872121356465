import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {MentalHealthIndicatorCategory} from "@/records/MentalHealthIndicatorCategory";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    showHeader: PropTypes.bool,
    active: PropTypes.bool
};

const toOneRelationships = {
    category: () => MentalHealthIndicatorCategory,
    parent: () => MentalHealthIndicatorSubcategory
};

const toManyRelationships = {
    children: () => MentalHealthIndicatorSubcategory
};

const meta = {
    inUse: PropTypes.bool
};

export class MentalHealthIndicatorSubcategory extends JsonApiRecord(
    'mental-health-indicator-subcategories',
    `${config.apiBaseUrl}/mental-health-indicator-subcategories`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'MentalHealthIndicatorSubcategory';
    }
}