import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {DiseaseMonitoringIndicator} from "@/records/DiseaseMonitoringIndicator";

const attributes = {
    maleSum: PropTypes.number,
    femaleSum: PropTypes.number,
    maleTotalAverage: PropTypes.number,
    femaleTotalAverage: PropTypes.number,
    maleLatest: PropTypes.number,
    femaleLatest: PropTypes.number
};

const toOneRelationships = {
    indicator: () => DiseaseMonitoringIndicator
};

export class DiseaseSurveillanceIndicatorAggregate extends JsonApiRecord('disease-surveillance-indicator-aggregates', '',
    attributes, toOneRelationships) {
    static get modelName() {
        return 'DiseaseSurveillanceIndicatorAggregate';
    }
}