import React from 'react';
import PropTypes from 'prop-types';
import Box from '@vallarj/react-adminlte/Box';
import {createCancelToken} from "@/utilities/jsonapi";
import ImportModal from "@/components/ImportModal";
import {noop} from "@/utilities/noop";
import IndicatorReportGroup from "./IndicatorReportGroup";
import {fetchIndicatorStructure} from "@/utilities/indicator-report";

class IndicatorReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            structure: [],
            currentInput: 0,
            indicatorMap: {},
        };

        this.inputCount = 0;
        this.fetchCancelToken = createCancelToken();
        this.openModal = noop;
    }

    componentDidMount() {
        this.buildStructure();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
        this.props.registerOpenModal(openModal);
    }

    buildStructure = () => {
        const {
            categoryResourceType, subcategoryResourceType, indicatorResourceType,
            hasDataSource, month
        } = this.props;
        this.setState({isLoading: true});
        fetchIndicatorStructure(
            categoryResourceType,
            subcategoryResourceType,
            indicatorResourceType,
            hasDataSource,
            this.fetchCancelToken,
            month
        ).then(({structure, indicatorMap}) => {
            this.setState({
                isLoading: false,
                structure,
                indicatorMap
            });
        });
    };

    handleFocusChange = (direction, currentPos) => {
        let newPos = currentPos;
        switch (direction) {
            case 'up':
                newPos -= 2;
                break;
            case 'down':
                newPos += 2;
                break;
            case 'left':
                newPos -= 1;
                break;
            case 'right':
                newPos += 1;
                break;
        }

        if (newPos >= 0 && newPos < this.inputCount) {
            this.setState({currentInput: newPos});
        }
    };

    getInputPosition = () => {
        return this.inputCount++;
    };

    handleImportClick = () => {
        this.openModal();
    };

    processWorkbook = workbook => {
        const {indicatorMap} = this.state;
        const {onIndicatorsReplace, worksheetProcessor, worksheetIndex} = this.props;

        return worksheetProcessor(workbook, worksheetIndex, 5, 'B', 'D', 'E', "i-")
            .then(items => {
                const importItems = {};
                const missingIndicators = [];
                Object.keys(indicatorMap).forEach(id => {
                    if (!items.hasOwnProperty(id)) {
                        const indicator = indicatorMap[id];
                        missingIndicators.push({
                            id,
                            name: indicator.name +
                                (indicator.subcategory.name ? ` (${indicator.subcategory.name})` : "")
                        });
                    } else {
                        importItems[id] = items[id];
                    }
                });
                if (missingIndicators.length === 0) {
                    onIndicatorsReplace(importItems);
                    return {status: "success"};
                } else if (Object.keys(importItems).length === 0) {
                    return {status: "empty"};
                } else {
                    onIndicatorsReplace(importItems);
                    missingIndicators.sort((a, b) => (
                        a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    );
                    return {
                        status: "partial",
                        missing: missingIndicators
                    };
                }
            });
    };

    render() {
        const {isLoading, structure, currentInput} = this.state;
        const {
            indicators: indicatorValues, onIndicatorChange,
            isLoading: entryIsLoading, isEditMode,
            title, hasDataSource, showFooter
        } = this.props;

        const classNamePrefix = (hasDataSource ? 'j-indicator-report' : 'j-base-indicator-report');

        return (<>
            <ImportModal header={`Import ${title}`}
                         registerOpenModal={this.registerOpenModal}
                         processWorkbook={this.processWorkbook}/>
            <Box theme='box-primary' isLoading={isLoading || entryIsLoading}>
                <Box.Header title={title}/>
                <Box.Body>
                    <div className={classNamePrefix}>
                        <div className={`${classNamePrefix}-header`}>
                            <span>Indicator</span>
                            <span>Male</span>
                            <span>Female</span>
                            <span>Total</span>
                            {
                                hasDataSource &&
                                <span>Data Source</span>
                            }
                        </div>
                        {
                            structure.map(item => (
                                <IndicatorReportGroup key={item.id} item={item}
                                                      notifyFocusChange={this.handleFocusChange}
                                                      getPosition={this.getInputPosition}
                                                      currentFocus={currentInput}
                                                      indicatorValues={indicatorValues}
                                                      onIndicatorChange={onIndicatorChange}
                                                      isEditMode={isEditMode}
                                                      hasDataSource={hasDataSource}/>
                            ))
                        }
                    </div>
                </Box.Body>
                {
                    showFooter && isEditMode &&
                    <Box.Footer>
                        <div className="pull-right">
                            <button className="btn btn-default margin-r-5"
                                    onClick={this.props.onIndicatorsRevert}
                                    disabled={!this.props.indicatorsChanged || entryIsLoading}>
                                <i className="fa fa-undo margin-r-5"/>Revert
                            </button>
                            <button className="btn btn-default margin-r-5"
                                    onClick={this.handleImportClick}>
                                <i className="fa fa-upload margin-r-5"/>Import
                            </button>
                            <button className="btn btn-primary" onClick={this.props.onSaveClick}
                                    disabled={!this.props.indicatorsChanged || entryIsLoading}>
                                {
                                    entryIsLoading ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-save margin-r-5"/>
                                }Save
                            </button>
                        </div>
                    </Box.Footer>
                }
            </Box>
        </>);
    }
}

IndicatorReport.defaultProps = {
    registerOpenModal: noop,
    onSaveClick: noop,
    onIndicatorsRevert: noop,
    indicatorsChanged: false,
};

IndicatorReport.propTypes = {
    title: PropTypes.string.isRequired,
    registerOpenModal: PropTypes.func,
    categoryResourceType: PropTypes.func.isRequired,
    subcategoryResourceType: PropTypes.func.isRequired,
    indicatorResourceType: PropTypes.func.isRequired,
    hasDataSource: PropTypes.bool.isRequired,
    worksheetProcessor: PropTypes.func.isRequired,
    indicators: PropTypes.object.isRequired,
    onIndicatorsReplace: PropTypes.func.isRequired,
    onIndicatorChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    onSaveClick: PropTypes.func,
    onIndicatorsRevert: PropTypes.func,
    indicatorsChanged: PropTypes.bool,
    month: PropTypes.number,
    showFooter: PropTypes.bool.isRequired,
    worksheetIndex: PropTypes.number.isRequired
};

export default IndicatorReport;