import React from 'react';
import PropTypes from 'prop-types';
import Box from '@vallarj/react-adminlte/Box';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import {noop} from "@/utilities/noop";
import {escapeRegExp} from "@/utilities/highlight-string";
import OptionIndexItem from "@/screens/Configuration/Options/OptionIndexItem";
import {createCancelToken, fetchResourceCollection} from "@/utilities/jsonapi";
import OptionModal from "@/screens/Configuration/Options/OptionModal";

class OptionsIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
            isLoading: true,
            search: null,
            changed: null
        };

        this.scrollRef = React.createRef();
        this.fetchCancelToken = createCancelToken();
        this.openModal = noop;
    }

    componentDidMount() {
        this.fetchOptions();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    fetchOptions = () => {
        const {optionType} = this.props;

        this.setState({isLoading: true});
        fetchResourceCollection(optionType)
            .onSuccess(collection => {
                this.setState({
                    options: collection,
                    isLoading: false
                })
            })
            .execute(this.fetchCancelToken);
    }

    handleAddOptionClick = () => {
        const OptionType = this.props.optionType;
        const option = (new OptionType()).update('active', true);
        this.openModal('create', option);
    };

    handleEditOptionClick = option => {
        this.openModal('edit', option);
    };

    handleDeleteOptionClick = option => {
        this.openModal('delete', option);
    };

    handleCreateSuccess = option => {
        const options = [...this.state.options, option].sort((a, b) => (
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ));
        this.setState({
            search: "",
            changed: option,
            options
        });
    };

    handleEditSuccess = option => {
        const options = this.state.options.map(o => option.id === o.id ? option: o).sort((a, b) => (
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ));
        this.setState({
            search: "",
            changed: option,
            options
        })
    };

    handleDeleteSuccess = option => {
        const options = this.state.options.filter(o => option.id !== o.id);
        this.setState({
            options
        });
    };

    handleChangeCommitted = scrollTop => {
        this.scrollRef.current.scrollTop = scrollTop - 25;
        this.setState({changed: null});
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});
    };

    filterOptions = () => {
        const {options} = this.state;
        const search = (this.state.search && this.state.search.trim()) || "";

        if (!search ){
            return options;
        }

        return options.filter(m =>
            m.name.match(RegExp(escapeRegExp(search), "i"))
        );
    };

    render() {
        const {search, changed, isLoading} = this.state;
        const options = this.filterOptions();
        const {title, itemSingular} = this.props;

        return (<>
            <OptionModal openModalRef={this.registerOpenModal}
                         onCreate={this.handleCreateSuccess}
                         onEdit={this.handleEditSuccess}
                         onDelete={this.handleDeleteSuccess}
                         itemSingular={itemSingular}/>
            <Box theme="box-primary" isLoading={isLoading}>
                <Box.Header title={title}/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..."
                               value={search} onChange={this.handleSearchChange}/>
                    <div className="j-box-scroll" ref={this.scrollRef}>
                        <div className="j-config-list-header j-config-list-header-single">
                            <span>{itemSingular}</span>
                        </div>
                        {
                            options.map(o => (
                                <OptionIndexItem key={o.id} option={o}
                                           onChangeCommitted={this.handleChangeCommitted}
                                           onEditClick={this.handleEditOptionClick}
                                           onDeleteClick={this.handleDeleteOptionClick}
                                           changed={changed} search={search}/>
                            ))
                        }
                    </div>
                </Box.Body>
                <Box.Footer>
                    <button className="btn btn-primary pull-right" onClick={this.handleAddOptionClick}>
                        <i className="fa fa-plus-circle margin-r-5"/>Add {itemSingular}
                    </button>
                </Box.Footer>
            </Box>
        </>);
    }
}

OptionsIndex.propTypes = {
    optionType: PropTypes.func,
    title: PropTypes.string,
    itemSingular: PropTypes.string,
    rootEndpoint: PropTypes.string
};

export default OptionsIndex;