import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {CauseOfDeath} from "@/records/CauseOfDeath";

const attributes = {
    total: PropTypes.number
};

const toOneRelationships = {
    causeOfDeath: () => CauseOfDeath
};

export class CauseOfDeathAggregate extends JsonApiRecord('cause-of-death-aggregates', '',
    attributes, toOneRelationships) {
    static get modelName() {
        return 'CauseOfDeathAggregate';
    }
}