import React from "react";
import PropTypes from 'prop-types';
import Box from "@vallarj/react-adminlte/Box";
import {
    buildChartDataSet,
    createHorizontalChartOptions,
    createPieChartOptions, createVerticalChartOptions,
    pctTotalOver
} from "@/utilities/dashboard";
import Pie from "@/components/Charts/Pie";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Doughnut from "@/components/Charts/Doughnut";
import {Bar} from "react-chartjs-2";

class DashboardComponent extends React.PureComponent {
    renderTable = () => {
        const {chartType, values} = this.props;
        const outerClass = chartType ? "col-lg-5" : "col-lg-12";
        const total = values.reduce((a, b) => a + b.value, 0);

        return (
            <div className={outerClass}>
                <div className="j-dash-tabular-group">
                    <table>
                        <thead>
                        <tr>
                            <th>Classification</th>
                            <th>Count</th>
                            <th>%</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            values.map(v => (
                                <tr key={v.id}>
                                    <td>{v.label}</td>
                                    <td>{v.value}</td>
                                    <td>{pctTotalOver(v.value, total)}%</td>
                                </tr>
                            ))
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{total}</td>
                            <td>100.00%</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    };

    renderChart = () => {
        const {chartType} = this.props;

        switch (chartType) {
            case 'pie':
                return this.renderPieChart();
            case 'doughnut':
                return this.renderDoughnutChart();
            case 'horizontal_bar':
                return this.renderHorizontalChart();
            case 'vertical_bar':
                return this.renderVerticalChart();
            default:
                return null;
        }
    };

    renderPieChart = () => {
        const {title, values} = this.props;
        const chartOptions = createPieChartOptions(title);

        const dataSet = buildChartDataSet(
            values.map(v => v.label),
            values.map(v => v.value),
            title,
            this.resolvePieChartColors(values.length),
            this.resolvePieChartBorders(values.length)
        );

        return (
            <div className="j-dash-custom-pie col-lg-7">
                <Pie data={dataSet} options={chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    };

    renderDoughnutChart = () => {
        const {title, values} = this.props;
        const chartOptions = createPieChartOptions(title);

        const dataSet = buildChartDataSet(
            values.map(v => v.label),
            values.map(v => v.value),
            title,
            this.resolvePieChartColors(values.length),
            this.resolvePieChartBorders(values.length)
        );

        return (
            <div className="j-dash-custom-pie col-lg-7">
                <Doughnut data={dataSet} options={chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    };

    renderHorizontalChart = () => {
        const {title, values} = this.props;
        const chartOptions = createHorizontalChartOptions(title);

        const dataSet = buildChartDataSet(
            values.map(v => v.label),
            values.map(v => v.value),
            title,
            ['#3b8cb9'],
            ['#3b8cb9']
        );

        return (
            <div className="j-dash-custom-horizontal-bar col-lg-7">
                <Bar data={dataSet} options={chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    };

    renderVerticalChart = () => {
        const {title, values} = this.props;
        const chartOptions = createVerticalChartOptions(title);

        const dataSet = buildChartDataSet(
            values.map(v => v.label),
            values.map(v => v.value),
            title,
            ['#d61d5e'],
            ['#d61d5e']
        );

        return (
            <div className="j-dash-custom-vertical-bar col-lg-7">
                <Bar data={dataSet} options={chartOptions} plugins={[ChartDataLabels]}/>
            </div>
        );
    };

    resolvePieChartColors = length => {
        const fixed = ["#f59fad", "#f5df9b", "#75a0c6", "#baded1"];
        const select = fixed.slice(0, length);
        if (length > select.length) {
            const more = length - select.length;
            for (let i = 0; i < more; i++) {
                const hue = i * 137.508;
                select.push(`hsl(${hue},50%,75%)`)
            }
        }
        return select;
    };

    resolvePieChartBorders = length => {
        return Array(length).fill('#fff');
    };

    render() {
        const {title, forceBreak, isLoading} = this.props;

        return (
            <div className={forceBreak ? 'j-dash-print-force-br' : undefined}>
                <Box theme='box-primary' collapsible isLoading={isLoading}>
                    <Box.Header title={title}/>
                    <Box.Body>
                        <div className="row">
                            {this.renderTable()}
                            {this.renderChart()}
                        </div>
                    </Box.Body>
                </Box>
            </div>
        );
    }
}

DashboardComponent.propTypes = {
    chartType: PropTypes.string,
    title: PropTypes.string.isRequired,
    forceBreak: PropTypes.bool,
    values: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
};

export default DashboardComponent;