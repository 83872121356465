import React from 'react';
import BaseIndicatorSubcategories from "@/screens/Configuration/IndicatorSubcategories";
import {MentalHealthIndicatorCategory} from "@/records/MentalHealthIndicatorCategory";
import {MentalHealthIndicatorSubcategory} from "@/records/MentalHealthIndicatorSubcategory";

class MentalHealthIndicatorSubcategories extends React.Component {
    render() {
        return (
            <BaseIndicatorSubcategories
                categoryResourceType={MentalHealthIndicatorCategory}
                subcategoryResourceType={MentalHealthIndicatorSubcategory}
                title="Mental Health Indicator Subcategories"
                hasShowTotal={false}
                hasQuarterly={false}
            />
        );
    }
}

export default MentalHealthIndicatorSubcategories;