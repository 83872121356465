import React from 'react';

class InfoBox extends React.Component {
    render() {
        const {className, iconClass, title, value, showProgress, progress, progressDescription} = this.props;
        let boxIconClass = this.props.boxIconClass ? ` ${this.props.boxIconClass}` : "";

        const infoBoxContentClass = "info-box-content" + (iconClass ? "" : " no-margin");
        return (
            <div className={"info-box" + (className ? ` ${className}` : "")}>
                {
                    iconClass &&
                    <span className={`info-box-icon ${boxIconClass}`}><i className={iconClass}/></span>
                }

                <div className={infoBoxContentClass}>
                    <span className="info-box-text">{title}</span>
                    <span className="info-box-number">{value}</span>
                    {
                        showProgress &&
                        <>
                            <div className="progress">
                                <div className="progress-bar" style={{width: "" + progress + "%"}}/>
                            </div>
                            <span className="progress-description">{progressDescription}</span>
                        </>
                    }
                </div>
            </div>
        );
    }
}

InfoBox.defaultProps = {
    className: "",
    showProgress: false,
    progress: 0,
    progressDescription: "",
};

export default InfoBox;