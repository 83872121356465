import React from 'react';
import PropTypes from 'prop-types';
import Form from '@vallarj/react-adminlte/Form';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import NumericInput from "@/components/NumericInput";
import SelectInput from "@vallarj/react-adminlte/Form/SelectInput";
import ValueButton from "@vallarj/react-adminlte/ValueButton";
import Checkbox from "@vallarj/react-adminlte/Form/CheckBox";
import BasicResourceModal from "@/components/BasicResourceModal";

class DataSources extends React.Component {
    handleAddClick = () => {
        const {onChange, selected} = this.props;
        onChange('addDataSource', selected);
    };

    handleRemoveClick = value => {
        const {onChange} = this.props;
        onChange('removeDataSource', value);
    };

    getLabelByName = option => option.name;
    getValueById = option => option.id;

    render() {
        const {selected, onChange} = this.props;
        const selectedDataSources = this.props.item.dataSources || [];

        const dataSources = this.props.dataSources.filter(d => !selectedDataSources.some(s => d.id === s.id));

        return (<div className="j-indicators-modal-data-sources">
            <div className="j-indicators-modal-data-sources-select">
                <div>
                    <SelectInput label="Data Sources" name="dataSource" value={selected}
                                 options={dataSources} onChange={onChange}
                                 getOptionLabel={this.getLabelByName}
                                 getOptionValue={this.getValueById}
                                 searchable clearable/>
                </div>
                <div>
                    <button className="btn btn-default btn-block" onClick={this.handleAddClick}
                            disabled={!selected}>
                        <i className="fa fa-plus-circle margin-r-5"/>Add
                    </button>
                </div>
            </div>
            <div className="j-indicators-modal-data-sources-list">
                <div>
                {
                    selectedDataSources.map(d => (
                        <div key={d.id} className="j-indicators-modal-data-sources-list-item">
                            <div>
                                {d.name}
                            </div>
                            <div>
                                <ValueButton className="btn btn-xs btn-danger" value={d}
                                             onClick={this.handleRemoveClick}>
                                    <i className="fa fa-trash margin-r-5"/>Remove
                                </ValueButton>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>);
    }
}

class IndicatorModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDataSource: null
        };

        this.summaryTypeOptions = [
            {label: "Sum", value: "sum"},
            {label: "Average", value: "average"},
            {label: "Latest", value: "latest"}
        ];
    }

    handleExit = () => {
        this.setState({
            selectedDataSource: null
        });
    }

    handleItemChangeNotify = (field, value, item) => {
        switch (field) {
            case "dataSource":
                this.setState({selectedDataSource: value});
                return item;
            case "addDataSource":
                this.setState({selectedDataSource: null});
                return item.update('dataSources', [...item.dataSources, value]);
            case "removeDataSource":
                return item.update('dataSources', item.dataSources.filter(d => d.id !== value.id));
            default:
                if (field === 'orderIndex') {
                    if (value === '') {
                        value = null;
                    } else {
                        value = parseInt(value, 10);
                    }
                }

                return item.update(field, value);
        }
    };

    renderItemView = (view, item, errors, notifyItemChange) => {
        const {dataSources, hasDataSource, hasSpecial} = this.props;
        const {selectedDataSource} = this.state;
        const subcategory = item.subcategory || {};

        return (<>
            <Form onChange={notifyItemChange} errors={errors}>
                <TextInput label="Subcategory" name="subcategory" value={subcategory.name} disabled/>
                <TextInput label="Indicator" name="name" value={item.name} maxLength={255}/>
                <NumericInput label="Order Index" name="orderIndex" value={item.orderIndex}/>
                <SelectInput label="Summary Type" name="summaryType" value={item.summaryType}
                             onChange={notifyItemChange} options={this.summaryTypeOptions} simpleValue/>
            </Form>
            {
                hasDataSource &&
                <DataSources item={item} onChange={notifyItemChange} dataSources={dataSources}
                             selected={selectedDataSource}/>
            }
            <Form onChange={notifyItemChange} errors={errors}>
                {
                    (view === 'edit' || hasSpecial) &&
                    <h3>Options</h3>
                }
                {
                    view === 'edit' &&
                    <Checkbox label="Active" name="active" checked={item.active}/>
                }
                {
                    hasSpecial &&
                    <Checkbox label="Special" name="special" checked={item.special}/>
                }
            </Form>
        </>);
    };

    renderConfirmDeleteView = item => {
        return (
            <div className="j-config-modal-confirm-delete">
                <p>Are you sure you want to delete this indicator? <strong>This action cannot be undone.</strong></p>
                <table>
                    <tbody>
                    <tr>
                        <td>Indicator</td>
                        <td>{item.name}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        return (
            <BasicResourceModal handleExit={this.handleExit}
                                openModalRef={this.props.openModalRef}
                                onCreate={this.props.onSuccess}
                                onEdit={this.props.onSuccess}
                                onDelete={this.props.onSuccess}
                                itemSingular="Indicator"
                                renderConfirmDeleteView={this.renderConfirmDeleteView}
                                renderItemView={this.renderItemView}
                                onItemChangeNotify={this.handleItemChangeNotify}
            />
        );
    }
}

IndicatorModal.propTypes = {
    openModalRef: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    dataSources: PropTypes.array.isRequired,
    hasSpecial: PropTypes.bool.isRequired,
    hasDataSource: PropTypes.bool.isRequired
};

export default IndicatorModal;