import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {DentalHealthIndicatorSubcategory} from "@/records/DentalHealthIndicatorSubcategory";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    orderIndex: PropTypes.number,
    summaryType: PropTypes.string,
    active: PropTypes.bool
};

const toOneRelationships = {
    subcategory: () => DentalHealthIndicatorSubcategory
};

const meta = {
    inUse: PropTypes.bool,
    tag: PropTypes.string
};

export class DentalHealthIndicator extends JsonApiRecord(
    'dental-health-indicators',
    `${config.apiBaseUrl}/dental-health-indicators`,
    attributes,
    toOneRelationships,
    {},
    meta
) {
    static get modelName() {
        return 'DentalHealthIndicator';
    }
}