import axios from 'axios';
import {saveAs} from 'file-saver';
import store from "@/store";

import {authSetAccessToken, authSetUser} from "@/actions/authActions";
import {getSession} from "@/utilities/auth";

const MAX_RETRIES = 1;

export function getRequest(address, axiosConfig = {}) {
    return executeRequest('get', address, axiosConfig);
}

export function getFileRequest(address, filename, axiosConfig = {}) {
    return getRequest(address, {...axiosConfig, responseType: 'blob'})
        .then(response => {
            saveAs(new Blob([response.data], {
                type: response.headers['content-type']
            }), filename)
        });
}

export function postRequest(address, request, axiosConfig = {}) {
    return executeRequest('post', address, axiosConfig, request);
}

export function patchRequest(address, request, axiosConfig = {}) {
    return executeRequest('patch', address, axiosConfig, request);
}

export function deleteRequest(address, request = undefined, axiosConfig = {}) {
    return executeRequest('delete', address, axiosConfig, request);
}

function executeRequest(method, url, config, data) {
    const {auth} = store.getState();

    config = {
        ...config,
        method,
        url
    }

    if (data) {
        config = {...config, data}
    }

    return baseRequest(config, auth.accessToken);
}

function baseRequest(config, accessToken, retries = 0) {
    config = {...config, headers: {'Authorization': `Bearer ${accessToken}`}};

    return axios.request(config)
        .catch(err => {
            if (err.response) {
                //request was made, but status code falls out of 2xx range
                switch (err.response.status) {
                    case 401:
                        if (retries < MAX_RETRIES) {
                            return renewAccessToken()
                                .then(newAccessToken => baseRequest(config, newAccessToken, retries + 1));
                        }
                }
            } else if (err.request) {
                //request was made, but no response was received
            } else {
                //an unknown error happened.
            }
            throw err;
        });
}

function renewAccessToken() {
    return getSession()
        .then(({data}) => {
            if (data["access_token"]) {
                // store new access token
                store.dispatch(authSetAccessToken(data["access_token"], data["expires"]));
                store.dispatch(authSetUser({id: data["user_id"], ...data["usr"]}));
                return Promise.resolve(data["access_token"]);
            } else {
                redirectLogout();
            }
        });
}

function redirectLogout() {
    window.location.replace(`${process.env.PUBLIC_URL}/logout`);
}