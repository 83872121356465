import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import BreadcrumbItem from '@vallarj/react-adminlte/Content/BreadcrumbItem';
import Content from '@vallarj/react-adminlte/Content';
import AccessManagementMenu from "@/screens/AccessManagement/AccessManagementMenu";
import Users from "@/screens/AccessManagement/Users";
import Roles from "@/screens/AccessManagement/Roles";
import {AbilityContext} from "@/ability";
import Offices from "@/screens/AccessManagement/Offices";

class AccessManagement extends React.Component {
    render() {
        const ability = this.context;
        const redirect = (ability.can('view', 'User') && '/access-management/users')
            || (ability.can('view', 'Role') && '/access-management/roles')
            || '/'

        return (
            <Content>
                <Content.Header title="Access Management"/>
                <Content.Breadcrumb>
                    <BreadcrumbItem label="Access Management" iconClass="fa fa-users" active/>
                </Content.Breadcrumb>
                <Content.Body>
                    <div className="row">
                        <AccessManagementMenu/>
                        <div className="col-xs-9">
                            <Switch>
                                {
                                    ability.can('view', 'User') &&
                                    <Route exact path='/access-management/users' component={Users}/>
                                }
                                {
                                    ability.can('manage', 'Role') &&
                                    <Route exact path='/access-management/roles' component={Roles}/>
                                }
                                {
                                    ability.can('manage', 'Office') &&
                                    <Route exact path='/access-management/offices' component={Offices}/>
                                }
                                <Redirect to={redirect}/>
                            </Switch>
                        </div>
                    </div>
                </Content.Body>
            </Content>
        );
    }
}

AccessManagement.contextType = AbilityContext;
export default AccessManagement;