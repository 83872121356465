import React from 'react';
import {notifyFormError, notifyItemRequestSuccess, notifyUnableToProcess} from "@/utilities/notifications";
import PropTypes from "prop-types";
import ModalFooter from "@/screens/Configuration/ModalFooter";
import Modal from "@vallarj/react-adminlte/Modal";
import {createCancelToken, deleteResource, patchResource, postResource} from "@/utilities/jsonapi";

class BasicResourceModal extends React.Component {
    constructor(props) {
        super(props);

        if (props.openModalRef) {
            props.openModalRef(this.handleOpenModal)
        }

        this.state = {
            show: false,
            isProcessing: false,
            view: 'create',
            item: {},
            errors: {}
        };
        this.requestCancelToken = createCancelToken();
    }

    componentWillUnmount() {
        this.requestCancelToken.cancel();
    }

    handleExit = () => {
        if (this.props.handleExit) {
            this.props.handleExit();
        }
        this.setState({
            show: false,
            isProcessing: false,
            view: 'create',
            item: {},
            errors: {}
        });
    }

    handleOpenModal = (view, item) => {
        this.setState({
            show: true,
            view,
            item
        });
    };

    handleCreateClick = () => {
        const {onCreate, itemSingular} = this.props;
        let {item} = this.state;

        this.setState({isProcessing: true});
        postResource(item)
            .onSuccess(item => {
                onCreate(item);
                notifyItemRequestSuccess(
                    `Add ${itemSingular}`,
                    `${this.getItemSingularSentence()} successfully added.`,
                    item.name
                );
                this.setState({show: false});
            })
            .onError(err => {
                this.setState({isProcessing: false});
                if (err.isValidationError) {
                    this.setState({errors: err.errors})
                    notifyFormError(`Add ${itemSingular}`);
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    handleSaveClick = () => {
        const {onEdit, itemSingular} = this.props;
        const {item} = this.state;

        this.setState({isProcessing: true});
        patchResource(item)
            .onSuccess(() => {
                onEdit(item);
                notifyItemRequestSuccess(
                    `Edit ${itemSingular}`,
                    `${this.getItemSingularSentence()} successfully updated.`,
                    item.name
                );
                this.setState({show: false});
            })
            .onError(err => {
                this.setState({isProcessing: false});
                if (err.isValidationError) {
                    this.setState({errors: err.errors});
                    notifyFormError(`Edit ${itemSingular}`);
                } else {
                    throw err;
                }
            })
            .execute(this.requestCancelToken);
    };

    handleDeleteClick = () => {
        const {onDelete, itemSingular} = this.props;
        const {item} = this.state;

        this.setState({isProcessing: true});
        deleteResource(item)
            .onSuccess(() => {
                onDelete(item);
                notifyItemRequestSuccess(
                    `Delete ${itemSingular}`,
                    `${this.getItemSingularSentence()} successfully deleted`,
                    item.name
                );
                this.setState({show: false});
            })
            .onError(err => {
                notifyUnableToProcess(`Delete ${itemSingular}`);
                this.setState({show: false});
            })
            .execute(this.requestCancelToken);
    };

    handleCloseClick = () => {
        const {isProcessing} = this.state;
        if (!isProcessing) {
            this.setState({show: false});
        }
    };

    renderHeader = () => {
        const {view} = this.state;
        const {itemSingular} = this.props;

        switch (view) {
            case 'create':
                return `Add ${itemSingular}`;
            case 'edit':
                return `Edit ${itemSingular}`;
            case 'delete':
                return `Confirm Delete ${itemSingular}`;
            default:
                return '';
        }
    };

    renderBody = () => {
        const {view} = this.state;

        switch (view) {
            case 'create':
            case 'edit':
                return this.renderItemView();
            case 'delete':
                return this.renderConfirmDeleteView();
            default:
                return null;
        }
    };

    renderFooter = () => {
        const {view, isProcessing} = this.state;
        return (
            <ModalFooter view={view} isProcessing={isProcessing}
                         onCloseClick={this.handleCloseClick}
                         onCreateClick={this.handleCreateClick}
                         onSaveClick={this.handleSaveClick}
                         onDeleteClick={this.handleDeleteClick}/>
        )
    };

    notifyItemChange = (field, value) => {
        const item = this.props.onItemChangeNotify(field, value, this.state.item);
        this.setState({item});
    };

    renderItemView = () => {
        const {view, item, errors} = this.state;
        return this.props.renderItemView(view, item, errors, this.notifyItemChange);
    };

    renderConfirmDeleteView = () => {
        const {item} = this.state;
        return this.props.renderConfirmDeleteView(item);
    };

    getItemSingularSentence = () => {
        const {itemSingular} = this.props;
        return itemSingular.charAt(0).toUpperCase() + itemSingular.slice(1).toLowerCase();
    };

    render() {
        const {isProcessing, show} = this.state;

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick}
                   className="j-config-modal" isLoading={isProcessing}>
                <Modal.Header>{this.renderHeader()}</Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>{this.renderFooter()}</Modal.Footer>
            </Modal>
        );
    }
}

BasicResourceModal.propTypes = {
    handleExit: PropTypes.func,
    openModalRef: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    itemSingular: PropTypes.string.isRequired,
    renderItemView: PropTypes.func.isRequired,
    renderConfirmDeleteView: PropTypes.func.isRequired,
    onItemChangeNotify: PropTypes.func.isRequired
};

export default BasicResourceModal;