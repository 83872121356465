import React from 'react';
import NumberFormat from "react-number-format";
import {asFormComponent} from "@vallarj/react-adminlte/Form/asFormComponent";

const helpBlockStyle = {
    fontSize: '12px',
    padding: '2px 0 0 20px',
    marginBottom: 0
};

class NumericInput extends React.Component {
    handleChange = ({value}) => {
        this.props.onChange(this.props.name, value);
    };

    handleKeyUp = event => {
        if(event.key === 'Enter' && this.props.onEnterKey) {
            this.props.onEnterKey();
        }

        if(this.props.onKeyUp) {
            this.props.onKeyUp(event);
        }
    };

    render() {
        let {gridClass, feedbackIconClass, feedbackIconLeft} = this.props;
        if(feedbackIconClass) {
            gridClass += " has-feedback";
        }
        if(feedbackIconLeft) {
            gridClass += " has-feedback-left";
        }
        const value = this.props.value || "";

        // Check for form errors
        const errors = this.props.errors[this.props.name] || [];

        return (
            <div className={"form-group " + (errors.length > 0 ? "has-error " : "") + gridClass}>
                {
                    this.props.label &&
                    <label>{this.props.label}</label>
                }
                <NumberFormat {...this.props.numberFormatProps}
                              placeholder={this.props.placeholder} className="form-control"
                              value={value} onKeyUp={this.handleKeyUp} disabled={this.props.disabled}
                              onValueChange={this.handleChange}/>
                {
                    feedbackIconClass &&
                    <span className={feedbackIconClass + " form-control-feedback"}/>
                }
                {
                    errors.length > 0 &&
                    <ul className='help-block' style={helpBlockStyle}>
                        {errors.map((e, index) => <li key={index}><span>{e}</span></li>)}
                    </ul>
                }
            </div>
        );
    }
}

NumericInput.defaultProps = {
    disabled: false,
    errors: {},
    gridClass: "",
    feedbackIconLeft: false,
    numberFormatProps: {},
    onChange: () => {}
};

export default asFormComponent(NumericInput);