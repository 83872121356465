import {deleteRequest, getRequest, patchRequest, postRequest} from "@/utilities/axios";
import {Record} from 'immutable';
import {noop} from "@/utilities/noop";
import axios from "axios";
import transformFormErrors from "@/utilities/transform-form-errors";

export function createCancelToken() {
    return axios.CancelToken.source();
}

export function waitForAllRequests(requests) {
    let successHandler = noop;
    let errorHandler = function(err) {
        throw err;
    }

    return {
        onSuccess(handler) {
            successHandler = handler;
            return this;
        },

        onError(handler) {
            errorHandler = handler;
            return this;
        },

        execute(cancelToken) {
            Promise.all(requests.map(r => new Promise((resolve, reject) => {
                r.onSuccess((value, document) => resolve([value, document]))
                    .onError(err => reject(err))
                    .onCancel(err => reject(err))
                    .execute(cancelToken);
            }))).then((returns) => {
                const values = [];
                const raws = [];
                returns.forEach(([v, r]) => {
                    values.push(v);
                    raws.push(r);
                });
                successHandler(values, raws);
            }).catch(err => {
                if (!axios.isCancel(err)) {
                    errorHandler(err);
                }
            });
        }
    }
}

export function fetchSingleResource(prototype, id, params = {}, altEndpoint) {
    // TODO: Do prototype checks here. Halt get request if prototype is not a Record or array of Records
    const endpoint = altEndpoint || `${prototype.getEndpoint().replace(/\/+$/, "")}/${id}`;

    let successHandler = noop;
    let errorHandler = function(err) {
        throw err;
    };
    let cancelHandler = noop;

    return {
        onSuccess(handler) {
            successHandler = handler;
            return this;
        },

        onError(handler) {
            errorHandler = handler;
            return this;
        },

        onCancel(handler) {
            cancelHandler = handler;
            return this;
        },

        execute(cancelToken) {
            const axiosConfig = {params};
            if (cancelToken) {
                axiosConfig["cancelToken"] = cancelToken.token
            }

            getRequest(endpoint, axiosConfig)
                .then(({data: document}) => {
                    const {data, included} = document;

                    if (prototype.prototype instanceof Record) {
                        successHandler(new prototype(data, included), document);
                        return;
                    } else if (prototype.constructor === Array) {
                        const {type} = data;
                        for (let i = 0; i < prototype.length; i++) {
                            if (type === prototype[i].getType()) {
                                successHandler(new prototype[i](data, included), document);
                                return;
                            }
                        }
                    }

                    successHandler(null, document);
                }).catch(err => {
                    if (!axios.isCancel(err)) {
                        errorHandler(err);
                    } else {
                        cancelHandler(err);
                    }
                });
        }
    };
}

export function fetchResourceCollection(prototype, params = {}, altEndpoint) {
    return baseFetchResourceCollection(prototype, () => true, params, altEndpoint);
}

export function fetchAndFilterResourceCollection(prototype, filter, params = {}, altEndpoint) {
    return baseFetchResourceCollection(prototype, filter, params, altEndpoint);
}

function baseFetchResourceCollection(prototype, filter, params = {}, altEndpoint) {
    // TODO: Do prototype checks here. Halt get request if prototype is not a Record or array of Records
    const endpoint = altEndpoint || prototype.getEndpoint().replace(/\/+$/, "");

    let successHandler = noop;
    let errorHandler = function(err) {
        throw err;
    };
    let cancelHandler = noop;

    return {
        onSuccess(handler) {
            successHandler = handler;
            return this;
        },

        onError(handler) {
            errorHandler = handler;
            return this;
        },

        onCancel(handler) {
            cancelHandler = handler;
            return this;
        },

        execute(cancelToken) {
            const axiosConfig = {params};
            if (cancelToken) {
                axiosConfig["cancelToken"] = cancelToken.token
            }
            getRequest(endpoint, axiosConfig)
                .then(({data: document}) => {
                    const {data, included} = document;

                    if (prototype.prototype instanceof Record) {
                        prototype = [prototype];
                    }

                    let collection = [];
                    data.forEach(item => {
                        if (filter(item)) {
                            const {type} = item;
                            for (let i = 0; i < prototype.length; i++) {
                                if (type === prototype[i].getType()) {
                                    const includedWithData = included ? [
                                        ...included,
                                        ...data
                                    ] : data;
                                    collection.push(new prototype[i](item, includedWithData));
                                }
                            }
                        }
                    });

                    successHandler(collection, document);
                }).catch(err => {
                if (!axios.isCancel(err)) {
                    errorHandler(err);
                } else {
                    cancelHandler(err);
                }
            });
        }
    };
}

export function postResource(resource, includeId = false, params, altEndpoint) {
    // TODO: Do prototype checks here. Halt get request if prototype is not a Record or array of Records
    const prototype = resource.constructor;
    const endpoint = altEndpoint || `${prototype.getEndpoint().replace(/\/+$/, "")}`;
    const request = resource.toRequestObject(includeId);

    let successHandler = noop;
    let errorHandler = function(err) {
        throw err;
    };
    let cancelHandler = noop;
    let validationErrorTransformer = transformFormErrors;

    return {
        onSuccess(handler) {
            successHandler = handler;
            return this;
        },

        onError(handler) {
            errorHandler = handler;
            return this;
        },

        onCancel(handler) {
            cancelHandler = handler;
            return this;
        },

        setValidationErrorTransformer(transformer) {
            validationErrorTransformer = transformer;
            return this
        },

        execute(cancelToken) {
            const axiosConfig = {params};
            if (cancelToken) {
                axiosConfig["cancelToken"] = cancelToken.token
            }

            postRequest(endpoint, request)
                .then(({data: document}) => {
                    const {data, included} = document;
                    const item = new prototype(data, included);
                    successHandler(item, document);
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelHandler(err);
                    } else {
                        const response = err.response || {};
                        if (response.status === 422 && response.hasOwnProperty('data')) {
                            errorHandler({
                                baseError: err,
                                isValidationError: true,
                                errors: validationErrorTransformer(response.data.errors)
                            });
                        } else {
                            errorHandler(err);
                        }
                    }
                });
        }
    };
}

export function patchResource(resource, params, altEndpoint) {
    // TODO: Do prototype checks here. Halt get request if prototype is not a Record or array of Records
    const prototype = resource.constructor;
    const {id} = resource;
    const endpoint = altEndpoint || `${prototype.getEndpoint().replace(/\/+$/, "")}/${id}`;
    const request = resource.toRequestObject(true);

    let successHandler = noop;
    let errorHandler = function(err) {
        throw err;
    };
    let cancelHandler = noop;
    let validationErrorTransformer = transformFormErrors;

    return {
        onSuccess(handler) {
            successHandler = handler;
            return this;
        },

        onError(handler) {
            errorHandler = handler;
            return this;
        },

        onCancel(handler) {
            cancelHandler = handler;
            return this;
        },

        setValidationErrorTransformer(transformer) {
            validationErrorTransformer = transformer;
            return this
        },

        execute(cancelToken) {
            const axiosConfig = {params};
            if (cancelToken) {
                axiosConfig["cancelToken"] = cancelToken.token
            }

            patchRequest(endpoint, request)
                .then(data => {
                    successHandler((data || {}).data);
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelHandler(err);
                    } else {
                        const response = err.response || {};
                        if (response.status === 422 && response.hasOwnProperty('data')) {
                            errorHandler({
                                baseError: err,
                                isValidationError: true,
                                errors: validationErrorTransformer(response.data.errors)
                            });
                        } else {
                            errorHandler(err);
                        }
                    }
                });
        }
    };
}

export function deleteResource(resource, params, altEndpoint) {
    // TODO: Do prototype checks here. Halt get request if prototype is not a Record or array of Records
    const prototype = resource.constructor;
    const {id} = resource;
    const endpoint = altEndpoint || `${prototype.getEndpoint().replace(/\/+$/, "")}/${id}`;

    let successHandler = noop;
    let errorHandler = function(err) {
        throw err;
    };
    let cancelHandler = noop;

    return {
        onSuccess(handler) {
            successHandler = handler;
            return this;
        },

        onError(handler) {
            errorHandler = handler;
            return this;
        },

        onCancel(handler) {
            cancelHandler = handler;
            return this;
        },

        execute(cancelToken) {
            const axiosConfig = {params};
            if (cancelToken) {
                axiosConfig["cancelToken"] = cancelToken.token
            }

            deleteRequest(endpoint)
                .then(data => {
                    successHandler((data || {}).data)
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelHandler(err);
                    } else {
                        errorHandler(err);
                    }
                });
        }
    };
}