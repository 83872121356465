import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {Office} from "@/records/Office";
import {config} from "@/config";

const attributes = {
    reportDate: PropTypes.datetime,
    indicators: PropTypes.object,
    finalized: PropTypes.bool,
};

const toOneRelationships = {
    office: () => Office
};

const toManyRelationships = {};
const meta = {
    isComplete: PropTypes.bool
};

export class DiseaseMonitoringReport extends JsonApiRecord(
    'disease-monitoring-reports',
    `${config.apiBaseUrl}/disease-monitoring-reports`,
    attributes,
    toOneRelationships,
    toManyRelationships,
    meta
) {
    static get modelName() {
        return 'DiseaseMonitoringReport';
    }
}