import React from 'react';
import PropTypes from 'prop-types';
import Form from '@vallarj/react-adminlte/Form';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import SelectInput from '@vallarj/react-adminlte/Form/SelectInput';
import BasicResourceModal from "@/components/BasicResourceModal";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import NumericInput from "@/components/NumericInput/NumericInput";

class OfficeModal extends React.Component {
    constructor(props) {
        super(props);

        const officeTypeOptions = Object.keys(props.officeTypes || {}).filter(k =>
            k !== OFFICE_TYPE_HQ
        ).map(k => ({
            label: props.officeTypes[k],
            value: k
        }));
        this.state = {
            officeTypeOptions
        };
    }

    handleChangeItemNotify = (field, value, item) => {
        const {headquarters} = this.props;

        switch (field) {
            case 'orderIndex':
                if (value === '') {
                    value = null;
                } else {
                    value = parseInt(value, 10);
                }
                break;
            case 'officeType':
                item = item.update('parent', value === OFFICE_TYPE_REGIONAL ? headquarters : null);
                break;
        }

        return item.update(field, value);
    };

    getLabelByName = option => option.name;
    getValueById = option => option.id;

    renderItemView = (view, item, errors, notifyItemChange) => {
        const {officeTypeOptions} = this.state;
        const {regions} = this.props;

        return (
            <Form onChange={notifyItemChange} errors={errors}>
                <TextInput label="Office" name="name" value={item.name} maxLength={255}/>
                <NumericInput label="Order Index" name="orderIndex" value={item.orderIndex}/>
                <SelectInput label="Type" name="officeType" value={item.officeType}
                             options={officeTypeOptions} simpleValue disabled={view === 'edit'}/>
                {
                    item.officeType === OFFICE_TYPE_JAIL_LEVEL &&
                    <SelectInput name='parent' value={item.parent} label="Region"
                                 options={regions} getOptionValue={this.getValueById}
                                 getOptionLabel={this.getLabelByName} disabled={view === 'edit'}/>
                }
            </Form>
        );
    };

    renderConfirmDeleteView = item => {
        const {officeTypes} = this.props;

        return (
            <div className="j-config-modal-confirm-delete">
                <p>Are you sure you want to delete this office?{" "}
                    <strong>This action cannot be undone.</strong>
                </p>
                <table>
                    <tbody>
                    <tr>
                        <td>Office</td>
                        <td>{item.name}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>{officeTypes[item.officeType]}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        return (
            <BasicResourceModal openModalRef={this.props.openModalRef}
                                onCreate={this.props.onSuccess}
                                onEdit={this.props.onSuccess}
                                onDelete={this.props.onSuccess}
                                itemSingular="Office"
                                renderConfirmDeleteView={this.renderConfirmDeleteView}
                                renderItemView={this.renderItemView}
                                onItemChangeNotify={this.handleChangeItemNotify}
                                />
        );
    }
}

OfficeModal.propTypes = {
    openModalRef: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default OfficeModal;