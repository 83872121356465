import React from "react";
import PropTypes from 'prop-types';
import {asFormComponent} from "@vallarj/react-adminlte/Form/asFormComponent";
import {noop} from "@/utilities/noop";

const helpBlockStyle = {
    fontSize: '12px',
    padding: '2px 0 0 20px',
    marginBottom: 0
};

class FileInput extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    handleInputChange = event => {
        const {onChange} = this.props;
        const {files} = event.target;
        if (files.length > 0) {
            onChange(files[0]);
            this.setState({value: files[0]});
            event.target.value = null;
        }
    };

    resolveValueLabel = () => {
        const {value} = this.props;
        if (!value) {
            return "No file selected"
        }
        return value ? (value.name || "Unknown file.") : "No file selected";
    };

    handleClick = () => {
        if (this.inputRef.current) {
            this.inputRef.current.click();
        }
    }

    render() {
        const {gridClass, label, className} = this.props;
        const value = this.resolveValueLabel();

        const errors = this.props.errors[this.props.name] || [];

        return (
            <div className={"form-group file-input " + (errors.length > 0 ? "has-error " : "") + gridClass}>
                {label && <label>{label}</label>}
                <input type="file" onChange={this.handleInputChange} ref={this.inputRef}/>
                <div>
                    <button className={className} onClick={this.handleClick}>
                        Select file...
                    </button>
                    <input type="text" value={value} className="form-control" readOnly
                           onClick={this.handleClick}/>
                </div>
                {
                    errors.length > 0 &&
                    <ul className='help-block' style={helpBlockStyle}>
                        {errors.map((e, index) => <li key={index}><span>{e}</span></li>)}
                    </ul>
                }
            </div>
        );
    }
}

FileInput.defaultProps = {
    onChange: noop,
    errors: {},
    className: "btn btn-default",
    buttonLabel: "Select file..."
};

FileInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    onChange: PropTypes.func,
    gridClass: PropTypes.string,
    className: PropTypes.string,
    buttonLabel: PropTypes.string,
    label: PropTypes.node
};

export default asFormComponent(FileInput);