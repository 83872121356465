import React from 'react';
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_JAIL_POPULATION = "default_2";
const TAG_PWD = "default_8";
const TAG_SENIOR = "default_9";
const TAG_PREGNANT = "default_10";

class PopulationGroups extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            females: 0,
            pFemales: 0,
            males: 0,
            pMales: 0,
            pwd: 0,
            pPwd: 0,
            senior: 0,
            pSenior: 0,
            pregnant: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const jailPop = getAggregateByTag(TAG_JAIL_POPULATION);
        const pwd = getAggregateByTag(TAG_PWD);
        const senior = getAggregateByTag(TAG_SENIOR);
        const pregnant = getAggregateByTag(TAG_PREGNANT);

        this.setState({
            males: jailPop.reportMale,
            pMales: pctTotalOver(jailPop.reportMale, jailPop.reportTotal),
            females: jailPop.reportFemale,
            pFemales: pctTotalOver(jailPop.reportFemale, jailPop.reportTotal),
            pwd: pwd.reportTotal,
            pPwd: pctTotalOver(pwd.reportTotal, jailPop.reportTotal),
            senior: senior.reportTotal,
            pSenior: pctTotalOver(senior.reportTotal, jailPop.reportTotal),
            pregnant: pregnant.reportTotal,
            pPregnant: pctTotalOver(pregnant.reportTotal, jailPop.reportTotal)
        });
    };

    render() {
        const {
            females, pFemales, males, pMales,
            pwd, pPwd, senior, pSenior,
            pregnant, pPregnant
        } = this.state;

        return (
            <div className="j-dash-tabular-group j-dash-who-table">
                <table>
                    <thead>
                    <tr>
                        <th>Group</th>
                        <th>Count</th>
                        <th>%</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Females</td>
                        <td>{females}</td>
                        <td>{pFemales}</td>
                    </tr>
                    <tr>
                        <td>Males</td>
                        <td>{males}</td>
                        <td>{pMales}</td>
                    </tr>
                    <tr>
                        <td>PWD</td>
                        <td>{pwd}</td>
                        <td>{pPwd}</td>
                    </tr>
                    <tr>
                        <td>Senior</td>
                        <td>{senior}</td>
                        <td>{pSenior}</td>
                    </tr>
                    <tr>
                        <td>Pregnant</td>
                        <td>{pregnant}</td>
                        <td>{pPregnant}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withAggregateTagMap(PopulationGroups);