import React from "react";
import InfoBox from "@/components/InfoBox";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_JAIL_POP = 'default_2';
const TAG_CVDS_BEFORE = 'default_77';
const TAG_CVDS_DURING = 'default_78';
const TAG_DMS_BEFORE = 'default_81';
const TAG_DMS_DURING = 'default_82';
const TAG_CA_BEFORE = 'default_85';
const TAG_CA_DURING = 'default_86';
const TAG_CRD_BEFORE = 'default_88';
const TAG_CRD_DURING = 'default_89';

class NCDMetrics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cvdsBefore: 0,
            cvdsDuring: 0,
            dmsBefore: 0,
            dmsDuring: 0,
            caBefore: 0,
            caDuring: 0,
            crdBefore: 0,
            crdDuring: 0,
            total: 0,
            cvdsTotal: 0,
            dmsTotal: 0,
            caTotal: 0,
            crdTotal: 0,
            proportionNCDs: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const jailPop = getAggregateByTag(TAG_JAIL_POP);
        const cvdsBefore = getAggregateByTag(TAG_CVDS_BEFORE);
        const cvdsDuring = getAggregateByTag(TAG_CVDS_DURING);
        const dmsBefore = getAggregateByTag(TAG_DMS_BEFORE);
        const dmsDuring = getAggregateByTag(TAG_DMS_DURING);
        const caBefore = getAggregateByTag(TAG_CA_BEFORE);
        const caDuring = getAggregateByTag(TAG_CA_DURING);
        const crdBefore = getAggregateByTag(TAG_CRD_BEFORE);
        const crdDuring = getAggregateByTag(TAG_CRD_DURING);

        const cvdsTotal = cvdsBefore.reportTotal + cvdsDuring.reportTotal;
        const dmsTotal = dmsBefore.reportTotal + dmsDuring.reportTotal;
        const caTotal = caBefore.reportTotal + caDuring.reportTotal;
        const crdTotal = crdBefore.reportTotal + crdDuring.reportTotal;
        const total = cvdsTotal + dmsTotal + caTotal + crdTotal;

        this.setState({
            cvdsBefore: cvdsBefore.reportTotal,
            cvdsDuring: cvdsDuring.reportTotal,
            dmsBefore: dmsBefore.reportTotal,
            dmsDuring: dmsDuring.reportTotal,
            caBefore: caBefore.reportTotal,
            caDuring: caDuring.reportTotal,
            crdBefore: crdBefore.reportTotal,
            crdDuring: crdDuring.reportTotal,
            total,
            cvdsTotal,
            dmsTotal,
            caTotal,
            crdTotal,
            proportionNCDs: pctTotalOver(total, jailPop.reportTotal)
        });
    };

    render() {
        const {
            cvdsBefore, cvdsDuring, dmsBefore, dmsDuring, total,
            caBefore, caDuring, crdBefore, crdDuring, proportionNCDs,
            cvdsTotal, dmsTotal, caTotal, crdTotal
        } = this.state;

        return (
            <div>
                <InfoBox className="simple-info-box"
                         title="Proportion with NCDs" value={total}
                         progressDescription={`${proportionNCDs}% of the population`}
                         progress={proportionNCDs} showProgress/>
                <div className="j-dash-tabular-group">
                    <h4>Distribution of Tested Detainees</h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Disease</th>
                            <th>Diagnosed Before Commit</th>
                            <th>Diagnosed During</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>CVDs</td>
                            <td>{cvdsBefore}</td>
                            <td>{cvdsDuring}</td>
                            <td>{cvdsTotal}</td>
                        </tr>
                        <tr>
                            <td>DMs</td>
                            <td>{dmsBefore}</td>
                            <td>{dmsDuring}</td>
                            <td>{dmsTotal}</td>
                        </tr>
                        <tr>
                            <td>Ca</td>
                            <td>{caBefore}</td>
                            <td>{caDuring}</td>
                            <td>{caTotal}</td>
                        </tr>
                        <tr>
                            <td>CRDs</td>
                            <td>{crdBefore}</td>
                            <td>{crdDuring}</td>
                            <td>{crdTotal}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(NCDMetrics);