import React from 'react';
import Tag from "@/screens/AccessManagement/Users/Tag";
import ValueButton from "@vallarj/react-adminlte/ValueButton";
import {permissionOrderComparator, roleOrderComparator} from "@/utilities/permissions";
import {escapeRegExp, highlightString} from "@/utilities/highlight-string";
import {Can} from "@/ability";

const ITEM_LIMIT = 4;

class UserIndexItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAllPermissions: false,
            showAllRoles: false
        };
    }

    handleToggleShowAllPermissionsClick = () => {
        this.setState({showAllPermissions: !this.state.showAllPermissions});
    };

    handleToggleShowRolesClick = () => {
        this.setState({showAllRoles: !this.state.showAllRoles});
    };

    renderName = () => {
        const {user} = this.props;
        let {search} = this.props;

        search = (search && search.trim()) || "";
        if (!search) {
            return `${user.lastName.toUpperCase()}, ${user.firstName} ${(user.middleName ? `${user.middleName} ` : "")}`;
        }

        // Split the search string
        const tokens = search.split(/\s+/);

        const lastName = this.highlightFromTokens(user.lastName.toUpperCase(), tokens);
        const firstName = this.highlightFromTokens(user.firstName, tokens);
        const middleName = user.middleName ? this.highlightFromTokens(user.middleName, tokens) : "";

        return <>{lastName}, {firstName} {middleName}</>;
    };

    renderUsername = () => {
        const {user} = this.props;
        let {search} = this.props;

        return this.highlightFromTokens(user.username, search.split(/\s+/));
    };

    highlightFromTokens(value, tokens) {
        let longest = "";
        tokens.forEach(token => {
            if (
                value.match(RegExp(escapeRegExp(token), "i"))
                && token.length > longest.length
            ) {
                longest = token;
            }
        });

        return longest ? highlightString(longest, value) : value;
    }

    render() {
        const {showAllPermissions, showAllRoles} = this.state;
        const {user, roles, permissions} = this.props;

        let effectivePermissions = {};
        user.roles.forEach(r => {
            roles[r.id].permissions.forEach(p => {
                effectivePermissions = {...effectivePermissions, [p.id]: permissions[p.id]}
            })
        });

        let orderedPermissions = Object.keys(effectivePermissions).sort(permissionOrderComparator);

        const excessPermissions = orderedPermissions.length - ITEM_LIMIT;
        if (!showAllPermissions) {
            orderedPermissions = orderedPermissions.slice(0, ITEM_LIMIT);
        }

        let orderedRoles = [...user.roles]
            .sort((a, b) => roleOrderComparator(a.id, b.id))
            .map(r => roles[r.id]);

        const excessRoles = orderedRoles.length - ITEM_LIMIT;
        if (!showAllRoles) {
            orderedRoles = orderedRoles.slice(0, ITEM_LIMIT);
        }

        return (
            <div className="j-users-item">
                <div>
                    <span>{this.renderName()}</span>
                    <span>{this.renderUsername()}</span>
                    <span>{user.office.name}</span>
                    <span><Tag active={user.active}/></span>
                </div>
                <div>
                    <ul>
                        {
                            orderedRoles.length ? orderedRoles.map(r => (
                                <li key={r.id}>{r.name}</li>
                            )) : <li>No Roles</li>
                        }
                    </ul>
                    {
                        !showAllRoles ?
                            (
                                (excessRoles > 0) &&
                                <span>
                                    <span>+ {excessRoles} more...</span>
                                    <button onClick={this.handleToggleShowRolesClick}
                                            className="btn btn-link btn-xs">Show All</button>
                                </span>
                            ) :
                            <span>
                                <button onClick={this.handleToggleShowRolesClick}
                                        className="btn btn-link btn-xs">
                                    <i className="fa fa-minus margin-r-5"/>Show Less
                                </button>
                            </span>
                    }
                </div>
                <div>
                    <ul>
                        {
                            orderedPermissions.length ? orderedPermissions.map(e => {
                                const permission = effectivePermissions[e];
                                return <li key={e}>{permission.description}</li>;
                            }) : <li>No Permissions</li>
                        }
                    </ul>
                    {
                        !showAllPermissions ?
                            (
                                (excessPermissions > 0) &&
                                <span>
                                    <span>+ {excessPermissions} more...</span>
                                    <button onClick={this.handleToggleShowAllPermissionsClick}
                                            className="btn btn-link btn-xs">Show All</button>
                                </span>
                            ) :
                            <span>
                                <button onClick={this.handleToggleShowAllPermissionsClick}
                                        className="btn btn-link btn-xs">
                                    <i className="fa fa-minus margin-r-5"/>Show Less
                                </button>
                            </span>
                    }
                </div>
                <div>
                    <span className="btn-group pull-right">
                        <Can I="update" this={user}>
                            <ValueButton className="btn btn-default btn-xs" value={user}
                                         onClick={this.props.onEditClick}>
                                <i className="fa fa-pencil margin-r-5"/>Edit
                            </ValueButton>
                        </Can>
                    </span>
                </div>
            </div>
        );
    }
}

export default UserIndexItem;