import React from "react";
import PropTypes from 'prop-types';
import {createCancelToken} from "@/utilities/jsonapi";
import {processWorksheet} from "@/utilities/indicator-report";
import BaseIndicatorReport from "@/components/IndicatorReport";
import {DiseaseMonitoringIndicatorCategory} from "@/records/DiseaseMonitoringIndicatorCategory";
import {DiseaseMonitoringIndicatorSubcategory} from "@/records/DiseaseMonitoringIndicatorSubcategory";
import {DiseaseMonitoringIndicator} from "@/records/DiseaseMonitoringIndicator";

class IndicatorReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            structure: [],
            currentInput: 0,
            indicatorMap: {}
        };

        this.inputCount = 0;
        this.fetchCancelToken = createCancelToken();
    }

    render() {
        const {
            indicators,
            onIndicatorChange, onIndicatorsReplace,
            registerOpenModal,
            isEditMode, isLoading
        } = this.props;

        return (
            <BaseIndicatorReport
                title="Disease Surveillance Indicators"
                registerOpenModal={registerOpenModal}
                categoryResourceType={DiseaseMonitoringIndicatorCategory}
                subcategoryResourceType={DiseaseMonitoringIndicatorSubcategory}
                indicatorResourceType={DiseaseMonitoringIndicator}
                hasDataSource={false}
                worksheetProcessor={processWorksheet}
                indicators={indicators}
                onIndicatorsReplace={onIndicatorsReplace}
                onIndicatorChange={onIndicatorChange}
                isLoading={isLoading}
                isEditMode={isEditMode}
                showFooter={false}
                worksheetIndex={0}
            />
        );
    }
}

IndicatorReport.propTypes = {
    indicators: PropTypes.object.isRequired,
    onIndicatorChange: PropTypes.func.isRequired,
    onIndicatorsReplace: PropTypes.func.isRequired,
    registerOpenModal: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default IndicatorReport;