import React from 'react';
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import Box from "@vallarj/react-adminlte/Box";
import TopEntryMorbidities from "@/screens/Dashboard/RiskFactors/TopEntryMorbidities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_JAIL_POP = 'default_2';
const TAG_TOBACCO = 'default_28';
const TAG_OBESE = 'default_30';
const TAG_RAISED_BP = 'default_33';
const TAG_MODERATE = 'default_31';
const TAG_SEVERE = 'default_32'
const TAG_HX_INJECT = 'default_34';
const TAG_HX_SUBSTANCE = 'default_35';

class RiskFactors extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            historyTobacco: 0,
            obese: 0,
            raisedBP: 0,
            malnourished: 0,
            hxInjecting: 0,
            hxSubstance: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;
        const jailPop = getAggregateByTag(TAG_JAIL_POP);
        const historyTobacco = getAggregateByTag(TAG_TOBACCO);
        const obese = getAggregateByTag(TAG_OBESE);
        const bp = getAggregateByTag(TAG_RAISED_BP);
        const moderate = getAggregateByTag(TAG_MODERATE);
        const severe = getAggregateByTag(TAG_SEVERE);
        const hxInjecting = getAggregateByTag(TAG_HX_INJECT);
        const hxSubstance = getAggregateByTag(TAG_HX_SUBSTANCE);

        this.setState({
            historyTobacco: pctTotalOver(historyTobacco.reportTotal, jailPop.reportTotal),
            obese: pctTotalOver(obese.reportTotal, jailPop.reportTotal),
            raisedBP: pctTotalOver(bp.reportTotal, jailPop.reportTotal),
            malnourished: pctTotalOver(moderate.reportTotal + severe.reportTotal, jailPop.reportTotal),
            hxInjecting: pctTotalOver(hxInjecting.reportTotal, jailPop.reportTotal),
            hxSubstance: pctTotalOver(hxSubstance.reportTotal, jailPop.reportTotal)
        });
    };

    render() {
        const {
            historyTobacco, obese, raisedBP,
            malnourished, hxInjecting, hxSubstance
        } = this.state;
        const {fromDate, toDate, office, isLoading} = this.props;

        return (
            <Box theme="box-primary" isLoading={isLoading} collapsible>
                <Box.Header title="Risk Factors for Ill-Health"/>
                <Box.Body>
                    <div className="j-dash-tabular-group single-row">
                        <table>
                            <thead>
                            <tr>
                                <th>With History of Tobacco Use</th>
                                <th>Obese</th>
                                <th>Raised BP</th>
                                <th>Malnourished</th>
                                <th>History Injecting Drug Use</th>
                                <th>History Substance Abuse</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{historyTobacco}%</td>
                                <td>{obese}%</td>
                                <td>{raisedBP}%</td>
                                <td>{malnourished}%</td>
                                <td>{hxInjecting}%</td>
                                <td>{hxSubstance}%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                <TopEntryMorbidities office={office} fromDate={fromDate} toDate={toDate}/>
                </Box.Body>
            </Box>
        );
    }
}

export default withAggregateTagMap(RiskFactors);