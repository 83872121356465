import Configuration from "@/screens/Configuration";
import AccessManagement from "@/screens/AccessManagement";
import Dashboard from "@/screens/Dashboard";
import JailLevelReports from "@/screens/JailLevelReports";
import DiseaseSurveillanceReports from "@/screens/DiseaseSurveillanceReports";
import DiseaseSurveillanceDashboard from "@/screens/DiseaseSurveillanceDashboard";
import OverallDashboard from "@/screens/Docs/OverallDashboard";
import MonthlyReports from "@/screens/Docs/MonthlyReports";
import HelpDiseaseSurveillanceDashboard from "@/screens/Docs/DiseaseSurveillanceDashboard";
import HelpDiseaseSurveillanceReports from "@/screens/Docs/DiseaseSurveillanceReports";
import HelpConfiguration from "@/screens/Docs/Configuration";
import HelpAccessManagement from "@/screens/Docs/AccessManagement";

export const routes = ability => [
    {
        type: 'item',
        iconClass: 'fa fa-dashboard',
        label: 'Overall Dashboard',
        component: Dashboard,
        path: '/dashboard',
        enabled: true
    },
    {
        type: 'item',
        iconClass: 'fa fa-file-text',
        label: 'Monthly Reports',
        component: JailLevelReports,
        path: '/jail-level-reports',
        enabled: ability.can('view', 'JailLevelReport')
    },
    {
        type: 'item',
        iconClass: 'fa fa-pie-chart',
        label: 'Disease Surveillance Dashboard',
        component: DiseaseSurveillanceDashboard,
        path: '/disease-surveillance-dashboard',
        enabled: true
    },
    {
        type: 'item',
        iconClass: 'fa fa-book',
        label: 'Disease Surveillance Reports',
        component: DiseaseSurveillanceReports,
        path: '/disease-surveillance-reports',
        enabled: ability.can('view', 'JailLevelReport')
    },
    {
        type: 'item',
        iconClass: 'fa fa-gears',
        label: 'Configuration',
        component: Configuration,
        path: '/config',
        enabled: ability.can('manage', 'Configuration')
    },
    {
        type: 'item',
        iconClass: 'fa fa-users',
        label: 'Access Management',
        component: AccessManagement,
        path: '/access-management',
        enabled: ability.can('view', 'AccessManagement')
    },
    {
        type: 'tree',
        id: 'help',
        iconClass: 'fa fa-question-circle',
        label: 'Help',
        path: '/help',
        items: [{
            type: 'item',
            label: 'Overall Dashboard',
            path: '/help/overall-dashboard',
            component: OverallDashboard
        }, {
            type: 'item',
            label: 'Monthly Reports',
            path: '/help/monthly-reports',
            component: MonthlyReports
        }, {
            type: 'item',
            label: 'Disease Surveillance Dashboard',
            path: '/help/disease-surveillance-dashboard',
            component: HelpDiseaseSurveillanceDashboard
        }, {
            type: 'item',
            label: 'Disease Surveillance Reports',
            path: '/help/disease-surveillance-reports',
            component: HelpDiseaseSurveillanceReports
        }, {
            type: 'item',
            label: 'Configuration',
            path: '/help/configuration',
            component: HelpConfiguration
        }, {
            type: 'item',
            label: 'Access Management',
            path: '/help/access-management',
            component: HelpAccessManagement
        }],
        enabled: true
    }
];