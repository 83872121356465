import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

import Form, {CalendarInput, SelectInput, TextInput} from "@vallarj/react-adminlte/Form";
import NumericInput from "@/components/NumericInput";
import {Modal} from "@vallarj/react-adminlte";
import {noop} from "@/utilities/noop";
import {notifyFormError, notifySuccess} from "@/utilities/notifications";
import moment from "moment";
import {resolveCurrentSystemTime} from "@/actions/globalActions";

class MortalityRegistryModal extends React.Component {
    constructor(props) {
        super(props);
        
        if (props.registerOpenModal) {
            props.registerOpenModal(this.handleOpenModal);
        }
        
        this.state = {
            show: false,
            view: 'create',
            errors: {},
            item: {},
            itemId: null,
            currentDate: null
        };

        this.sexOptions = [
            {label: "Male", value: "male"},
            {label: "Female", value: "female"}
        ];
    }

    componentDidMount() {
        const currentDate = resolveCurrentSystemTime();
        this.setState({
            currentDate
        });
    }

    handleExit = () => {
        this.setState({
            show: false,
            view: 'create',
            errors: {},
            item: {},
            itemId: null
        })
    };
    
    handleOpenModal = (view, itemId, item) => {
        if (item.hasOwnProperty('dateOfDeath')) {
            item = {
                ...item,
                dateOfDeath: moment(item.dateOfDeath, 'YYYY-MM-DD')
            };
        }

        this.setState({
            show: true,
            view,
            itemId,
            item
        })
    };
    
    handleAddClick = () => {
        const errors = this.validateItem();
        const {item} = this.state;

        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            // Rewrite dateOfDeath
            const newItem = {
                ...item,
                dateOfDeath: item['dateOfDeath'].format('YYYY-MM-DD')
            }
            this.props.onAddItem(uuidv4(), newItem);
            this.setState({show: false});
            notifySuccess("Entry successfully added", item.name)
        } else {
            notifyFormError("Add Entry");
        }
    };
    
    handleUpdateClick = () => {
        const errors = this.validateItem();
        const {item, itemId} = this.state;

        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            // Rewrite dateOfDeath
            const newItem = {
                ...item,
                dateOfDeath: item['dateOfDeath'].format('YYYY-MM-DD')
            }
            this.props.onEditItem(itemId, newItem);
            this.setState({show: false});
            notifySuccess("Entry successfully updated", item.name)
        } else {
            notifyFormError("Update Entry");
        }
    };
    
    handleDeleteClick = () => {
        const {itemId, item} = this.state;
        this.props.onDeleteItem(itemId, item);
        this.setState({
            show: false
        });
        notifySuccess("Entry successfully deleted", item.name);
    };
    
    handleCloseClick = () => {
        this.setState({show: false});
    };
    
    renderHeader = () => {
        const {view} = this.state;
        
        switch (view) {
            case 'create':
                return "Add Entry";
            case 'edit':
                return "Edit Entry";
            case 'delete':
                return "Confirm Delete Entry";
            default:
                return "";
        }
    };
    
    renderBody = () => {
        const {view} = this.state;
        
        switch (view) {
            case 'create':
            case 'edit':
                return this.renderItemView();
            case 'delete':
                return this.renderConfirmDeleteView();
            default:
                return null;
        }
    };
    
    renderFooter = () => {
        const {view} = this.state;
        
        switch (view) {
            case 'create':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={this.handleCloseClick}>
                            Cancel
                        </button>
                        <button className="btn btn-primary pull-right" onClick={this.handleAddClick}>
                            <i className="fa fa-plus-circle margin-r-5"/>Add
                        </button>
                    </>
                );
            case 'edit':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={this.handleCloseClick}>
                            Cancel
                        </button>
                        <button className="btn btn-primary pull-right" onClick={this.handleUpdateClick}>
                            <i className="fa fa-save margin-r-5"/>Update
                        </button>
                    </>
                );
            case 'delete':
                return (
                    <>
                        <button className="btn btn-default pull-left" onClick={this.handleCloseClick}>
                            Cancel
                        </button>
                        <button className="btn btn-danger pull-right" onClick={this.handleDeleteClick}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </>
                );
            default:
                return null;
        }
    };
    
    handleFormChange = (field, value) => {
        const {item} = this.state;
        if (
            value == null
            || (typeof value === "string" && value.trim() === "")
        ) {
            const {[field]: omit, ...rest} = item;
            this.setState({
                item: rest
            });
        } else {
            if (field === 'age') {
                value = parseInt(value, 10);
                if (value > 200) {
                    value = 200;
                }
            }
            this.setState({
                item: {...item, [field]: value}
            });
        }
    };

    validateItem = () => {
        const {item} = this.state;
        const errors = {};

        // Required fields
        const requiredFields = [
            'name',
            'age',
            'sex',
            'immediateCause',
            'dateOfDeath',
            'criminalOffense'
        ];

        requiredFields.forEach(field => {
            if (!item.hasOwnProperty(field)) {
                if (!errors.hasOwnProperty(field)) {
                    errors[field] = [];
                }

                errors[field].push('Field is required');
            }
        });

        return errors;
    };

    deathDateValidator = current => {
        const {currentDate} = this.state;
        return current.isSameOrBefore(currentDate, 'day');
    };
    
    renderItemView = () => {
        const {item, errors} = this.state;

        return (
            <Form onChange={this.handleFormChange} errors={errors}>
                <TextInput name="name" label="Name" value={item.name} maxLength={255}/>
                <NumericInput name="age" label="Age" value={item.age}
                              numberFormatProps={{allowNegative: false}}/>
                <SelectInput name="sex" label="Sex" options={this.sexOptions}
                             value={item['sex']} simpleValue/>
                <TextInput name="immediateCause" label="Immediate Cause"
                           value={item['immediateCause']} maxLength={300}/>
                <TextInput name="antecedentCause" label="Antecedent Cause"
                           value={item['antecedentCause']} maxLength={300}/>
                <TextInput name="underlyingCause" label="Underlying Cause"
                           value={item['underlyingCause']} maxLength={300}/>
                <CalendarInput name="dateOfDeath" label="Date of Death"
                               value={item['dateOfDeath']} timePicker={false} clearable={false}
                               isSelectableDate={this.deathDateValidator}/>
                <TextInput name="criminalOffense" label="Criminal Offense"
                           value={item['criminalOffense']} maxLength={300}/>
            </Form>
        );
    };

    renderConfirmDeleteView = () => {
        const {item} = this.state;

        return (
            <div className="j-mortality-registry-modal-confirm-delete">
                <p>Are you sure you want to delete this entry?</p>
                <table>
                    <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{item.name}</td>
                    </tr>
                    <tr>
                        <td>Age</td>
                        <td>{item.age}</td>
                    </tr>
                    <tr>
                        <td>Sex</td>
                        <td>{item['sex']}</td>
                    </tr>
                    <tr>
                        <td>Immediate Cause</td>
                        <td>{item['immediateCause']}</td>
                    </tr>
                    <tr>
                        <td>Antecedent Cause</td>
                        <td>{item['antecedentCause']}</td>
                    </tr>
                    <tr>
                        <td>Underlying Cause</td>
                        <td>{item['underlyingCause']}</td>
                    </tr>
                    <tr>
                        <td>Date of Death</td>
                        <td>{item['dateOfDeath'].format("MMMM D, YYYY")}</td>
                    </tr>
                    <tr>
                        <td>Criminal Offense</td>
                        <td>{item['criminalOffense']}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    };
    
    render() {
        const {show} = this.state;

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick} fixedScroll>
                <Modal.Header>{this.renderHeader()}</Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>{this.renderFooter()}</Modal.Footer>
            </Modal>
        );
    }
}

MortalityRegistryModal.defaultProps = {
    registerOpenModal: noop
};

MortalityRegistryModal.propTypes = {
    registerOpenModal: PropTypes.func.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onEditItem: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired
};

export default MortalityRegistryModal;