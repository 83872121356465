import React from "react";
import Prevalence from "@/screens/Dashboard/DiseaseScreening/Tuberculosis/Prevalence";
import DistributionScreened from "@/screens/Dashboard/DiseaseScreening/Tuberculosis/DistributionScreened";
import BacteriologicallyConfirmed from "@/screens/Dashboard/DiseaseScreening/Tuberculosis/BacteriologicallyConfirmed";
import Presumptives from "@/screens/Dashboard/DiseaseScreening/Tuberculosis/Presumptives";

class Tuberculosis extends React.Component {
    render() {
        return (
            <div className="j-dash-tuberculosis j-dash-box-subgroup">
                <h3>Tuberculosis</h3>
                <Prevalence/>
                <div className="row">
                    <div className="col-lg-12">
                        <DistributionScreened/>
                    </div>
                    <div className="col-lg-12 j-dash-print-force-br">
                        <BacteriologicallyConfirmed/>
                    </div>
                </div>
                <Presumptives/>
            </div>
        );
    }
}

export default Tuberculosis;