import {fetchResourceCollection} from "@/utilities/jsonapi";
import moment from "moment";
import {
    processWorksheet as baseProcessWorksheet,
    resolveNumericValueFromCell
} from "@/utilities/indicator-report";

export function processWorksheet(workbook, sheetIndex, startRow, idCol, maleCol, femaleCol, idPrefix = "") {
    return validateWorkbook(workbook, false)
        .then(workbook => validateWorksheet(workbook, sheetIndex))
        .then(workbook => baseProcessWorksheet(workbook, sheetIndex, startRow, idCol, maleCol, femaleCol, idPrefix));
}

export function processMortalityWorksheet(workbook) {
    return validateWorkbook(workbook, false)
        .then(workbook => validateWorksheet(workbook, 8))
        .then(workbook => baseProcessMortalityWorksheet(workbook));
}

export function baseProcessMortalityWorksheet(workbook) {
    const nameCol = 'C';
    const ageCol = 'D';
    const sexCol = 'E';
    const immediateCauseCol = 'F';
    const antecedentCauseCol = 'G';
    const underlyingCauseCol = 'H';
    const dateOfDeathCol = 'I';
    const criminalOffenseCol = 'J';

    return new Promise(resolve => {
        const sheetName = workbook.SheetNames[8];
        const worksheet = workbook.Sheets[sheetName];
        const errors = [];
        const items = [];

        const thresholdBlanks = 20;
        const startRow = 7;
        let blanks = 0;
        // Until "TOTAL" or thresholdBlanks
        for (let current = startRow; blanks < thresholdBlanks; current++) {
            const nameCell = `${nameCol}${current}`
            if (
                isEmptyCell(worksheet, nameCol, current)
                && isEmptyCell(worksheet, ageCol, current)
                && isEmptyCell(worksheet, sexCol, current)
                && isEmptyCell(worksheet, immediateCauseCol, current)
                && isEmptyCell(worksheet, antecedentCauseCol, current)
                && isEmptyCell(worksheet, dateOfDeathCol, current)
                && isEmptyCell(worksheet, criminalOffenseCol, current)
            ) {
                blanks++;
            } else {
                if (blanks === 1) {
                    errors.push(`Blank row: ${current}`);
                } else if (blanks > 1) {
                    errors.push(`Blank rows: ${current - blanks} - ${current - 1}`);
                }
                blanks = 0;

                if (isEmptyCell(worksheet, nameCol, current)) {
                    errors.push(`Empty name in row ${current}`);
                    continue;
                }

                const name = ("" + worksheet[nameCell].v).slice(0, 255);
                if (name.startsWith("TOTAL")) {
                    break;
                }

                // Age validation
                const ageCell = `${ageCol}${current}`;
                const age = resolveNumericValueFromCell(worksheet[ageCell]);
                if (!age) {
                    errors.push(`Invalid age in row ${current}`);
                    continue;
                }

                // Sex validation
                if (isEmptyCell(worksheet, sexCol, current)) {
                    errors.push(`Invalid sex in row ${current}`);
                    continue;
                }
                const sexCell = `${sexCol}${current}`;
                let sex = ("" + worksheet[sexCell].v).slice(0, 1).toLowerCase();
                if (sex === "m") {
                    sex = "male";
                } else if (sex === "f") {
                    sex = "female";
                } else {
                    errors.push(`Invalid sex in row ${current}`);
                    continue;
                }

                // Immediate cause
                if (isEmptyCell(worksheet, immediateCauseCol, current)) {
                    errors.push(`Immediate cause required in row ${current}`);
                    continue;
                }
                const immediateCauseCell = `${immediateCauseCol}${current}`;
                const immediateCause = ("" + worksheet[immediateCauseCell].v).slice(0, 300);
                const antecedentCause = resolveStringValue(worksheet, antecedentCauseCol, current).slice(0, 300);
                const underlyingCause = resolveStringValue(worksheet, underlyingCauseCol, current).slice(0, 300);

                // Date of death
                const dateOfDeath = resolveDateValue(worksheet, dateOfDeathCol, current);
                if (!dateOfDeath) {
                    errors.push(`Invalid date of death in row ${current}`);
                    continue;
                }

                // Criminal offense
                if (isEmptyCell(worksheet, criminalOffenseCol, current)) {
                    errors.push(`Criminal offense required in row ${current}`);
                    continue;
                }
                const criminalOffenseCell = `${criminalOffenseCol}${current}`;
                const criminalOffense = ("" + worksheet[criminalOffenseCell].v).slice(0, 300);

                const entry = {
                    name,
                    age,
                    sex,
                    immediateCause,
                    dateOfDeath,
                    criminalOffense
                };

                if (antecedentCause) {
                    entry['antecedentCause'] = antecedentCause;
                }
                if (underlyingCause) {
                    entry['underlyingCause'] = underlyingCause;
                }

                items.push(entry);
            }
        }

        resolve({items, errors});
    });
}

function isEmptyCell(worksheet, column, row) {
    const cell = `${column}${row}`;
    return !worksheet.hasOwnProperty(cell)
        || !("" + worksheet[cell].v).trim();
}

function resolveStringValue(worksheet, column, row) {
    const cell = `${column}${row}`;
    if (!worksheet.hasOwnProperty(cell)) {
        return "";
    } else {
        return ("" + worksheet[cell].v).trim();
    }
}

function resolveDateValue(worksheet, column, row) {
    const cell = `${column}${row}`;
    const numeric = resolveNumericValueFromCell(worksheet[cell]);
    if (!numeric) {
        return undefined;
    }

    const timestamp = (numeric - 25569) * 86400;
    return moment.unix(timestamp).format("YYYY-MM-DD");
}

function validateWorksheet(workbook, sheetIndex) {
    return new Promise((resolve, reject) => {
        const {SheetNames} = workbook;
        const sheetName = SheetNames[sheetIndex];
        const formName = 'J' + `${(sheetIndex + 1)}`.padStart(3, '0');
        if (!sheetName.includes(formName)) {
            reject({type: "invalid_file"});
        }

        resolve(workbook);
    });
}

export function validateWorkbook(workbook, checkWorksheets = true) {
    return new Promise((resolve, reject) => {
        const {SheetNames} = workbook;
        if (SheetNames.length < 9) {
            reject({type: "invalid_file"})
        }

        if (checkWorksheets) {
            for (let i = 0; i < SheetNames.length; i++) {
                const name = SheetNames[i];
                const formName = 'J' + `${(i + 1)}`.padStart(3, '0');
                if (!name.includes(formName)) {
                    reject({type: "invalid_file"});
                }
            }
        }

        resolve(workbook);
    });
}

export function fetchOptions(cancelToken, optionType) {
    return new Promise(resolve => {
        fetchResourceCollection(optionType)
            .onSuccess(options => {
                const internal = [];
                const regular = [];
                const optionMap = {};

                options.forEach(option => {
                    if (option.active) {
                        if (option.internal) {
                            internal.push(option);
                        } else {
                            regular.push(option);
                        }
                        optionMap[option.id] = option;
                    }
                });

                options = [...regular, ...internal];
                resolve({options, optionMap});
            })
            .execute(cancelToken);
    });
}
