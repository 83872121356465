import React from "react";
import Modal from "@vallarj/react-adminlte/Modal";
import PropTypes from 'prop-types';
import FileInput from "@/components/FileInput";
import {noop} from "@/utilities/noop";
import {notifyError, notifySuccess} from "@/utilities/notifications";

class ImportModal extends React.Component {
    constructor(props) {
        super(props);

        props.registerOpenModal(this.handleOpenModal);

        this.state = {
            show: false,
            isProcessing: false,
            file: null,
            errors: {},
            partialErrors: {},
            view: 'import',
            missing: [],
        };
    }

    setInvalidFileError = () => {
        notifyError(this.props.header, "Unable to process your request. Invalid file.");
        this.setState({
            errors: {
                file: ["Invalid file."]
            }
        })
    };

    handleOpenModal = () => {
        this.setState({show: true});
    };

    handleCloseClick = () => {
        const {isProcessing} = this.state;
        if (!isProcessing) {
            this.setState({show: false});
        }
    };

    handleExit = () => {
        this.setState({
            show: false,
            isProcessing: false,
            file: null,
            view: 'import',
            missing: [],
            errors: {},
            partialErrors: {}
        })
    };

    handleImportFileChange = file => {
        this.setState({
            file,
            errors: {}
        });
    };

    handleImportClick = () => {
        const {file} = this.state;
        if (!file) {
            this.setInvalidFileError();
            return;
        }

        this.setState({isProcessing: true});
        const {processWorkbook} = this.props;
        const reader = new FileReader();

        reader.onload = e => {
            // Get file contents
            const data = e.target.result;

            // Create workbook from file contents
            import('xlsx').then(({default: XLSX}) => {
                let workbook;

                try {
                    workbook = XLSX.read(data, {type: 'binary'});
                } catch {}

                if (workbook) {
                    processWorkbook(workbook)
                        .then(report => {
                            if (report.status === "success") {
                                this.setState({
                                    show: false,
                                    isProcessing: false
                                });
                                notifySuccess(this.props.header, "Import successful");
                            } else if (report.status === "empty") {
                                this.setState({
                                    show: false,
                                    isProcessing: false
                                });
                                notifyError("Import failed", "Import file empty.");
                            } else if (report.status === "partial") {
                                this.setState({
                                    view: 'partial',
                                    missing: report.missing,
                                    isProcessing: false
                                });
                            } else if (report.status === 'partial-invalid') {
                                this.setState({
                                    view: 'partial-invalid',
                                    partialErrors: report.errors,
                                    isProcessing: false
                                });
                            }
                        })
                        .catch(err => {
                            this.setInvalidFileError();
                            this.setState({isProcessing: false});
                        })
                } else {
                    this.setInvalidFileError();
                    this.setState({isProcessing: false});
                }
            });
        };

        reader.readAsBinaryString(file);
    };

    renderBody = () => {
        const {view, file, errors, missing, partialErrors} = this.state;

        if (view === 'import') {
            return (
                <FileInput label="File" value={file} name="file" errors={errors}
                           onChange={this.handleImportFileChange}/>
            );
        } else if (view === 'partial') {
            return (
                <div className="j-import-modal-partial">
                    <p>File partially imported. Click <kbd>Save</kbd> on the main screen to save the changes.</p>
                    <h3>Missing values</h3>
                    <ul>{missing.map(m => <li key={m.id}>{m.name}</li>)}</ul>
                </div>
            );
        } else if (view === 'partial-invalid') {
            return (
                <div className="j-import-modal-partial">
                    <p>File partially imported. Click <kbd>Save</kbd> on the main screen to save the changes.</p>
                    <h3>Invalid values</h3>
                    <ul>{partialErrors.map((m, i) => <li key={i}>{m}</li>)}</ul>
                </div>
            );
        }
    };

    renderFooter = () => {
        const {view, isProcessing} = this.state;
        if (view === 'import') {
            return (<>
                <button className="btn btn-default pull-left" onClick={this.handleCloseClick}
                        disabled={isProcessing}>
                    Cancel
                </button>
                <button className="btn btn-primary pull-right" onClick={this.handleImportClick}
                        disabled={isProcessing}>
                    <i className="fa fa-upload margin-r-5"/>Import
                </button>
            </>);
        } else if (view === 'partial' || view === 'partial-invalid') {
            return (
                <button className="btn btn-default pull-right" onClick={this.handleCloseClick}
                        disabled={isProcessing}>
                    Close
                </button>
            );
        }
    }

    render() {
        const {isProcessing, show} = this.state;
        const {header} = this.props;

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick}
                   isLoading={isProcessing}>
                <Modal.Header>{header}</Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>{this.renderFooter()}</Modal.Footer>
            </Modal>
        );
    }
}

ImportModal.defaultProps = {
    processWorkbook: noop
};

ImportModal.propTypes = {
    header: PropTypes.string,
    registerOpenModal: PropTypes.func.isRequired,
    processWorkbook: PropTypes.func
};

export default ImportModal;