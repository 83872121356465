import React from 'react';
import {connect} from "react-redux";

import Content from '@vallarj/react-adminlte/Content';
import BreadcrumbItem from '@vallarj/react-adminlte/Content/BreadcrumbItem';
import SelectInput from "@vallarj/react-adminlte/Form/SelectInput";
import Box from "@vallarj/react-adminlte/Box";
import DataTable from "@vallarj/react-adminlte/DataTable";
import ValueButton from "@vallarj/react-adminlte/ValueButton";
import ReportStatsTag from "@/components/ReportStatsTag";
import {Link} from "react-router-dom";
import {resolveCurrentSystemTime} from "@/actions/globalActions";
import {config} from "@/config";
import {createCancelToken, fetchResourceCollection, patchResource, postResource} from "@/utilities/jsonapi";
import {monthName} from "@/utilities/month-name";
import {JailLevelReport} from "@/records/JailLevelReport";
import {getFileRequest} from "@/utilities/axios";
import {Office} from "@/records/Office";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import {AbilityContext, Can} from "@/ability";
import {notifySuccess} from "@/utilities/notifications";

const START_YEAR = 2021;

class JailLevelReportsIndex extends React.Component {
    constructor(props) {
        super(props);

        this.columnDefs = [
            {
                header: 'Month',
                renderValue: item => {
                    let tags = [];
                    if (item.prepared) {
                        if (item.submitted) {
                            if (props.officeType === OFFICE_TYPE_JAIL_LEVEL) {
                                tags.push(<ReportStatsTag key="submitted" type={ReportStatsTag.Submitted}/>);
                            } else {
                                tags.push(<ReportStatsTag key="finalized" type={ReportStatsTag.Finalized}/>);
                            }
                        } else {
                            tags.push(<ReportStatsTag key={item.complete ? "complete" : "incomplete"}
                                                      type={item.complete ? ReportStatsTag.Complete : ReportStatsTag.Incomplete}/>);
                            if (props.officeType === OFFICE_TYPE_JAIL_LEVEL) {
                                tags.push(<ReportStatsTag key="not-submitted" type={ReportStatsTag.NotSubmitted}/>);
                            } else {
                                tags.push(<ReportStatsTag key="unfinalized" type={ReportStatsTag.Unfinalized}/>);
                            }
                        }
                    } else if (props.officeType !== OFFICE_TYPE_JAIL_LEVEL) {
                        tags.push(<ReportStatsTag key="not-prepared" type={ReportStatsTag.NotPrepared}/>);
                    }

                    return (
                        <>
                            <span>{item.month}</span>
                            {tags}
                        </>
                    );
                }
            },
            {
                header: '',
                renderValue: item => {
                    let openButton;

                    if (item.prepared) {
                        if (item.submitted) {
                            const {unfinalizing} = this.state;

                            openButton = (
                                <>
                                    <Link to={`/jail-level-reports/view/${item.id}`}
                                          className="btn btn-default btn-xs">
                                        <i className="fa fa-eye margin-r-5"/>View
                                    </Link>
                                    <Can I="unfinalize" a="JailLevelReport">
                                        <ValueButton className="btn btn-default btn-xs" value={item} disabled={!!unfinalizing}
                                                     onClick={this.handleUnfinalizeClick}>
                                            {
                                                unfinalizing && item === unfinalizing ?
                                                    <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                                    <i className="fa fa-undo margin-r-5"/>
                                            }
                                            Return for Changes
                                        </ValueButton>
                                    </Can>
                                </>
                            );
                        } else {
                            openButton = (
                                <>
                                    <Link to={`/jail-level-reports/view/${item.id}`}
                                          className="btn btn-default btn-xs">
                                        <i className="fa fa-eye margin-r-5"/>View
                                    </Link>
                                    <Can I="update" a="JailLevelReport">
                                        <Link to={`/jail-level-reports/edit/${item.id}`}
                                              className="btn btn-default btn-xs">
                                            <i className="fa fa-pencil margin-r-5"/>Edit
                                        </Link>
                                    </Can>
                                </>
                            );
                        }
                    } else if(this.context.can('create', 'JailLevelReport')) {
                        const {preparing} = this.state;

                        openButton = (
                            <ValueButton className="btn btn-default btn-xs" value={item} disabled={!!preparing}
                                         onClick={this.handlePrepareClick}>
                                {
                                    preparing && item === preparing ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-plus-circle margin-r-5"/>
                                }
                                Prepare
                            </ValueButton>
                        );
                    }

                    return (
                        <span className="btn-group pull-right">
                            {openButton}
                        </span>
                    );
                }
            }
        ];

        this.state = {
            yearOptions: [],
            selectedYear: null,
            yearData: [],
            isLoading: true,
            preparing: null,
            unfinalizing: null,
            jailLevelOffices: [],
            offices: [],
            office: null,
            regions: [],
            region: null,
            exportTemplateLoading: false,
        };
        this.fetchCancelToken = createCancelToken();
        this.prepareCancelToken = createCancelToken();
        this.unfinalizeCancelToken = createCancelToken();
    }

    componentDidMount() {
        const {officeId, officeType} = this.props;
        const time = resolveCurrentSystemTime();
        const currentYear = time.year();
        const yearOptions = [
            {label: "Current", value: currentYear}
        ];

        let year = currentYear;
        while (--year >= START_YEAR) {
            yearOptions.push({label: `${year}`, value: year})
        }

        this.setState({
            yearOptions,
            selectedYear: currentYear
        });

        if (officeType === OFFICE_TYPE_JAIL_LEVEL) {
            this.setState({
                office: (new Office()).set('id', officeId)
            });
            this.fetchYearReports(currentYear, officeId);
        } else {
            fetchResourceCollection(Office)
                .onSuccess(offices => {
                    const jailLevelOffices = offices.filter(o => o.officeType === OFFICE_TYPE_JAIL_LEVEL);

                    let regions;
                    if (officeType === OFFICE_TYPE_REGIONAL) {
                        regions = offices.filter(o => o.id === officeId);
                    } else {
                        regions = offices.filter(o => o.officeType === OFFICE_TYPE_REGIONAL);
                    }
                    const region = regions[0];
                    offices = jailLevelOffices.filter(o => o.parent && o.parent.id === region.id);

                    this.setState({
                        jailLevelOffices,
                        offices,
                        regions,
                        region,
                        office: offices[0]
                    });
                    if (offices[0]) {
                        this.fetchYearReports(currentYear, offices[0].id);
                    } else {
                        this.setState({isLoading: false});
                    }
                })
                .execute(this.fetchCancelToken);
        }
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
        this.prepareCancelToken.cancel();
        this.unfinalizeCancelToken.cancel();
    }

    fetchYearReports(year, officeId) {
        const params = {year, office: officeId};

        this.setState({isLoading: true});
        fetchResourceCollection(JailLevelReport, params)
            .onSuccess(reports => {
                this.setState({
                    isLoading: false,
                    yearData: this.buildYearIndex(year, reports)
                });
            })
            .execute(this.fetchCancelToken);
    }

    buildYearIndex = (year, reports) => {
        const time = resolveCurrentSystemTime();
        const currentYear = time.year();
        const currentMonth = time.month() + 1;

        const reportMap = reports.reduce((a, b) => ({...a, [b.month]: b}), {});
        const yearData = [];
        for (let i = 1; i <= 12; i++) {
            const month = monthName(i);
            if (reportMap.hasOwnProperty(i)) {
                const report = reportMap[i];
                yearData.push({
                    month,
                    id: report.id,
                    submitted: report.finalized,
                    prepared: true,
                    complete: report.isComplete
                });
            } else if (
                year < currentYear
                || (year === currentYear && i <= currentMonth)
            ) {
                yearData.push({
                    month,
                    monthIndex: i,
                    year,
                    submitted: false,
                    prepared: false,
                    complete: false
                });
            }
        }

        return yearData;
    };

    handleYearChange = (_, value) => {
        const {office} = this.state;
        this.setState({selectedYear: value});
        if (office) {
            this.fetchYearReports(value, office.id);
        }
    };

    handleOfficeChange = (_, value) => {
        const {selectedYear} = this.state;
        this.setState({office: value});
        this.fetchYearReports(selectedYear, value.id);
    };

    handleRegionChange = (_, value) => {
        const {selectedYear, jailLevelOffices} = this.state;
        const offices = jailLevelOffices.filter(o => o.parent.id === value.id);
        const office = offices[0];

        this.setState({
            region: value,
            offices,
            office: offices[0],
        });

        if (office) {
            this.fetchYearReports(selectedYear, office.id);
        } else {
            this.setState({yearData: []});
        }
    };

    handleExportTemplateClick = () => {
        this.setState({exportTemplateLoading: true});
        getFileRequest(`${config.apiBaseUrl}/templates/import`, 'BJMP-DHS-Template.xlsx')
            .then(() => {
                this.setState({exportTemplateLoading: false});
                notifySuccess("Generate XLSX Template", "Template successfully generated");
            });
    };

    handlePrepareClick = item => {
        const {officeId} = this.props;
        this.setState({
            preparing: item
        });
        const report = new JailLevelReport().mergeUpdate({
            month: item.monthIndex,
            year: item.year,
            office: new Office().set('id', officeId)
        });

        postResource(report)
            .onSuccess(report => {
                this.props.history.push(`/jail-level-reports/edit/${report.id}`);
            })
            .execute(this.prepareCancelToken);
    };

    handleUnfinalizeClick = item => {
        const {office, selectedYear} = this.state;
        this.setState({
            unfinalizing: item
        });

        const report = new JailLevelReport().set('id', item.id)
            .update('finalized', false);

        patchResource(report)
            .onSuccess(() => {
                this.fetchYearReports(selectedYear, office.id);
                notifySuccess("Return for Changes", "Report unfinalized");
                this.setState({
                    unfinalizing: null
                });
            })
            .execute(this.unfinalizeCancelToken);
    };

    getLabelByName = option => option.name;
    getValueById = option => option.id;
    
    renderBody = () => {
        const {
            yearOptions, selectedYear, yearData,
            isLoading, exportTemplateLoading, office, offices,
            regions, region
        } = this.state;
        const {officeType} = this.props;

        return (<>
            <div className="row">
                <div className="col-xs-3">
                    <SelectInput name="year" label="Year" value={selectedYear} onChange={this.handleYearChange}
                                 options={yearOptions} simpleValue/>
                    {
                        officeType === OFFICE_TYPE_HQ &&
                        <SelectInput name="region" label="Region" value={region} onChange={this.handleRegionChange}
                                     options={regions} getOptionValue={this.getValueById}
                                     getOptionLabel={this.getLabelByName} searchable/>
                    }
                    {
                        officeType !== OFFICE_TYPE_JAIL_LEVEL &&
                        <SelectInput name="office" label="Office" value={office} onChange={this.handleOfficeChange}
                                     options={offices} getOptionValue={this.getValueById}
                                     getOptionLabel={this.getLabelByName} searchable/>
                    }
                    <Can I="update" a="JailLevelReport">
                        <button className="btn btn-default btn-block" onClick={this.handleExportTemplateClick}
                                disabled={exportTemplateLoading}>
                            {
                                exportTemplateLoading ?
                                    <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                    <i className="fa fa-download margin-r-5"/>
                            }
                            Generate XLSX Template
                        </button>
                    </Can>
                </div>
                <div className="col-xs-9">
                    <Box theme='box-primary' isLoading={isLoading}>
                        <Box.Header title="Reports"/>
                        <Box.Body>
                            <DataTable className="j-jail-statistics-table"
                                       data={yearData} columnDefs={this.columnDefs}/>
                        </Box.Body>
                    </Box>
                </div>
            </div>
        </>);
    };

    render() {
        return (
            <Content>
                <Content.Header title="Monthly Reports"/>
                <Content.Breadcrumb>
                    <BreadcrumbItem label="Monthly Reports" iconClass="fa fa-file-text" active/>
                </Content.Breadcrumb>
                <Content.Body>
                    {this.renderBody()}
                </Content.Body>
            </Content>
        );
    }
}

function mapStateToProps({auth}) {
    return {officeId: auth.user.office.id, officeType: auth.user.office['office_type']};
}

JailLevelReportsIndex.contextType = AbilityContext;
export default connect(mapStateToProps)(JailLevelReportsIndex);