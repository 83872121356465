import React from "react";
import PropTypes from 'prop-types';
import NumberFormat from "react-number-format";
import {noop} from "@/utilities/noop";

class FocusAwareNumberInput extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            position: 0
        }
    }

    componentDidMount() {
        this.setState({position: this.props.getPosition()});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentFocus} = this.props;
        const {position} = this.state;

        if (currentFocus !== prevProps.currentFocus && position === currentFocus) {
            this.inputRef.current.focus();
        }
    }

    handleKeydown = event => {
        const {notifyFocusChange} = this.props;
        const {position} = this.state;

        switch (event.key) {
            case "ArrowLeft":
                notifyFocusChange('left', position);
                break;
            case "ArrowRight":
                notifyFocusChange('right', position);
                break;
            case "ArrowUp":
                notifyFocusChange('up', position);
                break;
            case "ArrowDown":
                notifyFocusChange('down', position);
                break;
            default:
                break;
        }
    };

    handleFocus = event => {
        event.target.select();
    };

    render() {
        const {onValueChange, disabled} = this.props;
        let value = this.props.value == null ? "" : this.props.value;

        return (
            <NumberFormat value={value} onValueChange={onValueChange} disabled={disabled} getInputRef={this.inputRef}
                          onFocus={this.handleFocus} onKeyDown={this.handleKeydown} allowNegative={false} decimalScale={0}/>
        );
    }
}

FocusAwareNumberInput.propTypes = {
    value: PropTypes.number,
    onValueChange: PropTypes.func,
    disabled: PropTypes.bool,
    getPosition: PropTypes.func.isRequired,
    currentFocus: PropTypes.number,
    notifyFocusChange: PropTypes.func,
};

FocusAwareNumberInput.defaultProps = {
    notifyFocusChange: noop
};

export default FocusAwareNumberInput;