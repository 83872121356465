import React from 'react';
import PropTypes from "prop-types";
import Box from '@vallarj/react-adminlte/Box';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import SelectInput from '@vallarj/react-adminlte/Form/SelectInput';
import {
    createCancelToken,
    fetchAndFilterResourceCollection,
    fetchResourceCollection,
    waitForAllRequests
} from "@/utilities/jsonapi";
import IndicatorSubcategoryItem from "./IndicatorSubcategoryItem";
import IndicatorSubcategoryModal from "./IndicatorSubcategoryModal";
import {noop} from "@/utilities/noop";

class IndicatorSubcategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search: null,
            isLoading: true,
            indicatorCategories: [],
            indicatorSubcategories: [],
            indicatorCategory: null
        };

        this.openModal = noop;
        this.fetchCancelToken = createCancelToken();
    }

    componentDidMount() {
        this.fetchDependencies();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    fetchDependencies = () => {
        const {categoryResourceType, subcategoryResourceType} = this.props;

        this.setState({isLoading: true});
        waitForAllRequests([
            fetchResourceCollection(categoryResourceType),
            fetchAndFilterResourceCollection(
                subcategoryResourceType,
                item => !(item.relationships || {}).parent
            )
        ]).onSuccess(([categories, subcategories]) => {
            this.setState({
                indicatorCategories: categories,
                indicatorSubcategories: subcategories
            });
            if (categories.length) {
                this.setState({
                    indicatorCategory: categories[0],
                });
            }
            this.setState({isLoading: false})
        }).execute(this.fetchCancelToken);
    }

    fetchIndicatorSubcategories = () => {
        const {subcategoryResourceType} = this.props;
        this.setState({isLoading: true});
        fetchAndFilterResourceCollection(
            subcategoryResourceType,
            item => !(item.relationships || {}).parent
        )
            .onSuccess(collection => {
                this.setState({
                    indicatorSubcategories: collection,
                    isLoading: false
                });
            }).execute(this.fetchCancelToken);
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});
    };

    handleAddIndicatorSubcategoryClick = () => {
        const {indicatorCategory} = this.state;
        const {subcategoryResourceType, hasShowTotal, hasQuarterly} = this.props;
        const mergeObject = {
            category: indicatorCategory,
            active: true,
            showHeader: true,
        };

        if (hasShowTotal) {
            mergeObject['showTotal'] = false;
        }

        if (hasQuarterly) {
            mergeObject['quarterly'] = false;
        }

        // noinspection JSUnresolvedFunction
        this.openModal('create', (new subcategoryResourceType()).mergeUpdate(mergeObject));
    };

    handleAddChildClick = indicatorSubcategory => {
        const {indicatorCategory} = this.state;
        const {subcategoryResourceType, hasShowTotal, hasQuarterly} = this.props;
        const mergeObject = {
            category: indicatorCategory,
            parent: indicatorSubcategory,
            active: true,
            showHeader: true,
        };

        if (hasShowTotal) {
            mergeObject['showTotal'] = false;
        }

        if (hasQuarterly) {
            mergeObject['quarterly'] = false;
        }

        // noinspection JSUnresolvedFunction
        this.openModal('create', (new subcategoryResourceType()).mergeUpdate(mergeObject));
    };

    handleEditItemClick = indicatorSubcategory => {
        this.openModal('edit', indicatorSubcategory);
    };

    handleDeleteItemClick = indicatorSubcategory => {
        this.openModal('delete', indicatorSubcategory);
    };

    handleIndicatorCategoryChange = (_, value) => {
        this.setState({indicatorCategory: value});
    };

    filterIndicatorSubcategories = () => {
        const {indicatorCategory, indicatorSubcategories} = this.state;
        if (!indicatorCategory) {
            return [];
        }

        return indicatorSubcategories.filter(s => s.category.id === indicatorCategory.id);
    };

    getIndicatorCategoryLabel = option => option.name;
    getOptionId = option => option.id;

    render() {
        const {indicatorCategories, indicatorCategory, search, isLoading} = this.state;
        const {title, hasShowTotal, hasQuarterly} = this.props;
        const indicatorSubcategories = this.filterIndicatorSubcategories();

        return (<>
            <IndicatorSubcategoryModal indicatorCategories={indicatorCategories}
                                       indicatorSubcategories={this.state.indicatorSubcategories}
                                       openModalRef={this.registerOpenModal}
                                       onSuccess={this.fetchIndicatorSubcategories}
                                       hasShowTotal={hasShowTotal}
                                       hasQuarterly={hasQuarterly}/>
            <Box theme='box-primary' isLoading={isLoading}>
                <Box.Header title={title}/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..."
                               onChange={this.handleSearchChange} value={search}/>
                    <div className="j-indicator-subcategories-select">
                        <div>
                            <SelectInput name="indicatorCategory" label="Indicator Category"
                                         onChange={this.handleIndicatorCategoryChange}
                                         getOptionLabel={this.getIndicatorCategoryLabel}
                                         getOptionValue={this.getOptionId}
                                         options={indicatorCategories}
                                         value={indicatorCategory}
                                         searchable/>
                        </div>
                        <div>
                            <button className="btn btn-default btn-block"
                                    onClick={this.handleAddIndicatorSubcategoryClick}>
                                <i className="fa fa-plus-circle margin-r-5"/>Add Top Level
                            </button>
                        </div>
                    </div>
                    <div className="j-indicator-subcategories j-box-scroll">
                        <ul>
                            {
                                indicatorSubcategories.map(i => (
                                    <IndicatorSubcategoryItem onAddChildClick={this.handleAddChildClick}
                                                              onEditClick={this.handleEditItemClick}
                                                              onDeleteClick={this.handleDeleteItemClick}
                                                              key={i.id} search={search}
                                                              indicatorSubcategory={i}
                                                              hasShowTotal={hasShowTotal}
                                                              hasQuarterly={hasQuarterly}/>
                                ))
                            }
                        </ul>
                    </div>
                </Box.Body>
            </Box>
        </>);
    }
}

IndicatorSubcategories.propTypes = {
    categoryResourceType: PropTypes.func.isRequired,
    subcategoryResourceType: PropTypes.func.isRequired,
    hasShowTotal: PropTypes.bool.isRequired,
    hasQuarterly: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default IndicatorSubcategories;