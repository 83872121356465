import React from 'react';
import {connect} from 'react-redux';
import {getConsentRequest, postConsentRequest} from "@/utilities/auth";

class Consent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            consentChallenge: null,
            show: false,
            requestedScope: [],
            clientName: null,
            user: null,
            csrf: null
        };
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const {history} = this.props;

        // TODO: If logged in, redirect to root instead.
        if(!params.has("consent_challenge")) {
            history.replace("/login");
        } else {
            this.setState({consentChallenge: params.get("consent_challenge")});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {consentChallenge} = this.state;
        if(consentChallenge && prevState.consentChallenge !== consentChallenge) {
            this.performConsentRequest();
        }
    }

    performConsentRequest = () => {
        const {consentChallenge} = this.state;
        getConsentRequest(consentChallenge)
            .then(({data}) => {
                if(data["status_code"] === 400) {
                    this.setState({
                        show: true,
                        user: data["user"],
                        clientName: data["client"],
                        requestedScope: data["requested_scope"],
                        csrf: data["state"]
                    });
                } else if(data["status_code"] >= 400) {
                    // TODO: handle this properly.
                } else if(data["status_code"] === 302) {
                    window.location.replace(data["redirect_to"]);
                }
            });
    };

    handleSubmit = allow => {
        const {consentChallenge, csrf} = this.state;

        postConsentRequest(consentChallenge, allow, csrf)
            .then(({data}) => {
                if(data["status_code"] === 302) {
                    window.location.replace(data["redirect_to"]);
                } else {
                    // TODO: Handle this.
                }
            });
    };

    handleCancelClick = () => this.handleSubmit(false);
    handleAllowClick = () => this.handleSubmit(true);

    render() {
        const {show, user, clientName, requestedScope} = this.state;
        if(!show) return null;

        return (
            <div className="consent-screen">
                <div className="box box-widget widget-consent">
                    <div className="widget-consent-header">
                        <h1>BJMP - DHS Health Reporting System</h1>
                    </div>
                    <div className="widget-consent-body">
                        <h2><span>{clientName}</span> requests access to your data.</h2>
                        <span className="widget-consent-username">
                            <i className="fa fa-user margin-r-5"/>{user}
                        </span>
                        <span className="widget-consent-warning">
                            Application <span>{clientName}</span> wants to access resources on your behalf and to:
                        </span>
                        <ul>
                            {
                                Object.keys(requestedScope)
                                    .map((scope, i) => <li key={scope}>{requestedScope[scope]}</li>)
                            }
                        </ul>
                        <span className="widget-consent-warning">
                            Make sure that you trust <span>{clientName}</span>.
                        </span>
                        <span className="widget-consent-disclaimer">
                            You may be sharing sensitive information with this site or application.
                            Find out how {clientName} will handle your data by reviewing
                            its terms of service and privacy policies.
                        </span>
                    </div>
                    <div className="widget-consent-footer">
                        <button className="btn btn-default"
                                onClick={this.handleCancelClick}>Cancel</button>
                        <button className="btn btn-primary pull-right"
                                onClick={this.handleAllowClick}>Allow</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(Consent);