import React from 'react';
import Tag from "@/screens/Configuration/Tag";
import {highlightString} from "@/utilities/highlight-string";
import {noop} from "@/utilities/noop";

class OptionIndexItem extends React.Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            changed: false
        };

        this.changeTimeout = null;
    }

    componentDidMount() {
        if (this.props.changed && this.props.changed.id === this.props.option.id) {
            this.handleChanged();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.changed && this.props.changed.id === this.props.option.id) {
            this.handleChanged();
        }
    }

    componentWillUnmount() {
        if (this.changeTimeout) {
            window.clearTimeout(this.changeTimeout);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state !== nextState) {
            return true;
        }

        if (this.props.option !== nextProps.option) {
            return true;
        }

        if (this.props.search !== nextProps.search) {
            return true;
        }

        return !!(this.props.changed !== nextProps.changed
            && nextProps.changed
            && nextProps.changed.id === this.props.option.id);
    }

    handleChanged = () => {
        this.props.onChangeCommitted(this.containerRef.current.offsetTop);
        this.setState({changed: true});
        this.changeTimeout = window.setTimeout(() => {
            this.setState({changed: false});
        }, 3000);
    };

    handleEditClick = () => {
        this.props.onEditClick(this.props.option);
    };

    handleDeleteClick = () => {
        this.props.onDeleteClick(this.props.option);
    };

    render() {
        const {option, search} = this.props;
        const {changed} = this.state;

        const className = "j-config-list-item j-config-list-item-single" +
            (changed ? " j-config-item-update" : "");
        return (
            <div className={className} ref={this.containerRef}>
                <div>
                    <span>{highlightString(search, option.name)}</span>
                    {
                        option.inUse &&
                        <Tag text="In Use" className="j-config-item-tag-in-use"/>
                    }
                    {
                        option.internal &&
                        <Tag text="System Preset" className="j-config-item-tag-system"/>
                    }
                    {
                        !option.active &&
                        <Tag text="Inactive" className="j-config-item-tag-inactive"/>
                    }
                </div>
                <div>
                    <span className="btn-group pull-right">
                        <button className="btn btn-default btn-xs" disabled={option.internal}
                                onClick={this.handleEditClick}>
                            <i className="fa fa-pencil margin-r-5"/>Edit
                        </button>
                        <button className="btn btn-default btn-xs" disabled={option.internal || option.inUse}
                                onClick={this.handleDeleteClick}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

OptionIndexItem.defaultProps = {
    onChangeCommitted: noop,
    onEditClick: noop,
    onDeleteClick: noop
};

export default OptionIndexItem;