import React from 'react';
import PropTypes from 'prop-types';

import {processWorksheet} from "@/screens/JailLevelReports/JailLevelReportEntry/utilities";
import BaseIndicatorReport from "@/components/IndicatorReport";
import {IndicatorCategory} from "@/records/IndicatorCategory";
import {IndicatorSubcategory} from "@/records/IndicatorSubcategory";
import {Indicator} from "@/records/Indicator";

class IndicatorReport extends React.Component {
    handleSaveClick = () => {
        this.props.onSaveClick('indicators');
    };

    render() {
        const {
            indicators, month,
            onIndicatorsReplace, onIndicatorChange, onIndicatorsRevert,
            isLoading, isEditMode, indicatorsChanged
        } = this.props;

        return (
            <BaseIndicatorReport
                title="Indicator Reporting Tool (J001)"
                categoryResourceType={IndicatorCategory}
                subcategoryResourceType={IndicatorSubcategory}
                indicatorResourceType={Indicator}
                hasDataSource={true}
                worksheetProcessor={processWorksheet}
                indicators={indicators}
                onIndicatorsReplace={onIndicatorsReplace}
                onIndicatorChange={onIndicatorChange}
                isLoading={isLoading}
                isEditMode={isEditMode}
                onSaveClick={this.handleSaveClick}
                onIndicatorsRevert={onIndicatorsRevert}
                indicatorsChanged={indicatorsChanged}
                month={month}
                showFooter
                worksheetIndex={0}
            />
        );
    }
}

IndicatorReport.propTypes = {
    indicators: PropTypes.object.isRequired,
    onIndicatorChange: PropTypes.func.isRequired,
    onIndicatorsReplace: PropTypes.func.isRequired,
    onIndicatorsRevert: PropTypes.func.isRequired,
    indicatorsChanged: PropTypes.bool.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    month: PropTypes.number.isRequired,
};

export default IndicatorReport;