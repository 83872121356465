import React from "react";
import Article from "@/screens/Docs/Article";
import ArticleImage from "@/screens/Docs/Article/ArticleImage";
import mr01 from "./images/mr-01.jpg";
import mr02 from "./images/mr-02.jpg";
import mr03 from "./images/mr-03.jpg";
import mr04 from "./images/mr-04.jpg";
import mr05 from "./images/mr-05.jpg";
import mr06 from "./images/mr-06.jpg";
import mr07 from "./images/mr-07.jpg";
import mr08 from "./images/mr-08.jpg";
import mr09 from "./images/mr-09.jpg";
import mr10 from "./images/mr-10.jpg";
import mr11 from "./images/mr-11.jpg";
import mr12 from "./images/mr-12.jpg";
import mr13 from "./images/mr-13.jpg";
import mr14 from "./images/mr-14.jpg";
import mr15 from "./images/mr-15.jpg";
import mr16 from "./images/mr-16.jpg";
import mr17 from "./images/mr-17.jpg";
import mr18 from "./images/mr-18.jpg";
import mr19 from "./images/mr-19.jpg";
import mr20 from "./images/mr-20.jpg";
import mr21 from "./images/mr-21.jpg";
import ReportStatsTag from "@/components/ReportStatsTag";
import {createPageDefinition} from "@/screens/Docs/utilities";
import ArticleTitle from "@/screens/Docs/Article/ArticleTitle";

const P_MONTHLY = 'monthly';
const H_INDEX_PAGE = createPageDefinition(P_MONTHLY, 'index-page', 'Index Page');
const H_INDEX_PAGE_TEMPLATE = createPageDefinition(P_MONTHLY, 'index-page-template', 'Report XLSX Template');
const H_INDEX_PAGE_STATUS_TAGS = createPageDefinition(P_MONTHLY, 'index-page-status-tags', 'Status Tags');
const H_INDEX_PAGE_VIEW = createPageDefinition(P_MONTHLY, 'index-page-view', 'Viewing a Monthly Report Entry');
const H_INDEX_PAGE_PREPARE = createPageDefinition(P_MONTHLY, 'index-page-prepare', 'Preparing a Monthly Report');
const H_INDEX_PAGE_EDIT = createPageDefinition(P_MONTHLY, 'index-page-edit', 'Editing a Monthly Report');
const H_INDEX_PAGE_RETURN = createPageDefinition(P_MONTHLY, 'index-page-return', 'Returning a Report for Changes');
const H_VIEW = createPageDefinition(P_MONTHLY, 'view', 'View Monthly Report');
const H_EDIT = createPageDefinition(P_MONTHLY, 'edit', 'Edit Monthly Report');
const H_EDIT_OVERVIEW = createPageDefinition(P_MONTHLY, 'edit-overview', 'Overview');
const H_EDIT_NAVIGATION = createPageDefinition(P_MONTHLY, 'edit-navigation', 'Navigation');
const H_EDIT_FORM_VIEW = createPageDefinition(P_MONTHLY, 'edit-form', 'Form View');
const H_EDIT_IMPORTING_REPORTS = createPageDefinition(P_MONTHLY, 'edit-import', 'Importing Reports using the XLSX Template');
const H_EDIT_SAVE = createPageDefinition(P_MONTHLY, 'edit-save', 'Saving a Monthly Report');
const H_EDIT_SUBMIT = createPageDefinition(P_MONTHLY, 'edit-submit', 'Submitting a Monthly Report');
const H_EDIT_ERRORS_ENCOUNTERED = createPageDefinition(P_MONTHLY, 'edit-errors', 'Errors Encountered when Submitting Monthly Reports');

const contents = [
    {
        ...H_INDEX_PAGE,
        contents: [
            H_INDEX_PAGE_TEMPLATE,
            H_INDEX_PAGE_STATUS_TAGS,
            H_INDEX_PAGE_VIEW,
            H_INDEX_PAGE_PREPARE,
            H_INDEX_PAGE_EDIT,
            H_INDEX_PAGE_RETURN
        ],
    },
    H_VIEW,
    {
        ...H_EDIT,
        contents: [
            H_EDIT_OVERVIEW,
            H_EDIT_NAVIGATION,
            H_EDIT_FORM_VIEW,
            H_EDIT_IMPORTING_REPORTS,
            H_EDIT_SAVE,
            H_EDIT_SUBMIT,
            H_EDIT_ERRORS_ENCOUNTERED
        ],
    }
];

class MonthlyReports extends React.Component {
    render() {
        return (
            <div className="row">
                <Article title="Monthly Reports" id={P_MONTHLY}
                         contentTitle="You can prepare, manage, and submit jail office monthly reports on the Monthly Reports page."
                         contents={contents}>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_INDEX_PAGE}/>
                        <p>The index page displays the list of the monthly reports and their current statuses.</p>
                        <ArticleImage src={mr01} alt="Overview"/>
                        <h4>Notes</h4>
                        <ul>
                            <li>Users at the jail level would only be able to view reports from their office</li>
                            <li>Regional level users would be able to view reports from jail offices under their region</li>
                            <li>HQ level users would be able to view reports from all jail offices</li>
                        </ul>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_TEMPLATE}>
                            {" "}<em>(Jail Level Encoder)</em>
                        </ArticleTitle>
                        <p>
                            You can generate a report XLSX template which can be used to import a monthly
                            report into the system. To generate an XLSX report template:
                        </p>
                        <ul>
                            <li>Click on the <code>Generate XLSX Template</code> button</li>
                            <li>
                                Wait for the process to complete. Depending on your browser, the file may be saved
                                automatically, or you may be prompted to save it with a save file pop-up.
                            </li>
                        </ul>
                        <ArticleImage src={mr02} alt="Report XLSX Template"/>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_STATUS_TAGS}/>
                        <p>The status tags indicate the current status of a monthly report entry.</p>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Status</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <ReportStatsTag type={ReportStatsTag.Finalized}/>
                                    <ReportStatsTag type={ReportStatsTag.Submitted}/>
                                </td>
                                <td>Indicates that a monthly report has been submitted</td>
                            </tr>
                            <tr>
                                <td>
                                    <ReportStatsTag type={ReportStatsTag.Unfinalized}/>
                                    <ReportStatsTag type={ReportStatsTag.NotSubmitted}/>
                                </td>
                                <td>Indicates that a monthly report has not yet been submitted</td>
                            </tr>
                            <tr>
                                <td>
                                    <ReportStatsTag type={ReportStatsTag.Complete}/>
                                </td>
                                <td>Indicates that a monthly report has been completed</td>
                            </tr>
                            <tr>
                                <td>
                                    <ReportStatsTag type={ReportStatsTag.Incomplete}/>
                                </td>
                                <td>Indicates that some field values for a monthly report are missing</td>
                            </tr>
                            </tbody>
                        </table>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_VIEW}/>
                        <p>
                            To view a monthly report entry, click on the <code>View</code> button on
                            the month row you want to view.
                        </p>
                        <ArticleImage src={mr03} alt="Viewing a Monthly Report Entry"/>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_PREPARE}>
                            {" "}<em>(Jail Level Encoder)</em>
                        </ArticleTitle>
                        <p>
                            To prepare a new monthly report entry, click on the <code>Prepare</code> button on
                            the row of a month that hasn't been prepared yet. You will then be redirected to the{" "}
                            <a href={`#${H_EDIT.id}`}>Edit Monthly Report</a> page.
                        </p>
                        <ArticleImage src={mr04} alt="Preparing a Monthly Report Entry"/>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_EDIT}>
                            {" "}<em>(Jail Level Encoder)</em>
                        </ArticleTitle>
                        <p>
                            To edit a monthly report entry, click on the <code>Edit</code> button
                            on the month row you want to edit. Note that the <code>Edit</code> button will
                            only appear for monthly reports that haven't been submitted yet.
                        </p>
                        <ArticleImage src={mr05} alt="Editing a Monthly Report"/>
                        <ArticleTitle level={4} pageDef={H_INDEX_PAGE_RETURN}>
                            {" "}<em>(Regional Administrator)</em>
                        </ArticleTitle>
                        <p>
                            A regional administrator may return a submitted monthly report if they believe it is incorrect
                            or if a jail level office requests it. To return a monthly report, click the
                            <code>Return for changes</code> button on the month row you want to return.
                        </p>
                        <ArticleImage src={mr06} alt="Returning a Monthly Report for Changes"/>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_VIEW}/>
                        <p>
                            The current state of a monthly report is displayed on the view monthly report page.
                            Note that you won't be able to change the report values in this view.
                        </p>
                        <p>
                            To select a report page, click on the <code>Reporting</code> navigation links:
                        </p>
                        <ul>
                            <li>Indicator Reporting Tool (J001)</li>
                            <li>Morbidities Detected Upon Entry (J002)</li>
                            <li>Dental Health (J003)</li>
                            <li>Mental Health (J004)</li>
                            <li>Medical Procedures Requested (J005)</li>
                            <li>Morbidities During Detention (J006)</li>
                            <li>Medications (J007)</li>
                            <li>Causes of Death (J008)</li>
                            <li>Mortality Registry (J009)</li>
                        </ul>
                        <p>
                            Click the <code>Edit Report</code> button to make changes to a report that has not yet been submitted.
                            Only jail level encoders will see the <code>Edit Report</code> button.
                        </p>
                        <p>
                            Click the <code>Submit</code> button to submit the monthly report. Only jail level
                            encoders will see this button.
                        </p>
                        <ArticleImage src={mr07} alt="View Monthly Report"/>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_EDIT}/>
                        <p>
                            On the Edit Monthly Report page, you can make changes to a monthly report.
                            Only jail level encoders have access to this page.
                        </p>

                        <ArticleTitle level={4} pageDef={H_EDIT_OVERVIEW}/>
                        <ArticleImage src={mr08} alt="Edit Monthly Report"/>
                        <ol>
                            <li>
                                <strong>Status Tags</strong>
                                <p>Indicate the status of the monthly report</p>
                            </li>
                            <li>
                                <strong>Report Navigation</strong>
                                <p>You can select the report page here</p>
                            </li>
                            <li>
                                <strong>Global Report Buttons</strong>
                                <p>Action buttons that affect all form pages at the same time</p>
                            </li>
                            <li>
                                <strong>Form View</strong>
                                <p>The page section where you can directly encode report data</p>
                            </li>
                            <li>
                                <strong>Current Form Action Buttons</strong>
                                <p>Action buttons that affect only the current form page</p>
                            </li>
                        </ol>

                        <ArticleTitle level={4} pageDef={H_EDIT_NAVIGATION}/>
                        <p>
                            To select a report page, click on the <code>Reporting</code> navigation links:
                        </p>
                        <ul>
                            <li>Indicator Reporting Tool (J001)</li>
                            <li>Morbidities Detected Upon Entry (J002)</li>
                            <li>Dental Health (J003)</li>
                            <li>Mental Health (J004)</li>
                            <li>Medical Procedures Requested (J005)</li>
                            <li>Morbidities During Detention (J006)</li>
                            <li>Medications (J007)</li>
                            <li>Causes of Death (J008)</li>
                            <li>Mortality Registry (J009)</li>
                        </ul>
                        <ArticleImage src={mr09} alt="Navigation"/>

                        <ArticleTitle level={4} pageDef={H_EDIT_FORM_VIEW}/>
                        <p>
                            The form view contains form fields where you can manually encode the report data.
                            The cursor keys can be used to navigate through the form fields.
                            Forms J001-J007 are similar in that values can be directly encoded into the indicator tables.
                        </p>
                        <p>
                            Notice that indicators that have missing values are labeled with an <code>incomplete*</code> tag.
                        </p>
                        <ArticleImage src={mr10} alt="Form View"/>
                        <p>
                            To add an entry in the Mortality Registry (J008) report annex:
                        </p>
                        <ol>
                            <li>Click the <code>Add Entry</code> button located at the lower right corner of the form view</li>
                            <li>Fill out the fields in the Add Entry form window that appears</li>
                            <li>Click <code>Add</code> to submit the entry</li>
                        </ol>
                        <ArticleImage src={mr11} alt="Mortality Registry Add Entry"/>

                        <ArticleTitle level={4} pageDef={H_EDIT_IMPORTING_REPORTS}/>
                        <p>
                            You may also import reports using the XLSX Template, which can be generated in the{" "}
                            <a href={`#${H_INDEX_PAGE.id}`}>Index Page</a>
                        </p>
                        <p>
                            Note: Only XLSX template files generated from the system are supported for importing.
                            Also, only XLSX files saved using Excel 2010+ version are supported by the system.
                        </p>
                        <p>To import all sheets at once:</p>
                        <ol>
                            <li>Click the <code>Import All</code> button on the global actions section</li>
                            <li>Select the XLSX file you want to import</li>
                            <li>Click <code>Import</code></li>
                        </ol>
                        <ArticleImage src={mr12} alt="Import All Sheets"/>
                        <p>To import one sheet at a time:</p>
                        <ol>
                            <li>
                                Click on the <code>Import</code> button located at the lower right corner
                                of the form view
                            </li>
                            <li>Select the XLSX file you want to import</li>
                            <li>Click <code>Import</code></li>
                        </ol>
                        <ArticleImage src={mr13} alt="Import Sheet"/>
                        <ArticleImage src={mr14} alt="Import Sheet Modal"/>

                        <ArticleTitle level={4} pageDef={H_EDIT_SAVE}/>
                        <p>
                            It's a good idea to save your monthly report whenever you make changes. Saving does not result
                            in the submission of the monthly report: it does not make your report final. It simply allows
                            you to resume where you left off.
                        </p>
                        <p>
                            You can tell which report pages have changed values that haven't yet been saved by looking at
                            the navigation page item and seeing if it has an <code>Unsaved</code> tag.
                        </p>
                        <ArticleImage src={mr15} alt="Unsaved Tag"/>
                        <p>There are two ways to save a report:</p>
                        <p>
                            You can save a specific report page by clicking on the <code>Save</code> button
                            located at the lower right corner of the form view.
                        </p>
                        <ArticleImage src={mr16} alt="Save Form"/>
                        <p>
                            You can save all report pages simultaneously by clicking on the <code>Save All</code> button.
                        </p>
                        <ArticleImage src={mr17} alt="Save All"/>
                        <p>
                            Note: If you leave the Edit Monthly Report page without saving the report forms,
                            all of your unsaved changes will be lost.
                        </p>

                        <ArticleTitle level={4} pageDef={H_EDIT_SUBMIT}/>
                        <p>
                            After ensuring that all required fields have been completed, you may now submit your report.
                            When you submit a report, it is considered finalized.  A report may only be submitted if it
                            has been completely saved. Reports that have been submitted will be
                            used to generate report and dashboard data.
                        </p>
                        <p>
                            To submit the monthly report, click on the <code>Submit</code> button
                        </p>
                        <ArticleImage src={mr18} alt="Submitting a Monthly Report"/>

                        <ArticleTitle level={4} pageDef={H_EDIT_ERRORS_ENCOUNTERED}/>
                        <p>
                            Here is a list of errors that you may encounter when submitting monthly reports, as well as
                            instructions on how to resolve them.
                        </p>
                        <h5>Missing Values</h5>
                        <p>
                            If your monthly report is incomplete or contains missing values, this error message will
                            appear. Simply fill in the missing values to fix this error. Also, if a value is supposed to be
                            zero, remember that you must specify it explicitly, as leaving it blank will mark it
                            as incomplete.
                        </p>
                        <ArticleImage src={mr19} alt="Error - Missing Values"/>
                        <h5>Must be the sum of</h5>
                        <p>
                            If the sum of the values in your monthly report does not match those of certain indicators,
                            this error message will appear. To correct this error, ensure that the total of the indicators
                            in the list equals the total of the main indicator specified.
                        </p>
                        <ArticleImage src={mr20} alt="Error - Must be the sum of"/>
                        <h5>Must be equal to</h5>
                        <p>
                            This error occurs when the values of one indicator is supposed to be equal to the values of
                            another indicator. To fix this error, make sure that both of the indicators specified
                            in the error message have the same value.
                        </p>
                        <ArticleImage src={mr21} alt="Error - Must be equal to"/>
                    </section>
                </Article>
            </div>
        );
    }
}

MonthlyReports.contents = contents;
MonthlyReports.pageId = P_MONTHLY;
export default MonthlyReports;