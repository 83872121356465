import React from "react";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import DistributionScreenedChart from "@/screens/Dashboard/DiseaseScreening/Tuberculosis/DistributionScreenedChart";
import InfoBox from "@/components/InfoBox";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_ENTRY = 'default_50';
const TAG_SS = 'default_51';
const TAG_SR = 'default_52';
const TAG_TBMS = 'default_53';

class DistributionScreened extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entry: 0,
            pEntry: 0,
            ss: 0,
            pSs: 0,
            sr: 0,
            pSr: 0,
            tbms: 0,
            pTbms: 0,
            total: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const entry = getAggregateByTag(TAG_ENTRY);
        const ss = getAggregateByTag(TAG_SS);
        const sr = getAggregateByTag(TAG_SR);
        const tbms = getAggregateByTag(TAG_TBMS);

        const total = entry.reportTotal + ss.reportTotal + sr.reportTotal + tbms.reportTotal;

        this.setState({
            entry: entry.reportTotal,
            pEntry: pctTotalOver(entry.reportTotal, total),
            ss: ss.reportTotal,
            pSs: pctTotalOver(ss.reportTotal, total),
            sr: sr.reportTotal,
            pSr: pctTotalOver(sr.reportTotal, total),
            tbms: tbms.reportTotal,
            pTbms: pctTotalOver(tbms.reportTotal, total),
            total
        });
    };

    render() {
        const {
            entry, pEntry, ss, pSs, sr,
            pSr, tbms, pTbms, total
        } = this.state;

        return (
            <div className="row">
                <div className="col-lg-5">
                    <div className="j-dash-tabular-group">
                        <h4>Distribution of Screened Detainees by Screening Methods</h4>
                        <table>
                            <thead>
                            <tr>
                                <th>Classification</th>
                                <th>Count</th>
                                <th>Proportion</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Entry</td>
                                <td>{entry}</td>
                                <td>{pEntry}%</td>
                            </tr>
                            <tr>
                                <td>SS</td>
                                <td>{ss}</td>
                                <td>{pSs}%</td>
                            </tr>
                            <tr>
                                <td>SR</td>
                                <td>{sr}</td>
                                <td>{pSr}%</td>
                            </tr>
                            <tr>
                                <td>TBMS</td>
                                <td>{tbms}</td>
                                <td>{pTbms}%</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td>Total</td>
                                <td>{total}</td>
                                <td>100%</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <InfoBox className="simple-info-box"
                             title="Cases detected positive upon entry" value={entry}
                             progressDescription={`${pEntry}% detected`}
                             progress={pEntry} showProgress/>
                </div>
                <div className="col-lg-7">
                    <DistributionScreenedChart entry={pEntry} ss={pSs} sr={pSr} tbms={pTbms}/>
                </div>
            </div>
        );
    }
}

export default withAggregateTagMap(DistributionScreened);