import React from 'react';
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";

const TAG_SYPHILIS = 'default_42';
const TAG_HIV = 'default_43';

class STDMetrics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            syphilisMale: 0,
            syphilisFemale: 0,
            hivMale: 0,
            hivFemale: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const syphilis = getAggregateByTag(TAG_SYPHILIS);
        const hiv = getAggregateByTag(TAG_HIV);

        this.setState({
            syphilisMale: syphilis.reportMale,
            syphilisFemale: syphilis.reportFemale,
            hivMale: hiv.reportMale,
            hivFemale: hiv.reportFemale
        });
    };

    render() {
        const {syphilisMale, syphilisFemale, hivMale, hivFemale} = this.state;

        return (
            <div className="j-dash-tabular-group">
                <table>
                    <thead>
                    <tr>
                        <th>Disease</th>
                        <th>Male</th>
                        <th>Female</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Syphilis</td>
                        <td>{syphilisMale}</td>
                        <td>{syphilisFemale}</td>
                    </tr>
                    <tr>
                        <td>HIV</td>
                        <td>{hivMale}</td>
                        <td>{hivFemale}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withAggregateTagMap(STDMetrics);