import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {config} from "@/config";
import {Indicator} from "@/records/Indicator";

const attributes = {
    active: PropTypes.bool
};

const toOneRelationships = {
    indicator: () => Indicator
};

const toManyRelationships = {
    dependencies: () => Indicator
};

export class IndicatorSumDependency extends JsonApiRecord(
    'indicator-sum-dependencies',
    `${config.apiBaseUrl}/indicator-sum-dependencies`,
    attributes,
    toOneRelationships,
    toManyRelationships
) {
    static get modelName() {
        return 'IndicatorSumDependency';
    }
}