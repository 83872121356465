import React from "react";
import NCDMetrics from "@/screens/Dashboard/DiseaseScreening/NonCommunicableDiseases/NCDMetrics";
import Profile from "@/screens/Dashboard/DiseaseScreening/NonCommunicableDiseases/Profile";

class NonCommunicableDiseases extends React.Component {
    render() {
        return (
            <div className="j-dash-non-communicable j-dash-box-subgroup">
                <h3>Non-Communicable Diseases</h3>
                <NCDMetrics/>
                <Profile/>
            </div>
        );
    }
}

export default NonCommunicableDiseases;