import {AbilityBuilder, Ability} from "@casl/ability";
import {
    PERM_JRS_CONFIGURATION_MANAGE,
    PERM_GROUP_JRS_USERS,
    PERM_GROUP_JRS_USERS_ACTIVATE,
    PERM_GROUP_JRS_USERS_CREATE,
    PERM_GROUP_JRS_USERS_DEACTIVATE,
    PERM_JRS_USERS_MANAGE_ROLES,
    PERM_GROUP_JRS_USERS_READ,
    PERM_GROUP_JRS_USERS_RESET_PASSWORD,
    PERM_GROUP_JRS_USERS_UPDATE,
    PERM_JRS_USERS_CREATE_HQ,
    OFFICE_TYPE_HQ,
    PERM_JRS_USERS_UPDATE_HQ,
    PERM_JRS_USERS_CREATE_REGIONAL,
    PERM_JRS_USERS_UPDATE_REGIONAL,
    OFFICE_TYPE_REGIONAL,
    PERM_JRS_USERS_CREATE_JAIL_LEVEL,
    PERM_JRS_USERS_UPDATE_JAIL_LEVEL,
    OFFICE_TYPE_JAIL_LEVEL,
    ROLE_TYPE_HQ,
    ROLE_TYPE_REGIONAL,
    ROLE_TYPE_JAIL_LEVEL,
    PERM_JRS_USERS_ACTIVATE_REGIONAL,
    PERM_JRS_USERS_ACTIVATE_HQ,
    PERM_JRS_USERS_ACTIVATE_JAIL_LEVEL,
    PERM_JRS_USERS_DEACTIVATE_HQ,
    PERM_JRS_USERS_DEACTIVATE_REGIONAL,
    PERM_JRS_USERS_RESET_PASSWORD_HQ,
    PERM_JRS_USERS_RESET_PASSWORD_REGIONAL,
    PERM_JRS_USERS_RESET_PASSWORD_JAIL_LEVEL,
    PERM_GROUP_JRS_JAIL_LEVEL_REPORTS,
    PERM_JRS_JAIL_LEVEL_REPORTS_MANAGE,
    PERM_JRS_OFFICES_MANAGE, PERM_JRS_JAIL_LEVEL_REPORTS_UNFINALIZE
} from "@/permissions";
import {createContext} from "react";
import {createContextualCan} from "@casl/react";

export const userAbility = new Ability();

export function defineAbilityRules(hasUserId, hasPermission, hasPermissionGroup, hasRole, office) {
    const {can, rules} = new AbilityBuilder();

    if (office) {
        if (office['office_type'] === OFFICE_TYPE_JAIL_LEVEL) {
            can('view', 'Dashboard', ['jail-level-office']);
        } else {
            can('view', 'Dashboard', ['region']);
        }
    }

    if (hasPermission(PERM_JRS_CONFIGURATION_MANAGE)) {
        can('manage', 'Configuration');
    }

    if (hasPermission(PERM_JRS_OFFICES_MANAGE)) {
        can('view', 'AccessManagement');
        can('manage', 'Office');
    }

    if (
        hasPermissionGroup(PERM_GROUP_JRS_USERS)
        || hasPermissionGroup(PERM_GROUP_JRS_USERS_READ)
        || hasPermission(PERM_JRS_USERS_MANAGE_ROLES)
    ) {
        can('view', 'AccessManagement');
    }

    if (hasPermissionGroup(PERM_GROUP_JRS_USERS_READ)) {
        can('view', 'User');
    }

    if (
        hasPermissionGroup(PERM_GROUP_JRS_USERS_CREATE)
    ) {
        can('view', 'User');
        can('create', 'User');
    }

    if (hasPermissionGroup(PERM_GROUP_JRS_USERS_UPDATE)) {
        can('view', 'User');
    }

    if (
        hasPermission(PERM_JRS_USERS_CREATE_HQ)
        || hasPermission(PERM_JRS_USERS_UPDATE_HQ)
    ) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('view', 'Office', {officeType: OFFICE_TYPE_HQ, id: office.id});
        }
        can('view', 'Role', {roleType: {$eq: null}});
        can('view', 'Role', {'roleType.id': ROLE_TYPE_HQ});
    }

    if (hasPermission(PERM_JRS_USERS_UPDATE_HQ)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['details'], {'office.officeType': OFFICE_TYPE_HQ, 'office.id': office.id});
        }
    }

    if (
        hasPermission(PERM_JRS_USERS_CREATE_REGIONAL)
        || hasPermission(PERM_JRS_USERS_UPDATE_REGIONAL)
    ) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('view', 'Office', {
                officeType: OFFICE_TYPE_REGIONAL,
                'parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('view', 'Office', {officeType: OFFICE_TYPE_REGIONAL, id: office.id});
        }
        can('view', 'Role', {roleType: {$eq: null}});
        can('view', 'Role', {'roleType.id': ROLE_TYPE_REGIONAL});
    }

    if (hasPermission(PERM_JRS_USERS_UPDATE_REGIONAL)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['details'], {
                'office.officeType': OFFICE_TYPE_REGIONAL,
                'office.parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('update', 'User', ['details'], {
                'office.officeType': OFFICE_TYPE_REGIONAL,
                'office.id': office.id
            });
        }
    }

    if (
        hasPermission(PERM_JRS_USERS_CREATE_JAIL_LEVEL)
        || hasPermission(PERM_JRS_USERS_UPDATE_JAIL_LEVEL)
    ) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('view', 'Office', {
                officeType: OFFICE_TYPE_JAIL_LEVEL,
                'parent.parent.id' : office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('view', 'Office', {
                officeType: OFFICE_TYPE_JAIL_LEVEL,
                'parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_JAIL_LEVEL) {
            can('view', 'Office', {
                officeType: OFFICE_TYPE_JAIL_LEVEL,
                id: office.id
            });
        }
        can('view', 'Role', {roleType: {$eq: null}});
        can('view', 'Role', {'roleType.id': ROLE_TYPE_JAIL_LEVEL});
    }

    if (hasPermission(PERM_JRS_USERS_UPDATE_JAIL_LEVEL)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['details'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.parent.parent.id' : office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('update', 'User', ['details'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_JAIL_LEVEL) {
            can('update', 'User', ['details'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.id': office.id
            });
        }
    }

    if (hasPermission(PERM_JRS_USERS_MANAGE_ROLES)) {
        can('view', 'AccessManagement')
        can('manage', 'Role');
    }

    if (
        hasPermissionGroup(PERM_GROUP_JRS_USERS_ACTIVATE)
        || hasPermissionGroup(PERM_GROUP_JRS_USERS_DEACTIVATE)
    ) {
        can('view', 'User');
    }

    if (hasPermission(PERM_JRS_USERS_ACTIVATE_HQ)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['activate'], {'office.officeType': OFFICE_TYPE_HQ, 'office.id': office.id});
        }
    }

    if (hasPermission(PERM_JRS_USERS_DEACTIVATE_HQ)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['deactivate'], {'office.officeType': OFFICE_TYPE_HQ, 'office.id': office.id});
        }
    }

    if (hasPermission(PERM_JRS_USERS_ACTIVATE_REGIONAL)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['activate'], {
                'office.officeType': OFFICE_TYPE_REGIONAL,
                'office.parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('update', 'User', ['activate'], {
                'office.officeType': OFFICE_TYPE_REGIONAL,
                'office.id': office.id
            });
        }
    }

    if (hasPermission(PERM_JRS_USERS_DEACTIVATE_REGIONAL)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['deactivate'], {
                'office.officeType': OFFICE_TYPE_REGIONAL,
                'office.parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('update', 'User', ['deactivate'], {
                'office.officeType': OFFICE_TYPE_REGIONAL,
                'office.id': office.id
            });
        }
    }

    if (hasPermission(PERM_JRS_USERS_ACTIVATE_JAIL_LEVEL)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['activate'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.parent.parent.id' : office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('update', 'User', ['activate'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_JAIL_LEVEL) {
            can('update', 'User', ['activate'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.id': office.id
            });
        }
    }

    if (hasPermission(PERM_JRS_USERS_ACTIVATE_JAIL_LEVEL)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['deactivate'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.parent.parent.id' : office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('update', 'User', ['deactivate'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_JAIL_LEVEL) {
            can('update', 'User', ['deactivate'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.id': office.id
            });
        }
    }

    if (hasPermissionGroup(PERM_GROUP_JRS_USERS_RESET_PASSWORD)) {
        can('view', 'User');
    }

    if (hasPermission(PERM_JRS_USERS_RESET_PASSWORD_HQ)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['password'], {'office.officeType': OFFICE_TYPE_HQ, 'office.id': office.id});
        }
    }

    if (hasPermission(PERM_JRS_USERS_RESET_PASSWORD_REGIONAL)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['password'], {
                'office.officeType': OFFICE_TYPE_REGIONAL,
                'office.parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('update', 'User', ['password'], {
                'office.officeType': OFFICE_TYPE_REGIONAL,
                'office.id': office.id
            });
        }
    }

    if (hasPermission(PERM_JRS_USERS_RESET_PASSWORD_JAIL_LEVEL)) {
        if (office['office_type'] === OFFICE_TYPE_HQ) {
            can('update', 'User', ['password'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.parent.parent.id' : office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_REGIONAL) {
            can('update', 'User', ['password'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.parent.id': office.id
            });
        } else if (office['office_type'] === OFFICE_TYPE_JAIL_LEVEL) {
            can('update', 'User', ['password'], {
                'office.officeType': OFFICE_TYPE_JAIL_LEVEL,
                'office.id': office.id
            });
        }
    }

    if (hasPermissionGroup(PERM_GROUP_JRS_JAIL_LEVEL_REPORTS)) {
        can('view', 'JailLevelReport');
    }

    if (hasPermission(PERM_JRS_JAIL_LEVEL_REPORTS_MANAGE)) {
        if (office['office_type'] === OFFICE_TYPE_JAIL_LEVEL) {
            can('create', 'JailLevelReport')
            can('update', 'JailLevelReport', {'office.id': office.id});
            can('update', 'DiseaseMonitoringReport', {'office.id': office.id});
        }
    }

    if (hasPermission(PERM_JRS_JAIL_LEVEL_REPORTS_UNFINALIZE)) {
        can('unfinalize', 'JailLevelReport');
    }

    return rules;
}

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);