import "./styles.css";
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Route} from "react-router-dom";
import moment from 'moment-timezone';
import Main from "@/Main";

import store from './store';

moment.tz.setDefault("Asia/Manila");
const {PUBLIC_URL} = process.env;
const basename = PUBLIC_URL.replace(/^.*\/\/[^/]+/, '');

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={basename}>
            <Route component={Main}/>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);