import React from 'react';
import OptionsIndex from "@/screens/Configuration/Options/OptionsIndex";
import {DataSource} from "@/records/DataSource";

class DataSources extends React.Component {
    render() {
        return (
            <OptionsIndex title="Data Sources"
                          itemSingular="Data Source"
                          optionType={DataSource}
                          />
        );
    }
}

export default DataSources;