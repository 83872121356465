import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {Medication} from "@/records/Medication";

const attributes = {
    total: PropTypes.number
};

const toOneRelationships = {
    medication: () => Medication
};

export class MedicationAggregate extends JsonApiRecord('medication-aggregates', '',
    attributes, toOneRelationships) {
    static get modelName() {
        return 'MedicationAggregate';
    }
}