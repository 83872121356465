import React from 'react';

import ValueButton from "@vallarj/react-adminlte/ValueButton";
import {permissionOrderComparator} from "@/utilities/permissions";
import {highlightString} from "@/utilities/highlight-string";
import Tag from "@/screens/AccessManagement/Roles/Tag";

const PERMISSION_LIMIT = 4;

class RoleIndexItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAllPermissions: false
        };
    }

    handleToggleShowAllPermissionsClick = () => {
        this.setState({showAllPermissions: !this.state.showAllPermissions});
    };

    render() {
        const {showAllPermissions} = this.state;
        const {role, permissions, roleTypes, search} = this.props;

        let rolePermissions = [...role.permissions]
            .sort((a, b) => permissionOrderComparator(a.id, b.id))
            .map(p => permissions[p.id]);

        const excessPermissions = rolePermissions.length - PERMISSION_LIMIT;
        if (!showAllPermissions) {
            rolePermissions = rolePermissions.slice(0, PERMISSION_LIMIT);
        }

        return (
            <div className="j-roles-item">
                <div>
                    <span>{highlightString(search, role.name)}</span>
                    <span>
                        { role.default && <Tag type="system" label="System Preset"/> }
                        { role.inUse && <Tag type="in-use" label="In Use"/> }
                    </span>
                </div>
                <div>
                    <span>{ !!role.roleType ? roleTypes[role.roleType.id].name : "ALL" } </span>
                </div>
                <div>
                    <ul>
                        {
                            rolePermissions.length ? rolePermissions.map(p => (
                                <li key={p.id}>{p.description}</li>
                            )) : <li>No Permissions</li>
                        }
                    </ul>
                    {
                        !showAllPermissions ?
                            (
                                (excessPermissions > 0) &&
                                <span>
                                        <span>+ {excessPermissions} more...</span>
                                        <button onClick={this.handleToggleShowAllPermissionsClick}
                                                className="btn btn-link btn-xs">Show All</button>
                                    </span>
                            ) :
                            <span>
                                <button onClick={this.handleToggleShowAllPermissionsClick}
                                        className="btn btn-link btn-xs">
                                    <i className="fa fa-minus margin-r-5"/>Show Less
                                </button>
                            </span>
                    }
                </div>
                <div>
                    <span className="btn-group pull-right">
                        <ValueButton className="btn btn-default btn-xs" value={role}
                                     onClick={this.props.onEditClick}
                                     disabled={role.default}>
                            <i className="fa fa-pencil"/>
                        </ValueButton>
                        <ValueButton className="btn btn-default btn-xs" value={role}
                                     onClick={this.props.onDeleteClick}
                                     disabled={role.default || role.inUse}>
                            <i className="fa fa-trash"/>
                        </ValueButton>
                    </span>
                </div>
            </div>
        );
    }
}

export default RoleIndexItem;