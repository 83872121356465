import React from 'react';
import BaseIndicatorSubcategories from "@/screens/Configuration/IndicatorSubcategories";
import {DentalHealthIndicatorCategory} from "@/records/DentalHealthIndicatorCategory";
import {DentalHealthIndicatorSubcategory} from "@/records/DentalHealthIndicatorSubcategory";

class DentalHealthIndicatorSubcategories extends React.Component {
    render() {
        return (
            <BaseIndicatorSubcategories
                categoryResourceType={DentalHealthIndicatorCategory}
                subcategoryResourceType={DentalHealthIndicatorSubcategory}
                title="Dental Health Indicator Subcategories"
                hasShowTotal={false}
                hasQuarterly={false}
            />
        );
    }
}

export default DentalHealthIndicatorSubcategories;