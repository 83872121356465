import React from 'react';
import PropTypes from 'prop-types';
import Box from '@vallarj/react-adminlte/Box';
import {createCancelToken} from "@/utilities/jsonapi";
import FocusAwareNumberInput from "@/components/FocusAwareNumberInput";
import {fetchOptions, processWorksheet} from "@/screens/JailLevelReports/JailLevelReportEntry/utilities";
import {noop} from "@/utilities/noop";
import ImportModal from "@/components/ImportModal";

class OptionItem extends React.Component {
    handleMaleChange = ({floatValue}) => {
        const {option, onAttributeChange} = this.props;
        onAttributeChange(option.id, 'male', floatValue);
    };

    handleFemaleChange = ({floatValue}) => {
        const {option, onAttributeChange} = this.props;
        onAttributeChange(option.id, 'female', floatValue);
    };

    render() {
        const {option, isEditMode, notifyFocusChange, getPosition, currentFocus} = this.props;

        let value = this.props.value || {};
        const {male, female} = value;
        const incomplete = male == null || female == null;
        const className = "j-options-report-item" + (incomplete ? " incomplete" : "");
        return (
            <div className={className}>
                <span>{option.name}</span>
                <span><FocusAwareNumberInput value={male} onValueChange={this.handleMaleChange}
                                             notifyFocusChange={notifyFocusChange}
                                             getPosition={getPosition} currentFocus={currentFocus}
                                             disabled={!isEditMode}/></span>
                <span><FocusAwareNumberInput value={female} onValueChange={this.handleFemaleChange}
                                             notifyFocusChange={notifyFocusChange}
                                             getPosition={getPosition} currentFocus={currentFocus}
                                             disabled={!isEditMode}/></span>
                <span>{(male || 0) + (female || 0)}</span>
            </div>
        )
    }
}

class OptionsBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            options: [],
            optionMap: {},
            currentInput: 0
        };

        this.inputCount = 0;
        this.fetchCancelToken = createCancelToken();
        this.openModal = noop;
    }

    componentDidMount() {
        this.fetchOptions();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    registerOpenModal = openModal => {
        this.openModal = openModal;
    }

    fetchOptions = () => {
        const {optionType} = this.props;

        this.setState({isLoading: true});
        fetchOptions(this.fetchCancelToken, optionType)
            .then(({options, optionMap}) => {
                this.setState({
                    isLoading: false,
                    options,
                    optionMap
                });
            });
    };

    handleAttributeChange = (attributeId, type, count) => {
        this.props.onAttributeChange(this.props.attributeName, attributeId, type, count);
    };

    handleAttributeRevert = () => {
        this.props.onAttributeRevert(this.props.attributeName);
    }

    handleSaveClick = () => {
        this.props.onSaveClick(this.props.attributeName);
    };

    handleFocusChange = (direction, currentPos) => {
        let newPos = currentPos;
        switch (direction) {
            case 'up':
                newPos -= 2;
                break;
            case 'down':
                newPos += 2;
                break;
            case 'left':
                newPos -= 1;
                break;
            case 'right':
                newPos += 1;
                break;
        }

        if (newPos >= 0 && newPos < this.inputCount) {
            this.setState({currentInput: newPos});
        }
    };

    getInputPosition = () => {
        return this.inputCount++;
    };

    handleImportClick = () => {
        this.openModal();
    };

    processWorkbook = workbook => {
        const {optionMap} = this.state;
        const {attributeName, onAttributesReplace, sheetIndex} = this.props;
        return processWorksheet(workbook, sheetIndex, 6, 'B', 'D', 'E', "")
            .then(items => {
                const importItems = {};
                const missingOptions = [];
                Object.keys(optionMap).forEach(id => {
                    if (!items.hasOwnProperty(id)) {
                        const option = optionMap[id];
                        missingOptions.push({
                            id,
                            name: option.name
                        });
                    } else {
                        importItems[id] = items[id];
                    }
                });

                if (missingOptions.length === 0) {
                    onAttributesReplace(attributeName, importItems);
                    return {status: "success"};
                } else if (Object.keys(importItems).length === 0) {
                    return {status: "empty"};
                } else {
                    onAttributesReplace(attributeName, importItems);
                    return {
                        status: "partial",
                        missing: missingOptions
                    };
                }
            });
    }

    render() {
        const {boxTitle, headerTitle, isLoading: entryIsLoading, isEditMode} = this.props;
        const {isLoading, options, currentInput} = this.state;
        let values = this.props.values || {};

        return (<>
            <ImportModal header={`Import ${boxTitle}`} processWorkbook={this.processWorkbook}
                         registerOpenModal={this.registerOpenModal}/>
            <Box theme="box-primary" isLoading={isLoading || entryIsLoading}>
                <Box.Header title={boxTitle}/>
                <Box.Body>
                    <div className="j-options-report">
                        <div className="j-options-report-header">
                            <span>{headerTitle}</span>
                            <span>Male</span>
                            <span>Female</span>
                            <span>Total</span>
                        </div>
                        {
                            options.map(option => (
                                <OptionItem key={option.id} option={option}
                                            value={values[option.id] || null} isEditMode={isEditMode}
                                            notifyFocusChange={this.handleFocusChange}
                                            getPosition={this.getInputPosition} currentFocus={currentInput}
                                            onAttributeChange={this.handleAttributeChange}/>
                            ))
                        }
                    </div>
                </Box.Body>
                {
                    isEditMode &&
                    <Box.Footer>
                        <div className="pull-right">
                            <button className="btn btn-default margin-r-5"
                                    onClick={this.handleAttributeRevert}
                                    disabled={!this.props.attributesChanged || entryIsLoading}>
                                <i className="fa fa-undo margin-r-5"/>Revert
                            </button>
                            <button className="btn btn-default margin-r-5"
                                    onClick={this.handleImportClick}>
                                <i className="fa fa-upload margin-r-5"/>Import
                            </button>
                            <button className="btn btn-primary" onClick={this.handleSaveClick}
                                    disabled={!this.props.attributesChanged || entryIsLoading}>
                                {
                                    entryIsLoading ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-save margin-r-5"/>
                                }Save
                            </button>
                        </div>
                    </Box.Footer>
                }
            </Box>
        </>);
    }
}

OptionsBox.propTypes = {
    boxTitle: PropTypes.string.isRequired,
    headerTitle: PropTypes.string.isRequired,
    attributeName: PropTypes.string.isRequired,
    optionType: PropTypes.func.isRequired,
    onAttributesReplace: PropTypes.func.isRequired,
    onAttributeChange: PropTypes.func.isRequired,
    onAttributeRevert: PropTypes.func.isRequired,
    attributesChanged: PropTypes.bool.isRequired,
    sheetIndex: PropTypes.number.isRequired,
    values: PropTypes.object,
    onSaveClick: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default OptionsBox;