import React from 'react';
import PropTypes from "prop-types";
import Box from '@vallarj/react-adminlte/Box';
import TextInput from '@vallarj/react-adminlte/Form/TextInput';
import SelectInput from '@vallarj/react-adminlte/Form/SelectInput';
import {
    createCancelToken,
    fetchAndFilterResourceCollection,
    fetchResourceCollection,
    waitForAllRequests
} from "@/utilities/jsonapi";
import {DataSource} from "@/records/DataSource";
import {escapeRegExp} from "@/utilities/highlight-string";
import IndicatorModal from "./IndicatorModal";
import IndicatorIndexItem from "./IndicatorIndexItem";
import {noop} from "@/utilities/noop";

class Indicators extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search: null,
            isLoading: true,
            indicatorCategories: [],
            indicatorCategoryMap: {},
            indicatorSubcategories: [],
            indicatorSubcategoryMap: {},
            dataSources: [],
            dataSourceMap: {},
            indicators: [],
            indicatorCategory: null,
            indicatorSubcategory: null
        };

        this.openModal = noop;
        this.fetchCancelToken = createCancelToken();
    }

    componentDidMount() {
        this.fetchDependencies();
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    fetchDependencies = () => {
        this.setState({isLoading: true});
        const {
            categoryResourceType, subcategoryResourceType,
            indicatorResourceType, hasDataSource
        } = this.props;

        waitForAllRequests([
            fetchResourceCollection(categoryResourceType),
            fetchAndFilterResourceCollection(
                subcategoryResourceType,
                item => !(item.relationships || {}).parent
            ),
            fetchResourceCollection(indicatorResourceType),
            ...hasDataSource ? [fetchResourceCollection(DataSource)] : []
        ]).onSuccess(([categories, subcategories, indicators, dataSources]) => {
            const indicatorCategoryMap = {};
            const indicatorSubcategoryMap = {};
            const dataSourceMap = {};
            categories.forEach(c => { indicatorCategoryMap[c.id] = c });
            subcategories = this.flattenSubcategories(subcategories);
            subcategories.forEach(s => { indicatorSubcategoryMap[s.id] = s});

            if (hasDataSource) {
                dataSources.forEach(d => { dataSourceMap[d.id] = d });
            }

            this.setState({
                indicatorCategories: categories,
                indicatorCategoryMap,
                indicatorSubcategories: subcategories,
                indicatorSubcategoryMap,
                dataSources: dataSources || [],
                dataSourceMap,
                indicators,
                isLoading: false
            });

            if (categories.length) {
                const subcategory = subcategories.find(s => s.category.id === categories[0].id) || null;
                this.setState({
                    indicatorCategory: categories[0],
                    indicatorSubcategory: subcategory
                });
            }
        }).execute(this.fetchCancelToken);
    };

    fetchIndicators = () => {
        const {indicatorResourceType} = this.props;

        this.setState({isLoading: true});
        fetchResourceCollection(indicatorResourceType)
            .onSuccess(collection => {
                this.setState({
                    isLoading: false,
                    indicators: collection
                });
            })
            .execute(this.fetchCancelToken);
    };

    flattenSubcategories = subcategories => {
        let flattened = [];
        subcategories.forEach(s => {
            flattened = [...flattened, s, ...this.flattenSubcategories(s.children)];
        });
        return flattened;
    };

    registerOpenModal = openModal => {
        this.openModal = openModal;
    };

    handleAddIndicatorClick = () => {
        const {indicatorResourceType, hasSpecial} = this.props;
        const {indicatorSubcategory} = this.state;
        const mergeObject = {
            subcategory: indicatorSubcategory,
            active: true
        };

        if (hasSpecial) {
            mergeObject['special'] = false;
        }

        // noinspection JSUnresolvedFunction
        this.openModal('create', (new indicatorResourceType()).mergeUpdate(mergeObject));
    };

    handleEditIndicatorClick = indicator => {
        this.openModal('edit', indicator);
    };

    handleDeleteIndicatorClick = indicator => {
        this.openModal('delete', indicator);
    };

    handleSearchChange = (_, value) => {
        this.setState({search: value});
    };

    handleFilterChange = (filter, value) => {
        const {indicatorSubcategories} = this.state;
        if (filter === 'indicatorCategory') {
            const indicatorSubcategory = indicatorSubcategories.find(i => i.category.id === value.id) || null;
            this.setState({indicatorSubcategory});
        }

        this.setState({[filter]: value});
    };

    getLabelByName = option => option.name || "<No Label>";
    getOptionId = option => option.id;

    resolveIndicators = () => {
        const {indicatorCategory, indicatorSubcategory, indicators} = this.state;
        const search = (this.state.search && this.state.search.trim()) || "";

        if (!indicatorCategory || !indicatorSubcategory) {
            return [];
        }

        return indicators.filter(i => (
            (!search || i.name.match(RegExp(escapeRegExp(search), "i")))
            && i.subcategory.id === indicatorSubcategory.id
        ));
    };

    resolveSubcategories = () => {
        const {indicatorCategory, indicatorSubcategories} = this.state;
        if (!indicatorCategory) {
            return [];
        }

        return indicatorSubcategories.filter(i => i.category.id === indicatorCategory.id);
    };

    renderListHeader = () => {
        const {hasDataSource} = this.props;
        const className = hasDataSource ? 'j-indicators-header' : 'j-base-indicators-header';

        return (
            <div className={`j-config-list-header ${className}`}>
                <span>Order</span>
                <span>Indicator</span>
                <span>Summary Type</span>
                {
                    hasDataSource &&
                    <span>Data Sources</span>
                }
            </div>
        );
    };

    render() {
        const {hasSpecial, hasDataSource, title} = this.props;
        const {
            search, isLoading, indicatorCategories, indicatorCategory,
            indicatorSubcategory, dataSources
        } = this.state;

        const indicators = this.resolveIndicators();
        const subcategories = this.resolveSubcategories();

        return (<>
            <IndicatorModal dataSources={dataSources}
                            openModalRef={this.registerOpenModal}
                            onSuccess={this.fetchIndicators}
                            hasSpecial={hasSpecial}
                            hasDataSource={hasDataSource}/>
            <Box theme='box-primary' isLoading={isLoading}>
                <Box.Header title={title}/>
                <Box.Body>
                    <TextInput name="search" placeholder="Search..."
                               value={search} onChange={this.handleSearchChange}/>
                    <div className="j-indicators-select">
                        <div>
                            <div className="row">
                                <div className="col-xs-6">
                                    <SelectInput name="indicatorCategory" label="Category"
                                                 options={indicatorCategories} value={indicatorCategory}
                                                 getOptionLabel={this.getLabelByName} getOptionValue={this.getOptionId}
                                                 onChange={this.handleFilterChange} searchable/>
                                </div>
                                <div className="col-xs-6">
                                    <SelectInput name="indicatorSubcategory" label="Subcategory"
                                                 options={subcategories} value={indicatorSubcategory}
                                                 getOptionLabel={this.getLabelByName} getOptionValue={this.getOptionId}
                                                 onChange={this.handleFilterChange} searchable/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <button className="btn btn-default btn-block" disabled={!indicatorSubcategory}
                                        onClick={this.handleAddIndicatorClick}>
                                    <i className="fa fa-plus-circle margin-r-5"/>Add Indicator
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="j-indicators j-box-scroll">
                        {this.renderListHeader()}
                        {
                            indicators.map(i => (
                                <IndicatorIndexItem key={i.id} indicator={i} search={search}
                                                    onEditClick={this.handleEditIndicatorClick}
                                                    onDeleteClick={this.handleDeleteIndicatorClick}
                                                    dataSources={dataSources}
                                                    hasDataSource={hasDataSource}
                                                    hasSpecial={hasSpecial}/>
                            ))
                        }
                    </div>
                </Box.Body>
            </Box>
        </>);
    }
}

Indicators.propTypes = {
    categoryResourceType: PropTypes.func.isRequired,
    subcategoryResourceType: PropTypes.func.isRequired,
    indicatorResourceType: PropTypes.func.isRequired,
    hasDataSource: PropTypes.bool.isRequired,
    hasSpecial: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
};

export default Indicators;