import React from "react";
import {NavLink} from "react-router-dom";
import ReportStatsTag from "@/components/ReportStatsTag";

class Menu extends React.Component {
    render() {
        const {
            path, indicatorsChanged, isEditMode, canManageEntry, submitting, finalized
        } = this.props;

        return (
            <div className="col-xs-3 config-menu">
                <div>
                    <h4>Reporting</h4>
                    <nav>
                        <ul>
                            <li>
                                <NavLink to={`${path}/indicators`}>
                                    Disease Surveillance Indicators
                                    {
                                        indicatorsChanged &&
                                        <ReportStatsTag type={ReportStatsTag.Changed}/>
                                    }
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    {
                        isEditMode &&
                        <div className="btn-block btn-group-vertical">
                            <button className="btn btn-default" onClick={this.props.onImportClick}>
                                <i className="fa fa-upload margin-r-5"/>Import File
                            </button>
                            <button className="btn btn-default" onClick={this.props.onRevertClick}
                                    disabled={!indicatorsChanged}>
                                <i className="fa fa-undo margin-r-5"/>Revert
                            </button>
                            <button className="btn btn-primary" onClick={this.props.onSaveClick}
                                    disabled={!indicatorsChanged}>
                                <i className="fa fa-save margin-r-5"/>Save
                            </button>
                            <button className="btn btn-success" disabled={indicatorsChanged || submitting}
                                    onClick={this.props.onSubmitClick}>
                                {
                                    submitting ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-check margin-r-5"/>
                                }Submit
                            </button>
                        </div>
                    }
                    {
                        !isEditMode && canManageEntry && !finalized &&
                        <div className="btn-group-vertical btn-block">
                            <NavLink to={`${path.replace('view', 'edit')}`}
                                     className="btn btn-default">
                                <i className="fa fa-pencil margin-r-5"/>Edit Report
                            </NavLink>
                            <button className="btn btn-success" disabled={indicatorsChanged || submitting}
                                    onClick={this.props.onSubmitClick}>
                                {
                                    submitting ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-check margin-r-5"/>
                                }Submit
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Menu;