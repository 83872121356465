import React from "react";
import {config} from "@/config";
import {createCancelToken, fetchResourceCollection, waitForAllRequests} from "@/utilities/jsonapi";
import {CauseOfDeath} from "@/records/CauseOfDeath";
import {CauseOfDeathAggregate} from "@/records/CauseOfDeathAggregate";
import TopCausesOfDeathChart from "@/screens/Dashboard/PopulationStatistics/TopCausesOfDeathChart";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import {buildAttributeChartDataSet} from "@/utilities/dashboard";

class TopCausesOfDeath extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            causesOfDeathMap: null,
            topCausesOfDeathDataSet: buildAttributeChartDataSet([], 'causeOfDeath', "Number of Deaths"),
        };
        this.fetchCancelToken = createCancelToken();
    }

    componentDidMount() {
        this.fetchCausesOfDeath();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fromDate, toDate, office} = this.props;

        if (prevProps.office !== office || prevProps.fromDate !== fromDate || prevProps.toDate !== toDate) {
            this.fetchCausesOfDeath();
        }
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    fetchCausesOfDeath = () => {
        const {causesOfDeathMap} = this.state;
        const {fromDate, toDate, office} = this.props;
        if (!fromDate || !toDate || !office) {
            return null;
        }

        const params = {
            f: fromDate.format('YYYY-MM-DD'),
            t: toDate.format('YYYY-MM-DD')
        };

        let endpointSubPath;
        switch (office.officeType) {
            case OFFICE_TYPE_HQ:
                endpointSubPath = 'headquarters';
                break;
            case OFFICE_TYPE_REGIONAL:
                endpointSubPath = 'region';
                break;
            case OFFICE_TYPE_JAIL_LEVEL:
                endpointSubPath = 'jail-level-office';
                break;
            default:
                return;
        }
        const causesOfDeathAggregatesEndpoint = `${config.apiBaseUrl}/reports/cause-of-death-aggregates/` +
            `${endpointSubPath}/${office.id}`;

        if (!causesOfDeathMap) {
            waitForAllRequests([
                fetchResourceCollection(CauseOfDeath),
                fetchResourceCollection(CauseOfDeathAggregate, params, causesOfDeathAggregatesEndpoint)
            ]).onSuccess(([causesOfDeath, aggregates]) => {
                const causesOfDeathMap = {};
                causesOfDeath.forEach(c => {
                    causesOfDeathMap[c.id] = c;
                });

                this.setState({causesOfDeathMap});
                this.setDataSet(aggregates, causesOfDeathMap);
            }).execute(this.fetchCancelToken);
        } else {
            fetchResourceCollection(CauseOfDeathAggregate, params, causesOfDeathAggregatesEndpoint)
                .onSuccess(aggregates => this.setDataSet(aggregates, causesOfDeathMap))
                .execute(this.fetchCancelToken);
        }
    };

    setDataSet = (aggregates, causesOfDeathMap) => {
        aggregates = aggregates.map(a => a.set('causeOfDeath', causesOfDeathMap[a.causeOfDeath.id]));
        this.setState({
            topCausesOfDeathDataSet: buildAttributeChartDataSet(aggregates, 'causeOfDeath',"Number of Deaths", '#f59fad')
        });
    };

    render() {
        const {topCausesOfDeathDataSet} = this.state;

        return (
            <div className="j-indicator-top-causes-of-death-chart">
                <TopCausesOfDeathChart dataSet={topCausesOfDeathDataSet}/>
            </div>
        );
    }
}

export default TopCausesOfDeath;