import React from "react";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";

const TAG_POSITIVE_HB = 'default_47';
const TAG_POSITIVE_HCV = 'default_48';

class HepatitisMetrics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hepaB: 0,
            hepaC: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const hepaB = getAggregateByTag(TAG_POSITIVE_HB);
        const hepaC = getAggregateByTag(TAG_POSITIVE_HCV);

        this.setState({
            hepaB: hepaB.reportTotal,
            hepaC: hepaC.reportTotal
        });
    };

    render() {
        const {hepaB, hepaC} = this.state;

        return (
            <div className="j-dash-tabular-group two-column">
                <table>
                    <thead>
                    <tr>
                        <th colSpan={2}>Hepatitis</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Hepatitis B</td>
                        <td>{hepaB}</td>
                    </tr>
                    <tr>
                        <td>Hepatitis C</td>
                        <td>{hepaC}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withAggregateTagMap(HepatitisMetrics);