import React from "react";
import Article from "@/screens/Docs/Article";
import ArticleImage from "@/screens/Docs/Article/ArticleImage";
import Tag from "@/screens/Configuration/Tag";
import conf01 from './images/conf-01.jpg';
import conf02 from './images/conf-02.jpg';
import conf03 from './images/conf-03.jpg';
import conf04 from './images/conf-04.jpg';
import conf05 from './images/conf-05.jpg';
import conf06 from './images/conf-06.jpg';
import conf07 from './images/conf-07.jpg';
import conf08 from './images/conf-08.jpg';
import conf09 from './images/conf-09.jpg';
import conf10 from './images/conf-10.jpg';
import conf11 from './images/conf-11.jpg';
import conf12 from './images/conf-12.jpg';
import conf13 from './images/conf-13.jpg';
import conf14 from './images/conf-14.jpg';
import conf15 from './images/conf-15.jpg';
import conf16 from './images/conf-16.jpg';
import conf17 from './images/conf-17.jpg';
import conf18 from './images/conf-18.jpg';
import conf19 from './images/conf-19.jpg';
import conf20 from './images/conf-20.jpg';
import conf21 from './images/conf-21.jpg';
import conf22 from './images/conf-22.jpg';
import conf23 from './images/conf-23.jpg';
import conf24 from './images/conf-24.jpg';
import conf25 from './images/conf-25.jpg';
import conf26 from './images/conf-26.jpg';
import {createPageDefinition} from "@/screens/Docs/utilities";
import ArticleTitle from "@/screens/Docs/Article/ArticleTitle";

const P_CONFIG = 'config';
const H_OVERVIEW = createPageDefinition(P_CONFIG, 'overview', 'Overview');
const H_OVERVIEW_TAGS = createPageDefinition(P_CONFIG, 'overview-tags', 'Common Configuration Tags');
const H_MONTHLY = createPageDefinition(P_CONFIG, 'monthly', 'Monthly Report');
const H_MONTHLY_IND_CAT = createPageDefinition(P_CONFIG, 'monthly-indicator-categories', 'Indicator Categories');
const H_MONTHLY_IND_CAT_ACTIONS = createPageDefinition(P_CONFIG, 'monthly-indicator-categories-actions', 'Indicator Category Actions');
const H_MONTHLY_IND_CAT_FIELDS = createPageDefinition(P_CONFIG, 'monthly-indicator-categories-fields', 'Indicator Category Fields');
const H_MONTHLY_IND_SUB = createPageDefinition(P_CONFIG, 'monthly-indicator-subcategories', 'Indicator Subcategories');
const H_MONTHLY_IND_SUB_ACTIONS = createPageDefinition(P_CONFIG, 'monthly-indicator-subcategories-actions', 'Indicator Subcategory Actions');
const H_MONTHLY_IND_SUB_FIELDS = createPageDefinition(P_CONFIG, 'monthly-indicator-subcategories-fields', 'Indicator Subcategory Fields');
const H_MONTHLY_IND = createPageDefinition(P_CONFIG, 'monthly-indicators', 'Indicators');
const H_MONTHLY_IND_ACTIONS = createPageDefinition(P_CONFIG, 'monthly-indicators-actions', 'Indicator Actions');
const H_MONTHLY_IND_FIELDS = createPageDefinition(P_CONFIG, 'monthly-indicators-fields', 'Indicator Fields');
const H_MONTHLY_IND_SUM = createPageDefinition(P_CONFIG, 'monthly-indicator-sum-validation', 'Indicator Sum Validation');
const H_MONTHLY_IND_SUM_ACTIONS = createPageDefinition(P_CONFIG, 'monthly-indicator-sum-validation-actions', 'Indicator Sum Validation Actions');
const H_MONTHLY_IND_SUM_FIELDS = createPageDefinition(P_CONFIG, 'monthly-indicator-sum-validation-fields', 'Indicator Sum Fields');
const H_MONTHLY_REPORT_OPTS = createPageDefinition(P_CONFIG, 'monthly-report-options', 'Monthly Report Options');
const H_MONTHLY_REPORT_OPTS_ACTIONS = createPageDefinition(P_CONFIG, 'monthly-report-options-actions', 'Monthly Report Options Actions');
const H_MONTHLY_REPORT_OPTS_FIELDS = createPageDefinition(P_CONFIG, 'monthly-report-options-fields', 'Monthly Report Options Fields');
const H_DS = createPageDefinition(P_CONFIG, 'ds', 'Disease Surveillance and Monitoring');
const H_DS_IND_CAT = createPageDefinition(P_CONFIG, 'ds-indicator-categories', 'Indicator Categories');
const H_DS_IND_CAT_ACTIONS = createPageDefinition(P_CONFIG, 'ds-indicator-categories-actions', 'Indicator Category Actions');
const H_DS_IND_CAT_FIELDS = createPageDefinition(P_CONFIG, 'ds-indicator-categories-fields', 'Indicator Category Fields');
const H_DS_IND_SUB = createPageDefinition(P_CONFIG, 'ds-indicator-subcategories', 'Indicator Subcategories');
const H_DS_IND_SUB_ACTIONS = createPageDefinition(P_CONFIG, 'ds-indicator-subcategories-actions', 'Indicator Subcategory Actions');
const H_DS_IND_SUB_FIELDS = createPageDefinition(P_CONFIG, 'ds-indicator-subcategories-fields', 'Indicator Subcategory Fields');
const H_DS_IND = createPageDefinition(P_CONFIG, 'ds-indicators', 'Indicators');
const H_DS_IND_ACTIONS = createPageDefinition(P_CONFIG, 'ds-indicators-actions', 'Indicator Actions');
const H_DS_IND_FIELDS = createPageDefinition(P_CONFIG, 'ds-indicators-fields', 'Indicator Fields');
const H_DS_DASHBOARD_COMP = createPageDefinition(P_CONFIG, 'ds-dashboard-components', 'Custom Dashboard Components');
const H_DS_DASHBOARD_COMP_ACTIONS = createPageDefinition(P_CONFIG, 'ds-dashboard-components-actions', 'Custom Dashboard Component Actions');
const H_DS_DASHBOARD_COMP_FIELDS = createPageDefinition(P_CONFIG, 'ds-dashboard-components-fields', 'Custom Dashboard Component Fields');
const H_DS_DASHBOARD_COMP_IND_FIELDS = createPageDefinition(P_CONFIG, 'ds-dashboard-components-indicator-fields', 'Component Indicator Fields');

const contents = [
    {
        ...H_OVERVIEW,
        contents: [
            H_OVERVIEW_TAGS
        ]
    },
    {
        ...H_MONTHLY,
        contents: [{
            ...H_MONTHLY_IND_CAT,
            contents: [
                H_MONTHLY_IND_CAT_ACTIONS,
                H_MONTHLY_IND_CAT_FIELDS
            ]
        }, {
            ...H_MONTHLY_IND_SUB,
            contents: [
                H_MONTHLY_IND_SUB_ACTIONS,
                H_MONTHLY_IND_SUB_FIELDS
            ]
        }, {
            ...H_MONTHLY_IND,
            contents: [
                H_MONTHLY_IND_ACTIONS,
                H_MONTHLY_IND_FIELDS
            ]
        }, {
            ...H_MONTHLY_IND_SUM,
            contents: [
                H_MONTHLY_IND_SUM_ACTIONS,
                H_MONTHLY_IND_SUM_FIELDS
            ]
        }, {
            ...H_MONTHLY_REPORT_OPTS,
            contents: [
                H_MONTHLY_REPORT_OPTS_ACTIONS,
                H_MONTHLY_REPORT_OPTS_FIELDS
            ]
        }]
    },
    {
        ...H_DS,
        contents: [{
            ...H_DS_IND_CAT,
            contents: [
                H_DS_IND_CAT_ACTIONS,
                H_DS_IND_CAT_FIELDS
            ]
        }, {
            ...H_DS_IND_SUB,
            contents: [
                H_DS_IND_SUB_ACTIONS,
                H_DS_IND_SUB_FIELDS
            ]
        }, {
            ...H_DS_IND,
            contents: [
                H_DS_IND_ACTIONS,
                H_DS_IND_FIELDS
            ]
        }, {
            ...H_DS_DASHBOARD_COMP,
            contents: [
                H_DS_DASHBOARD_COMP_ACTIONS,
                H_DS_DASHBOARD_COMP_FIELDS,
                H_DS_DASHBOARD_COMP_IND_FIELDS
            ]
        }]
    }
];

class Configuration extends React.Component {
    render() {
        return (
            <div className="row">
                <Article title="Configuration" id={P_CONFIG}
                         contentTitle="The configuration page is where you can manage the system configuration, which includes indicators and annex form options. Only headquarters administrators have access to the configuration page."
                         contents={contents}>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_OVERVIEW}/>
                        <ArticleImage src={conf01} alt="Configuration Overview"/>
                        <ol>
                            <li>
                                <strong>Page Navigation</strong>
                                <p>Navigate through the different configuration pages</p>
                            </li>
                            <li>
                                <strong>Configuration Page</strong>
                                <p>Displays the currently selected configuration page</p>
                            </li>
                        </ol>
                        <ArticleTitle level={4} pageDef={H_OVERVIEW_TAGS}/>
                        <p>The configuration tags indicate the state of a configuration entry.</p>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Status</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><Tag text="System Preset" className="j-config-item-tag-system"/></td>
                                <td>Denotes that a configuration entry is a system default. System preset
                                    entries are not editable or deletable.</td>
                            </tr>
                            <tr>
                                <td><Tag text="In Use" className="j-config-item-tag-in-use"/></td>
                                <td>Indicates that a configuration entry is currently in use. Entries that are
                                    currently in use can be edited but not deleted.</td>
                            </tr>
                            <tr>
                                <td><Tag text="Inactive" className="j-config-item-tag-inactive"/></td>
                                <td>Indicates that a configuration entry has been deactivated. Inactive
                                    configuration entries will not appear in XLSX reports, templates, and web forms and
                                    they will not be required to be present when submitting reports.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_MONTHLY}/>
                        <p>
                            System configuration related to monthly reports
                        </p>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_MONTHLY_IND_CAT}/>
                        <p>This section is applicable to the following configuration pages:</p>
                        <ul>
                            <li>Indicator Categories <small><em>(Indicator Reporting Tool (J001))</em></small></li>
                            <li>Dental Health Indicator Categories <small><em>(Dental Health (J003))</em></small></li>
                            <li>Mental Health Indicator Categories <small><em>(Mental Health (J004))</em></small></li>
                        </ul>
                        <p>
                            Indicator categories classify indicators into
                            distinct parent categories in order to organize related indicators. In reports, templates, and
                            web forms, indicator categories are distinguished by a dark blue row color.
                        </p>
                        <ArticleImage src={conf02} alt="Indicator Categories"/>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_IND_CAT_ACTIONS}/>
                        <ol>
                            <li>To add an indicator category, click the <code>Add Indicator Category</code> button</li>
                            <li>To edit an indicator category, click the <code>Edit</code> button</li>
                            <li>
                                To delete an indicator category, click the <code>Delete</code> button. Note that indicator
                                categories in use can not be deleted.
                            </li>
                        </ol>
                        <ArticleImage src={conf03} alt="Indicator Category Actions"/>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_IND_CAT_FIELDS}/>
                        <ArticleImage src={conf04} alt="Indicator Category Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Indicator Category</strong></td>
                                <td>Indicator category name</td>
                            </tr>
                            <tr>
                                <td><strong>Order Index</strong></td>
                                <td>Specifies the order in which the indicator category
                                    should appear in XLSX forms, templates, and web forms.</td>
                            </tr>
                            <tr>
                                <td><strong>Active</strong></td>
                                <td>Active indicator categories will be included in the XLSX forms,
                                    templates, and web forms. Uncheck this box to deactivate an indicator category.
                                    Deactivating an indicator category will also deactivate all child subcategories and
                                    indicators.</td>
                            </tr>
                            <tr>
                                <td><strong>Subcategory Numbered</strong></td>
                                <td>
                                    <p>
                                        <small><em>This option is applicable to J001 indicator categories only.</em></small>
                                    </p>
                                    <p>
                                        When enabled, it displays an index number beside
                                        the title of the topmost subcategory under this indicator category in XLSX
                                        forms and templates.
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_MONTHLY_IND_SUB}/>
                        <p>This section is applicable to the following configuration pages:</p>
                        <ul>
                            <li>Indicator Subcategories <small><em>(Indicator Reporting Tool (J001))</em></small></li>
                            <li>Dental Health Indicator Subcategories <small><em>(Dental Health (J003))</em></small></li>
                            <li>Mental Health Indicator Subcategories <small><em>(Mental Health (J004))</em></small></li>
                        </ul>
                        <p>
                            Indicators can be divided further into
                            distinct subcategories. Indicator subcategories are distinguished in reports, templates,
                            and web forms by a light blue row color.
                        </p>
                        <ArticleImage src={conf05} alt="Indicator Subcategories"/>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_IND_SUB_ACTIONS}/>
                        <ol>
                            <li>Select an indicator category using the <code>Indicator Category</code> dropdown</li>
                            <li>
                                To add a top-level subcategory under the selected indicator category, click the
                                <code>Add Top Level</code> button
                            </li>
                            <li>
                                To add a child subcategory under another subcategory, click the <code>Add Child</code>
                                button.
                            </li>
                            <li>To edit an indicator subcategory, click the <code><i className="fa fa-pencil"/></code> button</li>
                            <li>
                                To delete an indicator subcategory, click the <code><i className="fa fa-trash"/></code>{" "}
                                button. Note that indicator subcategories in use can not be deleted.
                            </li>
                        </ol>
                        <ArticleImage src={conf06} alt="Indicator Subcategory Actions"/>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_IND_SUB_FIELDS}/>
                        <ArticleImage src={conf07} alt="Indicator Subcategory Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Indicator Category</strong></td>
                                <td>Indicates the indicator category under which this subcategory will be placed.</td>
                            </tr>
                            <tr>
                                <td><strong>Parent Subcategory</strong></td>
                                <td>Indicates the subcategory under which this subcategory will be placed.</td>
                            </tr>
                            <tr>
                                <td><strong>Name</strong></td>
                                <td>Indicator subcategory name</td>
                            </tr>
                            <tr>
                                <td><strong>Order Index</strong></td>
                                <td>Specifies the order in which the indicator subcategory
                                    should appear within its category in XLSX forms, templates, and web forms.</td>
                            </tr>
                            <tr>
                                <td><strong>Active</strong></td>
                                <td>Active subcategories will be included in the XLSX forms,
                                    templates, and web forms. Uncheck this box to deactivate an subcategory. Deactivating
                                    a subcategory will also deactivate all child subcategories and indicators.</td>
                            </tr>
                            <tr>
                                <td><strong>Show Header</strong></td>
                                <td>When enabled, shows the subcategory header in XLSX
                                    forms, templates, and web forms. Unchecking this will hide only the header but
                                    still show the child subcategories and indicators.</td>
                            </tr>
                            <tr>
                                <td><strong>Show Indicator Group Total</strong></td>
                                <td>
                                    <p>
                                        <small><em>This option is applicable to J001 indicator subcategories only.</em></small>
                                    </p>
                                    <p>
                                        When enabled, shows the subcategory totals in excel sheet reports.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Quarterly</strong></td>
                                <td>
                                    <p>
                                        <small><em>This option is applicable to J001 indicator subcategories only.</em></small>
                                    </p>
                                    <p>
                                        Specifies if the subcategory is only required for end-of-quarter
                                        reports. When enabled, indicators under this subcategory will only show on
                                        end-of-quarter reports and report web forms.
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_MONTHLY_IND}/>
                        <p>This section is applicable to the following configuration pages:</p>
                        <ul>
                            <li>Indicators <small><em>(Indicator Reporting Tool (J001))</em></small></li>
                            <li>Dental Health Indicators <small><em>(Dental Health (J003))</em></small></li>
                            <li>Mental Health Indicators <small><em>(Mental Health (J004))</em></small></li>
                        </ul>
                        <p>
                            Indicators are the actual fields in the Monthly Report indicator forms that
                            must be filled with values.
                        </p>
                        <ArticleImage src={conf08} alt="Indicators"/>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_IND_ACTIONS}/>
                        <ol>
                            <li>Select an indicator category using the <code>Category</code> dropdown</li>
                            <li>Select an indicator subcategory using the <code>Subcategory</code> dropdown</li>
                            <li>Indicators for the selected category and subcategory are listed in the index table.</li>
                            <li>
                                To add an indicator under the selected category and subcategory, click the
                                <code>Add Indicator</code> button.
                            </li>
                            <li>
                                To edit an indicator, click the <code>Edit</code> button.
                            </li>
                            <li>
                                To delete an indicator, click the <code>Delete</code> button. Note that indicators in use
                                can not be deleted.
                            </li>
                        </ol>
                        <ArticleImage src={conf09} alt="Indicator Actions"/>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_IND_FIELDS}/>
                        <ArticleImage src={conf10} alt="Indicator Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Subcategory</strong></td>
                                <td>Indicates the subcategory under which this indicator
                                    will be placed. This value is read-only and is determined by the subcategory selected
                                    in the main screen.</td>
                            </tr>
                            <tr>
                                <td><strong>Indicator</strong></td>
                                <td>Indicator name</td>
                            </tr>
                            <tr>
                                <td><strong>Order Index</strong></td>
                                <td>Specifies the order in which the indicator should appear
                                    within its subcategory in XLSX forms, templates, and web forms.</td>
                            </tr>
                            <tr>
                                <td><strong>Summary Type</strong></td>
                                <td>Specifies the summary type which will be used in the
                                    aggregated excel reports and dashboard data.</td>
                            </tr>
                            <tr>
                                <td><strong>Data Sources</strong></td>
                                <td>
                                    <p>
                                        <small><em>This option is applicable to J001 indicators only.</em></small>
                                    </p>
                                    <p>
                                        You can select the data sources that will be displayed
                                        in XLSX forms, templates, and web forms. To add a data source, select from the
                                        drop-down list, then click <code>Add</code>.
                                    </p>
                                    <p>
                                        To remove a data source, click the <code>Remove</code> button.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Active</strong></td>
                                <td>Active indicators will be included in the XLSX forms,
                                    templates, and web forms. Also, active indicators will require values for report
                                    submission. Uncheck this box to deactivate an indicator.</td>
                            </tr>
                            <tr>
                                <td><strong>Special</strong></td>
                                <td>
                                    <p>
                                        <small><em>This option is applicable to J001 indicators only.</em></small>
                                    </p>
                                    <p>
                                        When enabled, indicator label will be highlighted in green
                                        in XLSX forms, templates, and web forms.
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_MONTHLY_IND_SUM}/>
                        <p>This section is applicable to the following configuration pages:</p>
                        <ul>
                            <li>Indicator Sum Validation <small><em>(Indicator Reporting Tool (J001))</em></small></li>
                        </ul>
                        <p>
                            You can create custom indicator sum validation rules to reduce the number of errors
                            in the report data you receive. You can add a data validation rule using the
                            <code>Add Indicator Sum</code> button.
                        </p>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_IND_SUM_ACTIONS}/>
                        <ol>
                            <li>To add an indicator sum validation rule, click the <code>Add Indicator Sum</code> button.</li>
                            <li>To edit an indicator sum validation rule, click the <code>Edit</code> button.</li>
                            <li>To delete an indicator sum validation rule, click the <code>Delete</code> button.</li>
                        </ol>
                        <ArticleImage src={conf11} alt="Indicator Sum Validation Actions"/>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_IND_SUM_FIELDS}/>
                        <ArticleImage src={conf12} alt="Indicator Sum Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Indicator</strong></td>
                                <td>The primary indicator that will be examined to see if it
                                    equals the sum of the indicator values in the <code>Sum Of</code> list.</td>
                            </tr>
                            <tr>
                                <td><strong>Sum Of</strong></td>
                                <td>
                                    <p>
                                        List of indicators whose sum of values should equal the primary indicator's value
                                    </p>
                                    <p>
                                        To remove an indicator from the list, click the
                                        <code>Remove</code> button.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Active</strong></td>
                                <td>Uncheck to deactivate an indicator sum validation rule.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_MONTHLY_REPORT_OPTS}/>
                        <p>This section is applicable to the following configuration pages:</p>
                        <ul>
                            <li>Data Sources</li>
                            <li>Morbidities</li>
                            <li>Medical Procedures</li>
                            <li>Medications</li>
                            <li>Causes of Death</li>
                        </ul>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_REPORT_OPTS_ACTIONS}/>
                        <ol>
                            <li>To add an option entry, click the <code>Add Option</code> button.</li>
                            <li>To edit an option entry, click the <code>Edit</code> button.</li>
                            <li>To delete an option entry, click the <code>Delete</code> button.</li>
                        </ol>
                        <ArticleImage src={conf13} alt="Monthly Report Options Actions"/>
                        <ArticleTitle level={4} pageDef={H_MONTHLY_REPORT_OPTS_FIELDS}/>
                        <ArticleImage src={conf14} alt="Monthly Report Options Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Option Name (e.g., Data Source)</strong></td>
                                <td>The option name</td>
                            </tr>
                            <tr>
                                <td><strong>Active</strong></td>
                                <td>Active options will be included in the XLSX forms, templates,
                                    and web form annex pages. Active options will require values form report submissions.
                                    Uncheck this box to deactivate the option.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_DS}/>
                        <p>
                            System configuration related to disease surveillance reports
                        </p>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_DS_IND_CAT}/>
                        <p>
                            Indicator categories classify indicators into distinct parent categories in order to organize
                            related indicators. In reports, templates, and web forms, indicator categories are
                            distinguished by a dark blue row color.
                        </p>
                        <ArticleImage src={conf15} alt="Indicator Categories"/>
                        <ArticleTitle level={4} pageDef={H_DS_IND_CAT_ACTIONS}/>
                        <ol>
                            <li>To add an indicator category, click the <code>Add Indicator Category</code> button</li>
                            <li>To edit an indicator category, click the <code>Edit</code> button</li>
                            <li>
                                To delete an indicator category, click the <code>Delete</code> button. Note that indicator
                                categories in use can not be deleted.
                            </li>
                        </ol>
                        <ArticleImage src={conf16} alt="Indicator Category Actions"/>
                        <ArticleTitle level={4} pageDef={H_DS_IND_CAT_FIELDS}/>
                        <ArticleImage src={conf17} alt="Edit Indicator Category"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Indicator Category</strong></td>
                                <td>Indicator category name</td>
                            </tr>
                            <tr>
                                <td><strong>Order Index</strong></td>
                                <td>Specifies the order in which the indicator category
                                    should appear in XLSX forms, templates, and web forms.</td>
                            </tr>
                            <tr>
                                <td><strong>Active</strong></td>
                                <td>Active indicator categories will be included in the XLSX forms,
                                    templates, and web forms. Uncheck this box to deactivate an indicator category.
                                    Deactivating an indicator category will also deactivate all child subcategories and
                                    indicators.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_DS_IND_SUB}/>
                        <p>
                            Indicators can be divided further into distinct subcategories.
                            Indicator subcategories are distinguished in reports, templates,
                            and web forms by a light blue row color.
                        </p>
                        <ArticleImage src={conf18} alt="Indicator Subcategories"/>
                        <ArticleTitle level={4} pageDef={H_DS_IND_SUB_ACTIONS}/>
                        <ol>
                            <li>Select an indicator category using the <code>Indicator Category</code> dropdown</li>
                            <li>
                                To add a top-level subcategory under the selected indicator category, click the
                                <code>Add Top Level</code> button
                            </li>
                            <li>
                                To add a child subcategory under another subcategory, click the <code>Add Child</code>
                                button.
                            </li>
                            <li>To edit an indicator subcategory, click the <code><i className="fa fa-pencil"/></code> button</li>
                            <li>
                                To delete an indicator subcategory, click the <code><i className="fa fa-trash"/></code>{" "}
                                button. Note that indicator subcategories in use can not be deleted.
                            </li>
                        </ol>
                        <ArticleImage src={conf19} alt="Indicator Subcategory Actions"/>
                        <ArticleTitle level={4} pageDef={H_DS_IND_SUB_FIELDS}/>
                        <ArticleImage src={conf20} alt="Indicator Subcategory Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Indicator Category</strong></td>
                                <td>Indicates the indicator category under which this
                                    subcategory will be placed.</td>
                            </tr>
                            <tr>
                                <td><strong>Parent Subcategory</strong></td>
                                <td>Indicates the subcategory under which this subcategory will be placed.</td>
                            </tr>
                            <tr>
                                <td><strong>Name</strong></td>
                                <td>Indicator subcategory name</td>
                            </tr>
                            <tr>
                                <td><strong>Order Index</strong></td>
                                <td>Specifies the order in which the indicator subcategory
                                    should appear within its category in XLSX forms, templates, and web forms.</td>
                            </tr>
                            <tr>
                                <td><strong>Active</strong></td>
                                <td>Active subcategories will be included in the XLSX forms,
                                    templates, and web forms. Uncheck this box to deactivate an subcategory. Deactivating
                                    a subcategory will also deactivate all child subcategories and indicators.</td>
                            </tr>
                            <tr>
                                <td><strong>Show Header</strong></td>
                                <td>When enabled, shows the subcategory header in XLSX
                                    forms, templates, and web forms. Unchecking this will hide only the header but
                                    still show the child subcategories and indicators.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_DS_IND}/>
                        <p>
                            Indicators are the actual fields in the disease surveillance report that
                            must be filled with values.
                        </p>
                        <ArticleImage src={conf21} alt="Indicators"/>
                        <ArticleTitle level={4} pageDef={H_DS_IND_ACTIONS}/>
                        <ol>
                            <li>Select an indicator category using the <code>Category</code> dropdown</li>
                            <li>Select an indicator subcategory using the <code>Subcategory</code> dropdown</li>
                            <li>Indicators for the selected category and subcategory are listed in the index table.</li>
                            <li>
                                To add an indicator under the selected category and subcategory, click the
                                <code>Add Indicator</code> button.
                            </li>
                            <li>
                                To edit an indicator, click the <code>Edit</code> button.
                            </li>
                            <li>
                                To delete an indicator, click the <code>Delete</code> button. Note that indicators in use
                                can not be deleted.
                            </li>
                        </ol>
                        <ArticleImage src={conf22} alt="Indicator Actions"/>
                        <ArticleTitle level={4} pageDef={H_DS_IND_FIELDS}/>
                        <ArticleImage src={conf23} alt="Indicator Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Subcategory</strong></td>
                                <td>Indicates the subcategory under which this indicator
                                    will be placed. This value is read-only and is determined by the subcategory selected
                                    in the main screen.</td>
                            </tr>
                            <tr>
                                <td><strong>Indicator</strong></td>
                                <td>Indicator name</td>
                            </tr>
                            <tr>
                                <td><strong>Order Index</strong></td>
                                <td>Specifies the order in which the indicator should appear
                                    within its subcategory in XLSX forms, templates, and web forms.</td>
                            </tr>
                            <tr>
                                <td><strong>Summary Type</strong></td>
                                <td>Specifies the summary type which will be used in the
                                    aggregated excel reports and dashboard data</td>
                            </tr>
                            <tr>
                                <td><strong>Active</strong></td>
                                <td>Active indicators will be included in the XLSX forms,
                                    templates, and web forms. Also, active indicators will require values for report
                                    submission. Uncheck this box to deactivate an indicator</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="subsection">
                        <ArticleTitle level={3} pageDef={H_DS_DASHBOARD_COMP}/>
                        <p>
                            You can add custom components that will appear in the disease surveillance dashboard.
                        </p>
                        <ArticleTitle level={4} pageDef={H_DS_DASHBOARD_COMP_ACTIONS}/>
                        <ol>
                            <li>To add a custom dashboard component, click the <code>Create Component</code> button.</li>
                            <li>To edit a custom dashboard component, click the <code>Edit</code> button.</li>
                            <li>To delete a custom dashboard component, click the <code>Delete</code> button.</li>
                        </ol>
                        <ArticleImage src={conf24} alt="Custom Dashboard Component Actions"/>
                        <ArticleTitle level={4} pageDef={H_DS_DASHBOARD_COMP_FIELDS}/>
                        <ol>
                            <li>
                                <strong>Indicator</strong> - indicators to add in this dashboard component.
                            </li>
                            <li>
                                To add an indicator, click the <code>Add Indicator</code> button
                            </li>
                            <li>
                                To edit an indicator, click the <code>Edit</code> button
                            </li>
                            <li>
                                To delete an indicator, click the <code>Delete</code> button
                            </li>
                            <li>
                                <strong>Title</strong> - the title that will appear in the dashboard box component
                            </li>
                            <li>
                                <strong>Chart Type</strong> - the chart type that represents the data aggregates from the
                                selected indicators
                            </li>
                            <li>
                                <strong>Dashboard Order Index</strong> - This specifies the position of this dashboard
                                component after all of the default dashboard components
                            </li>
                            <li>
                                <strong>Force Page Break in PDF</strong> - Select <code>Yes</code> to force a page break
                                before this component when exporting the dashboard to PDF.
                            </li>
                            <li>
                                Click <code>Save</code> to save changes to this component.
                            </li>
                            <li>
                                Sample preview of the custom dashboard component as it would appear on the
                                disease surveillance dashboard
                            </li>
                        </ol>
                        <ArticleImage src={conf25} alt="Custom Dashboard Component Actions"/>
                        <ArticleTitle level={4} pageDef={H_DS_DASHBOARD_COMP_IND_FIELDS}/>
                        <ArticleImage src={conf26} alt="Component Indicator Fields"/>
                        <table className="help-table help-table-two-column">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>Indicator Category</strong></td>
                                <td>Filters the list of indicators in the
                                    <code>Indicator</code> dropdown according to indicator category.</td>
                            </tr>
                            <tr>
                                <td><strong>Indicator Subcategory</strong></td>
                                <td>Filters the list of indicators in the
                                    <code>Indicator</code> dropdown according to indicator subcategory</td>
                            </tr>
                            <tr>
                                <td><strong>Indicator</strong></td>
                                <td>The indicator to add in the dashboard component</td>
                            </tr>
                            <tr>
                                <td><strong>Label</strong></td>
                                <td>Label to display for the selected indicator in the dashboard component
                                    table and chart legend.</td>
                            </tr>
                            </tbody>
                        </table>
                    </section>
                </Article>
            </div>
        );
    }
}

Configuration.contents = contents;
Configuration.pageId = P_CONFIG;
export default Configuration;