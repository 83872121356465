import React from "react";
import PropTypes from 'prop-types';
import Modal from "@vallarj/react-adminlte/Modal";
import {CalendarInput} from "@vallarj/react-adminlte/Form";
import {resolveCurrentSystemTime} from "@/actions/globalActions";
import {DiseaseMonitoringReport} from "@/records/DiseaseMonitoringReport";
import {Office} from "@/records/Office";
import {getCurrentUser} from "@/actions/authActions";
import {createCancelToken, postResource} from "@/utilities/jsonapi";
import {notifySuccess} from "@/utilities/notifications";

class PrepareReportModal extends React.Component {
    constructor(props) {
        super(props);

        props.registerOpenModal(this.openModal);

        this.state = {
            isProcessing: false,
            show: false,
            errors: {},
            reportDate: null,
            currentDate: resolveCurrentSystemTime()
        };

        this.prepareCancelToken = createCancelToken();
    }

    componentWillUnmount() {
        this.prepareCancelToken.cancel();
    }

    openModal = () => {
        this.setState({
            show: true
        });
    };

    handleExit = () => {
        this.setState({
            isProcessing: false,
            show: false,
            errors: {},
            reportDate: null,
        });
    };

    handleCloseClick = () => {
        const {isProcessing} = this.state;
        if (!isProcessing) {
            this.setState({
                show: false
            });
        }
    };

    handleReportDateChange = (_, value) => {
        this.setState({
            reportDate: value
        });
    };

    handlePrepareClick = () => {
        const {onPrepareSuccess} = this.props;
        const {reportDate} = this.state;
        const user = getCurrentUser();

        const report = new DiseaseMonitoringReport().mergeUpdate({
            reportDate,
            office: new Office().set('id', user.office.id)
        });

        postResource(report)
            .onSuccess(report => {
                onPrepareSuccess(report.id);
                notifySuccess("Disease Surveillance Reports", "Report successfully prepared");
            })
            .onError(err => {
                if (err.isValidationError) {
                    this.setState({
                        errors: err.errors
                    })
                } else {
                    throw err;
                }
            })
            .execute(this.prepareCancelToken);
    };

    dateValidator = current => {
        const {currentDate} = this.state;
        return current.isSameOrBefore(currentDate, 'day');
    };

    render() {
        const {show, reportDate, errors} = this.state;

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick}>
                <Modal.Header>Prepare Disease Surveillance Report</Modal.Header>
                <Modal.Body>
                    <CalendarInput label="Report Date" name="reportDate" value={reportDate} errors={errors}
                                   onChange={this.handleReportDateChange} isSelectableDate={this.dateValidator}
                                   timePicker={false}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-default pull-left"
                            onClick={this.handleCloseClick}>
                        Cancel
                    </button>
                    <button className="btn btn-primary pull-right"
                            onClick={this.handlePrepareClick}>
                        Prepare
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

PrepareReportModal.propTypes = {
    registerOpenModal: PropTypes.func.isRequired,
    onPrepareSuccess: PropTypes.func.isRequired
};

export default PrepareReportModal;