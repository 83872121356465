import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {config} from "@/config";

const attributes = {
    name: PropTypes.string,
    active: PropTypes.bool
};

const meta = {
    internal: PropTypes.bool,
    inUse: PropTypes.bool
};

export class Medication extends JsonApiRecord(
    'medications',
    `${config.apiBaseUrl}/medications`,
    attributes,
    {},
    {},
    meta
) {
    static get modelName() {
        return 'Medication';
    }
}