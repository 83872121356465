import React from 'react';
import {highlightString} from "@/utilities/highlight-string";
import Tag from "./Tag";
import {OFFICE_TYPE_HQ} from "@/permissions";

class OfficeIndexItem extends React.Component {
    handleEditClick = () => {
        this.props.onEditClick(this.props.office);
    };

    handleDeleteClick = () => {
        this.props.onDeleteClick(this.props.office);
    };

    render() {
        const {office, search} = this.props;

        return (
            <div className="j-offices-item">
                <div>
                    <span>{office.orderIndex}</span>
                </div>
                <div>
                    <span>{highlightString(search, office.name)}</span>
                    <span>{ office.inUse && <Tag type="in-use" label="In Use"/> }</span>
                </div>
                <div>
                    <span className="btn-group pull-right">
                        <button className="btn btn-default btn-xs" onClick={this.handleEditClick}
                                disabled={office.officeType === OFFICE_TYPE_HQ}>
                            <i className="fa fa-pencil margin-r-5"/>Edit
                        </button>
                        <button className="btn btn-default btn-xs" onClick={this.handleDeleteClick}
                                disabled={office.inUse}>
                            <i className="fa fa-trash margin-r-5"/>Delete
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

export default OfficeIndexItem;