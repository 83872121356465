import React from 'react';
import {createCancelToken} from "@/utilities/jsonapi";
import {fetchIndicatorStructure} from "@/utilities/indicator-report";
import Modal from "@vallarj/react-adminlte/Modal";
import PropTypes from "prop-types";
import {DiseaseMonitoringIndicatorCategory} from "@/records/DiseaseMonitoringIndicatorCategory";
import {DiseaseMonitoringIndicatorSubcategory} from "@/records/DiseaseMonitoringIndicatorSubcategory";
import {DiseaseMonitoringIndicator} from "@/records/DiseaseMonitoringIndicator";

class SubmitErrorModal extends React.Component {
    constructor(props) {
        super(props);
        props.registerOpenModal(this.openModal);

        this.state = {
            isLoading: false,
            error: null,
            indicators: null,
            show: false
        };

        this.fetchCancelToken = createCancelToken();
    }

    loadIndicators = () => {
        if (this.state.indicators === null) {
            fetchIndicatorStructure(
                DiseaseMonitoringIndicatorCategory,
                DiseaseMonitoringIndicatorSubcategory,
                DiseaseMonitoringIndicator,
                false,
                this.fetchCancelToken
            ).then(({indicatorMap}) => {
                this.setState({
                    isLoading: false,
                    indicators: indicatorMap
                });
            });
        } else {
            this.setState({isLoading: false});
        }
    };

    openModal = error => {
        this.setState({
            show: true,
            error,
            isLoading: true
        });
        this.loadIndicators();
    };

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
    }

    handleExit = () => {
        this.setState({
            show: false,
            isLoading: false
        });
    };

    handleCloseClick = () => {
        const {isLoading} = this.state;
        if (!isLoading) {
            this.setState({show: false});
        }
    };

    renderIndicatorName = indicator => {
        const subcategory = indicator.subcategory && indicator.subcategory.name;
        return indicator.name + (subcategory ? ` (${subcategory})` : "");
    };

    renderBody = () => {
        const {isLoading, error} = this.state;
        if (!error) {
            return null;
        }

        const missingIds = isLoading ? [] : error.meta.missing;
        const {indicators} = this.state;
        return (
            <div className="j-import-modal-partial">
                <p>Errors were encountered in Disease Surveillance Report:</p>
                <h3>Missing Values</h3>
                <ul>
                    {
                        missingIds.map(i => {
                            const indicator = indicators[i];
                            const name = this.renderIndicatorName(indicator)

                            return <li key={i}>{name}</li>
                        })
                    }
                </ul>
            </div>
        );
    };

    render() {
        const {isLoading, show} = this.state;

        return (
            <Modal show={show} onExit={this.handleExit} onCloseClick={this.handleCloseClick}
                   isLoading={isLoading}>
                <Modal.Header>Submission Errors</Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-default pull-right"
                            onClick={this.handleCloseClick}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

SubmitErrorModal.propTypes = {
    registerOpenModal: PropTypes.func.isRequired
};

export default SubmitErrorModal;