import React from 'react';
import BaseIndicatorSubcategories from "@/screens/Configuration/IndicatorSubcategories";
import {DiseaseMonitoringIndicatorCategory} from "@/records/DiseaseMonitoringIndicatorCategory";
import {DiseaseMonitoringIndicatorSubcategory} from "@/records/DiseaseMonitoringIndicatorSubcategory";

class IndicatorSubcategories extends React.Component {
    render() {
        return (
            <BaseIndicatorSubcategories
                categoryResourceType={DiseaseMonitoringIndicatorCategory}
                subcategoryResourceType={DiseaseMonitoringIndicatorSubcategory}
                title="Disease Surveillance Indicator Subcategories"
                hasShowTotal={false}
                hasQuarterly={false}
            />
        );
    }
}

export default IndicatorSubcategories;