import React from 'react';
import {toast} from "react-toastify";
import ToastMessage from "@/components/ToastMessage";

export function notifyError(title, message) {
    return toast.error(<ToastMessage title={title} message={message}/>, {
        position: "bottom-left"
    });
}

export function notifyFormError(title) {
    return notifyError(title, "Unable to process your request. Please review the marked fields then try again.");
}

export function notifyUnableToProcess(title) {
    return notifyError(title, "Unable to process your request.");
}

export function notifySuccess(title, message) {
    return toast.success(<ToastMessage title={title} message={message}/>, {
        position: "bottom-left"
    });
}

export function notifyItemRequestSuccess(title, subtitle, itemDetails) {
    const messageClass = subtitle && itemDetails ? "app-toast-message-bold" : null;
    return toast.success(
        <ToastMessage title={title}
                      subtitle={subtitle}
                      message={itemDetails}
                      messageClassName={messageClass}/>,
        {
            position: "bottom-left"
        }
    );
}