import React from "react";
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_QUESTIONNAIRE = 'default_55';
const TAG_XRAY = 'default_56';
const TAG_BOTH = 'default_57';

class Presumptives extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            questionnaire: 0,
            pQuestionnaire: 0,
            xray: 0,
            pXray: 0,
            both: 0,
            pBoth: 0
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const questionnaire = getAggregateByTag(TAG_QUESTIONNAIRE);
        const xray = getAggregateByTag(TAG_XRAY);
        const both = getAggregateByTag(TAG_BOTH);

        const total = questionnaire.reportTotal + xray.reportTotal + both.reportTotal;

        this.setState({
            questionnaire: questionnaire.reportTotal,
            pQuestionnaire: pctTotalOver(questionnaire.reportTotal, total),
            xray: xray.reportTotal,
            pXray: pctTotalOver(xray.reportTotal, total),
            both: both.reportTotal,
            pBoth: pctTotalOver(both.reportTotal, total)
        });
    };

    render() {
        const {
            questionnaire, pQuestionnaire,
            xray, pXray, both, pBoth
        } = this.state;

        return (
            <div className="j-dash-tabular-group single-row j-dash-presumptives-table">
                <h4>Presumptives by Screening Tools</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Presumptive through Questionnaire Only</th>
                        <th>Presumptive through X-Ray Only</th>
                        <th>Presumptive in Both Questionnaire and X-Ray</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{questionnaire}</td>
                        <td>{xray}</td>
                        <td>{both}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>{pQuestionnaire}%</td>
                        <td>{pXray}%</td>
                        <td>{pBoth}%</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}

export default withAggregateTagMap(Presumptives);