import React from 'react';
import PropTypes from 'prop-types';

import Box from "@vallarj/react-adminlte/Box";
import moment from "moment";
import {noop} from "@/utilities/noop";
import MortalityRegistryModal from "@/screens/JailLevelReports/JailLevelReportEntry/MortalityRegistryModal";
import ImportModal from "@/components/ImportModal";
import {processMortalityWorksheet} from "@/screens/JailLevelReports/JailLevelReportEntry/utilities";

class DeathRecordItem extends React.Component {
    handleEditClick = () => {
        const {id, item, onEditClick} = this.props;
        onEditClick(id, item);
    };

    handleDeleteClick = () => {
        const {id, item, onDeleteClick} = this.props;
        onDeleteClick(id, item);
    };

    render() {
        const {item, isEditMode} = this.props;
        const dateOfDeath = moment(item['dateOfDeath'], 'YYYY-MM-DD');
        const sex = item['sex'] === 'male' ? 'M' : 'F';

        return (
            <div className="j-mortality-registry-item">
                <span>{item.name}</span>
                <span>{item.age}</span>
                <span>{sex}</span>
                <span>{item['immediateCause']}</span>
                <span>{item['antecedentCause']}</span>
                <span>{item['underlyingCause']}</span>
                <span>{dateOfDeath.format("MMMM D, Y")}</span>
                <span>{item['criminalOffense']}</span>
                {
                    isEditMode &&
                    <span>
                        <span className="btn-group">
                            <button className="btn btn-default btn-xs" onClick={this.handleEditClick}>
                                <i className="fa fa-pencil"/>
                            </button>
                            <button className="btn btn-default btn-xs" onClick={this.handleDeleteClick}>
                                <i className="fa fa-trash"/>
                            </button>
                        </span>
                    </span>
                }
            </div>
        );
    }
}

class MortalityRegistry extends React.Component {
    constructor(props) {
        super(props);

        this.openEntryModal = noop;
        this.openImportModal = noop;
    }

    registerOpenEntryModal = openModal => {
        this.openEntryModal = openModal;
    };

    registerOpenImportModal = openModal => {
        this.openImportModal = openModal;
    };

    handleImportClick = () => {
        this.openImportModal();
    };

    handleAddEntryClick = () => {
        this.openEntryModal('create', null, {});
    };

    handleEditClick = (id, item) => {
        this.openEntryModal('edit', id, item);
    };

    handleDeleteClick = (id, item) => {
        this.openEntryModal('delete', id, item);
    };

    handleSaveClick = () => {
        this.props.onSaveClick('deathRecords');
    };

    processWorkbook = workbook => {
        const {onDeathRecordsReplace} = this.props;
        return processMortalityWorksheet(workbook)
            .then(({items, errors}) => {
                if (errors.length === 0) {
                    onDeathRecordsReplace(items);
                    if (items.length === 0) {
                        return {status: "empty"};
                    } else {
                        return {status: "success"};
                    }
                } else {
                    onDeathRecordsReplace(items);
                    return {
                        status: "partial-invalid",
                        errors
                    };
                }
            });
    };

    render() {
        const {isLoading: entryIsLoading, deathRecords, deathRecordsChanged, isEditMode} = this.props;
        const containerClass = "j-mortality-registry" + (isEditMode ? "" : " view-mode")

        return (<>
            <MortalityRegistryModal registerOpenModal={this.registerOpenEntryModal}
                                    onAddItem={this.props.onAddItem}
                                    onEditItem={this.props.onEditItem}
                                    onDeleteItem={this.props.onDeleteItem}/>
            <ImportModal header="Import Mortality Registry (J009)" processWorkbook={this.processWorkbook}
                         registerOpenModal={this.registerOpenImportModal}/>
            <Box theme='box-primary' isLoading={entryIsLoading}>
                <Box.Header title="Mortality Registry (J009)"/>
                <Box.Body>
                    <div className={containerClass}>
                        <div className="j-mortality-registry-header">
                            <span>Name</span>
                            <span>Age</span>
                            <span>Sex</span>
                            <span>Immediate Cause</span>
                            <span>Antecedent Cause</span>
                            <span>Underlying Cause</span>
                            <span>Date of Death</span>
                            <span>Criminal Offense</span>
                        </div>
                        {
                            Object.keys(deathRecords).map(id => (
                                <DeathRecordItem key={id} id={id} item={deathRecords[id]}
                                                 onEditClick={this.handleEditClick}
                                                 onDeleteClick={this.handleDeleteClick}
                                                 isEditMode={isEditMode}/>
                            ))
                        }
                    </div>
                </Box.Body>
                {
                    isEditMode &&
                    <Box.Footer>
                        <div className="pull-right">
                            <button className="btn btn-default margin-r-5"
                                    onClick={this.props.onRevertClick}
                                    disabled={!deathRecordsChanged || entryIsLoading}>
                                <i className="fa fa-undo margin-r-5"/>Revert
                            </button>
                            <button className="btn btn-default margin-r-5"
                                    onClick={this.handleImportClick}>
                                <i className="fa fa-upload margin-r-5"/>Import
                            </button>
                            <button className="btn btn-default margin-r-5"
                                    onClick={this.handleAddEntryClick}>
                                <i className="fa fa-plus-circle margin-r-5"/>Add Entry
                            </button>
                            <button className="btn btn-primary" onClick={this.handleSaveClick}
                                    disabled={!deathRecordsChanged || entryIsLoading}>
                                {
                                    entryIsLoading ?
                                        <i className="fa fa-spinner fa-spin margin-r-5"/> :
                                        <i className="fa fa-save margin-r-5"/>
                                }Save
                            </button>
                        </div>
                    </Box.Footer>
                }
            </Box>
        </>);
    }
}

MortalityRegistry.propTypes = {
    onAddItem: PropTypes.func.isRequired,
    onEditItem: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired,
    onRevertClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onDeathRecordsReplace: PropTypes.func.isRequired
};

export default MortalityRegistry