import React from "react";
import PropTypes from 'prop-types';
import FocusAwareNumberInput from "@/components/FocusAwareNumberInput";

class IndicatorReportItem extends React.Component {
    handleMaleChange = ({floatValue}) => {
        const {item, onIndicatorChange} = this.props;
        onIndicatorChange(item.id, 'male', floatValue);
    }

    handleFemaleChange = ({floatValue}) => {
        const {item, onIndicatorChange} = this.props;
        onIndicatorChange(item.id, 'female', floatValue);
    }

    render() {
        const {
            item, isEditMode, notifyFocusChange,
            getPosition, currentFocus,
            hasDataSource
        } = this.props;
        const {name, special, dataSources} = item;

        let value = this.props.value || {};
        const {male, female} = value;
        const incomplete = male == null || female == null;

        const classNamePrefix = (hasDataSource ? 'j-indicator-report' : 'j-base-indicator-report');
        const className = `${classNamePrefix}-group-indicator` + (special ? " special" : "")
            + (incomplete ? " incomplete" : "");
        return (
            <div className={className}>
                <span>{name}</span>
                <span><FocusAwareNumberInput value={male} onValueChange={this.handleMaleChange}
                                             notifyFocusChange={notifyFocusChange}
                                             getPosition={getPosition} currentFocus={currentFocus}
                                             disabled={!isEditMode}/></span>
                <span><FocusAwareNumberInput value={female} onValueChange={this.handleFemaleChange}
                                             notifyFocusChange={notifyFocusChange}
                                             getPosition={getPosition} currentFocus={currentFocus}
                                             disabled={!isEditMode}/></span>
                <span>{(male || 0) + (female || 0)}</span>
                {hasDataSource && <span>{dataSources}</span>}
            </div>
        );
    }
}

IndicatorReportItem.propTypes = {
    item: PropTypes.object.isRequired,
    onIndicatorChange: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    notifyFocusChange: PropTypes.func.isRequired,
    getPosition: PropTypes.func.isRequired,
    currentFocus: PropTypes.number,
    value: PropTypes.object,
    hasDataSource: PropTypes.bool.isRequired,
};

export default IndicatorReportItem;