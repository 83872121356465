import React from 'react';
import {withAggregateTagMap} from "@/screens/Dashboard/utilities";
import {pctTotalOver} from "@/utilities/dashboard";

const TAG_JAIL_POPULATION = "default_2";
const TAG_IDEAL_CAP = "default_1";
const TAG_DETAINEES = "default_3";
const TAG_SENTENCED = "default_4";
const TAG_INSULAR = "default_5";
const TAG_COMMITTED = "default_6";
const TAG_RELEASED = "default_7";

class PopulationMetrics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jailPopulation: 0,
            excessPopulation: 0,
            pDetainees: 0,
            pSentenced: 0,
            pInsular: 0,
            commit: 0,
            released: 0,
        };
    }

    onAggregatesLoad = () => {
        const {getAggregateByTag} = this.props;

        const jailPop = getAggregateByTag(TAG_JAIL_POPULATION);
        const ideal = getAggregateByTag(TAG_IDEAL_CAP);
        const detainees = getAggregateByTag(TAG_DETAINEES);
        const sentenced = getAggregateByTag(TAG_SENTENCED);
        const insular = getAggregateByTag(TAG_INSULAR);
        const committed = getAggregateByTag(TAG_COMMITTED);
        const released = getAggregateByTag(TAG_RELEASED);

        this.setState({
            jailPopulation: jailPop.reportTotal,
            excessPopulation: jailPop.reportTotal - ideal.reportTotal,
            pDetainees: pctTotalOver(detainees.reportTotal, jailPop.reportTotal),
            pSentenced: pctTotalOver(sentenced.reportTotal, jailPop.reportTotal),
            pInsular: pctTotalOver(insular.reportTotal, jailPop.reportTotal),
            commit: 1,
            released: Math.round(committed.reportTotal === 0 ? 0 : released.reportTotal / committed.reportTotal)
        });
    };

    render() {
        const {
            jailPopulation, excessPopulation, pDetainees, pSentenced,
            pInsular, commit, released
        } = this.state;

        return (
            <div className="j-dash-tabular-group single-row j-dash-who-table">
                <table>
                    <thead>
                    <tr>
                        <th>Average Monthly Population</th>
                        <th>Excess Population from Actual Capacity</th>
                        <th>Proportion of Detainees</th>
                        <th>Proportion of Sentenced</th>
                        <th>Proportion of Insular</th>
                        <th>Average</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{jailPopulation}</td>
                        <td>{excessPopulation}</td>
                        <td>{pDetainees}%</td>
                        <td>{pSentenced}%</td>
                        <td>{pInsular}%</td>
                        <td>
                            <span>{commit}</span>
                            <span> Commit per </span>
                            <span>{released}</span>
                            <span> Released</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withAggregateTagMap(PopulationMetrics);